import React from "react";
import styled from "styled-components";

const SearchInputWrapper = styled.input`
  border-radius: 10px;
  border: 1px solid var(--orange);
  padding: 0.2rem 0.5rem;
  margin-left: 0.2rem;
  max-width: 45%;
`;
// padding-right: 1.5rem;
// > button {
//   position: absolute;
//   right: 0.5rem;
//   border: 0;
//   background: transparent;
//   font-size: 1.5rem;
// }

const SearchInput = ({ handleSearchTerm, placeholder, value }) => (
  <SearchInputWrapper
    type="search"
    placeholder={placeholder}
    onChange={handleSearchTerm}
    value={value}
  />
);

export default SearchInput;
