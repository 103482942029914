import React, { Component } from "react";
import { connect } from "react-redux";
import "./InviteList.css";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Check, Close } from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import CloseCircleIcon from "@material-ui/icons/CancelOutlined";
import Axios from "axios";
import { hostApi, IDEATOR } from "../../constants";
import { NotificationManager } from "react-notifications";
import { getInvitations, sendRWOI } from "../../store/actions/actions";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import firebase from "../../constants";
import Typography from "@material-ui/core/Typography";



class InviteList extends Component {
  respondToInvite = (action, inviteID, firebaseId) => {
    const { getInvitations, profileID, updateData, userType, cookies, jwtToken } = this.props;
    const _userType = userType || cookies.get("user_type");
    if (action === "approve") {
      let myFirebaseId = firebase.auth().currentUser.uid;
      sendRWOI(myFirebaseId, firebaseId);
    }
    Axios.patch(`${hostApi}/collaborations/invitation/${action}/${inviteID}`, null, {
      headers:{
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then(
        // success
        ({ data, status }) => {
          if (status === 200) {
            NotificationManager.success("Success", `Invite (${action})`, 3000);
            // console.log("INVITE LIST:::", _userType);
            getInvitations(jwtToken, profileID, _userType === IDEATOR? "ideator": "collaborator", status => {
              if (status !== 200) {
              }
            });
            updateData(profileID);
          } else {
            NotificationManager.error(
              "Error",
              "Approve/Reject API Error",
              3000
            );
          }
        }
      )
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {

    if(
      prevProps.invitationData !== this.props.invitationData
    ) {
      //console.log("NICK", prevProps.invitationData, this.props.invitationData);
    }
  }

  render() {
    const { invitationData, jwtToken, navigateToIdea } = this.props;
    const _invitationData = invitationData.filter(
      item => item.istatus === "Pending"
    );

    //console.log("INVITE ///////// FROM IDEA", _invitationData);

    return _invitationData.length === 0 ? (
      <div>
        <Typography variant="Subheading" color="inherit">
          Keep a look out. Invitations to collaborate on ideas will be shown here. You can accept&nbsp;(<IconButtonComponent  type={"approve"} iconStyle={"iconDisabled"} />) or reject&nbsp;(<IconButtonComponent  type={"reject"} iconStyle={"iconDisabled"} />) the request.
        </Typography>
       {/* Keep a look out. Invitations to collaborate on ideas will be shown here. You can view the idea (icon) and accept (icon) or reject (icon) the request. */}
      </div>
    ) : (
      <div className="invite-list__wrapper">
        <table className="invite-list">
          <thead>
            <tr className="invite-list__header">
              <td>Idea Name</td>
              <td>Status</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {_invitationData.map(invite => {
              return (
                <tr key={invite.id}>
                  <td className="invite-list__nameField">
                    <div className="invite-list__name">{invite.ideaName}</div>
                    <div className="invite-list__id">#{invite.ideaId}</div>
                  </td>
                  <td>
                    <div className="invite-list__status">{invite.istatus}</div>
                  </td>
                  <td className="invite-list__expand">
                  <IconButtonComponent 
                      type={"approve"}
                      toolTip={true}
                      title={`Approve`}
                      iconStyle={"iconDefault iconCodeGreen iconPadRight"}
                      link={() => this.respondToInvite("approve", invite.id, invite.firebaseId)}
                    />
                    <IconButtonComponent 
                      type={"reject"}
                      toolTip={true}
                      title={"Reject"}
                      iconStyle={"iconDefault iconWarning"}
                      link={() => this.respondToInvite("reject", invite.id, invite.firebaseId)}
                    />

                    {/* <IconButtonComponent 
                      type={"profile"}
                      toolTip={true}
                      title={`View ${invite.ideaId}`}
                      iconStyle={"iconAction"}
                      link={() => navigateToIdea(invite.ideaId, "PREVIEW")}
                    /> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  emailAddress: state.emailAddress,
  userType: state.userType,
  profileID: state.profileID,
  token: state.jwtToken
});

const mapDispatchToProps = dispatch => ({
  getInvitations: (jwtToken, profileID, filter, callback) => {
    getInvitations(jwtToken, profileID, filter, callback, dispatch);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteList);
