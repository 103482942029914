import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { hostApi } from "../../constants";
import TextField from "@material-ui/core/TextField";
import techImage from "../../static/technology.jpeg";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Paper from "@material-ui/core/Paper";
import { Public } from "@material-ui/icons";

import axios from "axios";
import { CardHeader } from "@material-ui/core";
import logo from "../../static/Sunrise_white.png";
import FormHeader from "../FormHeader/FormHeader";
import { NotificationManager } from "react-notifications";


const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  bgimage: {
    backgroundColor: fade(theme.palette.secondary.dark, 0.2),
    backgroundImage: `url(${techImage})`
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
    marginTop: theme.spacing.unit * 2
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

class PublishIdea extends React.Component {
  state = {
    open: false,
    secret: false
  };

  componentDidMount() {
    const { data } = this.props;

    this.setState({
      synopsis: data.ideaDescription,
      collabSkillsRequired: "none"
    });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handlePublic = () => {
    const { jwtToken } = this.props;
    axios.post(`${hostApi}/changeIdeaStatus`, {
      ideaUniqueSignature: this.props.data.ideaId,
      action: 2
    },
    {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      },
    });
  };

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.checked
      },
      this.handlePublic()
    );
  };

  handleChangeData = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = () => {
    const { jwtToken } = this.props;
    console.log("handleSubmit", this.state);
    this.handlePublic(); 
    axios
      .post(`${hostApi}/listIdeaForCollaboration`, {
        ideaUniqueSignature: this.props.data.ideaId,
        collabSynopsis: this.state.synopsis,
        collabSkillsRequired: this.state.collabSkillsRequired
      },
      {
        headers: {
          'Authorization': `Bearer ${jwtToken}`
        },
      })
      .then(response => {
        NotificationManager.success(
          `Idea #${this.props.data.ideaId} is now Searchable`,
          "Idea Searchable",
          4000
        );
      });
    this.props.onClose();
    console.log("Idea Updated");
  };

  render() {
    const { fullScreen, open, onClose, data, classes, action, jwtToken } = this.props;

    //console.log("MORE data", data);

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Paper>
            <FormHeader>
              <CardHeader
                avatar={<Public style={{ fontSize: 50 }} color="primary" />}
                title={
                  <Typography color="default" variant="title">
                    Make Idea Searchable
                  </Typography>
                }
                subheader={
                  <>
                  <Typography color="default">
                    Make your idea searchable for the SPRXS community to provide
                    you with feedback. You can edit the fields below to share as
                    much or as little as you wish.
                  </Typography>
                  <Typography color="error">
                   This information will be visible to all SPRXS users through the search ideas page. Be careful not to give away any secret information in your synopsis!
                </Typography>
                </>
                }
              />
            </FormHeader>

            <DialogTitle id="responsive-dialog-title">
              {`Idea #${data.ideaId}`} <br />
              {/* <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={this.state.secret}
                    onChange={this.handleChange("secret")}
                    value="secret"
                  />
                }
                label="Secret"
              /> */}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <form
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    disabled={this.state.secret}
                    id="outlined-name"
                    label="Idea Name"
                    className={classes.textField}
                    value={data.name}
                    defaultValue={data.ideaName}
                    onChange={this.handleChangeData("name")}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    disabled={this.state.secret}
                    id="outlined-multiline-flexible"
                    label="Idea Synopsis"
                    multiline
                    rowsMax="6"
                    rows="6"
                    value={data.synopsis}
                    defaultValue={data.ideaDescription}
                    onChange={this.handleChangeData("synopsis")}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    disabled={this.state.secret}
                    id="outlined-multiline-flexible"
                    label="Collaboration Skills Required"
                    multiline
                    rowsMax="3"
                    rows="3"
                    value={data.skills}
                    defaultValue={data.collabSkillsRequired}
                    onChange={this.handleChangeData("collabSkillsRequired")}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </form>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={this.state.secret}
                onClick={this.handleSubmit}
                color="secondary"
                autoFocus
              >
                Publish
              </Button>
            </DialogActions>
          </Paper>
        </Dialog>
      </div>
    );
  }
}

PublishIdea.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    token: state.jwtToken
  };
};

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(connect(mapStateToProps)(PublishIdea))
);
