import React, { Component } from "react";
import * as Styled from "./DashboardIdeator.style";
import { skillList, collaboratorList } from "./DashboardIdeator.data";
import SearchInput from "../../components/SearchInput";
import Fuse from "fuse.js";

class DashboardIdeator extends Component {
  state = {
    skillSearchTerm: ""
  };

  updateSkillSearchTerm = skill => {
    this.setState({
      skillSearchTerm: skill
    });
  };

  handleSkillSearchTerm = e => {
    this.updateSkillSearchTerm(e.target.value);
  };

  getCollaboratorList = () => {
    const { skillSearchTerm } = this.state;

    // filter ideas by search term
    if (skillSearchTerm.length) {
      const searchSettings = {
        keys: ["skill", "about"]
      };
      const searchResult = new Fuse(collaboratorList, searchSettings);
      return searchResult.search(skillSearchTerm);
    }

    return collaboratorList;
  };

  renderSkillList = () => {
    return this.state.skillSearchTerm
      ? this.getCollaboratorList().map(
          ({ about, skill, name: { first, last }, picture }) => {
            return (
              <Styled.CollaboratorCard>
                <Styled.CollaboratorDisplayNameWrapper>
                  <Styled.CollaboratorAvatar
                    style={{ backgroundImage: `url(${picture})` }}
                  />
                  <Styled.CollaboratorDisplayName>
                    {first} {last}
                  </Styled.CollaboratorDisplayName>
                </Styled.CollaboratorDisplayNameWrapper>
                <div>
                  <img
                    src={picture}
                    alt={`Image of: ${first} ${last}`}
                    width="200"
                    height="80"
                  />
                </div>
                <div>{skill}</div>
                <div>{about}</div>
              </Styled.CollaboratorCard>
            );
          }
        )
      : skillList.map(({ skillName, skillIcon }) => {
          return (
            <Styled.SkillCardStyled
              onClick={() => this.updateSkillSearchTerm(skillName)}
            >
              <Styled.SkillTitleStyled className={`sprxs-${skillIcon}`} />
              <div>{skillName}</div>
            </Styled.SkillCardStyled>
          );
        });
  };

  render() {
    const myDashboardIdeatorPropList = {
      placeholder: "Search skills",
      value: this.state.skillSearchTerm,
      handleSearchTerm: this.handleSkillSearchTerm
    };

    return (
      <Styled.SkillCardWrapperStyled>
        <Styled.SkillCardTitleStyled>
          <span>Collaborator Market</span>
          <SearchInput {...myDashboardIdeatorPropList} />
        </Styled.SkillCardTitleStyled>
        <Styled.SkillCardBodyStyled>
          <Styled.DashboardIdeatorStyled
            singleLine={!!this.state.skillSearchTerm}
          >
            {this.renderSkillList()}
          </Styled.DashboardIdeatorStyled>
        </Styled.SkillCardBodyStyled>
      </Styled.SkillCardWrapperStyled>
    );
  }
}

export default DashboardIdeator;
