import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import { Send } from "@material-ui/icons";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  fullButton: {
    display: "block",
    alignItems: "center"
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class ProgressButton extends React.Component {
  state = {
    loading: false,
    success: false
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearTimeout(this.timer2);
  }

  handleButtonClick = e => {
    if (!this.state.loading) {
      if (this.state.success) {
        this.props.closeModal();
      } else {
        this.setState(
          {
            success: false,
            loading: true
          },
          () => {
            this.timer = setTimeout(() => {
              this.setState({
                loading: false,
                success: true
              });

              this.timer2 = setTimeout(() => {
                this.props.submitHandler(e);
              }, 500);
            }, 2000);
          }
        );
      }
    }
  };

  render() {
    const { loading, success } = this.state;
    const { classes, circle, fullwidth, error } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: success

    });

    const rootClassname = classNames({
      [classes.root]: !fullwidth,
      [classes.fullButton]: fullwidth

    });


    return (
      <div className={rootClassname}>
        {circle && <div className={classes.wrapper}>
          <Button
            variant="fab"
            color="secondary"
            className={buttonClassname}
            onClick={this.handleButtonClick}
          >
            {success ? <CheckIcon /> : <Send />}
          </Button>
          {loading && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>}
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="secondary"
            className={buttonClassname}
            disabled={loading}
            onClick={this.handleButtonClick}
            fullWidth={fullwidth}
          >
            {success ? (error ? "Error" : "Success") : "Submit"}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>
    );
  }
}

ProgressButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProgressButton);
