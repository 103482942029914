import React, { Component } from "react";
import { connect } from "react-redux";
import "./MyWallet.css";
import { IDEATOR } from "../../constants";
import { withCookies } from "react-cookie";
import { Help } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { launchAlert } from "../../store/actions/actions";

class MyWalletCollab extends Component {
  state = {
    collaboratorList: [],
  };

  renderProgressBar = () => {

    /*
      {
          "total_equity_issued": "15",
          "total_equity_for_all_ideas": "300",
          "total_equity_available_to_offer": "275",
          "total_equity_pending": "10"
      }
    */

    /* 
    console.log ("MyWallet.js Line 26");
    console.log (this.props.myWallet);
    */

    // 2019.03.12 / Fixed by KN

    const { numberOfIdeas, userType, cookies, showHelp } = this.props;
    const _userType = userType || cookies.get("user_type");
    const middlewareActive = _userType === IDEATOR ? true : false;
    let myWallet = [];

    if (middlewareActive) {
      myWallet.push({
        barChartTitle: "Total Equity Issued to Collaborators",
        progressValue: this.props.myWallet.total_equity_issued,
        totalValue: this.props.myWallet.total_equity_for_all_ideas
      });
      myWallet.push({
        barChartTitle: "Total Equity Available to Issue",
        progressValue: this.props.myWallet.total_equity_available_to_offer,
        totalValue: this.props.myWallet.total_equity_for_all_ideas
      });
      myWallet.push({
        barChartTitle: "Total Equity Pending Milestones",
        progressValue: this.props.myWallet.total_equity_pending,
        totalValue: this.props.myWallet.total_equity_available_to_offer
      });
    } else {
      myWallet.push(this.props.myWallet);
    }

    /*
    if (middlewareActive) {
      myWallet.push({
        barChartTitle: "Total Tokens",
        progressValue: this.props.myWallet[0],
        totalValue: numberOfIdeas * 100
      });
      myWallet.push({
        barChartTitle: "Allocated Tokens",
        progressValue: this.props.myWallet[1],
        totalValue: numberOfIdeas * 100
      });
      myWallet.push({
        barChartTitle: "Pending Tokens",
        progressValue: this.props.myWallet[2],
        totalValue: numberOfIdeas * 100
      });
    } else {
      myWallet.push(this.props.myWallet);
    }
    */

    return myWallet.map(({ barChartTitle, progressValue, totalValue }, key) => (
      <div key={key}>
        <div className="wallet__title">
          {barChartTitle}
          {Boolean(showHelp) && (
            <span>
              <IconButton
                aria-label="Help"
                onClick={() => {
                  this.props.launchAlert({
                    intent: "help",
                    title: "Wallet",
                    message:
                      "This dashboard tells you how many equity tokens you have.  Every idea has a potential 100 tokens that the owner can distribute."
                  });
                }}
              >
                <Help fontSize="small" />
              </IconButton>
            </span>
          )}
        </div>
        <div className="wallet__progress-bar-background">
          <div
            className="wallet__progress-bar-foreground"
            style={{
              width: Math.ceil((progressValue * 100) / totalValue) + "%"
            }}
          >
            <div className="wallet__progress-text">
              {progressValue}/{totalValue}
          </div>
          </div>
           
        </div>
        
      </div>
    ));
  };

  render() {
    return <div className="wallet__wrapper">{this.renderProgressBar()}</div>;
  }
}

const mapStateToProps = state => ({
  userType: state.userType
});

const mapDispatchToProps = dispatch => ({
  launchAlert: payload => dispatch(launchAlert(payload))
});

export default withCookies(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyWalletCollab)
);