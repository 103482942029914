import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import {
  CLOSE_MODAL,
  SEARCH_CRITERIA,
  storeSearchResults
} from "../../store/actions/actions";
import "./SearchModal.css";
import "../../App.css";
import { MdHelpOutline, MdCheck, MdClear } from "react-icons/md";
import axios from "axios";
import { hostApi } from "../../constants";
import { withCookies, Cookies } from "react-cookie";

class ReactModal extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      modal: false,
      validData: false,

      searchName: "",
      searchDesc: "",
      searchID: ""
    };

    this.toggle = this.toggle.bind(this);

    //console.log("this.props", this);
    //console.log("this.props.className", this.props.className);
  }

  // componentDidUpdate(){
  //   if( this.props.showSearchModal ) {
  //     this.props.stopBusy();
  //   }
  // }

  componentWillMount() {
    //console.log("SearchModal: componentWillMount");
    this.setState({
      validData: false,
      searchResults: "",
      searchName: "",
      searchID: ""
    });
  }

  toggle(e) {
    e.preventDefault();
    //console.log("toggle modal");
    this.setState({
      modal: false,
      validData: false
    });

    this.props.closeModal();
    //this.props.history.push(`/searchresults`);
  }

  handleName = event => {
    //console.log("handleName", event.target.value);
    this.setState({ validData: true, searchName: event.target.value });
  };

  handleDesc = event => {
    //console.log("handleDesc", event.target.value);
    this.setState({ validData: true, searchDesc: event.target.value });
  };

  handleID = event => {
    //console.log("handleID", event.target.value);
    this.setState({ validData: true, searchID: event.target.value });
  };

  handleSubmit = event => {
    const { cookies, token } = this.props;
    event.preventDefault();
    //console.log("handleSubmit", this.props.emailAddress);
    //console.log("handleSubmit", cookies.get("emailAddress"));

    if (this.state.validData) {
      this.props.dispatchSearchCriteria({
        searchName: this.state.searchName,
        searchDesc: this.state.searchDesc,
        searchID: this.state.searchID
      });

      axios
        .post(`${hostApi}/myIdeas`, {
          lkp_email: this.props.emailAddress || cookies.get("emailAddress"),
          idea_name: this.state.searchName || "",
          idea_description: this.state.searchDesc || ""
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          //console.log("axios response1", response.data);
          this.props.storeSearchResults(response.data);
          this.props.history.push(`/searchresults`);
        });
    } else {
      this.setState({
        validData: false,
        searchResults: "",
        searchName: "",
        searchID: ""
      });
      this.props.dispatchSearchCriteria({
        searchName: "",
        searchDesc: "",
        searchID: ""
      });

      axios
        .post(`${hostApi}/myIdeas`, {
          lkp_email: this.props.emailAddress || cookies.get("emailAddress"),
          idea_name: this.state.searchName || "",
          idea_description: this.state.searchDesc || ""
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          //console.log("axios response2", response.data);
          this.props.storeSearchResults(response.data);
          this.props.history.push(`/searchresults`);
        });
    }

    axios
      .post(`${hostApi}/myIdeas`, {
        lkp_email: this.props.emailAddress || cookies.get("emailAddress"),
        idea_name: this.state.searchName || "",
        idea_description: this.state.searchDesc || ""
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        //console.log("axios response3", response.data);
        this.props.storeSearchResults(response.data);
        this.props.history.push(`/searchresults`);
      });

    //console.log("history push", this.state.searchName);
    //console.log("this.props.location.pathname", this.props.location.pathname);

    //this.props.history.push(`/searchresults`);

    this.toggle(event);
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.props.showSearchModal} toggle={this.toggle}>
          <ModalHeader
            color="secondary"
            toggle={this.toggle}
            style={{ color: "#000", textAlign: "center" }}
            className={this.props.className}
          >
            {this.props.title}
          </ModalHeader>
          <ModalBody style={{ color: "#000" }}>
            <MdHelpOutline style={{ float: "right" }} onClick={this.toggle} />
            <Form>
              <FormGroup>
                <Label for="Name">Name</Label>
                <Input
                  type="input"
                  name="Name"
                  id="Name"
                  placeholder="e.g. Flux capacitor"
                  value={this.state.searchName}
                  onChange={this.handleName}
                />
              </FormGroup>
              <FormGroup>
                <Label for="Description">Description contains</Label>
                <Input
                  type="input"
                  name="Description"
                  id="Description"
                  placeholder="e.g. timetravel"
                  value={this.state.searchDesc}
                  onChange={this.handleDesc}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ID">I.D.</Label>
                <Input
                  type="input"
                  name="ID"
                  id="ID"
                  placeholder="e.g. 2650"
                  value={this.state.searchID}
                  onChange={this.handleID}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Link
              to="#"
              className="custom-btn custom-btn-blue"
              onClick={this.toggle}
            >
              <MdClear size={30} />
            </Link>
            <Link
              to="#"
              className="custom-btn custom-btn-blue"
              onClick={this.handleSubmit}
            >
              <MdCheck size={30} />
            </Link>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showSearchModal: state.showSearchModal,
    emailAddress: state.emailAddress,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: CLOSE_MODAL }),
    storeSearchResults: payload => dispatch(storeSearchResults(payload)),
    dispatchSearchCriteria: searchCriteria => {
      dispatch({
        type: SEARCH_CRITERIA,
        payload: {
          searchCriteria: searchCriteria
        }
      });
    }
  };
};

export default withRouter(
  withCookies(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ReactModal)
  )
);
