import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class MilestoneConsentAlert extends React.Component {
    render() {
        const { open, onClose, onCancelConsent } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Pending Collaborator's Approval"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Cannot edit / cancel milestone as milestone is pending edit approval from collaborator. A notification has been sent to the collaborator.
            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onCancelConsent}
                            color="primary">
                            Close
                     </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default MilestoneConsentAlert;
