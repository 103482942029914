import React, { Component } from "react";
import PlayIcon from "@material-ui/icons/PlayCircleOutline";

class VideoNavItem extends Component {
  render() {
    return (
      <div className="videoNavWrapper">
        <div
          className="videoLink"
          onClick={this.props.updateVideo.bind(this, this.props.nav.link)}
        >
          <img src={this.props.nav.thumb} width="100%" />
          <div className="playIcon">
            <PlayIcon fontSize={"large"} />
          </div>
        </div>
        <p>{this.props.nav.title}</p>
      </div>
    );
  }
}

export default VideoNavItem;
