import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CircularProgress } from "@material-ui/core";

const styles = theme => ({
  linearColorPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  linearBarColorPrimary: {
    backgroundColor: theme.palette.secondary.main
  },
  spinner: {
    display: "inline-block",
    marginLeft: "50%",
    transform: "translateX(-50%)"
  }
});

const Progress = props => {
  console.log("progress", props);

  return (
    <>
      <div className={props.classes.spinner}>
        <CircularProgress />
      </div>
    </>
  );
};

export default withStyles(styles)(Progress);
