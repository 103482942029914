import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./IconButton.css";
import Tooltip from "@material-ui/core/Tooltip";
import { launchAlert } from "../../store/actions/actions";
//Icons
import {
  Delete,
  Help,
  ChatBubbleOutline,
  CheckCircleOutline,
  CancelOutlined,
  ErrorOutline,
  AccountCircleOutlined,
  AddCircleOutline,
  Edit,
  Public,
  Launch,
  VpnLock,
  SupervisedUserCircle,
  Save,
  AttachFile,
  Image,
  Clear,
  PersonAdd,
  Add,
  Build,
  School,
  Star,
  StarBorder,
  Person
} from "@material-ui/icons";

import TokenIcon from "../../static/tokenIcon.svg";
import TokenIconDark from "../../static/tokenIconDark.svg";

function handleAlert(){
  console.log("DOING SOMETHING");
}

function returnIcon(type) {
  switch (type) {
    case "help":
      return <Help />;
    case "create":
      return <AddCircleOutline />;
    case "approve":
      return <CheckCircleOutline />;
    case "reject":
      return <CancelOutlined />;
    case "launch":
      return <Launch />;
    case "public":
      return <Public />;
    case "locked":
      return <VpnLock />;
    case "edit":
      return <Edit />;
    case "chat":
      return <ChatBubbleOutline />;
    case "token":
      return (
        <img src={TokenIcon} height="24px" width="24px" />
      );
    case "tokenDark":
      return (
        <img src={TokenIconDark} height="24px" width="24px" />
      );
    case "save":
      return <Save />;
    case "delete":
      return <Delete />;
    case "attach":
      return <AttachFile />;
    case "alert":
      return <ErrorOutline />;
    case "collaborate":
      return <SupervisedUserCircle />;
    case "pending":
      return <AccountCircleOutlined />;
    case "image":
      return <Image />;
    case "close":
      return <Clear />;
    case "invite":
      return <PersonAdd />;
    case "build":
      return <Build />
    case "school":
      return <School />;
    case "add":
      return <Add />;
    case "profile":
      return <Person />;
    case "star":
      return <Star />;
    case "starBorder":
      return <StarBorder />;
    default:
      return <Help />;
  }
}

function IconButton(props) {
  return (
    <>
      {props.alert && (
        <div
          className={props.iconStyle}
          onClick={() => {
            props.launchAlert({
              intent: props.intent,
              title: props.alertTitle,
              message: props.message
            });
          }}
        >
          <Tooltip title={props.title}>
            {returnIcon(props.type)}
          </Tooltip>
        </div>
      )}
      {(props.toolTip && props.link) && (
        <div 
          className={props.iconStyle}
          onClick={() => props.link()}>
          <Tooltip title={props.title}>
            {returnIcon(props.type)}
          </Tooltip>
        </div>
      )}
      {(props.toolTip && !props.link && !props.alert) && (
        <div
          className={props.iconStyle}
        >
          <Tooltip title={props.title}>
            {returnIcon(props.type)}
          </Tooltip>
        </div>        
      )}
      {!props.toolTip && (
        <div
          className={props.iconStyle}
        >
          {returnIcon(props.type)}
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  launchAlert: payload => dispatch(launchAlert(payload))
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(IconButton)
);
