import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

const legalId = 4;

function CompPolicy( {handleChange}) {

  return (
    <div className="legalWrapper">
      <h1 className="legalHeader">SPRXS Ideation Competition 2019</h1>
      <div className="legalContent">
        <h3 className="legalSubHead">Competition Rules</h3>
        <p className="legalCopy">
          <ol className="legalNumber">
            <li>
              The promoter is: The Fifth9 Ltd (06423825) whose registered office
              is at 14 Hackwood Lane, Cliddesden, Basingstoke, RG25 2NH
            </li>
            <li>
              The competition is open to applicants aged 18 years or over except
              employees of The Fifth9 Ltd, close relatives of Fifth9 employees,
              or anyone otherwise connected with the organisation or
              adjudication of the competition.
            </li>
            <li>
              There is no entry fee and no purchase necessary to enter this
              competition. However applicants must create the idea on the SPRXS
              website and accept the associated terms and conditions and give us
              permission to contact them about the competition.
            </li>
            <li>
              Route to entry for the competition and details of how to enter are
              via <a href="http://www.sprxs.io">http://www.sprxs.io</a>.
            </li>
            <li>
              Up to 10 novel / new entries will be accepted per person, however
              these must be submitted by the same person via the SPRXS website.
            </li>
            <li>Any entries by Web Robot (BOT) will be disqualified.</li>
            <li>
              Closing date for entry will be 30th April 2020 at 12:00 GMT. After
              this date, the no further entries to the competition will be
              permitted.
            </li>
            <li>
              No responsibility can be accepted for entries not received for
              whatever reason, before or after the closing date.
            </li>
            <li>
              The rules of the competition and how to enter are as follows:<br />Log
              your first idea on the SPRXS website through <a href="http://www.sprxs.io">http://www.sprxs.io</a>.<br />Enter
              the competition via the pop-up prompt that follows you create your
              idea.
            </li>
            <li>
              The promoter reserves the right to cancel or amend the competition
              and these terms and conditions without notice in the event of a
              catastrophe, war, civil or military disturbance, act of God or any
              actual or anticipated breach of any applicable law or regulation
              or any other event outside of the promoter’s control.
            </li>
            <li>
              Any changes to the competition will be notified to entrants as
              soon as possible by the promoter. Such change shall be effective
              immediately upon posting to the webpage.
            </li>
            <li>
              The promoter is not responsible for inaccurate prize details
              supplied to any entrant by any third party connected with this
              competition.
            </li>
            <li>
              The prize is GBP 9,250.00. Where the prize is payable in another
              currency, the exchange of Natwest Bank PLC at the date of award
              will apply.
            </li>
            <li>
              The prize is as stated and no other alternatives will be offered.
              The prize is not transferable.
            </li>
            <li>
              The winner will be chosen by a panel of 3 people to be set up by
              The Fifth9 Limited.
            </li>
            <li>
              The promoter will notify the winner using the contact details
              provided when registering on the SPRXS website. The promoter’s
              decision in respect of all matters to do with the competition will
              be final and no correspondence will be entered into.
            </li>
            <li>
              The winner will be notified by email within 28 days of the closing
              date. If the winner cannot be contacted personally or do not claim
              the prize within 14 days of notification, we reserve the right to
              withdraw the prize from the winner and pick a replacement winner.{" "}
            </li>
            <li>
              By entering this competition, an entrant is indicating his / her
              agreement to be bound by these terms and conditions. As such,
              entry into the competition will be deemed as acceptance of these
              terms and conditions.{" "}
            </li>
            <li>
              The competition and these terms and conditions will be governed by
              English law and any disputes will be subject to the exclusive
              jurisdiction of the courts of England.
            </li>
            <li>
              The winner agrees to the use of his / her name and image in any
              publicity material, as well as their entry. Any personal data
              relating to the winner or any other entrants will be used solely
              in accordance with current UK data protection legislation and will
              not be disclosed to a third party without the entrant’s prior
              consent.
            </li>
            <li>
              You are providing your information to The Fifth9 Limited and not
              to any other party. Any personal data / information provided will be
              used in line with the Privacy&nbsp;Policy found at our
              {" "}<a href="#" className={" "} onClick={(e) => {
               handleChange(e, "privacy");
              }}
            >
                Privacy&nbsp;Policy
              </a>.
            </li>
          </ol>
        </p>
      </div>
    </div>
  );
}

CompPolicy.propTypes = {
  handleChange: PropTypes.func.isRequired
};



export default withStyles(styles)(CompPolicy);
