import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { withCookies, Cookies } from "react-cookie";
import EditIdea from "./EditIdeaModal";
import logo from "../../static/Sunrise_white.png";
import FormHeader from "../FormHeader/FormHeader";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { NotificationManager } from "react-notifications";
import { hostApi, ideaClassification } from "../../constants";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import firebase from "../../constants";
import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 3}px`,
    marginTop: theme.spacing.unit * 2
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginTop: 10
  },
  input: {
    display: "none"
  },
  btnContainer: {
    overflowY: "hidden"
  }
});

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class EditIdeaDialog extends React.Component {
  state = {
    activeStep: 0,
    disableNext: true,
    lkp_idea_cat1: "",
    lkp_idea_cat2: 0,
    lkp_idea_cat3: 0,
    lkp_email: "",
    idea_name: "",
    idea_description: "",
    filename: "",
    fileDetails: [],
    fistname: "",
    lastname: "",
    checkedA: false,
    successNtf: false,
    coverPhoto: "",
    coverPhotoUrl: "",
    loading: false,
    loaded: false
  };

  componentDidMount() {
    const { data, cookies } = this.props;
    this.setState({
      lkp_email: cookies.get("emailAddress"),
      idea_id: data.ideaId,
      idea_name: data.ideaName,
      idea_description: data.ideaDescription,
      lkp_idea_cat1: data.lkpIdeaCat1,
      coverPhoto: data.coverPhoto,
      coverPhotoUrl: data.coverPhotoUrl
    });
  }

  handleIdeaName = event => {
    this.setState({ idea_name: event.target.value });
  };

  handleDesc = event => {
    this.setState({ idea_description: event.target.value });
    if (event.target.value.length > 500) {
      this.setState({ invalidDesc: true });
    } else {
      this.setState({ invalidDesc: false });
    }
  };

  handleCoverPhotoName = event => {
    var that = this;
    var filename = event.target.files[0].name;
    this.setState({
      coverPhoto: filename,
      loading: true
    });
    var uid = firebase.auth().currentUser.uid;
    // Points to the root reference
    var storageRef = firebase.storage().ref();
    // Points to 'All user files'
    var allUserFiles = storageRef
      .child("AllUsersFileData")
      .child(uid)
      .child("coverPhoto")
      .child(filename);
    var file = event.target.files[0];
    allUserFiles
      .put(file)
      .then(function(snapshot) {
        allUserFiles
          .getDownloadURL()
          .then(url => {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.open("GET", url);
            xhr.send();
            that.setState({
              coverPhotoUrl: url,
              loading: false,
              loaded: true
            });
          })
          .catch(error => {
            console.log("Error on getting cover photo url:", error);
          });
      })
      .catch(error => {
        console.log("Error on getting cover photo url:", error);
      });
  };

  handleSubmit = event => {
    event.preventDefault();
    axios
    .post(
      `${hostApi}/editIdea_v2`,
      {
        lkp_email: this.props.emailAddress || this.props.cookies.get("emailAddress"),
        idea_id: this.state.idea_id,
        idea_name: this.state.idea_name,
        lkp_idea_cat1: this.state.lkp_idea_cat1,
        lkp_idea_cat2: this.state.lkp_idea_cat2,
        lkp_idea_cat3: this.state.lkp_idea_cat3,
        idea_description: this.state.idea_description,
        filename: this.state.filename,
        coverPhoto: this.state.coverPhoto,
        coverPhotoUrl: this.state.coverPhotoUrl
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${this.props.cookies.get("bearerToken")}`
        }
      }
    )
      .then(response => {
        //console.log("axios response", response);
        if (response.data.createIdea_response === "FAIL") {
          NotificationManager.error(
            response.data.login_message,
            "Edit Idea Error",
            4000
          );
        } else {
          NotificationManager.success(
            `Idea updated on blockchain with ID ${response.data.idea_ID}`,
            "Edit Idea",
            5000
          );
        }
        //this.toggle(event);
      })
      .catch(error => {
        //console.log("ERROR", error);
        NotificationManager.error("API Error", "Edit Idea Error", 4000);
      });

    this.setState({ successNtf: true });

    this.props.onClose();
  };

  render() {
    const {
      classes,
      fullScreen,
      open,
      onClose,
      cookies,
      data /*pass the card data here*/
    } = this.props;

    return (
      <div>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          fullScreen={fullScreen}
          open={open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <FormHeader>Edit Idea</FormHeader>
          <DialogContent>
            <DialogContentText>{`Idea #${data.ideaId}`}</DialogContentText>
            <DialogContent>
              <form className={classes.container} noValidate autoComplete="off">
              <input
                  accept="image/*"
                  className={classes.input}
                  onChange={this.handleCoverPhotoName}
                  id="contained-button-file"
                  type="file"
                  margin="normal"
                />
                <TextField
                  placeholder=""
                  variant="outlined"
                  fullWidth={false}
                  className={classes.textField}
                  label={
                    this.state.loading ? this.state.coverPhoto : 
                      this.state.loaded ? this.state.coverPhoto : "Upload your idea image"
                    }
                  value={this.state.coverPhoto}
                  inputProps={{
                    "aria-label": "Description"
                  }}
                />
                 <div style={{ display: "inline-block", marginLeft: "0%", marginTop: "20px" }}>
                  {this.state.loading  ?
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={35}
                    color={"#123abc"}
                    loading={this.state.loading}
                  /> : 
                  <label htmlFor="contained-button-file">
                    <IconButtonComponent 
                      type={"attach"}
                      iconStyle={"iconAction"}
                    />
                  </label> 
                  }
                  
                  
                </div>
                <TextField
                  id="select-category"
                  select
                  fullWidth={true}
                  className={classes.textField}
                  value={this.state.lkp_idea_cat1}
                  onChange={this.statehandleCat1}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                >
                  {ideaClassification.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {`${option.label} idea`}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  error={this.state.invalidIdeaName}
                  id="required"
                  label="Idea Name"
                  className={classes.textField}
                  value={this.state.idea_name}
                  onChange={this.handleIdeaName}
                  fullWidth={true}
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  required
                  error={this.state.invalidDesc}
                  id="multiline-flexible"
                  label="Description"
                  multiline
                  rows="6"
                  value={this.state.idea_description}
                  onChange={this.handleDesc}
                  className={classes.textField}
                  fullWidth={true}
                  variant="outlined"
                  margin="normal"
                />
                
              </form>
            </DialogContent>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} color="primary" autoFocus>
              Close
            </Button>
            <Button
              variant="contained"
              disabled={false}
              color="secondary"
              onClick={this.handleSubmit}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditIdeaDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
  // lkpIdeaCat1: PropTypes.number.isRequired,
  // lkpIdeaCat2: PropTypes.number.isRequired,
  // lkpIdeaCat3: PropTypes.number.isRequired
};

// export default withMobileDialog()(EditIdeaDialog);

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(EditIdeaDialog)
);
