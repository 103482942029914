// CORE
import "./App.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Route, Switch, withRouter } from "react-router-dom";

import ReactGA from "react-ga";

import FrontPageV2 from "./containers/LandingPage/FrontPageV2/FrontPage";

import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import {
  IS_AUTHENTICATED,
  dispatchFirebaseUid,
  logOut,
  toggleCardView,
  launchAlert,
  launchTimeout,
  launchSessionWarning,
  saveToken,
  saveCollabID,
  dispatchAuth
} from "./store/actions/actions";

// CONTAINERS
import Layout from "./containers/Layout/Layout";
import FrontPage from "./containers/LandingPage/FrontPage";
import FAQPage from "./containers/LandingPage/FAQPage";
import TechnicalPage from "./containers/LandingPage/TechnicalPage";
import MediaPage from "./containers/LandingPage/MediaPage";
import { NTFTestPage } from "./containers/NTFTestPage/NTFTestPage";
import SearchResultsPage from "./containers/SearchResultsPage/SearchResultsPage";
import Signup from "./containers/Signup/Signup";
import SocialSignup from "./containers/SocialSignup/SocialSignup";
import FbLogin from "./containers/LoginPage/FbLogin";
import LoginPage from "./containers/LoginPage/LoginPage";
import QuickSignup from "./containers/QuickSignup/QuickSignup ";

import MySPRXS from "./containers/MySPRXS/MySPRXS";
import PublicIdeasPage from "./containers/PublicIdeasPage/PublicIdeasPage";
import SearchCovidPage from "./containers/SearchCovidPage/SearchCovidPage";
import Dashboard from "./containers/Dashboard";
import Legal from "./containers/Legal/Legal";
import Error from "./containers/404/ErrorPage";
// NICK 18/02
import LegalModal from "./containers/Legal/LegalModal";
//
import DashboardIdeator from "./containers/DashboardIdeator";
import Marketplace from "./containers/Marketplace/Marketplace";

// COMPONENTS
import SearchModal from "./components/SearchModal/SearchModal";
import NTFTest from "./components/NTFTest/NTFTest";
import EditProfileModal from "./containers/Dashboard/EditProfileModal";
import CollabRequestModal from "./components/CollabRequestModal/CollabRequestModal";
import EnquiryModal from "./components/EnquiriesModal/EnquiryModal";
import RequestForUniqueModal from "./components/RequestForUniqueModal/RequestForUniqueModal";
import CookiesNTF from "./components/CookiesNTF/CookiesNTF";
import Toaster from "./components/Toaster/Toaster";
import CompEnteredModal from "./components/CompEnteredModal/CompEnteredModal";
//import  SocialSigned from "./containers/Signup/SocialSigned"
import NavBar from "./components/NavBar/NavBar";
import HomeNav from "./components/HomeNav/HomeNav";
import SessionHoc from "./hoc/SessionHoc";

// MATERIAL UI
import { withTheme } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// CONTSTANTS
import { firebasePassword } from "./constants";
import fire from "./constants";
import AlertPopup from "./components/AlertPopup/AlertPopup";

import AlertSessionTimeOut from "./components/AlertPopup/AlertSessionTimeout";
import AlertSessionWarning from "./components/AlertPopup/AlertSessionWarning";
import { gaInitialise, gaPageview } from "./utility/google_analytics";
import { fbPixelInit, fbPixelPageView } from "./utility/facebook_pixel";

const UserSession = SessionHoc(Dashboard);

//Initialise Google Analytics
gaInitialise();

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;
    this.state = {
      name: cookies.get("name") || "",
      isAuthenticated: cookies.get("isAuthenticated") || false,
      email: cookies.get("emailAddress") || false,
      fireId: cookies.get("fireId") || false,
      AgreeCookie: cookies.get("AgreeCookie") || false
    };
  }

  componentWillMount() {
    const {
      history,
      cookies,
      token,
      saveToken,
      dispatchAuth,
      collaboratorId
    } = this.props;
    //console.log("App - componentWillMount()", this.props);
    //Check if we have a JWT token.  If not then check for one in the cookies.
    if (token === null) {
      //check the cookies
      let bearerToken = cookies.get("bearerToken");

      //check that the cookie is not null
      if (bearerToken) {
        //then dispatch the action to save it in the reducer
        saveToken(cookies, bearerToken);
      }
    }

    //Check if we have a collaborator ID in redux
    if (collaboratorId === null) {
      //We don't so check the cookies
      let collabID = cookies.get("profile_id");
      let userType = cookies.get("user_type");

      //dispatch it to redux
      if (collabID && userType) {
        dispatchAuth(userType === "IDEATOR" ? "1" : "2", collabID);
      }
    }
  }

  componentDidMount() {
    const { history } = this.props;
    //console.log("App - componentDidMount()", this.props);

    /* ================================================================= */
    /* =                        Analytics                              = */
    /* ================================================================= */
    /**
     * Google Analytics
     */
    history.listen(location => {
      gaPageview(location.pathname);
    });

    /**
     * Fire the GA event here to capture people just visiting the front page.
     */
    gaPageview(history.location.pathname);

    /**
     * Facebook Pixel initialisation for tracking user behaviour.
     */
    fbPixelInit();

    /**
     * Register with Facebook Pixel that the user has viewed the page.
     */
    fbPixelPageView();
    /* ================================================================= */

    if (this.state.isAuthenticated) {
      this.props.dispatchisAuthenticated(this.state.email);
      this.props.dispatchFirebaseUid(this.state.fireId);

      //console.log("this.state.email", this.state.email);

      fire
        .auth()
        .signInWithEmailAndPassword(this.state.email, firebasePassword)
        .then(u => {
          let user = fire.auth().currentUser;
          let auth = fire.auth();
          ////console.log("uid", user.uid);
        })
        .catch(error => {
          //console.log(error);
          NotificationManager.error(error.message, error.code, 4000);
          this.props.logOut(this.props.cookies);
          this.props.history.push("/");
        });
    }
  }

  warnSession = () => {
    this.props.launchSessionWarning();
  };

  endSession = () => {
    this.props.history.push("/");
    this.props.logOut(this.props.cookies);
    this.props.launchTimeout();
  };

  render() {
    const {
      location: { pathname }
    } = this.props;
    const homeLinks = [
      "/",
      "/v2",
      "/media",
      "/faq",
      "/technical",
      "/legal",
      "/legal/privacy",
      "/legal/competition",
      "/login",
      "/signup",
      "/quickSignup",
      "/socialSignup",
      "/error"
    ];

    let theme = null; //FIXME: don't need to do this anyymore. Only needed in index.js
    if (this.props.themeType === true) {
      theme = createMuiTheme({
        palette: {
          type: "light",
          primary: {
            light: "#ff7358",
            main: "#ef8406",
            dark: "#b23820",
            contrastText: "#fff"
          },
          secondary: {
            light: "#0b358e",
            main: "#114DCC",
            dark: "#4070d6",
            contrastText: "#fff"
          }
        },
        typography: {
          // Use the system font instead of the default Roboto font.
          fontFamily: ['"Ubuntu", sans-serif']
        }
      });
    } else {
      theme = createMuiTheme({
        palette: {
          type: "light",
          primary: {
            light: "#ff7358",
            main: "#ef8406",
            dark: "#b23820",
            contrastText: "#fff"
          },
          secondary: {
            light: "#0b358e",
            main: "#114DCC",
            dark: "#4070d6",
            contrastText: "#fff"
          }
        },
        typography: {
          // Use the system font instead of the default Roboto font.
          fontFamily: ['"Ubuntu", sans-serif']
        }
      });
    }

    return (
      <MuiThemeProvider theme={theme}>
        {homeLinks.includes(pathname) && <HomeNav />}
        {!homeLinks.includes(pathname) && (
          <NavBar
            title={"My SPRXS"}
            search
            changeView={this.props.toggleCardView}
          />
        )}
        <div className="">
          <Switch>
            <Route
              exact
              path="/old"
              render={() => <FrontPage cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/"
              render={() => <FrontPageV2 cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/faq"
              render={() => <FAQPage cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/technical"
              render={() => <TechnicalPage cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/legal"
              render={() => (
                <Legal cookies={this.props.cookies} propValue={"terms"} />
              )}
            />
            <Route
              exact
              path="/legal/privacy"
              render={() => (
                <Legal cookies={this.props.cookies} propValue={"privacy"} />
              )}
            />
            <Route
              exact
              path="/legal/competition"
              render={() => (
                <Legal cookies={this.props.cookies} propValue={"comp"} />
              )}
            />
            <Route
              exact
              path="/media"
              render={() => <MediaPage cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/signup"
              render={() => <Signup cookies={this.props.cookies} />}
            />

            <Route
              exact
              path="/login"
              render={() => <LoginPage cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/facebook"
              render={() => <FbLogin cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/socialsignup"
              render={() => <SocialSignup cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/quickSignup"
              render={() => <QuickSignup cookies={this.props.cookies} />}
            />

            <Route
              exact
              path="/MySPRXS"
              render={() => <MySPRXS cookies={this.props.cookies} />}
            />

            <Route exact path="/NTFTest" render={() => <NTFTestPage />} />

            <Route
              exact
              path="/error"
              render={() => <Error cookies={this.props.cookies} />}
            />

            <Route exact path="/NTFTest" render={() => <NTFTestPage />} />

            <Route
              exact
              path="/searchresults"
              render={() => (
                <SearchResultsPage searchItems={this.props.searchName} />
              )}
            />

            <Route
              exact
              path="/dashboard"
              render={() => (
                <UserSession
                  cookies={this.props.cookies}
                  warnSession={this.warnSession}
                  endSession={this.endSession}
                />
              )}
            />
            <Route
              exact
              path="/dashboard-ideator"
              render={() => <DashboardIdeator cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/searchIdea_covid19"
              render={() => <SearchCovidPage cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/publicIdeas"
              render={() => <PublicIdeasPage cookies={this.props.cookies} />}
            />
            <Route
              exact
              path="/marketplace"
              render={() => <Marketplace cookies={this.props.cookies} />}
            />
          </Switch>

          <SearchModal title={"Search ideas by"} centered={true} size="sm" />
          <EditProfileModal
            title={"Edit Profile"}
            cookies={this.props.cookies}
            centered={true}
            size="sm"
          />

          <CollabRequestModal
            jwtToken={this.props.cookies.get("bearerToken")}
            title={"Work on Idea"}
            centered={true}
            size="sm"
          />
          <EnquiryModal title={"Enquiries"} centered={true} size="sm" />
          <RequestForUniqueModal
            title={"Request for Unique"}
            centered={true}
            size="sm"
          />

          <NTFTest />
          <CookiesNTF isAuthenticated={this.state.isAuthenticated} />
          <LegalModal />
          <Toaster />
          <CompEnteredModal />
          <AlertPopup />
          <AlertSessionTimeOut />
          <AlertSessionWarning />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchName: state.searchName,
    searchSkills: state.searchSkills,
    searchDesc: state.searchDesc,
    searchID: state.searchID,
    collaboratorId: state.collaboratorID,
    themeType: state.themeType,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCardView: () => dispatch(toggleCardView()),
    dispatchFirebaseUid: user => dispatch(dispatchFirebaseUid(user)),
    launchAlert: payload => dispatch(launchAlert(payload)),
    launchTimeout: () => dispatch(launchTimeout()),
    launchSessionWarning: () => dispatch(launchSessionWarning()),

    logOut: cookies => {
      logOut(cookies, dispatch);
    },

    dispatchisAuthenticated: (emailAddress = null) =>
      dispatch({
        type: IS_AUTHENTICATED,
        payload: {
          email: emailAddress
        }
      }),
    saveToken: (cookies, token) => saveToken(cookies, token, dispatch),
    dispatchAuth: (type, profileID) => {
      dispatchAuth(type, profileID, dispatch);
    }
  };
};

export default withRouter(
  withCookies(connect(mapStateToProps, mapDispatchToProps)(withTheme()(App)))
);
