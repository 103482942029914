import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { hostApi, woiRole, woiCapacity, woiRemun } from "../../constants";
import TextField from "@material-ui/core/TextField";
import techImage from "../../static/technology.jpeg";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Paper from "@material-ui/core/Paper";
import { Public, SupervisedUserCircle } from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

import axios from "axios";
import { CardHeader } from "@material-ui/core";
import logo from "../../static/Sunrise_white.png";
import FormHeader from "../FormHeader/FormHeader";
import { launchAlert } from "../../store/actions/actions";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  bgimage: {
    backgroundColor: fade(theme.palette.secondary.dark, 0.2),
    backgroundImage: `url(${techImage})`
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
    marginTop: theme.spacing.unit * 2
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

class RequestWOI extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    open: false,
    isDisabled: true
  };

  componentDidMount() {
    const { data, cookies } = this.props;
    //console.log("RequestWOI: Line 61*");
    //console.log(cookies.get("emailAddress"));
    this.setState({
      collab_id: cookies.get("collab_id"),
      idea_id: data.ideaUniqueID
    });
  }

  // Handlers
  // --------------------------------------------------------------------------------------------------------------------------
  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked }, this.handleRWOI());
  };
  handleChangeData = name => event => {
    this.setState({ [name]: event.target.value, isDisabled: false });
  };

  handleRWOI = () => {
    const { launchAlert, jwtToken } = this.props;
    console.log("requestWorkOnIdea", this.state, this.props);
    /* {
      "profile_id"			:319,
      "idea_id"				: "GEFBWG",
      "lkp_woi_role"			: 1,
      "lkp_woi_capacity"		: 2,
      "lkp_woi_remun"			: 3,
      "collab_reason"			:"TEST Reason 0923",
      "collab_valueadd"		:"TEST Value 0923"
    } */
    axios
      .post(`${hostApi}/requestWorkOnIdea`, {
        idea_id: this.state.idea_id,
        profile_id: this.state.collab_id,
        lkp_woi_role: this.state.lkp_woi_role,
        lkp_woi_capacity: 1,
        lkp_woi_remun: 1,
        collab_reason: this.state.collab_reason,
        collab_valueadd: this.state.collab_valueadd
      },
      {
        headers: {
          'Authorization': `Bearer ${jwtToken}`
        },
      })
      .then(response => {
        console.log("axios response", response);
        launchAlert({
          intent: "success",
          title: "Request Sent",
          message: (
            <Typography variant="Subheading" color="inherit">
              You have sent a request to the idea owner asking for permission to
              collaborate on their idea. You will now need to wait for them to
              approve your request.
            </Typography>
          )
        });
      })
      .catch(error => {
        launchAlert({
          intent: "error",
          title: "Request Failed",
          message: (
            <Typography variant="Subheading" color="inherit">
              There was a problem with your request to approve/reject. Please
              try again.
              <br/>
              <br/>
              {`${error}`}
            </Typography>
          )
        });
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log("====================================");
        console.log(error);
        console.log("====================================");
        console.log(error.config);
      });
    this.props.onClose();
    //console.log("RWOI Updated");
  };

  /*  JAVA API GUIDE
      "profile_id"			:"kevin.naicker3@fifth-9.com",
    "idea_id"				:"HMRK54",
    "lkp_woi_role"			: 1,
    "lkp_woi_capacity"		: 2,
    "lkp_woi_remun"			: 3,
    "collab_reason"			:"TEST Reason 0500",
      "collab_valueadd"		:"TEST ValueA 0500" 
      */

  // Render
  // --------------------------------------------------------------------------------------------------------------------------

  render() {
    const {
      fullScreen,
      open,
      onClose,
      data,
      classes,
      action,
      cookie_email
    } = this.props;

    //console.log("COLLAB data", data);

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Paper>
            <FormHeader>
              <CardHeader
                avatar={
                  <SupervisedUserCircle
                    style={{ fontSize: 50 }}
                    color="primary"
                  />
                }
                title={
                  <Typography color="default" variant="title">
                    Request to Work on an Idea
                  </Typography>
                }
                subheader={
                  <Typography color="default">
                    If this idea is a project you would like to collaborate on,
                    send the idea owner a request to collaborate, and should it
                    get approved, you will become part of the project team.
                  </Typography>
                }
              />
            </FormHeader>
            <DialogTitle id="responsive-dialog-title">
              {`Idea #${data.ideaUniqueID}`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <form
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    required
                    id="lkp_woi_role"
                    select
                    label="Role"
                    fullWidth={true}
                    className={classes.textField}
                    value={this.state.lkp_woi_role}
                    onChange={this.handleChangeData("lkp_woi_role")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.textField
                      }
                    }}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  >
                    {woiRole.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {`${option.label}`}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    isRequired
                    id="outlined-reason"
                    label="Reason to join project"
                    multiline
                    rowsMax="3"
                    rows="3"
                    className={classes.textField}
                    value={data.collab_reason}
                    defaultValue={data.collab_reason}
                    onChange={this.handleChangeData("collab_reason")}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    isRequired
                    id="outlined-multiline-flexible"
                    label="Value that you will bring to the project"
                    multiline
                    rowsMax="3"
                    rows="3"
                    value={data.collab_valueadd}
                    defaultValue={data.collab_valueadd}
                    onChange={this.handleChangeData("collab_valueadd")}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </form>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button
                disabled={this.state.isDisabled}
                onClick={this.handleRWOI}
                color="secondary"
                variant="contained"
                autoFocus
              >
                Submit Request
              </Button>
            </DialogActions>
          </Paper>
        </Dialog>
      </div>
    );
  }
}

RequestWOI.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  token: state.jwtToken
});

const mapDispatchToProps = dispatch => ({
  launchAlert: payload => {
    dispatch(launchAlert(payload));
  }
});

export default withCookies(
  withStyles(styles, { withTheme: true })(
    withMobileDialog()(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(RequestWOI)
    )
  )
);
