import React, { Component } from "react";
import "./IdeaList.css";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import Typography from "@material-ui/core/Typography";
import { IDEATOR, COLLABORATOR } from "../../constants";


class IdeaList extends Component {
  render() {
    const { myIdeas, navigateToIdea, collaborator} = this.props;

    return myIdeas.length === 0 ? (
      <div>
        <>
        {Boolean(collaborator) ? 
        <Typography variant="Subheading" color="inherit">
        This is where all the ideas you are collaborating on will be visible.<br />
        Start collaborating by clicking on SEARCH IDEAS in the nav bar and selecting an idea to work on.< br/>
        Once you have found something interesting, request to collaborate (<IconButtonComponent type={"collaborate"} iconStyle={"iconDisabled"}/>) on the idea and the idea owner will approve or reject your request.
      </Typography>
       :
        <Typography variant="Subheading" color="inherit">
          Welcome to SPRXS.<br />
          Start your journey by creating (<IconButtonComponent type={"create"} iconStyle={"iconDisabled"}/>) an idea.<br />
          Once It has been created don't forget to share it (<IconButtonComponent  type={"public"} iconStyle={"iconDisabled"} />) with the rest of the SPRXS community, search for Collaborators in the MARKETPLACE and invite them (<IconButtonComponent type={"invite"} iconStyle={"iconDisabled"}/> ) to work on your idea<br /><br />
          Once created, this section will show a list of all of your ideas.
          <br />Use the expand idea (<IconButtonComponent  type={"launch"} iconStyle={"iconDisabled"} />) icon to edit your idea, create Milestones and transfer equity to collaborators.
        </Typography>
        }
        </>
      </div>
    ) : (
      <div className="idea-list__wrapper">
        <table className={Boolean(collaborator) ? "idea-list-collab" : "idea-list"}>
          <thead>
            <tr className="idea-list__header">
              {!collaborator && <td>Status</td>}
              <td>{Boolean(collaborator) ? "Idea Name" : "Name" }</td>
              {!collaborator && (<td>Collaborators</td>)}
              <td>{Boolean(collaborator) ?  "Equity" : "Equity" }</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {myIdeas.map((idea, id) => (
              <tr key={id}>
                {!collaborator && (
                  <td className="sprxs-idea-list-wrapper">
                    {idea.idea_status ? 
                      <IconButtonComponent toolTip={true} type={"public"} iconStyle={"iconDisabled"} title={"Idea Seachable"} /> : 
                      <IconButtonComponent toolTip={true} type={"locked"} iconStyle={"iconDisabled"} title={"Idea Private"} />
                    }
                  </td>
                )}
                {!collaborator && (
                <>
                  <td>
                    <div className="idea-list__name">{idea.idea_name}</div>
                    <div className="idea-list__id">#{idea.idea_id}</div>
                  </td>
                  <td className="idea-list__collaborators">
                    {idea.no_of_collaborators}
                  </td>
                  <td className="idea-list__equity">{idea.tokens_owned}</td>
                  <td className="idea-list__expand">
                    <IconButtonComponent 
                      type={"launch"}
                      toolTip={true}
                      title={`Open ${idea.idea_name}`}
                      iconStyle={"iconAction"}
                      link={() => navigateToIdea(idea.idea_id, IDEATOR)}
                    />
                  </td>
                </>
                )}
                {collaborator && (
                  <>
                   <td>
                     <div className="idea-list__name">{idea.ideaName}</div>
                     <div className="idea-list__id">#{idea.ideaId}</div>
                   </td>
                   {/* <td className="idea-list__collaborators">{idea.equitySplit}</td> */}
                   <td className="idea-list__equity">{idea.equitySplit}</td>
                   <td className="idea-list__expand">
                      <IconButtonComponent 
                      type={"launch"}
                      toolTip={true}
                      title={`Open ${idea.ideaName}`}
                      iconStyle={"iconAction"}
                      link={() => navigateToIdea(idea.ideaId, COLLABORATOR)}
                      />
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
export default IdeaList;
