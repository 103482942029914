import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import IdeaCard from "./IdeaCard";
import CollaboratorsByIdea from "../../components/CollaboratorsByIdea/CollaboratorsByIdea";
import MilestoneList from "../../components/Milestone/MilestoneList/MilestoneList";
import MilestoneForm from "../../components/Milestone/MilestoneForm/MilestoneForm";
import "./IdeaDash.css";
import { hostApi, IDEATOR, COLLABORATOR } from "../../constants";
import MyWalletIdea from "../../components/MyWallet/MyWalletIdea";
import MyWalletCollab from "../../components/MyWallet/MyWalletCollab";
import { withCookies } from "react-cookie";
import { withStyles} from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import axios from "axios";
import DialogActions from "@material-ui/core/DialogActions";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import {
  getMilestones,
  lodgeConsensus,
  launchAlert,
  startChatWithPerson
} from "../../store/actions/actions";


const styles = theme => ({
  root: {
    flexGrow: 1
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class IdeaDash extends React.Component {
  state = {
    open: false,
    openTransEquity: false,
    openMilestoneForm: false,
    isTransfer: false,
    collabID: 0,
    collabName: "",
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseEquity = () => {
    this.setState({ openTransEquity: false });
  };

  handleOpenEquity = () => {
    this.setState({ openTransEquity: true });
  };

  handleOpenMilestoneForm = (v, transfer, collabID, collab_firstName, collab_surName) => {
    let collabName = "";
    collabName =  collab_firstName + " "  + collab_surName;

    this.setState({ openMilestoneForm: true, isTransfer: transfer, collabID: collabID, collabName: collabName });
  };

  handleCloseMilestoneForm = () => {
    this.setState({ openMilestoneForm: false });
  };


  handleOpen = () => {
    const { getMilestones, data, jwtToken, userType, collaboratorID} = this.props;
    
    
    if(userType === COLLABORATOR){
      getMilestones(jwtToken, data.ideaId, collaboratorID);
    } else {
      getMilestones(jwtToken, data.ideaId, 0);
    }
    

    axios
    .post(`${hostApi}/getAllIdeaFiles`,
      {
        ideaUniqueID: data.ideaId
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.jwtToken}`
        },
      })
    .then(response => {
      if (response.status === 200) {
        this.setState({ selectedIdeaFiles: response.data });
      }      
    });
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    const { getMilestones, data, jwtToken, userType, collaboratorID} = this.props;

      if (prevProps.data.ideaId !== data.ideaId) {
        if(userType === COLLABORATOR){
          getMilestones(jwtToken, data.ideaId, collaboratorID);
        } else {
          getMilestones(jwtToken, data.ideaId, 0);
        }
      }
  } 

  approveCallback = response => {
    const { getMilestones, data, launchAlert, collaboratorID, jwtToken } = this.props;
    getMilestones(jwtToken, data.ideaId, collaboratorID);

    if (response < 300) {
      launchAlert({
        intent: "success",
        title: "Approve/Reject",
        message: (
          <Typography variant="Subheading" color="inherit">
            You have successfully approved/rejected this milestone.
          </Typography>
        )
      });
    } else {
      launchAlert({
        intent: "error",
        title: "Approve/Reject",
        message: (
          <Typography variant="Subheading" color="inherit">
            There was a problem....status code {response}
          </Typography>
        )
      });
    }
  };

  render() {
    const {
      classes,
      open,
      handleClose,
      data,
      userType,
      cookies,
      fullScreen,
      jwtToken
    } = this.props;
    const { openTransEquity } = this.state;

    return (
      <>
      <div>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth={"xl"}
          open={open}
          onClose={handleClose}
          onEnter={() => this.handleOpen()}
          TransitionComponent={Transition}
        >
        <DialogContent className="idea-dashboard">
          {!fullScreen && (
          <div className="idea-dashboard__close">
            <IconButtonComponent 
              type={"close"}
              iconStyle={"iconDefault iconCodeBlack"}
              toolTip={true}
              title={"Close"}
              link={handleClose}
            />
          </div>
          )}
          <div className="idea-dashboard-wrapper">
            <div className="idea-dashboard__item--my-idea">
              <div className="idea-dashboard__title-wrapper">
                <div className="idea-dashboard__title">
                  <span>Idea Details</span>
                  <span>
                    <IconButtonComponent
                        type={"help"}
                        toolTip={true}
                        title={"Idea Details Help"}
                        iconStyle={"iconDefault"}
                        alert={true}
                        alertTitle={"Idea Details Help"}
                        intent={"help"}
                        message={(
                          <Typography variant="Subheading" color="inherit">
                          Edit (<IconButtonComponent type={"edit"} iconStyle={"iconDisabled"}/>) your idea or add (<IconButtonComponent type={"add"} iconStyle={"iconDisabled"}/>) file attachments.<br/> Make your Idea searchable (<IconButtonComponent type={"public"} iconStyle={"iconDisabled"}/>) for collaboration.  
                         </Typography>
                            )}
                      />
                  </span>
                </div>
              </div>
              <div className="idea-dashboard__body idea-card">
                  <IdeaCard
                    jwtToken={this.props.jwtToken}
                    ideaData={
                      data
                    }
                    selectedIdeaFiles={this.state.selectedIdeaFiles}
                    pub={false}
                    showChat={this.props.showChat}
                    userType={this.props.userType}
                  />
              </div>
            </div>
           
              <div className="idea-dashboard__item--milstones">
                <div className="idea-dashboard__title-wrapper">
                  <div className="idea-dashboard__title">
                    <span>Milestones</span>
                    <span>
                      {userType === IDEATOR && (
                      <IconButtonComponent 
                        type={"create"}
                        toolTip={true}
                        title={"Create Milestone"}
                        link={e => this.handleOpenMilestoneForm(e, false)}
                        iconStyle={"iconDefault"}
                      />
                      )}
                    </span>
                  </div>
                </div>
                <div className="idea-dashboard__body idea-milstones">
                  {!Boolean(this.props.milestoneData.length) && (
                    <Typography variant="body1" color="default">
                      There are no milestones yet for this idea. Hit the 'Add
                      Milestone' button to create one.
                    </Typography>
                  )}
                    {Boolean(this.props.milestoneData.length) && (
                      <MilestoneList
                        jwtToken={this.props.jwtToken}
                        userType={this.props.userType}
                        ideaID={data.ideaId}
                        data={this.props.milestoneData}
                        ideaName={this.props.milestoneData.ideaName}
                        lodgeConsensus={this.props.lodgeConsensus}
                      />
                    )}
                </div>
              </div>
              <div className="idea-dashboard__item--my-wallet">
                <div className="idea-dashboard__title-wrapper">
                  <div className="idea-dashboard__title">
                    <span>Wallet</span>
                    <span>
                      <IconButtonComponent
                        type={"help"}
                        toolTip={true}
                        title={"Idea Wallet Help"}
                        iconStyle={"iconDefault"}
                        alert={true}
                        alertTitle={"Idea Wallet Help"}
                        intent={"help"}
                        message={(
                          <Typography variant="Subheading" color="inherit">
                            This is your idea wallet. You can see a breakdown of SPRXS tokens owned by you and distributed to your collaborators.<br />
                            You can also transfer (<IconButtonComponent type={"tokenDark"} iconStyle={"iconDisabled"}/>) tokens, or chat (<IconButtonComponent type={"chat"} iconStyle={"iconDisabled"}/>) directly to a collaborator.  
                          </Typography>
                        )}
                      />
                    </span>
                  </div>
                </div>
                <div className="Idea-dashboard__body">
                  { userType === IDEATOR && (
                  <MyWalletIdea jwtToken={this.props.jwtToken}/>
                  )}
                  { userType === COLLABORATOR && (
                  <MyWalletCollab numberOfIdeas={1}
                    myWallet={{
                      barChartTitle: "Your Equity",
                      progressValue: data.equitySplit,
                      totalValue: 100
                    }}/>
                  )}
                </div>
                <div className="idea-dashboard__body">
                {data.ideaId !== undefined && userType === IDEATOR && (
                  <CollaboratorsByIdea
                    jwtToken={this.props.jwtToken}
                    ideaID={data.ideaId}
                    openChat={this.props.showChat}
                    handleOpenMilestoneForm={this.handleOpenMilestoneForm}
                    startChatWithPerson={this.props.startChatWithPerson}
                    equity
                  />
                  )}
                </div>
              </div> 
          </div>
          </DialogContent>
          {fullScreen && (
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </div>
      {data.ideaId !== undefined && (
       <MilestoneForm
       jwtToken={this.props.jwtToken}
       ideaName={data.ideaName}
       ideaID={data.ideaId}
       open={this.state.openMilestoneForm}
       closeMilestoneForm={this.handleCloseMilestoneForm}
       transfer={this.state.isTransfer}
       edit={false}
       collabID={this.state.collabID}
       collabName={this.state.collabName}
     />
     )}
     </>
    );
  }
}

IdeaDash.propTypes = {
    fullScreen: PropTypes.bool.isRequired
  };
  
  const mapStateToProps = state => ({
    milestoneData: state.milestoneData,
    collaboratorID: state.profileID,
  });

const mapDispatchToProps = dispatch => ({
  getMilestones: (jwtToken, ideaID, collaboratorID) => {
    getMilestones(jwtToken, ideaID, collaboratorID, dispatch);
  },
  lodgeConsensus: (jwtToken, approvalCode, profileId, milestoneId, callback) => {
    lodgeConsensus(jwtToken, approvalCode, profileId, milestoneId, callback);
  },
  launchAlert: payload => dispatch(launchAlert(payload)),
  startChatWithPerson: payload => dispatch(startChatWithPerson(payload)),
});

export default withCookies(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withMobileDialog()(IdeaDash)))
);
