import React, { Component, Fragment } from "react";
import { PropTypes, instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import "firebase/auth";
import {
  CLOSE_MODAL,
  SEARCH_CRITERIA,
  showLegalModal,
  showIdeaSubmittedNTF,
  isAuthenticated,
  dispatchFirebaseUid,
  postCollaborator,
  dispatchAuth,
  saveToken
} from "../../store/actions/actions";
import axios from "axios";
import fire, { IDEATOR } from "../../constants";
import {
  firebasePassword,
  hostApi,
  ideaClassification,
  covid19Classification,
  industry
} from "../../constants";
import { withStyles } from "@material-ui/core/styles";
import { ideatorAccountCreated } from "../../utility/google_analytics";
// import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import fallback from "../LandingPage/FrontPageV2/Images/collab_avatar.png";
// import fallback2 from "../LandingPage/FrontPageV2/Images/ideaIcon.svg";

const styles = theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  avatar: {
    margin: theme.spacing.unit * 2,
    backgroundColor: "white !important"
  },
  bigAvatar: {
    verticalAlign: "baseline",
    margin: "0 auto",
    width: 80,
    height: 80,
    backgroundColor: "white !important"
  },
  overrides: {
    MuiAvatar: {
      root: {
        "& .MuiAvatar-root": {
          display: "inline-block",
          backgroundColor: "white !important"
          // "&:not(:first-of-type)": {
          //   marginLeft: -muiBaseTheme.spacing.unit
          // }
        },
        "& .MuiAvatar-color-default-254": {
          backgroundColor: "white !important"
        }
      }
    }
  }
});
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 2999;
  background-color: #fafafa;
`;

export function validateCreate(context) {
  const { firstname, lastname, lkp_email, emailValidation } = context.state;

  if (
    firstname === "" ||
    lastname === "" ||
    lkp_email === "" ||
    emailValidation === true
  ) {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

function validateUser(context) {
  const {
    firstname,
    lastname,
    lkp_email,
    lkp_email2,
    emailValidation,
    emailValidation2,
    passwrd,
    passwrd2
  } = context.state;

  if (
    firstname === "" ||
    lastname === "" ||
    lkp_email === "" ||
    lkp_email2 === "" ||
    emailValidation === true ||
    emailValidation2 === true ||
    lkp_email !== lkp_email2 ||
    passwrd === "" ||
    passwrd2 === "" ||
    passwrd !== passwrd2
  ) {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

// function validateUser(context) {
//   const { firstname, lastname, lkp_email, emailValidation } = context.state;

//   if (
//     firstname === "" ||
//     lastname === "" ||
//     lkp_email === "" ||
//     emailValidation === true
//   ) {
//     return true; /* disable the button */
//   } else {
//     return false; /* enable the button */
//   }
// }

function validateFields(email, password) {
  if (email === "" || email === undefined || password === "") {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

function validateLegal(context) {
  const { checkedTerms } = context.state;
  if (checkedTerms === false) {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}
const helpMessageEnterEmail =
  "Please enter the email address this account is registered to.";

//const user = null;

export function getStepContent(
  classes,
  currentStep,
  setFormTitle,
  setJourney,
  journey,
  joinTypeValue,
  email,
  profilePicLink,
  context,
  authUser,
  otherPrimary,
  otherSecondary
) {
  switch (currentStep) {
    case 0:
      return (
        <div className="formWrapper">
          <div className="formInner"></div>
        </div>
      );
    case 1:
      setFormTitle("with a bit about you");
      return (
        <div>
          <form
            className={classes.container}
            style={{ backgroundColor: "#fafafa !important" }}
            noValidate
            autoComplete="off"
          >
            <Typography color="error">
              You can skip creating idea &amp; do it later. However, you must
              Tick Terms &amp; Conditions
            </Typography>
            <br />
            <FormControlLabel
              style={{ marginLeft: "0%", marginTop: "10px" }}
              control={
                <Checkbox
                  checked={context.state.covid_19_idea}
                  onChange={context.handleCovidIdea}
                  value="covid_19_idea"
                  color="primary"
                />
              }
              label={
                <div>
                  <span
                    className="covidspan"
                    style={{ backgroundColor: "var(--red)" }}
                  >
                    This idea is realated to the current Covid-19 Situation.
                  </span>
                </div>
              }
            />
            {context.state.covid_19_idea && (
              <TextField
                InputLabelProps={{ shrink: true }}
                id="select-category"
                select
                label="Select Category"
                fullWidth={true}
                className={classes.textField}
                style={{ clear: "both" }}
                value={context.state.lkp_idea_cat1}
                onChange={context.handleCat1}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                variant="outlined"
                margin="normal"
                // margin="dense"
              >
                {covid19Classification.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {`${option.label} idea`}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {!context.state.covid_19_idea && (
              <TextField
                id="select-category"
                select
                label="I have a"
                fullWidth={true}
                className={classes.textField}
                style={{ clear: "both" }}
                value={context.state.lkp_idea_cat1}
                onChange={context.handleCat1}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                variant="outlined"
                margin="dense"
              >
                {ideaClassification.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {`${option.label} idea`}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <TextField
              required
              error={context.state.invalidIdeaName}
              id="required"
              label="Idea Name"
              placeholder="e.g. Flux Capacitor"
              helperText="Enter the name of your idea"
              className={classes.textField}
              style={{ clear: "both" }}
              value={context.state.idea_name}
              onChange={context.handleIdeaName}
              fullWidth={true}
              variant="outlined"
              // margin="dense"
            />
            {joinTypeValue !== "organisation" && (
              <Fragment>
                                
                <TextField
                  required
                  error={
                    context.state.emailValidation || context.state.authUser
                  }
                  id="email"
                  label="Email"
                  type="email"
                  placeholder="e.g. you@email.com"
                  className={classes.textField}
                  value={context.state.lkp_email}
                  onChange={context.handleEmail}
                  margin="dense"
                  variant="outlined"
                  fullWidth={true}
                />
                <Fragment>
                  <TextField
                    required
                    id="outlined-adornment-password"
                    margin="dense"
                    fullWidth={true}
                    className={classes.textField}
                    variant="outlined"
                    type={context.state.showPassword ? "text" : "password"}
                    label="Password"
                    value={context.state.passwrd}
                    onChange={context.handlePassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={context.handleClickShowPassword}
                          >
                            {context.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    required
                    error={
                      context.state.passWordValidation ||
                      context.state.passwrd !== context.state.passwrd2
                    }
                    id="outlined-adornment-password2"
                    margin="dense"
                    fullWidth={true}
                    className={classes.textField}
                    variant="outlined"
                    type={context.state.showPassword2 ? "text" : "password"}
                    label="Confirm Password"
                    value={context.state.passwrd2}
                    onChange={context.handlePassword2}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={context.handleClickShowPassword2}
                          >
                            {context.state.showPassword2 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    helperText={
                      context.state.passwordValidation
                        ? " "
                        : context.state.passwrd !== context.state.passwrd2
                        ? "Password does not match"
                        : ""
                    }
                  />
                </Fragment>
                <TextField
                  required
                  error={context.state.invalidFirstName}
                  id="firstname"
                  label="First name"
                  placeholder="First name"
                  className={classes.textField}
                  value={context.state.firstname}
                  onChange={context.handleFirstname}
                  helperText={
                    context.state.invalidFirstName
                      ? "Please enter your first name"
                      : ""
                  }
                  margin="dense"
                  variant="outlined"
                  fullWidth={true}
                />
                <TextField
                  required
                  error={context.state.invalidLastName}
                  id="lastname"
                  label="Last name"
                  placeholder="Last name"
                  className={classes.textField}
                  value={context.state.surname}
                  onChange={context.handleLastname}
                  helperText={
                    context.state.invalidLastName
                      ? "Please enter your last name"
                      : ""
                  }
                  margin="dense"
                  variant="outlined"
                  fullWidth={true}
                />
                {/* {context.state.authUser && (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ backgroundColor: "#fafafa !important" }}
                  >
                    <div
                      className={"MuiAvatar-root"}
                      style={{ backgroundColor: "#fafafa !important" }}
                    >
                      <Avatar className={"MuiAvatar-color-default-254"}
                        style={{ backgroundColor: "#fafafa !important" }}
                        alt="sprxs user"
                        srcset={context.state.profilePicLink + fallback}
                        src={context.state.profilePicLink}
                        onError={`this.onerror=null; this.src=${fallback};`}
                        className={classes.bigAvatar}
                      />
                    </div>
                  </Grid>
                )} */}
                                         
              </Fragment>
            )}

            {/* <FormControlLabel
              style={{ marginLeft: "0%", marginTop: "10px" }}
              control={
                <Checkbox
                  checked={context.state.opt_in_comp}
                  onChange={context.handleCheckComp}
                  value="opt_in_comp"
                  color="primary"
                />
              }
              label={
                <div>
                  <span>
                    I want to enter the <strong>SPRXS competition</strong> for a
                    chance to win <strong>£9,250</strong> and have read and
                    accepted the
                  </span>
                  <Link to="#" onClick={context.props.showCompTerms}>
                    {" "}
                    competition rules
                  </Link>
                </div>
              }
            /> */}
            <br />
            <div className={classes.container}>
              <FormControlLabel
                style={{ marginLeft: "0%", marginTop: "10px" }}
                control={
                  <Checkbox
                    checked={context.state.opt_in_notif}
                    onChange={context.handleCheckNotification}
                    value="opt_in_notif"
                    color="primary"
                  />
                }
                label="I want to stay in the loop for information on SPRXS"
              />
              <FormControlLabel
                style={{ marginLeft: "0%", marginTop: "10px" }}
                control={
                  <Checkbox
                    checked={context.state.checkedTerms}
                    onChange={context.handleCheckTerms}
                    value="checkedA"
                    color="primary"
                  />
                }
                label={
                  <div>
                    <span>I have read and accepted the </span>
                    <Link to="#" onClick={context.props.showPriv}>
                      Privacy Policy
                    </Link>
                    <span>, </span>
                    <Link to="#" onClick={context.props.showTerms}>
                      Terms and Conditions
                    </Link>
                    <span> and </span>
                    <Link to="#" onClick={context.props.showCollab}>
                      Tokens and Collaboration Policy
                    </Link>
                  </div>
                }
              />
            </div>
          </form>
        </div>
      );
    case 2:
      return (
        <div>
          <h4>WELCOME TO SPRXS</h4>
        </div>
      );
    default:
      return "Unknown step";
  }
}

class RegStepQuick extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      userId: "",
      profileId: "",
      firstname: "",
      surname: "",
      passwrd: "",
      passwrd2: "",
      password: "",
      forgotPassword: false,
      focusPassword: false,
      showPassword: false,
      lkp_email: "",
      idea_name: "",
      profilePicLink: "",
      checkedTerms: false,
      opt_in_comp: false,
      opt_in_notif: false,
      disableButton: " ",
      disableNext: true,
      joinTypeValue: "individual",
      authUser: false,
      pendingResponse: false,
      createIdea: true,
      userType: "",
      tokenId: "",
      emailValidation: true,
      emailVerified: false,
      isSubmit: false,
      covid_19_idea: true
    };
  }

  // If you need to run a function on login or logout
  // componentWillMount() {
  //   this.removeAuthListener = fire.auth().onAuthStateChanged(user => {
  //     if (user) {
  //       this.setState({
  //         authenticated: true,
  //         emailVerified: user.emailVerified,
  //         loading: false,
  //         lkp_email: user.providerData[0].email,
  //         profilePicLink: user.photoURL
  //       });
  //     } else {
  //       this.setState({
  //         authenticated: false
  //       });
  //     }
  //   });
  // }

  handleAuthenticateUser = e => {
    fire
      .auth()
      .fetchSignInMethodsForEmail(this.state.lkp_email)
      .then(providers => {
        if (providers.length === 0) {
          // this email hasn't signed up yet
          this.setState({ authUser: false });
        } else {
          // has signed up
          this.setState({ authUser: true });
          //console.log(this.state.lkp, "Regstep authUser")
        }
      });
  };

  componentDidMount() {
    if (this.state.lkp_email !== undefined) {
      this.setState({
        emailValidation: false,
        focusEmail: false,
        focusPassword: true
        // helpMessage: helpMessageFirstTimeLogin
      });
    } else {
      this.setState({
        emailValidation: true,
        // focusEmail: true,
        focusPassword: false
      });
    }
  }

  onChange(e) {
    this.setState({
      lkp_email: e.target.value,
      profilePicLink: e.target.value
    });
  }

  // handlePassword = e => {
  //   this.setState({ password: e.target.value });

  //   if (e.target.value.length === 0) {
  //     this.setState({ isDisabled: true });
  //   } else {
  //     this.setState({
  //       isDisabled: validateFields(this.state.lkp_email, e.target.value)
  //     });
  //     //activate
  //   }
  // };

  handlePassword = e => {
    this.setState({ passwrd: e.target.value }, () => {});
  };

  handlePassword2 = e => {
    this.setState({ passwrd2: e.target.value }, () => {
      this.setState({ disableNext: validateUser(this) });
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleClickShowPassword2 = () => {
    this.setState(state => ({ showPassword2: !state.showPassword2 }));
  };

  componentWillUnmount() {
    // this.removeAuthListener();
    this.setState({
      authenticated: false,
      loading: false,
      profilePicLink: ""
    });
  }

  // USER DETAILS
  handleJoinTypeChange = e => {
    this.setState({ joinTypeValue: e.target.value });
  };

  handleFirstname = e => {
    this.setState({
      firstname: e.target.value,
      firstNameValid: true,
      invalidFirstName: false
    });
  };

  handleLastname = e => {
    this.setState({
      surname: e.target.value,
      lastNameValid: true,
      invalidLastName: false
    });
  };

  handleEmail = e => {
    const emailValid = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    this.setState({
      emailValidation: emailValid === null ? true : false
    });
    this.setState({ lkp_email: e.target.value }, () => {
      if (this.props.journey === "CREATE") {
        this.setState({ disableNext: validateCreate(this) });
      }
    });
  };
  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value }, () => {});
  };

  // LEGAL
  handleCheckTerms = event => {
    this.setState({ checkedTerms: !this.state.checkedTerms }, () => {
      this.setState({ disableNext: validateLegal(this) });
    });
  };
  handleCheckNotification = event => {
    this.setState({ opt_in_notif: !this.state.opt_in_notif });
  };
  handleCheckComp = event => {
    this.setState({ opt_in_comp: !this.state.opt_in_comp });
  };

  handleNext = e => {
    e.preventDefault();
    this.props.handleNext();
    if (this.state.currentStep === 1) {
      this.setState({ createIdea: true });
    }
  };

  handlePassToLogin = e => {
    this.props.handlePassToLogin(this.state.lkp_email);
  };

  handleIdeaName = event => {
    this.setState({ idea_name: event.target.value });
  };

  // IDEA
  handleCat1 = event => {
    this.setState({
      lkp_idea_cat1: event.target.value
    });
  };

  handleCovidIdea = event => {
    this.setState({ covid_19_idea: !this.state.covid_19_idea });
  };

  // Forget Password Response
  // -----------------------------------------------------------------------------------------------------------------------------------------------
  handleForgot = e => {
    e.preventDefault();
    this.setState({
      forgotPassword: true,
      formTitle: "Forgot your password?",
      helpMessage: helpMessageEnterEmail,
      errorMessage: ""
    });

    if (this.state.lkp_email !== undefined) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ focusEmail: true, isDisabled: true });
    }
  };

  handleClose = e => {
    this.props.history.push(`/`);
  };

  // handleSubmit(e) {
  //   const that = this;
  //   this.setState({ pendingResponse: true, userType: IDEATOR });
  //   const { cookies, saveToken } = this.props;
  //   // fire
  //   fire
  //     .auth()
  //     .currentUser.updateProfile({
  //       displayName: this.state.firstname
  //     })
  //     .then(u => {
  //       var user = fire.auth().currentUser;
  //       fire
  //         .database()
  //         .ref()
  //         .child("users")
  //         .child(user.uid)
  //         .child("credentials")
  //         .update({
  //           firstName: this.state.firstname,
  //           lastName: this.state.surname,
  //           email: this.state.lkp_email.trim(),
  //           profilePicLink: this.state.profilePicLink
  //         });

  //       that.setState({
  //         userId: user.uid
  //       });

  //       // creating_idea: false,
  //       let dataPackage = {
  //         firstname: that.state.firstname,
  //         surname: that.state.surname,
  //         email_add: that.state.lkp_email,
  //         passwrd: that.state.passwrd,
  //         passwrd2: that.state.passwrd2,
  //         firebase_uid: that.state.userId,
  //         terms_cond: that.state.checkedTerms,
  //         opt_in_notif: that.state.opt_in_notif,
  //         opt_in_comp: that.state.opt_in_comp,
  //         lkp_country: 0,
  //         lkp_idea_cat1: 1,
  //         lkp_idea_cat2: 2,
  //         lkp_idea_cat3: 3,
  //         mobile_cc: 0,
  //         mobile_no: "0",
  //         idea_name: that.state.idea_name,
  //         creating_idea: that.state.createIdea,
  //         covid_19_idea: that.state.covid_19_idea
  //       };
  //       //axios Post
  //       axios
  //         .post(`${hostApi}/createProfile_v2`, dataPackage, {
  //           headers: {
  //             "Content-Type": "application/json"
  //           }
  //         })
  //         .then(response => {
  //           console.log("createProfile:createProfile ", response);
  //           if (response.data.createProfile_response === "FAIL") {
  //             console.log("fail");
  //           } else {
  //             console.log("not failed", response.data.token);
  //             console.log("response data", response.data);
  //             saveToken(cookies, response.data.token);
  //             that.setState({
  //               pendingResponse: false,
  //               profileId: response.data.profile_id
  //             });
  //             this.props.dispatchAuth("0", 0);
  //             ideatorAccountCreated(); // Google analytics event capture.
  //             this.moveToDashboard();
  //           }
  //         })
  //         .catch(error => {
  //           console.log("AXIOS ERROR", error);
  //         });
  //     })
  //     .catch(error => {
  //       console.log("FIREBASE ERROR--->", error);
  //     });
  // }

  handleSubmit(e) {
    const that = this;
    const { userType } = this.state;
    console.log(userType, "the submit userType");
    this.setState({ pendingResponse: true });
    const { cookies, saveToken } = this.props;

    // console.log("REACHED SUBMIT", saveToken);
    fire
      .auth()
      .createUserWithEmailAndPassword(this.state.lkp_email, firebasePassword)
      .then(u => {
        let user = fire.auth().currentUser;
        if (user != null) {
          user.providerData.forEach(function(profile) {
            console.log("Sign-in provider: " + profile.providerId);
            console.log("  Provider-specific UID: " + profile.uid);
            console.log("  Name: " + profile.displayName);
            console.log("  Email: " + profile.email);
          });
        }
        fire
          .database()
          .ref()
          .child("users")
          .child(user.uid)
          .child("credentials")
          .update({
            firstName: this.state.firstname,
            lastName: this.state.surname,
            email: this.state.lkp_email,
            password: firebasePassword,
            profilePicLink: ""
          });

        that.setState({
          userId: user.uid
        });
        let dataPackage = {
          firstname: that.state.firstname,
          surname: that.state.surname,
          lkp_country: 0,
          mobile_cc: 0,
          mobile_no: "0",
          email_add: that.state.lkp_email,
          passwrd: that.state.passwrd,
          passwrd2: that.state.passwrd2,
          opt_in_comp: that.state.opt_in_comp,
          opt_in_notif: that.state.opt_in_notif,
          firebase_uid: that.state.userId,
          lkp_idea_cat1: that.state.lkp_idea_cat1,
          lkp_idea_cat2: 2,
          lkp_idea_cat3: 3,
          idea_name: that.state.idea_name,
          idea_description: that.state.idea_description,
          ideaFilepath: "",
          creating_idea: that.state.createIdea,
          covid_19_idea: that.state.covid_19_idea
        };

        //axios Post
        axios
          .post(`${hostApi}/createProfile_v2`, dataPackage, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.token}`
            }
          })
          .then(response => {
            console.log("createProfile: ", response);
            console.log("createProfile_token: ", response.token);
            if (response.data.createProfile_response === "FAIL") {
              console.log("fail");
            } else {
              saveToken(cookies, response.data.token);
              that.setState({
                pendingResponse: false,
                profileId: response.data.profile_id
              });
              this.props.dispatchAuth("0", 0);
              ideatorAccountCreated(); // Google analytics event capture.
              this.moveToDashboard();
            }
          })
          .catch(error => {
            console.log("AXIOS ERROR", error);
          });
      })
      .catch(error => {
        console.log("FIREBASE ERROR--->", error);
      });
  }

  moveToDashboard = () => {
    this.props.dispatchFirebaseUid(this.state.userId);
    this.props.isAuthenticated(this.state.lkp_email);
    this.props.navigateToMySPRXS(
      this.state.userId,
      this.state.profileId,
      this.state.lkp_email,
      this.state.firstname,
      this.state.surname,
      this.state.userType
    );
  };

  render() {
    const {
      classes,
      journey,
      currentStep,
      setFormTitle,
      setJourney,
      email,
      navigateToMySPRXS,
      profilePicLink
    } = this.props;

    const {
      disableNext,
      disableButton,
      joinTypeValue,
      focusPassword,
      pendingResponse
    } = this.state;

    return (
      <div className={classes.root}>
        {getStepContent(
          classes,
          currentStep,
          setFormTitle,
          setJourney,
          journey,
          joinTypeValue,
          email,
          profilePicLink,
          this
        )}
        <div className={classes.actionsContainer}>
          <div>
            {currentStep === 1 && (
              <>
                {pendingResponse ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={35}
                    color={"#123abc"}
                    loading={this.state.isLoading}
                  />
                ) : (
                  <Button
                    variant="contained"
                    disabled={disableNext}
                    color="secondary"
                    onClick={e => this.handleSubmit(e)}
                    className={`${classes.button} ${disableButton}`}
                  >
                    Join
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

RegStepQuick.propTypes = {
  classes: PropTypes.object,
  cookies: instanceOf(Cookies).isRequired,
  handleNext: PropTypes.func,
  onClick: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    emailAddress: state.emailAddress,
    Authenticated: state.isAuthenticated,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //POST
    postCollaborator: (payload, callback) => {
      postCollaborator(payload, callback, dispatch);
    },

    dispatchAuth: (type, collaboratorID) => {
      dispatchAuth(type, collaboratorID, dispatch);
    },

    closeModal: () => dispatch({ type: CLOSE_MODAL }),
    dispatchFirebaseUid: user => dispatch(dispatchFirebaseUid(user)),
    isAuthenticated: (emailAddress = null) =>
      dispatch(isAuthenticated(emailAddress)),
    showPriv: () => dispatch(showLegalModal("privacy")),
    showTerms: () => dispatch(showLegalModal("terms")),
    showCollab: () => dispatch(showLegalModal("collab")),
    showCompTerms: () => dispatch(showLegalModal("comp")),
    showIdeaSubmittedNTF: () => dispatch(showIdeaSubmittedNTF()),
    saveToken: (cookies, token) => saveToken(cookies, token, dispatch),
    dispatchSearchCriteria: searchCriteria => {
      dispatch({
        type: SEARCH_CRITERIA,
        payload: {
          searchCriteria: searchCriteria
        }
      });
    }
  };
};

export default withRouter(
  withCookies(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(styles)(RegStepQuick))
  )
);
