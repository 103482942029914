import React from "react";
import CardHeader from "@material-ui/core/CardHeader";
import s from "../../static/s.png";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

/*
 * In order to make the modals and forms consistent,
 * this component provides that consistency by ensuring
 * that the logo is top right. 
 * 
 * You use the component by wrapping whatever title text
 * or content you wnat on the top left
 * 
 * e.g. <FormHeader>This is my title</FormHeader>
 * 
 * or <FormHeader><AnotherComponent/></FormHeader>
 */

const styles = theme => ({
  formLogo: {
    margin: 15,
    width: 30,
    height: 40
  }
});

function FormHeader(props) {
  const { classes } = props;

  return (
    <CardHeader
      action={<img src={s} className={classes.formLogo} />}
      title={
        <Typography variant="h5" color="primary" gutterBottom>
          {props.children}
        </Typography>
      }
    />
  );
}

export default withStyles(styles)(FormHeader);
