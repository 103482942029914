import AIONIcon from "../../static/aion.png";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import WhiteSPRX from "../../static/whitesprxs.png";
import "./CompetitionBar.css";

import { FaInstagram, FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa";
import {
  isAuthenticated,
  dispatchFirebaseUid,
  dispatchAuth,
  jwtAuth,
  saveToken
} from "../../store/actions/actions";
import "firebase/auth";
import "firebase/database";
import * as fire from "firebase/app";

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

class CompetitionBar extends React.Component {
  state = {
    authUser: false,
    isSignedIn: false,
    authenticated: false
  };
  render() {
    const { classes, setJourney, user } = this.props;

    return (
      <div className="compContainer">
        <div className="sideComp-1 ">
          <img src={WhiteSPRX} width={"80px"} Alt="sprxs logo" />
        </div>
        <div className="compContainer-para">
          <p>Share your ideas that could help alleviate CV-19 issues</p>
        </div>

        <div>
          <button
            className="compButton"
            onClick={e =>
              (this.unregisterAuthObserver = fire
                .auth()
                .onAuthStateChanged(user => {
                  if (user) {
                    this.setState({
                      authUser: true,
                      isSignedIn: !!user,
                      authenticated: true
                    });
                    this.props.history.push("/searchIdea_covid19");
                  } else {
                    this.props.history.push("/quickSignup");
                  }
                }))
            }
          >
            CV-19 ideas
          </button>
          <button
            className="compButton"
            onClick={e =>
              (this.unregisterAuthObserver = fire
                .auth()
                .onAuthStateChanged(user => {
                  if (user) {
                    this.setState({
                      authUser: true,
                      isSignedIn: !!user,
                      authenticated: true
                    });
                    this.props.history.push("/searchIdea_covid19");
                  } else {
                    this.props.history.push("/login");
                  }
                }))
            }
          >
            View all ideas
          </button>
        </div>

        <span className="compSocial">
          <a href="https://www.instagram.com/sprxs_app/" target="_blank">
            <FaInstagram className="compIcon" size={24} />
          </a>
          <a href="https://www.facebook.com/SPRXS" target="_blank">
            <FaFacebook className="compIcon" size={24} />
          </a>
          <a href="https://twitter.com/sprxsapp?lang=en" target="_blank">
            <FaTwitter className="compIcon" size={24} />
          </a>
        </span>
      </div>
    );
  }
}

CompetitionBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(CompetitionBar));
