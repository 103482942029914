import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import FormHeader from "../FormHeader/FormHeader";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import firebase from "../../constants";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import {
  hostApi,
} from "../../constants";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 3}px`,
    marginTop: theme.spacing.unit * 2
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  btnContainer: {
    overflowY: "hidden"
  },
  input: {
    display: "none"
  },
  formName: {
    marginBottom: 20
  }

});

class IdeaAttachDialog extends React.Component {
  state = {
    fileName: "",
    fileType: "",
    fileDetails: [],
    successNtf: false
  };

  componentDidMount() {
    const { data, cookies } = this.props;
    this.setState({
      ideaFilepath: ""
    });
  }

  updateFilename = event => {
    console.clear();
    if (event.target.files.length) {
      let fileType = event.target.files[0].type.split('/');
      this.setState({
        fileName: event.target.files[0].name,
        fileType: fileType[1],
        fileDetails: event.target.files[0]
      });
    } else {
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    axios
    .post(
      `${hostApi}/saveFileForIdea`,
      {
          ideaId: this.props.data.ideaId,
          fileExtension: this.state.fileType,
          filename: this.state.fileName
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => {
      if (response.data.createIdea_response === "FAIL") {
        NotificationManager.error(
          response.data.login_message,
          "File Upload Error",
          4000
        );
    } else {
        if (this.state.fileName != "") {
          let filename = this.state.fileName;
          var user = firebase.auth().currentUser;
          // Points to the root reference
          var storageRef = firebase.storage().ref();


          // Points to 'All user files'

          var myIdeaFolder = storageRef
            .child("AllUsersFileData")
            .child(user.uid)
            .child("myIdeas")
            .child(filename);
          var file = this.state.fileDetails;
          myIdeaFolder
            .put(file)
            .then(function(snapshot) {
            })
            .catch(error => {
            });
          }
          NotificationManager.success(
            `File Uploaded`,
            "File was succsessfully uploaded",
            5000
          );
        }
        //this.toggle(event);
      })
      .catch(error => {
        NotificationManager.error("API Error", "File Upload Idea Error", 4000);
      });

    this.setState({ successNtf: true });
    this.props.onClose();
  };

  render() {
    const {
      classes,
      fullScreen,
      open,
      onClose,
      cookies,
      data /*pass the card data here*/
    } = this.props;

    return (
      <div>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          fullScreen={fullScreen}
          open={open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          
          <FormHeader>Add Attachment</FormHeader>
          <DialogContent>
            <DialogContentText className={classes.formName}>
              {`Idea #${data.ideaId}`}
            </DialogContentText>
           <DialogContent>
           <form className={classes.container} noValidate autoComplete="off">
           <input
              accept="xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.odt,.ods,.svg,.csv"
              className={classes.input}
              onChange={this.updateFilename}
              id="contained-button-file"
              multiple
              type="file"
            />
            <TextField
              placeholder=""
              variant="outlined"
              fullWidth={false}
              className={classes.textField}
              label="Attach a file"
              value={this.state.fileName}
              inputProps={{
                "aria-label": "Description"
              }}
            />
            <div style={{ display: "inline-block", marginLeft: "0%", marginTop: "10px" }}>
              <label htmlFor="contained-button-file">
                <IconButtonComponent 
                  type={"attach"}
                  iconStyle={"iconAction"}
                />
              </label>
            </div>
          </form>
           </DialogContent>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} color="primary" autoFocus>
              Close
            </Button>
            <Button
                  color="secondary"
                  variant="contained"
                  component="span"
                  className={classes.button}
                  onClick={this.handleSubmit}
                >
                  Submit file
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

IdeaAttachDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(IdeaAttachDialog)
);
