import React, { Component } from "react";
import { connect } from "react-redux";
import "./MyWallet.css";
import { IDEATOR, COLLABORATOR } from "../../constants";
import { withCookies } from "react-cookie";
import { Help } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import {
  launchAlert,
  getEquityByIdea,
  getPendingEquityForIdea,
  getRemainingTokens
} from "../../store/actions/actions";

class MyWalletIdea extends Component {
  state = {
    collaboratorList: []
  };

  componentWillMount() {
    const { ideaID, jwtToken } = this.props;
    if (ideaID !== undefined) {
      this.props.getEquityByIdea(jwtToken, ideaID);
      this.props.getPendingEquityForIdea(jwtToken, ideaID);
      this.props.getRemainingTokens(jwtToken, ideaID)
    }
  }

  renderProgressBar = () => {
    // const _userType = userType || cookies.get("user_type");
    // const middlewareActive = _userType === IDEATOR ? true : false;
    let myWallet = [];
    let available = 0;
    let pending = 0;
    let issued = 0;

    if (this.props.equityHolders) {
      for (let i = 0; i < this.props.equityHolders.length; i++) {
        if (this.props.equityHolders[i].id !== 0 && i > 0) {
          issued += this.props.equityHolders[i].tokensOwned;
        }
        //  else if (this.props.equityHolders[i].id === 0) {
        //   pending += this.props.equityHolders[i].tokensOwned;
        // }
         else {
          available = this.props.availableTokens;
        }
      }
    }

    // let available2 = this.props.availableTokens
    // console.log("AVAILABLE TOKENS::::", available2);

    pending = (100 - (issued + this.props.availableTokens))
    

    if (this.props.equityHolders) {
      myWallet.push({
        barChartTitle: "Total Idea Equity Available to Issue",
        progressValue: available
      });
      myWallet.push({
        barChartTitle: "Total Idea Equity Pending Milestones",
        progressValue: pending
      });
      myWallet.push({
        barChartTitle: "Total Idea Equity Issued to Collaborators",
        progressValue: issued
      });
    } else {
      myWallet.push(this.props.myWallet);
    }

    return myWallet.map(({ barChartTitle, progressValue }, key) => (
      <div key={key}>
        <div className="wallet__title-idea">{barChartTitle}</div>
        <div className="wallet__progress-bar-background">
          <div
            className="wallet__progress-bar-foreground"
            style={{
              width: Math.ceil((progressValue * 100) / 100) + "%"
            }}
          >
            <div className="wallet__progress-text">{progressValue}/100</div>
          </div>
        </div>
      </div>
    ));
  };

  render() {
    return <div className="wallet__wrapper">{this.renderProgressBar()}</div>;
  }
}

const mapStateToProps = state => ({
  userType: state.userType,
  equityHolders: state.equityHolders,
  pendingEquity: state.pendingEquity,
  availableTokens: state.availableTokens
});

const mapDispatchToProps = dispatch => ({
  launchAlert: payload => dispatch(launchAlert(payload)),
  getEquityByIdea: (jwtToken, ideaID) => {
    getEquityByIdea(jwtToken, ideaID, dispatch);
  },
  getPendingEquityForIdea: (jwtToken, ideaID) => {
    getPendingEquityForIdea(jwtToken, ideaID, dispatch);
  },
  getRemainingTokens: (jwtToken, ideaID) => {
    getRemainingTokens(jwtToken, ideaID, dispatch)
  }
});

export default withCookies(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyWalletIdea)
);
