import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Hoc from "../../hoc/Hoc";
import "./Layout.css";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

const layout = props => (
  <Hoc>
    <ProgressBar show={props.waiting} />
    <main className="Content">{props.children}</main>
  </Hoc>
);

const mapStateToProps = state => {
  return {
    waiting: state.waiting,
    isAuthenticated: state.isAuthenticated
  };
};

export default connect(mapStateToProps)(layout);
