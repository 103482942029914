import fire, { hostApi, timeOut } from "../../constants";
import firebase from "../../constants";

import axios from "axios";

import store from "../store"; // need access to the store to read the JWT token

export const SET_MY_SPRKS_TAB = "SET_MY_SPRKS_TAB";
export const STOP_BUSY = "STOP_BUSY";
export const BUSY = "BUSY";
export const SHOW_SEARCH_MODAL = "SHOW_SEARCH_MODAL";
export const SHOW_COLLAB_REQUEST_MODAL = "SHOW_COLLAB_REQUEST_MODAL";
export const SEARCH_CRITERIA = "SEARCH_CRITERIA";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const TERMS_ALERT = "TERMS_ALERT";
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const SHOW_CREATE_IDEA_MODAL = "SHOW_CREATE_IDEA_MODAL";
export const LOG_OUT = "LOG_OUT";
export const ANIMATION = "ANIMATION";
export const COLLAB_REQUEST = "COLLAB_REQUEST";
export const ENQUIRY_REQUEST = "ENQUIRY_REQUEST";
export const REQUEST_FOR_UNIQUE = "REQUEST_FOR_UNIQUE";
export const SHOW_ENQUIRY_MODAL = "SHOW_ENQUIRY_MODAL";
export const SHOW_REQUEST_FOR_UNIQUE_MODAL = "SHOW_REQUEST_FOR_UNIQUE_MODAL";
export const STORE_SEARCH_RESULTS = "STORE_SEARCH_RESULTS";
export const STORE_GLOBAL_SEARCH_RESULTS = "STORE_GLOBAL_SEARCH_RESULTS";
export const STORE_GLOBAL_SEARCH_CV_RESULTS = "STORE_GLOBAL_SEARCH_CV_RESULTS";
export const STORE_GLOBAL_SKILL_RESULTS = "STORE_GLOBAL_SKILL_RESULTS";
// NC 18/02/19
export const SHOW_LEGAL_MODAL = "SHOW_LEGAL_MODAL";
export const CLOSE_LEGAL_MODAL = "CLOSE_LEGAL_MODAL";
//
export const SHOW_COMP_MODAL = "SHOW_COMP_MODAL";
export const CLOSE_COMP_MODAL = "CLOSE_COMP_MODAL";
export const SHOW_PRIV_MODAL = "SHOW_PRIV_MODAL";
export const CLOSE_PRIV_MODAL = "CLOSE_PRIV_MODAL";
export const SHOW_COOKIE_MODAL = "SHOW_COOKIE_MODAL";
export const CLOSE_COOKIE_MODAL = "CLOSE_COOKIE_MODAL";
export const SHOW_COOKIE_TOASTER = "SHOW_COOKIE_TOASTER";
export const CLOSE_COOKIE_TOASTER = "CLOSE_COOKIE_TOASTER";
export const SHOW_TERMS_MODAL = "SHOW_TERMS_MODAL";
export const CLOSE_TERMS_MODAL = "CLOSE_TERMS_MODAL";
export const SHOW_COMP_ENTERED_MODAL = "SHOW_COMP_ENTERED_MODAL";
export const CLOSE_COMP_ENTERED_MODAL = "CLOSE_COMP_ENTERED_MODAL";
export const SHOW_ACCPT_USE_POL = "SHOW_ACCPT_USE_POL";
export const CLOSE_ACCPT_USE_POL = "CLOSE_ACCPT_USE_POL";
export const SHOW_IDEA_SUBMITTED_NTF = "SHOW_IDEA_SUBMITTED_NTF";
export const CLOSE_IDEA_SUBMITTED_NTF = "CLOSE_IDEA_SUBMITTED_NTF";
export const SHOW_CREATE_IDEA_EXISTING = "SHOW_CREATE_IDEA_EXISTING";
export const CLOSE_CREATE_IDEA_EXISTING = "CLOSE_CREATE_IDEA_EXISTING";
export const SHOW_CHAT_MODAL = "SHOW_CHAT_MODAL";
export const CLOSE_CHAT_MODAL = "CLOSE_CHAT_MODAL";
export const SHOW_ACCOUNT_MODAL = "SHOW_ACCOUNT_MODAL";
export const CLOSE_ACCOUNT_MODAL = "CLOSE_ACCOUNT_MODAL";
export const START_CHAT_WITH_PERSON = "START_CHAT_WITH_PERSON";
export const SHOW_IDEA_DETAILS = "SHOW_IDEA_DETAILS";
export const CLOSE_IDEA_DETAILS = "CLOSE_IDEA_DETAILS";
export const SHOW_EDIT_IDEA = "SHOW_EDIT_IDEA";
export const CLOSE_EDIT_IDEA = "CLOSE_EDIT_IDEA";
export const TOGGLE_THEME = "TOGGLE_THEME";
export const FIREBASE_UID = "FIREBASE_UID";
export const GET_COLLABORATORS_BY_IDEA = "GET_COLLABORATORS_BY_IDEA";
export const GET_COLLABORATORS_BY_IDEA_FOR_MILESTONE =
  "GET_COLLABORATORS_BY_IDEA_FOR_MILESTONE";
export const GET_MY_IDEAS_FOR_DASHBOARD = "GET_MY_IDEAS_FOR_DASHBOARD";
export const GET_MILESTONES_BY_IDEA = "GET_MILESTONES_BY_IDEA";
export const SET_IDEA_TO_EDIT = "SET_IDEA_TO_EDIT";
export const UNSET_IDEA_TO_EDIT = "UNSET_IDEA_TO_EDIT";
export const GET_AVAILABLE_TOKENS_BY_IDEA = "GET_AVAILABLE_TOKENS_BY_IDEA";
export const GET_EQUITY_BY_IDEA = "GET_EQUITY_BY_IDEA";
export const GET_EQUITY_PER_IDEA = "GET_EQUITY_PER_IDEA";
export const GET_PENDING_EQUITY_BY_IDEA = "GET_PENDING_EQUITY_BY_IDEA";
export const UPDATE_EQUITY_HOLDERS = "UPDATE_EQUITY_HOLDERS";
export const SHOW_EDIT_PROFILE_MODAL = "SHOW_EDIT_PROFILE_MODAL";

export const GET_MY_PROFILE = "GET_MY_PROFILE";
export const GET_MY_NAME = "GET_MY_NAME";
export const INCREMENT = "INCREMENT";
export const TOGGLE_CARD_VIEW = "TOGGLE_CARD_VIEW";
export const SHOW_CARD_VIEW = "SHOW_CARD_VIEW";
export const GET_FILE_LIST = "GET_FILE_LIST";

export const SHOW_RTWOI_PROFILE = "SHOW_RTWOI_PROFILE";

export const GET_COLLABORATOR = "GET_COLLABORATOR";
export const GET_ALL_COLLABORATOR = "GET_ALL_COLLABORATOR";
export const PUT_COLLABORATOR = "PUT_COLLABORATOR";
export const POST_COLLABORATOR = "POST_COLLABORATOR";
export const SEARCH_COLLABORATOR = "SEARCH_COLLABORATOR";
export const COLLABORATOR_AUTHENTICATED = "COLLABORATOR_AUTHENTICATED";
export const IDEATOR_AUTHENTICATED = "IDEATOR_AUTHENTICATED";
export const GET_INVITATION_DATA = "GET_INVITATION_DATA";
export const GET_INVITATION_RTWOI_DATA = "GET_INVITATION_RTWOI_DATA";
export const LAUNCH_ALERT = "LAUNCH_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";
export const LAUNCH_TIMEOUT = "LAUNCH_TIMEOUT";
export const CLOSE_TIMEOUT = "CLOSE_TIMEOUT";
export const LAUNCH_SESSION_WARNING = "LAUNCH_SESSION_WARNING";
export const CLOSE_SESSION_WARNING = "CLOSE_SESSION_WARNING";
export const JWT_TOKEN = "JWT_TOKEN";
export const STORE_COLLABORATOR_ID = "STORE_COLLABORATOR_ID";
export const GET_AVAILABLE_TOKENS = "GET_AVAILABLE_TOKENS";

const baseUrl = hostApi;
//const baseUrl = "http://localhost:8081"; // test
//const testBaseUrl = "http://localhost:8081"; // test

/**
 * Action creator for saving the token on redux.
 * @param {string} token - Jason Web Token
 */
export const saveToken = (cookies, token, dispatch) => {
  dispatch({ type: JWT_TOKEN, payload: token });
  cookies.set("bearerToken", token, { path: "/", maxAge: timeOut });
};

/**
 * Auth http request, returns a JWT token in the response.
 * @param {string} username - e.g. email.address@email.com
 * @param {string} password - password string
 * @param {function} callback - function to be called on success
 * @param {function} dispatch - redux dispatch function
 */
export const jwtAuth = (username, password, callback, dispatch) => {
  axios
    .post(
      `${baseUrl}/auth`,
      {
        username: username,
        password: password
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    )
    .then(
      // success
      ({ data, status }) => {
        console.log("jwtAuth", data, status);
        callback(data);
        /* return dispatch({
          type: JWT_TOKEN,
          payload: data
        }); */
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });
};

export const launchSessionWarning = () => ({
  type: LAUNCH_SESSION_WARNING
});

export const closeSessionWarning = () => ({
  type: CLOSE_SESSION_WARNING
});

export const launchTimeout = () => ({
  type: LAUNCH_TIMEOUT
});

export const closeTimeout = () => ({
  type: CLOSE_TIMEOUT
});

export const editProfile = (payload, jwtToken, callback) => {
  axios
    .post(
      `${hostApi}/editProfile`,
      {
        email_add: payload.email_add,
        firstname: payload.firstname,
        surname: payload.surname,
        lkp_country: "27",
        mobile_cc: "27",
        profileType: payload.profileType,
        // "desktop_notif" : payload.desktop_notif,
        mobile_no: "843117860",
        opt_in_comp: payload.opt_in_comp,
        opt_in_notif: payload.opt_in_notif,
        existingPasswrd: payload.existingPasswrd,
        passwrd: payload.passwrd,
        passwrd2: payload.passwrd2
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(response => {
      console.log("Edit Profile Response", response.data);
      callback(response.status);
    })
    .catch(error => {
      callback(error.response.status);
    });
};

export const lodgeConsensus = (
  jwtToken,
  approvalCode,
  profileId,
  milestoneId,
  callback
) => {
  console.log("lodgeConsensus", milestoneId, profileId, approvalCode);

  axios
    .post(
      `${hostApi}/lodgeConsensus`,
      {
        milestone_id: milestoneId,
        profile: profileId,
        approval: approvalCode
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(response => {
      console.log("milestone data after axios", response.data);
      callback(response.status);
    })
    .catch(error => {
      //callback(error.response.status);
      console.log("UNABLE TO COMPUTE LODGE CONSENSUS");
    });
};

export const launchAlert = payload => ({
  type: LAUNCH_ALERT,
  payload
});

export const closeAlert = () => ({
  type: CLOSE_ALERT
});

export const sendRWOI = (sendFirebaseid, receiveFirebaseID) => {
  //console.log("CREATE FIREBASE CHAT RtWOI", sendFirebaseid, receiveFirebaseID);
  //const token = store.getState().jwtToken;
  var message =
    "Hi! I have accepted your request to collaborate. Lets make our idea into a reality.";
  const dateTime = Date.now();
  const timestamp = Math.floor(dateTime / 1000);
  var values = {
    type: "text",
    content: message,
    fromID: sendFirebaseid /*currentUserID*/,
    toID: receiveFirebaseID,
    timestamp: timestamp,
    isRead: false
  };
  firebase
    .database()
    .ref()
    .child("users")
    .child(sendFirebaseid) //Current User(Sender)
    .child("conversations")
    .child(receiveFirebaseID) //Receiver
    .once("value")
    .then(snapshot => {
      if (snapshot.exists()) {
        //console.log("It exists");

        //console.log(values);
        const locRef = fire
          .database()
          .ref()
          .child("conversations")
          .child(snapshot.val()["location"])
          .push(values);
      } else {
        fire
          .database()
          .ref()
          .child("users")
          .child(sendFirebaseid) //Current User(Sender)
          .child("conversations")
          .child(receiveFirebaseID) //Receiver
          .once("value")
          .then(snapshot => {
            console.log("Doesn't exists");
            const messLoc = fire
              .database()
              .ref()
              .child("conversations")
              .push()
              .push(values);
            const messLocKey = messLoc.parent.key;
            let sampleDict = { location: messLocKey };
            fire
              .database()
              .ref()
              .child("users")
              .child(sendFirebaseid) //Current user(Sender)
              .child("conversations")
              .child(receiveFirebaseID) //Receiver
              .update(sampleDict);

            fire
              .database()
              .ref()
              .child("users")
              .child(receiveFirebaseID) //Receiver
              .child("conversations")
              .child(sendFirebaseid) //Current User (Sender)
              .update(sampleDict);
          });
      }
    });
};

/**
 * Posts a get request to retrieve all ivitations for the given collaboratorID.
 * @param {int} collaboratorID - integer value of the profile ID
 * @param {string} filter - "ideator", "collaborator" or null for get all.
 * @param {Function} callback - callback function that will get called when response received
 * @param {Function} dispatch - redux dispatch function
 */
export const getInvitations = (
  jwtToken,
  collaboratorID,
  filter,
  callback,
  dispatch
) => {
  let url = `${hostApi}/collaborations/invitation/${collaboratorID}`;
  if (filter) {
    url = `${hostApi}/collaborations/invitation/${collaboratorID}?pending=collaborator`;
  }

  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${jwtToken}`
      }
    })
    .then(
      // success
      ({ data, status }) => {
        if (callback) {
          //callbacks are optional
          callback(status);
        }

        console.log(
          "Get Invitations getInvitations COLLABORATOR+++++++++++++++++++",
          data
        );
        return dispatch({
          type: GET_INVITATION_DATA,
          payload: data
        });
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      // console.log("=================getInvitations  ===================");
      // console.log(error);
      // console.log("====================================");
      // console.log(error.config);
    });
};

export const getInvitationsRTWOI = (
  jwtToken,
  collaboratorID,
  filter,
  callback,
  dispatch
) => {
  let url = `${hostApi}/collaborations/invitation/${collaboratorID}`;
  if (filter) {
    console.log("GETTING RTWOI  WITH FILTER", filter);
    url = `${hostApi}/collaborations/invitation/${collaboratorID}?pending=collaborator`;
  }

  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${jwtToken}`
      }
    })
    .then(
      // success
      ({ data, status }) => {
        if (callback) {
          //callbacks are optional
          callback(status);
        }

        return dispatch({
          type: GET_INVITATION_RTWOI_DATA,
          payload: data
        });
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("=================getInvitations  ===================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });
};

export const inviteCollaborator = (
  jwtToken,
  collaboratorID,
  ideaID,
  callback
) => {
  //const token = store.getState().jwtToken;
  console.log("JWT", jwtToken);
  axios
    .post(
      `${hostApi}/collaborations/invitation`,
      {
        collaboratorID: collaboratorID,
        ideaID: ideaID
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(
      // success
      ({ data, status }) => {
        console.log("status", data, status);
        if (callback) {
          //callbacks are optional
          callback({ data, status });
        }
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (callback) {
          callback({
            data: error.response.data,
            status: error.response.status
          });
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });
};

export const searchCollaborators = (
  jwtToken,
  searchString,
  callback = null,
  dispatch
) => {
  //const token = store.getState().jwtToken;

  axios
    .get(
      `${baseUrl}/collaborators?skill=${searchString}`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
      //      `${testBaseUrl}/collaborator?q=${searchString}`
    )
    .then(
      // success
      ({ data, status }) => {
        console.log("status", data, status);
        if (callback) {
          //callbacks are optional
          callback({ data, status });
        }
        return dispatch({
          type: SEARCH_COLLABORATOR,
          payload: data
        });
      }
    )
    .catch(error => {
      callback(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });
};

export const getAllCollaborator = (
  jwtToken,
  profile_id,
  callback,
  dispatch
) => {
  console.log("COLLABORATORS", profile_id);
  axios
    .get(`${baseUrl}/collaborator?id=${profile_id}`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`
      }
    })
    .then(
      // success
      ({ data, status }) => {
        // console.log("status", data, status);
        if (callback) {
          //callbacks are optional
          // console.log("TRY");
          callback(data);
        }
        return dispatch({
          type: GET_ALL_COLLABORATOR,
          payload: data
        });
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });
};

export const putCollaborator = (
  jwtToken,
  collaboratorPayload,
  callback,
  dispatch
) => {
  const token = store.getState().jwtToken;
  axios
    .put(
      `${baseUrl}/collaborator/${collaboratorPayload.id}`,
      collaboratorPayload,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(
      // success
      ({ data, status }) => {
        console.log("payload", collaboratorPayload);
        console.log("status", data, status);
        callback(data);
        return dispatch({
          type: PUT_COLLABORATOR,
          payload: data
        });
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });
};

export const getCollaborator = (jwtToken, collaboratorID, dispatch) => {
  const token = store.getState().jwtToken;
  axios({
    method: "get",
    baseURL: `${baseUrl}`,
    url: `/collaborator/${collaboratorID}`,
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  })
    .then(
      // success
      ({ data, status }) => {
        // console.log("status", status);

        return dispatch({
          type: GET_COLLABORATOR,
          payload: data
        });
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });
};

export const dispatchAuth = (profileType, collaboratorID, dispatch) => {
  //console.log('======dispatchAuth======', profileType);
  // console.log("DISPATCH AUTH", collaboratorID);
  //console.log('====================================');
  return dispatch({
    type:
      profileType === "3" ? COLLABORATOR_AUTHENTICATED : IDEATOR_AUTHENTICATED,
    payload: collaboratorID
  });
};

/**
 * This action creator stores the user id in the global redux store.
 * @param {integer} collaboratorID - the user id
 * @param {function} dispatch - the redux store dispatch function
 */
export const saveCollabID = (collaboratorID, dispatch) => {
  return dispatch({
    type: STORE_COLLABORATOR_ID,
    payload: collaboratorID
  });
};

export const postCollaborator = (collaboratorPayload, callback) => {
  const token = store.getState().jwtToken;
  axios
    .post(`${baseUrl}/collaborator`, collaboratorPayload, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
    .then(
      // success
      ({ data }) => {
        /* let data_copy = {
          error: null,
          id: null
        }
        console.log("callback data", data["id:"]);
        data_copy.id = data["id:"];
        data_copy.error = data.error; */
        callback(data);
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("============ERROR===================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });
};

/**
 * function: getFileList()
 * Description: When given an idea ID, this action
 * creator function will retrieve the file attachments
 * for the idea and dispatch an action to store the filenames
 * on the redux state.
 */
export const getFileList = (jwtToken, ideaUniqueID, dispatch) => {
  //console.log("GETTING JWT, IDEA", jwtToken, ideaUniqueID);
  //const token = store.getState().jwtToken;
  axios
    .post(
      `${hostApi}/getAllIdeaFiles`,
      {
        ideaUniqueID: ideaUniqueID
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(
      // success
      ({ data }) => {
        let filename = data[0].filename;

        if (filename && firebase.auth().currentUser) {
          var uid = firebase.auth().currentUser.uid;
          // Points to the root reference
          var storageRef = firebase.storage().ref();

          // Points to 'All user files'
          var filename2 = storageRef
            .child("AllUsersFileData")
            .child(uid)
            .child("myIdeas")
            .child(filename);
          storageRef
            .child(filename2.location.path)
            .getDownloadURL()
            .then(url => {
              var xhr = new XMLHttpRequest();
              xhr.responseType = "blob";

              xhr.onload = event => {
                var blob = xhr.response;
                //console.log("FILE:::", blob, window.URL.createObjectURL(blob));
                //this.setState({ file: window.URL.createObjectURL(blob), blob });
              };
              xhr.open("GET", url);
              xhr.send();

              console.log("THE NEW URL:::", url);
              return dispatch({
                type: GET_FILE_LIST,
                payload: { [ideaUniqueID]: [{ filename, url }] }
              });
            })
            .catch(error => {
              console.log("ERROR:", error);
            });
        } else {
          console.log("No file");
        }
      }
    );
};
/* ===================================================== */

export const launchCreateIdea = () => {
  return (dispatch, getState) => {
    //console.log(getState());
    return dispatch({
      type: SHOW_CREATE_IDEA_MODAL
    });
  };
};

export const launchEditProfile = () => {
  return (dispatch, getState) => {
    //console.log(getState());
    return dispatch({
      type: SHOW_EDIT_PROFILE_MODAL
    });
  };
};

export const getMyProfile = (jwtToken, emailAddress, dispatch) => {
  //console.log("MY Profile");
  axios
    .post(
      `${baseUrl}/myProfile`,
      {
        email_add: emailAddress
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(
      // success
      ({ data }) => {
        console.log("PAYLOAD", data);
        return dispatch({
          type: GET_MY_PROFILE,
          payload: data
        });
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("============ERROR===================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });
  //////////////////
};

/**
 * This function sends an http GET request to `myIdeasSummary/`.
 * @param {string} emailAddress - idea owner email address
 */
export const getMyIdeasForDashBoard = (jwtToken, emailAddress, dispatch) => {
  // console.log("MY IDEAS SUMMARY");
  //const token = store.getState().jwtToken;
  axios
    .post(
      `${baseUrl}/myIdeasSummary`,
      {
        lkp_email: emailAddress
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(
      // success
      ({ data }) => {
        //console.log("PAYLOAD", data)
        return dispatch({
          type: GET_MY_IDEAS_FOR_DASHBOARD,
          payload: data
        });
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("============ERROR===================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });
  //////////////////
};

export const showRTWOIProfile = (jwtToken, v, dispatch) => {
  console.log("Showing Values", v);
  const token = store.getState().jwtToken;
  console.log("Showing token", token);
  axios({
    method: "get",
    baseURL: `${baseUrl}`,
    url: `/collaborator/${v[0].collabId}`,
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  })
    .then(
      // success
      ({ data, status }) => {
        console.log(data);
        const profile = {
          profileId: data.id,
          profileName: data.firstname,
          profileSurname: data.surname,
          profileDesc: data.profileDesc,
          profileFirebaseId: data.firebaseUid,
          profileSkils: data.skills,
          profileReason: v[0].collabReason,
          profileValue: v[0].collabValue
        };

        return dispatch({
          type: SHOW_RTWOI_PROFILE,
          payload: profile
        });
      }
    )
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      console.log(error.config);
    });

  // return {
  //   type: SHOW_RTWOI_PROFILE,
  //   payload
  // }
};

export const setIdeaToEdit = payload => {
  return {
    type: SET_IDEA_TO_EDIT,
    payload
  };
};

export const unsetIdeaToEdit = () => {
  return {
    type: UNSET_IDEA_TO_EDIT
  };
};

export const toggleCardView = () => {
  return {
    type: TOGGLE_CARD_VIEW
  };
};

export const showCardView = () => {
  return {
    type: SHOW_CARD_VIEW
  };
};

export const getEquityByIdea = (jwtToken, ideaID, dispatch) => {
  //const token = store.getState().jwtToken;
  axios
    .post(
      `${hostApi}/showAllEquityForIdea`,
      {
        idea_id: ideaID
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(
      // success
      ({ data }) => {
        console.log("EQUITY DATA", data);
        return dispatch({
          type: GET_EQUITY_BY_IDEA,
          payload: data
        });
      }
    );
};

export const getEquityPerIdea = (jwtToken, ideaID, dispatch) => {
  const token = store.getState().jwtToken;
  axios
    .post(
      `${hostApi}/showEquityForIdea`,
      {
        idea_id: ideaID
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(
      // success
      ({ data }) => {
        console.log("EQUITY DATA fro", data);
        return dispatch({
          type: GET_EQUITY_PER_IDEA,
          payload: data
        });
      }
    );
};

export const getPendingEquityForIdea = (jwtToken, ideaID, dispatch) => {
  const token = store.getState().jwtToken;
  axios
    .post(
      `${hostApi}/showPendingEquityForIdea`,
      {
        idea_id: ideaID
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(
      // success
      ({ data }) => {
        console.log("PENDING EQUITY DATA Actions.js", data);
        return dispatch({
          type: GET_PENDING_EQUITY_BY_IDEA,
          payload: data
        });
      }
    );
};

export const updateEquityHolders = payload => {
  return {
    type: UPDATE_EQUITY_HOLDERS,
    payload: payload
  };
};

export const getRemainingTokens = (jwtToken, ideaID, dispatch) => {
  //const token = store.getState().jwtToken;
  axios
    .post(
      `${hostApi}/showAvailableTokensToOffer`,
      {
        idea_uniqueid: ideaID
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(
      // success
      ({ data }) => {
        return dispatch({
          type: GET_AVAILABLE_TOKENS_BY_IDEA,
          payload: data
        });
      }
    );
};

/**
 * @description
 * This function sends a request to `/viewMilestone` which will then retrieve
 * the milestones in a list of objects.
 *
 * @param {integer} ideaID
 * @param {integer} collaboratorID
 * @param {function} dispatch
 */
export const getMilestones = (jwtToken, ideaID, collaboratorID, dispatch) => {
  console.log("getMilestones()", ideaID, collaboratorID);

  axios({
    method: "post",
    url: `${hostApi}/viewMilestone`,
    data: {
      idea_id: ideaID ? ideaID : "0",
      id: 0,
      collab_id: collaboratorID,
      owner_profile: ""
    },
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  }).then(
    // success
    ({ data }) => {
      // Sort the data by time value.
      data.sort(
        (a, b) => a.agreedCompletionTimestamp - b.agreedCompletionTimestamp
      );
      console.log("DATA FROM GET MS", data);
      return dispatch({
        type: GET_MILESTONES_BY_IDEA,
        payload: data
      });
    }
  );
};

// FIXME: filter out approvals = 2
export const getCollaborators = (jwtToken, payload, dispatch) => {
  if (payload) {
    // KN 2018.12.28 // Implemented this check so that API does not get called with no payload data
    //const token = store.getState().jwtToken;

    axios
      .post(
        `${hostApi}/getCollaboratorsByIdea`,
        {
          idea_ID: payload
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`
          }
        }
      )
      .then(
        // success
        ({ data }) => {
          return dispatch({
            type: GET_COLLABORATORS_BY_IDEA,
            payload: data //.filter(item => item.collabApproved !== 2)
          });
        }
      );
  }
  return (dispatch, getState) => {};
};

export const getCollaboratorsForMilestone = (jwtToken, payload, dispatch) => {
  //const token = store.getState().jwtToken;
  axios
    .post(
      `${hostApi}/getCollaboratorsByIdeaForMilestone`,
      {
        idea_ID: payload
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
    .then(
      // success
      ({ data }) => {
        return dispatch({
          type: GET_COLLABORATORS_BY_IDEA_FOR_MILESTONE,
          payload: data
        });
      }
    );
  return (dispatch, getState) => {
    //console.log("collab", payload);
  };
};

export const setMySprxsTab = payload => ({ type: SET_MY_SPRKS_TAB, payload });

export const dispatchFirebaseUid = payload => {
  return {
    type: FIREBASE_UID,
    payload: payload
  };
};

export const increment = payload => {
  return {
    type: INCREMENT,
    payload: payload
  };
};

export const storeSearchResults = payload => {
  return {
    type: STORE_SEARCH_RESULTS,
    payload: payload
  };
};

export const storeGlobalSearchResults = payload => {
  return {
    type: STORE_GLOBAL_SEARCH_RESULTS,
    payload: payload
  };
};
export const storeGlobalSearchCvResults = payload => {
  return {
    type: STORE_GLOBAL_SEARCH_CV_RESULTS,
    payload: payload
  };
};

export const storeGlobalSkillResults = payload => {
  return {
    type: STORE_GLOBAL_SKILL_RESULTS,
    payload: payload
  };
};

export const logOut = (cookies, dispatch) => {
  if (cookies) {
    cookies.remove("collab_id");
    cookies.remove("user_type");
    cookies.remove("name");
    cookies.remove("last_name");
    cookies.remove("fireId");
    cookies.remove("emailAddress");
    cookies.remove("isAuthenticated");
    cookies.remove("joinType");
    cookies.remove("bearerToken");
  }
  window.sessionStorage.removeItem("userInfo");
  fire
    .auth()
    .signOut()
    .then(
      function() {
        console.log("Firebase: Signed Out");
      },
      function(error) {
        console.error("Firebase: Sign Out Error", error);
      }
    );
  return dispatch({
    type: LOG_OUT
  });
};

export const busy = () => {
  return {
    type: BUSY
  };
};

export const isAuthenticated = emailAddress => {
  return {
    type: IS_AUTHENTICATED,
    payload: {
      email: emailAddress
    }
  };
};

export const termsAlert = () => {
  return {
    type: TERMS_ALERT
  };
};

export const showSearchModal = () => {
  return {
    type: SHOW_SEARCH_MODAL
  };
};

export const showLegalModal = policyType => {
  return {
    type: SHOW_LEGAL_MODAL,
    payload: {
      policyType: policyType
    }
  };
};

export const closeLegalModal = () => {
  return {
    type: CLOSE_LEGAL_MODAL
  };
};

export const showCompModal = () => {
  return {
    type: SHOW_COMP_MODAL
  };
};

export const closeCompModal = () => {
  return {
    type: CLOSE_COMP_MODAL
  };
};

export const showPrivModal = () => {
  return {
    type: SHOW_PRIV_MODAL
  };
};

export const closePrivModal = () => {
  return {
    type: CLOSE_PRIV_MODAL
  };
};

export const showCookiePolicy = () => {
  return {
    type: SHOW_COOKIE_MODAL
  };
};
export const closeCookiePolicy = () => {
  return {
    type: CLOSE_COOKIE_MODAL
  };
};

export const showCookieToaster = () => {
  return {
    type: SHOW_COOKIE_TOASTER
  };
};
export const closeCookieToaster = () => {
  return {
    type: CLOSE_COOKIE_TOASTER
  };
};

export const showTermsPolicy = () => {
  return {
    type: SHOW_TERMS_MODAL
  };
};
export const closeTermsPolicy = () => {
  return {
    type: CLOSE_TERMS_MODAL
  };
};

export const showCompEntered = () => {
  return {
    type: SHOW_COMP_ENTERED_MODAL
  };
};

export const closeCompEntered = () => {
  return {
    type: CLOSE_COMP_ENTERED_MODAL
  };
};

export const showAcceptUsePol = () => {
  return {
    type: SHOW_ACCPT_USE_POL
  };
};

export const closeAcceptUsePol = () => {
  return {
    type: CLOSE_ACCPT_USE_POL
  };
};

export const showIdeaSubmittedNTF = () => {
  return {
    type: SHOW_IDEA_SUBMITTED_NTF
  };
};

export const closeIdeaSubmittedNTF = () => {
  return {
    type: CLOSE_IDEA_SUBMITTED_NTF
  };
};

export const showCreateIdeaExisting = () => {
  return {
    type: SHOW_CREATE_IDEA_EXISTING
  };
};

export const closeCreateIdeaExisting = () => {
  return {
    type: CLOSE_CREATE_IDEA_EXISTING
  };
};

export const startChatWithPerson = payload => {
  return {
    type: START_CHAT_WITH_PERSON,
    payload
  };
};

export const showChatModal = () => {
  return {
    type: SHOW_CHAT_MODAL
  };
};

export const closeChatModal = () => {
  return {
    type: CLOSE_CHAT_MODAL
  };
};

export const showAccountModal = () => {
  return {
    type: SHOW_ACCOUNT_MODAL
  };
};

export const closeAccountModal = () => {
  return {
    type: CLOSE_ACCOUNT_MODAL
  };
};

export const showIdeaDetails = () => {
  return {
    type: SHOW_IDEA_DETAILS
  };
};

export const closeIdeaDetails = () => {
  return {
    type: CLOSE_IDEA_DETAILS
  };
};

export const showEditIdea = () => {
  return {
    type: SHOW_EDIT_IDEA
  };
};

export const closeEditIdea = () => {
  return {
    type: CLOSE_EDIT_IDEA
  };
};

export const toggleTheme = () => {
  return {
    type: TOGGLE_THEME
  };
};
