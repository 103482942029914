import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import registerServiceWorker from "./registerServiceWorker";
import { CookiesProvider } from "react-cookie";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';


const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      light: "#ff7358",
      main: "#ff512f",
      dark: "#b23820",
      contrastText: "#fff"
    },
    secondary: {
      light: "#0b358e",
      main: "#114DCC",
      dark: "#4070d6",
      contrastText: "#000"
    },
    
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Neuton, serif"'
    ],
  },
  
});

ReactDOM.render(
  
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
        <MuiThemeProvider theme={theme}>
        <CssBaseline />
          <App />
        </MuiThemeProvider>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  ,
  document.getElementById("root")
);
registerServiceWorker();
