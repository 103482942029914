import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%",
  },
});

function CookiePolicy( {handleChange}) {

  return (
    <div className="legalWrapper">
      <h1 className="legalHeader">
        Information about our use of cookies
      </h1>
      <div className="legalContent">
          <h3 className="legalSubHead">
            What's in these terms?
          </h3>
          <p className="legalCopy">
            Our website uses cookies to distinguish you from other users of
              our website. This helps us to provide you with a good experience
              when you browse our website and also allows us to improve our
              site. [<i>By continuing to browse the site, you are agreeing to our
              use of cookies.</i>]<br />
              A cookie is a small file of letters and numbers that we store on
              your browser or the hard drive of your computer if you agree.
              Cookies contain information that is transferred to your
              computer's hard drive.
          </p>
           <h3 className="legalSubHead">
             We use the following cookies:
          </h3>
          <p className="legalCopy">
          <span className="copySubHead"> Strictly necessary cookies.</span>
            These are cookies that are required for the operation of our
              website. They include, for example, cookies that enable you to
              log into secure areas of our website, use a shopping cart or
              make use of e-billing services.
               <span className="copySubHead"> Analytical / performance cookies.</span>
               They allow us to recognise and count the number of visitors and
              to see how visitors move around our website when they are using
              it. This helps us to improve the way our website works, for
              example, by ensuring that users are finding what they are
              looking for easily.
              <span className="copySubHead"> Targeting cookies.</span>
              These cookies record your visit to our website, the pages you
              have visited and the links you have followed. We will use this
              information to make our website and the advertising displayed on
              it more relevant to your interests. We may also share this
              information with third parties for this purpose.
              You can find more information about the individual cookies we
              use and the purposes for which we use them below:
              <span className="copySubHead cookie"> Cookie Name: <i>isAuthenticated</i></span>
              Purpose: Allow you to use our site in a way that makes your
              browsing experience more convenient, for example, by allowing
              you to store items in an electronic shopping basket between
              visits. If you register with us or complete our online forms, we
              will use cookies to remember your details during your current
              visit, and any future visits provided the cookie was not deleted
              in the interim.
              <span className="copySubHead cookie"> Cookie Name: <i>emailAddress</i></span>
              Purpose: Recognise you when you return to our site.
              <span className="copySubHead cookie">Cookie Name: <i>cookiePolicyAccepted</i></span>
              Purpose: Store information about your preferences, and so allow
              us to customise our site and to provide you with offers that are
              targeted at your individual interests.<br />
               [Please note that third parties (including, for example,
              advertising networks and providers of external services like web
              traffic analysis services) may also use cookies, over which we
              have no control. These cookies are likely to be
              analytical/performance cookies or targeting cookies].
              <br />You block cookies by activating the setting on your browser that allows
              you to refuse the setting of all or some cookies. However, if
              you use your browser settings to block all cookies (including
              essential cookies) you may not be able to access all or parts of
              our site. Except for essential cookies, all cookies will expire
              after one hour.
          </p>
        </div>
    </div>
  );
  }

  export default withStyles(styles)(CookiePolicy);

