import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class MilestoneAcknowledgmentAlert extends React.Component {

    render() {
        const { open, onClose, milestone, onCancelAcknowledge, onConfirmAcknowledge } = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Acknowledge Edit"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            By acknowledging the following edit, you agree to the contents of the edit, agreed that you have discussed it with the ideator, and confirm that the milestone will now become active and recorded on the blockchain.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onCancelAcknowledge}
                            color="primary">
                            Cancel
                     </Button>
                        <Button
                            onClick={onConfirmAcknowledge}
                            color="primary">
                            Reject
                     </Button>
                        <Button
                            onClick={onConfirmAcknowledge}
                            color="primary" >
                            Confirm
                     </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default MilestoneAcknowledgmentAlert;
