import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { PersonAdd } from "@material-ui/icons";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class MarketplaceWelcome extends React.Component {
  state = {};

  render() {
    const { open, handleClose, setCookie } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Welcome to SPRXS Marketplace"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              This marketplace contains SPRXS collaborators who have skills
              and resources that they would like to offer to SPRXS Ideators (you).

              Simply connect with a collaborator by inviting them <span>(<PersonAdd/>)</span> to work on one of your ideas.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
            <Button onClick={setCookie} color="primary">
              OK, don't show this again
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default MarketplaceWelcome;
