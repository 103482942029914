import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import VideoNavItem from "./VideoNavItem";
import { videos } from "./VideoList";

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

function BuildNav(updateVideo, navItems) {
  let navItem;

  if (navItems) {
    navItem = navItems.map(i => {
      return <VideoNavItem key={i.key} nav={i} updateVideo={updateVideo} />;
    });
  }
  return (
    <div className="videoNav" id="vidNav">
      {navItem}
    </div>
  );
}

class VideoNav extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      height: props.height,
      width: props.width,
      isMobile: false,
      isMobileLandscape: false,
      navItems: [],
      navSections: [],
      currentSection: 1,
      marginLeft: 0,
      // width of holding container.
      navDesktop: 680,
      navMobileLandscape: 524,
      navMobile: 260,
      navMobileSmall: 310
    };
    this.state.navItems = videos;
  }

  throttledHandleWindowResize = () => {
    if (window.innerWidth < 600) {
      console.log("MOBILE PORTRAIT");
      // divide videoArray into sections of two
      this.getSections(2);
      this.setState({ isMobile: true });
    } else if (window.innerWidth > 400 && window.innerHeight < 400) {
      console.log("MOBILE LANDSCAPE");
      // divide videoArray into sections of two
      this.getSections(3);
      this.setState({ isMobileLandscape: true });
    } else {
      console.log("DESKTOP");
      // divide videoArray into sections of four
      this.getSections(4);
      this.setState({ isMobile: false });
      this.setState({ isMobileLandscape: false });
    }
  };

  componentWillMount() {
    // this.throttledHandleWindowResize();
  }

  componentDidMount() {
    this.throttledHandleWindowResize();
    window.addEventListener("resize", this.throttledHandleWindowResize);
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    window.removeEventListener("resize", this.throttledHandleWindowResize);
  }

  getSections = size => {
    let tempArr = [];
    for (var i = 0; i < this.state.navItems.length; i += size) {
      tempArr.push(this.state.navItems.slice(i, i + size));
    }
    this.setState({ navSections: tempArr });

    if (tempArr.length > 1) {
      document.getElementById("btnNext").style.display = "inline";
    }
  };

  handleNextSection() {

    let numberOfSections = this.state.navSections.length;
    if (this.state.currentSection <= numberOfSections - 1) {
      this.state.currentSection += 1;
      if (this.state.isMobile) {
        this.scrollnav(-this.state.navMobile);
      } else if (this.state.isMobileLandscape) {
        this.scrollnav(-this.state.navMobileLandscape);
      } else {
        this.scrollnav(-this.state.navDesktop);
      }
    }

    if (this.state.currentSection !== 1) {
      document.getElementById("btnPrev").style.display = "inline";
    }
    if (this.state.currentSection === numberOfSections) {
      document.getElementById("btnNext").style.display = "none";
    }
  }

  handlePrevSection() {
    let numberOfSections = this.state.navSections.length;
    if (this.state.currentSection !== 1) {
      this.state.currentSection -= 1;
      if (this.state.isMobile) {
        this.scrollnav(this.state.navMobile);
      } else if (this.state.isMobileLandscape) {
        this.scrollnav(this.state.navMobileLandscape);
      } else {
        this.scrollnav(this.state.navDesktop);
      }
    }
    if (this.state.currentSection === 1) {
      document.getElementById("btnPrev").style.display = "none";
    }
    if (this.state.currentSection !== numberOfSections) {
      document.getElementById("btnNext").style.display = "inline";
    }
  }

  scrollnav(dir) {
    this.state.marginLeft += dir;
    document.getElementById("vidNav").style.marginLeft = `${
      this.state.marginLeft
    }px`;
  }

  render() {
    const { updateVideo } = this.props;
    const { navItems } = this.state;

    return (
      <div className="videoNavContainer">
        <div className="videoNavSide">
          <span
            className="videoNavButton"
            id="btnPrev"
            onClick={() => this.handlePrevSection()}
          />
        </div>
        <div className="navInner">{BuildNav(updateVideo, navItems)}</div>
        <div className="videoNavSide">
          <span
            className="videoNavButton vbRight"
            id="btnNext"
            onClick={() => this.handleNextSection()}
          />
        </div>
      </div>
    );
  }
}

VideoNav.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(VideoNav));
