import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LandingDrawer from "../LandingPage/LandingDrawer";
import sprxlogo from "../../static/sprxslogo.png";
import { HomeFooter } from "../../components/HomeFooter/HomeFooter";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import { connect } from "react-redux";
import { SHOW_TERMS_MODAL, SHOW_PRIV_MODAL } from "../../store/actions/actions";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Terms from "../../components/Terms/TermsConds";
import Privacy from "../../components/Terms/PrivPol";
import AcceptUse from "../../components/Terms/AcceptUsePol";
import Collab from "../../components/Terms/CollabTokensPolicy";
import Cookie from "../../components/Terms/CookiePolicy";
import Competiton from "../../components/Terms/CompPolicy";

import LegalModal from "./LegalModal";
import "./Legal.css";


const bgColor = "white";

const styles = theme => ({
  root: {
    backgroundColor: bgColor,
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  top: {
    marginTop: 80
  }
});

class Legal extends React.Component {
  state = {
    openDrawer: false,
    value: "terms"
  };

  componentWillMount = () => {
    const {propValue} = this.props;
    if(propValue) {
      console.log("got a vlue");
      this.setState({value: propValue })
    }
  }

  handleCloseDrawer = () => {
    this.setState({ openDrawer: false });
  };

  handleOpenDrawer = () => {
    this.setState({ openDrawer: true });
  };  

  handleChange = (event, value) => {
    this.setState({ value: value });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  render() {
    const { classes, fullScreen, propValue } = this.props;
    const { openDrawer, value } = this.state;

    console.log("WHAT IS FULLSCREEN? ", fullScreen)

    return (
      <div className={classes.root}>
        <LandingDrawer open={openDrawer} onClose={this.handleCloseDrawer} />
        <LegalModal />
        <Grid container spacing={0} className={classes.container}>
        <div className={"legalNav"}>
            <Tabs 
              value={value} 
              onChange={this.handleChange} 
              centered = {!fullScreen}
              variant={fullScreen ?  "scrollable" : "standard"}
              scrollButtons="auto" 
              indicatorColor="primary"
            > 
              <Tab value={"terms"} selected={true} label="TERMS OF WEBSITE USE" />
              <Tab value={"privacy"} label="PRIVACY POLICY" />
              <Tab value={"acceptUse"} label="ACCEPTABLE USE POLICY" />
              <Tab value={"collab"} label="Tokens and Collaboration" />
              <Tab value={"cookie"} label="COOKIES" />
              <Tab value={"comp"} label="COMPETITION" />
            </Tabs>
        </div>
        {value === "terms" && <Terms handleChange={this.handleChange} />}
        {value === "privacy" && <Privacy handleChange={this.handleChange} />}
        {value === "acceptUse" && <AcceptUse handleChange={this.handleChange} />}
        {value === "collab" && <Collab handleChange={this.handleChange} />}
        {value === "cookie" && <Cookie handleChange={this.handleChange} />}
        {value === "comp" && <Competiton handleChange={this.handleChange} />} */}
          {HomeFooter(
            classes,
            this
          )}
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showTermsPolicyModal: () => dispatch({ type: SHOW_TERMS_MODAL }),
    showPrivModal: () => dispatch({ type: SHOW_PRIV_MODAL })
  };
};

Legal.propTypes = {
  classes: PropTypes.object
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(styles)(withMobileDialog()(Legal)))
);
