import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import ChatInput from "../../components/ChatInput/ChatInput";
import ChatMessages from "../../components/ChatMessages/ChatMessages";
import ChatMenu from "../../components/ChatMenu/ChatMenu";
import ChatUser from "../../components/ChatMenu/ChatUser";
import ChatMessageDialog from "../ChatMessageDialog/ChatMessageDialog";
import fire from "../../constants";
import { firebasePassword } from "../../constants";
import Typography from "@material-ui/core/Typography";

/* test data */
const userList = [
  {
    name: "Andy",
    id: "0x12ed"
  },
  {
    name: "Asad",
    id: "0x12ed"
  },
  {
    name: "Akib",
    id: "0x12ed"
  }
];
const dateTime = Date.now();
const timestamp = Math.floor(dateTime / 1000);

/* Test data */
const chatMessages = [
  {
    sender: "Andy",
    message:
      "Hello this is a message sdfhslf ldshg lsdghldhgsdlghlhgl hglsh lkhks hgk shglshgsljglsdjflkdsjflsdk jflsdj ldsj lsdjf sdlkj",
    timestamp: timestamp,
    outgoing: true
  },
  {
    sender: "Asad",
    message:
      "Hello this is a message ldfkgjsd llfgjl gj ljgldjglksjsldkjferwojg ldfjgl;sd jl j j jgl;sjd slj lsj ldsj j dslfjkjflksjflkdjkl ljkjlkjdl jdskl jdsk j",
    timestamp: timestamp,
    outgoing: false
  },
  {
    sender: "Akib",
    message: "Hello this is a message",
    timestamp: timestamp,
    outgoing: false
  },
  {
    sender: "Andy",
    message: "Hello this is a message",
    timestamp: timestamp,
    outgoing: true
  }
];
class ChatDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openChat: false,
      users: [],
      emails: []
    };
  }

  componentDidMount() {
    // console.log("ChatDialog componentDidMount", this.state, this.props);

    var _users = null;

    var userListArray = [];

    if (this.props.uid) {
      let allusers = fire
        .database()
        .ref()
        .child("users")
        .child(this.props.uid)
        .child("conversations")
        .on(
          "child_added",
          snapshot => {
            //let allUserData = snapshot;
            // console.log("SNAPSHOT", snapshot, snapshot.key);
            _users = snapshot.val()["credentials"];
            //let credentials = allUserData.val()["credentials"];
            //let email = credentials.email;
            let userId = snapshot.key;
            //console.log("snapshot", snapshot.val());
            //console.log("credentials.firstName", credentials.firstName);
            //console.log("email", email);
            //console.log("userId", userId);

            //this.storeSomeValue(email, userId);
            //console.log(email);
            let userCredentials = fire
              .database()
              .ref()
              .child("users")
              .child(userId)
              .child("credentials")
              .once("value")
              .then(snapshot => {
                let credentials = snapshot.val()["credentials"];
                let firstName = snapshot.val().firstName;
                let lastName = snapshot.val().lastName;
                userListArray.push({
                  credentials,
                  firstName,
                  lastName,
                  userId
                });
                // console.log("userListArray:", userListArray);
              });
          },
          function(errorObject) {
            // console.log("The read failed: " + errorObject.code);
          }
        );
    } else {
      // console.log("this.props.uid === NULL");
    }

    // console.log("USERS ON STATE", this.state, _users);
    // console.log("OUR NEW ARRAY", userListArray);

    this.setState(
      {
        users: userListArray
      },
      () => {
        // console.log("STATE", this.state);
      }
    );
  }

  launchChat = value => {
    // console.log("launchChat value", value);

    this.setState({ targetUID: value }, () => {
      this.setState({ openChat: true });
    });
  };

  handleCloseChat = () => {
    this.setState({ openChat: false });
  };

  componentWillUnmount() {
    // console.log("componentWillUnmount");
  }

  render() {
    const { fullScreen, open, onClose, uid, ideaRef } = this.props;
    const { openChat, users, emails, targetUID } = this.state;

    //console.log("firebaseUid", uid);
    // console.log("users", users, users.length);
    //console.log("emails", emails);

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{`Inbox`}</DialogTitle>
          <DialogContent>
            {users.length ? (
              <ChatUser userList={users} launchChat={this.launchChat} />
            ) : (
              <Typography>Hey, you don't have any friends yet!</Typography>
            )}
          </DialogContent>
          {/* <ChatInput /> */}
          <DialogActions>
            <Button onClick={onClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <ChatMessageDialog
          uid={uid}
          chatID={targetUID}
          open={openChat}
          onClose={this.handleCloseChat}
          ideaRef={ideaRef}
        />
      </div>
    );
  }
}

ChatDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  // open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    uid: state.firebaseUid
  };
};

export default connect(mapStateToProps)(withMobileDialog()(ChatDialog));
