import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHeader from "../../components/FormHeader/FormHeader";
import {
  Typography,
  Link,
  Chip,
  Tooltip,
  Divider,
  withStyles,
  MenuItem
} from "@material-ui/core";
import axios from "axios";
import { hostApi } from "../../constants";
import { inviteCollaborator } from "../../store/actions/actions";
import { NotificationManager } from "react-notifications";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "95%"
  },
  menu: {
    width: "100%"
  }
});

class InviteCollaborator extends React.Component {
  state = {
    ideaData: null,
    selectedIdea: null,
    isDisabled: true
  };

  getIdeas = emailAddress => {
    if (emailAddress) {
      axios
        .post(
          `${hostApi}/myIdeasSummary`,
          {
            lkp_email: emailAddress
          },
          {
            headers: {
              Authorization: `Bearer ${this.props.jwtToken}`
            }
          }
        )
        .then(
          // success
          ({ data, status }) => {
            console.log("status", data, status);

            this.setState({ ideaData: data });
          }
        )
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log("====================================");
          console.log(error);
          console.log("====================================");
          console.log(error.config);
        });
    }
  };

  componentDidMount() {
    const { emailAddress } = this.props;
    this.getIdeas(emailAddress);
  }

  componentDidUpdate(prevProps, prevState) {
    const { emailAddress } = this.props;
    //console.log("prevProps, props", prevProps, this.props);

    if (prevProps.emailAddress !== emailAddress) {
      //get ideas list from backend
      this.getIdeas(emailAddress);
    }
  }

  handleChange = event => {
    //console.log("event", event.target.value);

    this.setState({ selectedIdea: event.target.value, isDisabled: false });
  };

  inviteCallback = response => {
    //console.log("inviteCallback response", response);

    this.props.handleClose();

    switch (response.status) {
      case 201:
        NotificationManager.success("Success", "Invite sent", 3000);
        break;

      case 409:
        NotificationManager.error("Error", response.data.error, 4000);
        break;

      default:
        NotificationManager.error("Error", "Something went wrong", 3000);
        break;
    }
  };

  handleSubmit = () => {
    const { inviteCollaborator } = this.props;

    inviteCollaborator(
      this.props.jwtToken,
      this.props.collabData.id,
      this.state.selectedIdea,
      this.inviteCallback
    );
  };

  render() {
    const { collabData, classes, jwtToken } = this.props;
    const { ideaData, isDisabled } = this.state;
    return (
      <div>
        {collabData && (
          <Dialog
            open={this.props.open}
            onClose={this.props.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <FormHeader>{`Invite ${collabData.firstname} ${
              collabData.surname
            }`}</FormHeader>
            <DialogContent>
              <DialogContentText>
                {`Select an idea that you would like ${
                  collabData.firstname
                } to collaborate on.`}
              </DialogContentText>

              <TextField
                id="outlined-select-idea"
                fullwidth
                select
                label="Idea"
                className={classes.textField}
                value={this.state.selectedIdea}
                onChange={this.handleChange}
                InputLabelProps={{ shrink: true }}
                
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                helperText="Please select your idea"
                margin="dense"
                variant="outlined"
              >
                {ideaData &&
                  ideaData.map(option => (
                    <MenuItem key={option.idea_id} value={option.idea_id}>
                      {`${option.idea_name} - #${option.idea_id}`}
                    </MenuItem>
                  ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={this.handleSubmit}
                disabled={this.state.isDisabled}
                color="secondary"
                variant="contained"
                autoFocus
              >
                Invite
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  emailAddress: state.emailAddress,
  token: state.jwtToken
});

const mapDispatchToProps = dispatch => ({
  inviteCollaborator: (jwtToken, collaboratorID, ideaID, callback) => {
    inviteCollaborator(jwtToken, collaboratorID, ideaID, callback);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(InviteCollaborator));
