import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import CardHeader from "@material-ui/core/CardHeader";
import techImage from "../../static/technology.jpeg";
import busImage from "../../static/business.jpeg";
import fashionImage from "../../static/fashionart.jpeg";
import filmImage from "../../static/film.jpeg";
import foodImage from "../../static/fooddrink.jpeg";
import lifestyleImage from "../../static/lifestyle.jpeg";
import mediaImage from "../../static/media.jpeg";
import gamingImage from "../../static/gaming.jpeg";
import otherImage from "../../static/other.jpeg";
import EditIdeaDialog from "../../components/EditIdeaModal/EditIdeaDialog";
import IdeaAttachDialog from "../../components/EditIdeaModal/IdeaAttachDialog";
import IdeaDetails from "../../components/IdeaDetails/PublishIdea";
import { getFileList } from "../../store/actions/actions";
import { withCookies } from "react-cookie";
import "./IdeaCard.css";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import { hostApi, IDEATOR, COLLABORATOR } from "../../constants";
import axios from "axios";
import { NotificationManager } from "react-notifications";

/* import { SHOW_IDEA_DETAILS } from "../../store/actions/actions";
 */
const styles = theme => ({
  card: {
    /* backgroundColor: fade(theme.palette.secondary.dark, 0.2) */
    /* backgroundImage: `url(${techImage})` */
  },
  media: {
    // ⚠️ object-fit is not supported by IE11.
    objectFit: "cover",
    height: 150,
    paddingTop: "56.25%" // 16:9
  },
  avatarLock: {
    backgroundColor: green[500]
  },
  avatarUnLock: {
    backgroundColor: red[500]
  },
  actions: {
    display: "flex"
  },
  textField: {
    /* marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit */
  },
  dense: {
    marginTop: 16
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8
    }
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  chip: {
    margin: theme.spacing.unit
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  button: {
    margin: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

/* TODO: Make use of the original CardView2 component
 * but for ease and speed, we are using this custom
 * IdeaCard component for the Idea Dash view.
 */

class IdeaCard extends React.Component {
  state = {
    expanded: false,
    launch: false,
    showEdit: false,
    showAttach: false,
    showRWOI: false,
    selectedData: []
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };
  handleCloseDetails = () => {
    this.setState({ launch: false });
  };
  launchIdea = () => {
    this.setState({ launch: true });
  };
  launchEdit = () => {
    this.setState({ showEdit: true });
  };
  handleCloseEdit = () => {
    this.setState({ showEdit: false });
  };
  launchAttach = () => {
    this.setState({ showAttach: true });
  };
  handleCloseAttach = () => {
    this.setState({ showAttach: false });
  };
  launchRWOI = () => {
    this.setState({ showRWOI: true });
  };
  handleCloseRWOI = () => {
    this.setState({ showRWOI: false });
  };

  handlePublic = (id) => {
    axios.post(
      `${hostApi}/changeIdeaStatus`,
      {
        ideaUniqueSignature: id,
        action: 1
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.jwtToken}`
        }
      }
    );

    NotificationManager.success(
      `Idea #${id} is now Private`,
      "Idea Private",
      4000
    );
  };

  componentDidMount() {}

  getImage = (coverPhotoUrl, category) => {
    if (coverPhotoUrl === "" || coverPhotoUrl === null) {
      switch (category) {
        case "Technology":
          return techImage;
        case "Lifestyle & Wellbeing":
          return lifestyleImage;
        case "Food & Drink":
          return foodImage;
        case "Gaming":
          return gamingImage;
        case "Business/Finance":
          return busImage;
        case "Fashion & Art":
          return fashionImage;
        case "Film, Theatre & Music":
          return filmImage;
        case "Media and Journalism":
          return mediaImage;
        default:
          return otherImage;
      }
    } else {
      return coverPhotoUrl;
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedIdeaFiles, ideaData, jwtToken } = this.props;

      if (prevProps.ideaData !== this.props.ideaData){
        console.log("UPDATED");
      }

      if (selectedIdeaFiles) {
        if(prevProps.selectedIdeaFiles != selectedIdeaFiles){
          this.props.getFileList(jwtToken, this.props.ideaData.ideaId);
        }else {
      }
    }
  }
    render() {
      const {
        classes,
        ideaData,
        jwtToken,
        userType
      } = this.props;
  
      console.log({ideaData});

    return (
      <>
        <div className={classes.card}>
        {userType === IDEATOR && (
          <CardHeader
            avatar={
              ideaData.allowSearch ? (
                <>
                <button className="public_button" onClick={() => this.handlePublic(ideaData.ideaId)}>
                <IconButtonComponent
                  type={"public"}
                  iconStyle={"iconDefault iconPadRight"}
                  toolTip={true}
                  title={"Idea Searchable. Click to make private"}
                />
                  Hide Idea
                </button>
                </>
              ) : (
                <>
                <button className="public_button" onClick={this.launchIdea}>  
                <IconButtonComponent
                  type={"locked"}
                  iconStyle={"iconDisabled iconPadRight"}
                  toolTip={true}
                  title={"This Idea is Private. Click to Make Idea Searchable"}
                />
                  Show Idea
                </button>
                </>
              )
            }
            action={
              <IconButtonComponent
                type={"edit"}
                iconStyle={"iconAction"}
                toolTip={true}
                title={"Edit Idea"}
                link={this.launchEdit}
              />
            }
            title={
              <Typography color="default">
                #{ideaData.ideaId}{" "}
                <span className="my-idea__card-subheader">
                  {ideaData.ideaName}
                </span>
              </Typography>
            }
            subheader={
              <Typography color="default">
                Created: {ideaData.IdeaDateCreated}
              </Typography>
            }
          />
        )}
        {userType === COLLABORATOR && (
          <CardHeader
            title={
              <Typography color="default">
                #{ideaData.ideaId}{" "}
                <span className="my-idea__card-subheader">
                  {ideaData.ideaName}
                </span>
              </Typography>
            }
            subheader={
              <Typography color="default">
                Created: {ideaData.IdeaDateCreated}
              </Typography>
            }
          />
        )}
          <CardMedia
            className={classes.media}
            image={this.getImage(ideaData.coverPhotoUrl, ideaData.lkpIdeaCat1)} // Return Stock Image if no cover photo is added.
            title={ideaData.coverPhoto}
          />
          <CardContent>
            <Typography variant="subtitle1">Blockchain ID:</Typography>
            <Typography variant="body2">{ideaData.tokenId}</Typography>
            <Typography variant="subtitle1">Description</Typography>
            <Typography variant="body2" paragraph={true}>
              {ideaData.ideaDescription}
            </Typography>
            <span className="my-idea__list-title">
              <h3 className="my-idea__card-subheader">Attachments</h3>
              { userType === IDEATOR && (
              <IconButtonComponent
                type={"add"}
                iconStyle={"iconAction iconRight"}
                toolTip={true}
                title={"Add Attachment"}
                link={this.launchAttach}
              />
              )}
            </span>
            <table className="my-idea-list">
              <tbody>
                {this.props.firebaseFiles &&
                  this.props.firebaseFiles[ideaData.ideaId] &&
                  this.props.firebaseFiles[ideaData.ideaId].map((file, key) => (
                    <tr key={key}>
                      <td>
                        <IconButtonComponent
                          type={"attach"}
                          iconStyle={"iconDisabled"}
                        />
                      </td>
                      <td className="my-idea-list__equity">
                        <a href={file.url} download target="_blank">
                          {file.filename}
                        </a>
                      </td>
                      <td>
                        {/* <IconButtonComponent 
                      type={"delete"}
                      iconStyle={"iconAction iconRight"}
                      toolTip={true}
                      title={"Delete Attachment"}
                      link={this.launchAttach}
                    />  */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </CardContent>
        </div>
        <IdeaDetails
          jwtToken={this.props.jwtToken}
          open={this.state.launch}
          onClose={this.handleCloseDetails}
          data={ideaData}
        />
        <EditIdeaDialog
          jwtToken={this.props.jwtToken}
          open={this.state.showEdit}
          onClose={this.handleCloseEdit}
          data={ideaData}
          cookies={this.props.cookies}
        />
        <IdeaAttachDialog
          jwtToken={this.props.jwtToken}
          open={this.state.showAttach}
          onClose={this.handleCloseAttach}
          data={ideaData}
          cookies={this.props.cookies}
        />
      </>
    );
  }
}
IdeaCard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    firebaseFiles: state.firebaseFiles
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getFileList: (jwtToken, ideaUniqueID) => {
      getFileList(jwtToken, ideaUniqueID, dispatch);
    }
  };
};
export default withCookies(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(IdeaCard))
);
