import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import HowItem from "../../components/Home/HowItem";
import BlochChainIcon from "../../static/blockchainJoinIcon.svg";
import JourneyImg from "../../static/journey_2x.svg";
import SectionItem from "./SectionItem";
import VideoSection from "../HomeVideo/VideoPlayer";
import DigitalEconomy from "./DigitalEconomy";
import "./Section.css";

const styles = theme => ({
  root: {
    flexGrow: 1,
    scrollBehavior: "smooth"
  }
});

function renderJourney(classes, title) {
  return (
    <React.Fragment>
      <div className="sectionContainer">
        <div />
        <div>
          <h4 className="sectionTitle">{title}</h4>
        </div>
        <div className="gridSection2Row">
          <div>
            <DigitalEconomy />
          </div>
          <div />
        </div>
        <div />
      </div>
    </React.Fragment>
  );
}

function renderRoles(classes, title, link, signUpJourney) {
  return (
    <div className="sectionContainerMin">
      <div />
      <div>
        <h4 className="sectionTitle">{title}</h4>
      </div>
      <div className="gridSection3Col">
        <div className="gridInnerSection">
          <SectionItem
            title={"IDEATORS"}
            text={
              <Fragment>
                <p>
                  <span>Protect your ideas</span> - Log and protect your ideas
                  using the SPRXS Blockchain to ensure your ideas are registered
                  and logged securely, either keeping them private or making
                  them searchable to others.
                </p>
                <p>
                  <span>Manage your ideas</span> – Use our ideation dashboard
                  and wallet to protect, plan, manage, resource, monetize and
                  execute your ideas from a secure position.
                </p>
                <p>
                  <span>Collaborate</span> - Use SPRXS tokens to bring your idea
                  to life, with no need for cash investment. 100 SPRXS tokens
                  will be allocated to each idea, representing percentage
                  equity. Exchange SPRXS token equity for skills and resources
                  within the SPRXS global community.
                </p>
                <p>
                  <span>Monetize</span> – Connect with investors for options to
                  exchange SPRXS tokens for cash.
                </p>
              </Fragment>
            }
            buttonText={"Create Idea"}
            link={link}
            signUpJourney={"CREATE"}
          />
        </div>
        <div className="gridInnerSection">
          <SectionItem
            title={"COLLABORATORS"}
            text={
              <Fragment>
                <p>
                  <span>Help bring ideas to life</span> – Use your skills and
                  resources to contribute to ideas and make a difference.
                </p>
                <p>
                  <span>
                    Become a part of the SPRXS digital innovation economy
                  </span>{" "}
                  – Exchange your skills and resources for equity in ideas; with
                  the opportunity to own a part of the innovative companies of
                  the future.
                </p>
                <p>
                  <span>Unlock value</span> – Make the most of your
                  unused/under-utilised resources by exchanging them for SPRXS
                  token equity in ideas e.g. office space or computer processing
                  power.
                </p>
              </Fragment>
            }
            buttonText={"Collaborate"}
            link={link}
            signUpJourney={"COLLABORATE"}
          />
        </div>
        <div className="gridInnerSection">
          <SectionItem
            title={"INVESTORS"}
            text={
              <Fragment>
                <p>
                  <span>Find the right investment for you</span> – Register on
                  the SPRXS marketplace to access an extensive pool of
                  searchable ideas logged on the SPRXS blockchain.
                </p>
                <p>
                  <span>Connect</span> – Make use of features like our milestone
                  timeline view to get a clear picture of a project's
                  development since its inception.
                </p>
                <p>
                  <span>Acquire</span> – Purchase SPRXS tokens in the right idea
                  to expand your portfolio and help fund the development of
                  innovative projects.
                </p>
              </Fragment>
            }
            buttonText={"coming soon"}
            link={""}
            signUpJourney={""}
          />
        </div>
        <div />
      </div>
    </div>
  );
}

class Section extends React.Component {
  render() {
    const {
      classes,
      title,
      how,
      media,
      journey,
      roles,
      link,
      signUpJourney
    } = this.props;
    return (
      <React.Fragment>
        {media && <VideoSection />}
        {journey && renderJourney(classes, title)}
        {roles &&
          renderRoles(classes, title, link, signUpJourney)}
      </React.Fragment>
    );
  }
}

Section.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Section));
