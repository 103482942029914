import React, { Component } from "react";
import { connect } from "react-redux";
import "./MyWallet.css";
import { IDEATOR } from "../../constants";
import { withCookies } from "react-cookie";
import { Help } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { launchAlert } from "../../store/actions/actions";

class MyWallet extends Component {
  state = {
    collaboratorList: [],
  };

  renderProgressBar = () => {

    /*
      {
          "total_equity_issued": "15",
          "total_equity_for_all_ideas": "300",
          "total_equity_available_to_offer": "275",
          "total_equity_pending": "10"
      }
    */

    /* 
    console.log ("MyWallet.js Line 26");
    console.log (this.props.myWallet);
    */

    // 2019.03.12 / Fixed by KN

    const { numberOfIdeas, userType, cookies, showHelp } = this.props;
    const _userType = userType || cookies.get("user_type");
    const middlewareActive = _userType === IDEATOR ? true : false;
    let myWallet = [];
    let collabValue = 0;

    if (this.props.collaborationWallet.totalValue === 0) {
      collabValue = 1;
    } else {
      collabValue = this.props.collaborationWallet.totalValue;
    }


      myWallet.push({
        barChartTitle: "Total Idea Equity",
        progressValue: this.props.myWallet.total_equity_available_to_offer,
        totalValue: this.props.myWallet.total_equity_for_all_ideas
       });
       myWallet.push({
        barChartTitle: "Total Collaboration Equity",
        progressValue: this.props.collaborationWallet.progressValue,
        totalValue: collabValue
        //totalValue: Boolean (this.props.collaborationWallet.totalValue === 0 ? 1 : this.props.collaborationWallet.totalValue)
      });


    return myWallet.map(({ barChartTitle, progressValue, totalValue }, key) => (
      <div key={key}>
        <div className="wallet__title">
          {barChartTitle}
          {Boolean(showHelp) && (
            <span>
              <IconButton
                aria-label="Help"
                onClick={() => {
                  this.props.launchAlert({
                    intent: "help",
                    title: "Wallet",
                    message:
                      "This dashboard tells you how many equity tokens you have.  Every idea has a potential 100 tokens that the owner can distribute."
                  });
                }}
              >
                <Help fontSize="small" />
              </IconButton>
            </span>
          )}
        </div>
        <div className="wallet__progress-bar-background">
          <div
            className="wallet__progress-bar-foreground"
            style={{
              width: Math.ceil((progressValue * 100) / totalValue) + "%"
            }}
          >
            <div className="wallet__progress-text">
              {progressValue}/{totalValue === 1 ? 0 : `${totalValue}`}
          </div>
          </div>
           
        </div>
        
      </div>
    ));
  };

  render() {
    return <div className="wallet__wrapper">{this.renderProgressBar()}</div>;
  }
}

const mapStateToProps = state => ({
  userType: state.userType
});

const mapDispatchToProps = dispatch => ({
  launchAlert: payload => dispatch(launchAlert(payload))
});

export default withCookies(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyWallet)
);
