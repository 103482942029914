import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import { Send } from "@material-ui/icons";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  fullButton: {
    marginTop: 10,
    display: "block",
    alignItems: "center"
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class ProgressButton extends React.Component {
  state = {
    loading: false
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleButtonClick = e => {
    this.setState({loading: true});
  };

  render() {
    const { loading, classes, circle, fullwidth, success, onClick, btnText, btnResponse, isDisabled } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: success

    });

    const rootClassname = classNames({
      [classes.root]: !fullwidth,
      [classes.fullButton]: fullwidth

    });

    return (
      <div className={rootClassname}>
        {circle && <div className={classes.wrapper}>
          <Button
            variant="fab"
            color="primary"
            className={buttonClassname}
            onClick={onClick}
          >
            {success ? <CheckIcon /> : <Send />}
          </Button>
          {loading && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>}
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            className={buttonClassname}
            disabled={loading || success || isDisabled}
            onClick={onClick}
            fullWidth={fullwidth}
          >
            {success ? `${btnResponse}` : `${btnText}`}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>
    );
  }
}

ProgressButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProgressButton);
