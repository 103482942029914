import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import ChatInput from "../../components/ChatInput/ChatInput";
import ChatMessages from "../../components/ChatMessages/ChatMessages";
import ChatMenu from "../../components/ChatMenu/ChatMenu";
import ChatUser from "../../components/ChatMenu/ChatUser";
import fire from "../../constants";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import RootRef from "@material-ui/core/RootRef";

/* test data */
const userList = [
  {
    name: "Andy",
    id: "0x12ed"
  },
  {
    name: "Asad",
    id: "0x12ed"
  },
  {
    name: "Akib",
    id: "0x12ed"
  }
];

/* Test data */
const chatMessages = [
  {
    sender: "Andy",
    message:
      "Hello this is a message sdfhslf ldshg lsdghldhgsdlghlhgl hglsh lkhks hgk shglshgsljglsdjflkdsjflsdk jflsdj ldsj lsdjf sdlkj",
    timestamp: "this is a timestamp",
    outgoing: true
  },
  {
    sender: "Asad",
    message:
      "Hello this is a message ldfkgjsd llfgjl gj ljgldjglksjsldkjferwojg ldfjgl;sd jl j j jgl;sjd slj lsj ldsj j dslfjkjflksjflkdjkl ljkjlkjdl jdskl jdsk j",
    timestamp: "this is a timestamp",
    outgoing: false
  },
  {
    sender: "Akib",
    message: "Hello this is a message",
    timestamp: "this is a timestamp",
    outgoing: false
  },
  {
    sender: "Andy",
    message: "Hello this is a message",
    timestamp: "this is a timestamp",
    outgoing: true
  },
  {
    sender: "Asad",
    message:
      "Hello this is a message ldfkgjsd llfgjl gj ljgldjglksjsldkjferwojg ldfjgl;sd jl j j jgl;sjd slj lsj ldsj j dslfjkjflksjflkdjkl ljkjlkjdl jdskl jdsk j",
    timestamp: "this is a timestamp",
    outgoing: false
  },
  {
    sender: "Akib",
    message: "Hello this is a message",
    timestamp: "this is a timestamp",
    outgoing: false
  },
  {
    sender: "Andy",
    message: "Hello this is a message",
    timestamp: "this is a timestamp",
    outgoing: true
  }
];

const styles = theme => ({
  root: {
    margin: 0,
    padding: 0
  }
});

class ChatMessageDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      chatLoc: [],
      messages: []
    };
    this.domRef = React.createRef();
  }

  launchChat = value => {
    // //console.log("launchChat value", value);
  };

  getMessages = () => {
    const that = this;
    // //console.log("get messages called", this.state.chatLoc);
    var messagesDB = fire
      .database()
      .ref()
      .child("conversations")
      .child(this.state.chatLoc);
    messagesDB.on("value", snapshot => {
      let messagesValue = snapshot.val();
      let messages = Object.keys(messagesValue).map(childAsDS => {
        let data = messagesValue[childAsDS];

        return {
          firstName: data.firstName,
          lastName: data.lastName,
          text: data.content,
          fromID: data.fromID,
          timestamp: data.timestamp,
          outgoing: data.fromID === that.props.uid ? true : false
        };
      });
      this.setState({ messages });
    });
  };

  componentDidUpdate(prevProps) {
    // //console.log("componentDidUpdate", prevProps, this.props);
    // Typical usage (don't forget to compare props):

    const that = this;
    var globalChatLoc = null;
    var checkIfExists = false;

    if (that.props.open !== prevProps.open) {
      // //console.log("A this.props.uid", that.props.uid, that.props.chatID);
      const { uid, chatID } = that.props;
      // //console.log("ChatID", chatID.userId);

      // //console.log("Ref", this.domRef);
      //window.scrollTo(0, this.myRef.current.offsetTop);

      if (uid && chatID) {
        if (that.props.open === true) {
          var myChats2 = fire
            .database()
            .ref()
            .child("users")
            .child(uid)
            .child("conversations")
            .child(chatID.userId)
            .on("value", snapshot => {
              checkIfExists = snapshot.exists();
              // //console.log("CIE", checkIfExists);
              // //console.log("SNAPSHOT.var", snapshot.val());
              if (checkIfExists) {
                globalChatLoc = snapshot.val()["location"];
              } else {
                // //console.log("NO MESSAGES");
              }
            });

          if (checkIfExists) {
            this.setState(
              {
                chatLoc: globalChatLoc
              },
              () => {
                this.getMessages();
              }
            );
          } else {
            // //console.log("NO MESSAGES");
            this.setState({ messages: [] });
          }
        }

        if (that.props.open === false) {
          // //console.log("REMOVE THE CHAT INSTANCE!!!", fire);
          /* myChats2.off();  */
        }
      }
    }
  }

  componentDidMount() {
    const { chatID, uid } = this.props;
    const that = this;

    // //console.log("Ref", this.domRef.current);

    // //console.log("componentDidMount uid - chatID", uid, chatID);
    if (uid && chatID) {
      fire
        .database()
        .ref()
        .child("users")
        .child(uid)
        .child("conversations")
        .child(chatID.userId)
        .on("value", snapshot => {
          var checkIfExists = snapshot.exists();
          // //console.log("CIE", checkIfExists);
          if (checkIfExists) {
            that.setState(
              {
                chatLoc: snapshot.val()["location"]
              },
              () => {
                that.getMessages();
              }
            );
          } else {
            // //console.log("NO MESSAGES");
            that.setState({ messages: [] });
          }
        });
    } else {
      // //console.log("no UID or chat ID", uid, chatID);
    }

    this.scrollToBottom();
  }

  handleSendMessage = (message, type = "text") => {
    //console.log("message", message, type);
    const { uid, chatID } = this.props;

    // //console.log("uid, chatID", uid, chatID);

    if (!uid) return;

    var currentUser = uid;
    const dateTime = Date.now();
    const timestamp = Math.floor(dateTime / 1000);
    var values = {
      type,
      content: message,
      fromID: currentUser /*currentUserID*/,
      toID: chatID.userId /*toID*/,
      timestamp: timestamp,
      isRead: false
    };
    fire
      .database()
      .ref()
      .child("users")
      .child(currentUser) //Current User(Sender)
      .child("conversations")
      .child(chatID.userId) //Receiver
      .once("value")
      .then(snapshot => {
        if (snapshot.exists()) {
          // //console.log("It exists");

          //console.log(values);
          const locRef = fire
            .database()
            .ref()
            .child("conversations")
            .child(snapshot.val()["location"])
            .push(values);

          this.setState({ chatLoc: snapshot.val()["location"] }, () => {
            this.getMessages();
          });
        } else {
          // console.log("Doesn't exists");
          const messLoc = fire
            .database()
            .ref()
            .child("conversations")
            .push()
            .push(values);
          const messLocKey = messLoc.parent.key;
          let sampleDict = { location: messLocKey };
          fire
            .database()
            .ref()
            .child("users")
            .child(currentUser) //Current user(Sender)
            .child("conversations")
            .child(chatID.userId) //Receiver
            .update(sampleDict);

          fire
            .database()
            .ref()
            .child("users")
            .child(chatID.userId) //Receiver
            .child("conversations")
            .child(currentUser) //Current User (Sender)
            .update(sampleDict);
        }
      });
  };

  scrollToBottom = () => {
    // //console.log("Scroll to bottom", this.domRef);
    //this.myRef.current.scrollIntoView({ behavior: "smooth" });
    //this.myRef.current.focus();
    const ref = this.domRef.current;
    if (ref) {
      const scrollHeight = ref.scrollHeight;
      const height = ref.clientHeight;
      const maxScrollTop = scrollHeight - height;
      // //console.log("scroll: scrollHeight", scrollHeight);
      // //console.log("scroll: height", height);
      // //console.log("scroll: maxScrollTop", maxScrollTop);
      ReactDOM.findDOMNode(ref).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  render() {
    const {
      fullScreen,
      open,
      onClose,
      uid,
      chatID,
      classes,
      ideaRef
    } = this.props;
    const { messages } = this.state;

    // //console.log("firebaseUid", uid);
    // //console.log("chatID", chatID);
    // //console.log("messages", messages);

    let firstName = chatID ? chatID.firstName : "";
    let lastName = chatID ? chatID.lastName : "";

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <CardHeader
              className={classes.root}
              action={
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              }
              title={`Chat with ${firstName} ${lastName}`}
              subheader={ideaRef ? `Chat Idea Ref: ${ideaRef}` : "Chat"}
            />
          </DialogTitle>

          <RootRef rootRef={this.domRef}>
            <DialogContent>
              <ChatMessages messageList={messages} />
            </DialogContent>
          </RootRef>
          <DialogActions>
            <ChatInput sendMessage={this.handleSendMessage} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ChatMessageDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    uid: state.firebaseUid
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(withMobileDialog()(ChatMessageDialog))
);
