import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Slide from "@material-ui/core/Slide";
import FormHeader from "../../components/FormHeader/FormHeader";
import {
  Typography,
  Tooltip,
  Divider,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import MyWalletCollab from "../../components/MyWallet/MyWalletCollab";
import {
  getFileList,
  getMilestones,
  lodgeConsensus,
  launchAlert,
  startChatWithPerson
} from "../../store/actions/actions";
import {
  ExpandMore,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import green from "@material-ui/core/colors/green";
import Collapse from "@material-ui/core/Collapse";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";


const approvalStatus = [
  "Unknown", // 0
  "Complete", // 1
  "Pending", // 2
  "Active", // 3
  "Cancel Request" // 4
];


const iconStatus = type => {
  switch (type) {
    case 1:
      return <IconButtonComponent type={"approve"} iconStyle={"iconDefault iconCodeGreen"} />
      break;
    case 2:
      return <IconButtonComponent type={"pending"} iconStyle={"iconDefault"} toolTip={true} title={"Pending Collaborator Approval"} />
      break;
    case 3:
      return <IconButtonComponent type={"token"} iconStyle={"iconDefault"} />
      break;
    case 4:
      return <IconButtonComponent type={"alert"} iconStyle={"iconDefault iconWarning"} />
      break;
    default:  
      return <IconButtonComponent type={"token"} iconStyle={"iconDefault"} />
      break;
  }
};

function Transition(props) {
  return <Slide direction="right" {...props} />;
}

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
    left: "50%",
    transform: "translateX(-50%)"
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  actionButton: {
    marginLeft: theme.spacing.unit,
    marginTop: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
    marginRight: "8vw",
    float: "right"
  },
  expand: {
    transform: "rotate(0deg)",
    marginRight: theme.spacing.unit,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: green[500]
  }
});

class IdeaDashLite extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  componentDidMount() {
    const { getFileList, getMilestones, data } = this.props;
    //getFileList(data.ideaId); //Need to stop so many calls to FireBase to collect files as it kills our temp storage. 
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { getFileList, getMilestones, data, collaboratorID, jwtToken} = this.props;
    if (prevProps.data.ideaId != data.ideaId) {
      getMilestones(jwtToken, data.ideaId, collaboratorID);
    }
  };

  approveCallback = response => {
    const { getMilestones, data, launchAlert, collaboratorID, jwtToken } = this.props;
    getMilestones(jwtToken, data.ideaId, collaboratorID);
    if (response < 300) {
      launchAlert({
        intent: "success",
        title: "Approve/Reject",
        message: (
          <Typography variant="Subheading" color="inherit">
            You have successfully approved/rejected this milestone.
          </Typography>
        )
      });
    } else {
      launchAlert({
        intent: "error",
        title: "Approve/Reject",
        message: (
          <Typography variant="Subheading" color="inherit">
            There was a problem....status code {response}
          </Typography>
        )
      });
    }
  };

  render() {
    const {
      fullScreen,
      open,
      handleClose,
      data,
      firebaseFiles,
      classes,
      milestoneData,
      jwtToken,
      launchAlert,
      startChatWithPerson
    } = this.props;


    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth="sm"
          TransitionComponent={Transition}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <FormHeader>
            Idea ID #{data.ideaId}{" "}
          </FormHeader>
          <DialogContent>
            <DialogContentText>
            <MyWalletCollab numberOfIdeas={1}
                    myWallet={{
                      barChartTitle: "Your Equity",
                      progressValue: data.equitySplit,
                      totalValue: 100
                    }}/>
              <Typography variant="caption" color="inherit">
                Name
              </Typography>
              <Typography
                style={{ paddingLeft: 10 }}
                variant="h5"
                color="inherit"
                gutterBottom
              >
                {`${data.ideaName}`}
              </Typography>
              <Typography variant="caption" color="inherit">
                Description
              </Typography>
              <Typography
                style={{ paddingLeft: 10 }}
                variant="subtitle1"
                color="inherit"
                gutterBottom
              >
                {`${data.ideaDescription}`}
              </Typography>
              <Typography variant="caption" color="inherit">
                Owner Name
              </Typography>
              <Typography
                style={{ paddingLeft: 10 }}
                variant="subtitle1"
                color="inherit"
                gutterBottom
              >
                {`${data.OwnerFirstname} ${data.OwnerLastname}`}
                {/* <IconButtonComponent 
                  type={"chat"}
                  iconStyle={"iconAction iconPadLeft"}
                  toolTip={true}
                  title={`Chat with ${data.OwnerFirstname}`}
                  link={() => startChatWithPerson(data.OwnerFirebaseId)}
                /> */}
                </Typography>
            </DialogContentText>
            {/* <Button
              disabled={!Boolean(milestoneData.length)}
              variant="extended"
              size="medium"
              color="primary"
              aria-label="Add"
              className={classes.margin}
              onClick={this.handleExpandClick}
            > */}
              {/* <ExpandMore
                className={classnames(classes.expand, {
                  [classes.expandOpen]: this.state.expanded
                })}
              /> */}
              Milestones
            {/* </Button> */}
            {/* <Collapse in={this.state.expanded} timeout="auto" unmountOnExit> */}
              <div>
                <List dense={false}>
                  {milestoneData &&
                    milestoneData.map(item => {
                      return (
                        <>
                          <Divider variant="middle" />
                          <ListItem>
                            <ListItemText
                              primary={
                                <>
                                  <Typography variant="subtitle1">
                                    {iconStatus(item.approval)}
                                    {item.milestoneName}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <>
                                  <Typography variant="caption">
                                    {`Status: ${approvalStatus[item.approval]}`}
                                  </Typography>
                                  <Typography variant="caption">
                                    {`Created: ${item.milestoneDateCreated}`}
                                  </Typography>
                                  <Typography variant="caption">
                                    {`Planned Completion: ${
                                      item.agreedCompletionDate
                                    }`}
                                  </Typography>
                                  <Typography variant="caption">
                                    {`Offered Tokens: ${item.offeredTokens}`}
                                  </Typography>
                                  <Tooltip title={item.milestoneDescription}>
                                    <Typography
                                      variant="caption"
                                      noWrap={true}
                                      style={{ maxWidth: "80vw" }}
                                      gutterBottom
                                    >
                                      {`Description: ${
                                        item.milestoneDescription
                                      }`}
                                    </Typography>
                                  </Tooltip>
                                </>
                              }
                            />
                            <ListItemSecondaryAction>
                              {(item.approval === 2 || item.approval === 4) && (
                              <>
                                <IconButtonComponent 
                                  type={"approve"} 
                                  iconStyle={"iconDefault iconCodeGreen"}
                                  toolTip={true}
                                  title={"Approve"}
                                  link={() => {
                                    this.props.lodgeConsensus(
                                      this.props.jwtToken,
                                      1 /*Approved*/,
                                      this.props.collaboratorID,
                                      item.id,
                                      this.approveCallback
                                    );
                                  }}
                                />
                                <IconButtonComponent 
                                  type={"reject"} 
                                  iconStyle={"iconDefault iconWarning"}
                                  toolTip={true}
                                  title={"Reject"}
                                  link={() => {
                                    this.props.lodgeConsensus(
                                      this.props.jwtToken,
                                      0 /*Rejected*/,
                                      this.props.collaboratorID,
                                      item.id,
                                      this.approveCallback
                                    );
                                  }}
                                />
                              </>
                              )}
                            </ListItemSecondaryAction>
                          </ListItem>
                        </>
                      );
                    })}
                </List>
                <Divider variant="middle" />
              </div>
            {/* </Collapse> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

IdeaDashLite.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  firebaseFiles: state.firebaseFiles,
  milestoneData: state.milestoneData,
  collaboratorID: state.profileID,
});

const mapDispatchToProps = dispatch => ({
  getFileList: (jwtToken, ideaID) => {
    getFileList(jwtToken, ideaID, dispatch);
  },
  getMilestones: (jwtToken, ideaID, collaboratorID) => {
    getMilestones(jwtToken, ideaID, collaboratorID, dispatch);
  },
  lodgeConsensus: (jwtToken, approvalCode, profileId, milestoneId, callback) => {
    lodgeConsensus(jwtToken, approvalCode, profileId, milestoneId, callback);
  },
  launchAlert: payload => dispatch(launchAlert(payload)),
  startChatWithPerson: payload => dispatch(startChatWithPerson(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(withStyles(styles)(IdeaDashLite)));
