import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core";
import { CheckCircle, Error, Help } from "@material-ui/icons";
import { closeAlert } from "../../store/actions/actions";
import FormHeader from "../FormHeader/FormHeader";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
    textAlign: "center"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AlertPopup extends React.Component {
  state = {};

  returnIcon = intent => {
    const { classes } = this.props;
    switch (intent) {
      case "success":
        return <IconButtonComponent type={"approve"} iconStyle={"iconDefault iconCodeGreen"} />
      case "help":
        return <IconButtonComponent type={"help"} iconStyle={"iconDefault"} />
    
      default:
      return <IconButtonComponent type={"alert"} iconStyle={"iconDefault iconWarning"} />
    }
  }

  render() {
    const { classes, open, onClose, title, message, intent } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
          onClose={onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <FormHeader>{this.returnIcon(intent)} {title}</FormHeader>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  title: state.alertData.title,
  message: state.alertData.message,
  intent: state.alertData.intent,
  open: state.launchAlert
});

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => dispatch(closeAlert())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AlertPopup));
