import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%",
  },
});

function TermsConds( {handleChange}) {

    return (
      <div className={"legalWrapper"}>
        <h1 className={"legalHeader"}>
          Terms&nbsp;of&nbsp;website&nbsp;use
        </h1>
        <div className={"legalContent"}>
            <h3 className={"legalSubHead"}>
              What's in these terms?
            </h3>
            <p className={"legalCopy"}>
              These terms tell you the rules for using our website www.sprxs.io
            </p>
            <h3 className={"legalSubHead"}>
              Who we are and how to contact us
            </h3>
            <p className={"legalCopy"}>
              SPRXS.io, SPRXS.com and SPRXS.cloud are sites operated by The
              Fifth 9 Limited ("We"), a company registered in England and Wales
              under company number 06423825 and have our registered office at 14 Hackwood Lane, Basingstoke, Hampshire, RG25 2NH.
              Our main trading address is 20 Eastbourne Terrace, Paddington, London, W2 6LE.
              We are a limited company, regulated by the Companies Act 2006
              (UK).
              <br />
              To contact us, please email{" "}
              <a href="mailTo:enquiries@sprxs.io">enquiries@sprxs.io</a>.
            </p>
            <h3 className={"legalSubHead"}>
              By using our site you accept these terms
            </h3>
            <p className={"legalCopy"}>
              By using our site, you confirm that you accept these terms of use
              and that you agree to comply with them.
              <br />
              If you do not agree to these terms, you must not use our site.
              <br />
              We recommend that you print a copy of these terms for future
              reference.
            </p>
            <h3 className={"legalSubHead"}>
              There are other terms that may apply to you
            </h3>
            <p className={"legalCopy"}>
              These terms of use refer to the following additional terms, which
              also apply to your use of our site:
              <ul>
                <li>
                  Our <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "privacy");
              }}
            >Privacy&nbsp;Policy </a>.
                </li>
                <li>
                  Our <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "acceptUse");
              }}
            >Acceptable&nbsp;Use&nbsp;Policy</a>, which sets out the permitted uses and prohibited uses
                  of our site. When using our site, you must comply with this
                  Acceptable&nbsp;Use&nbsp;Policy.
                </li>
                <li>
                  Our <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "cookie");
              }}
            >Cookie&nbsp;Policy</a>, which
                  sets out information about the cookies on our site.
                </li>
                <li>
                  Our Terms of Use for Tokens and Collaboration <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "collab");
              }}
            >Terms of use for Tokens and Collaboration</a>; which sets out
                  terms when using the SPRXS platform to collaborate and
                  use/allocate/transfer tokens.
                </li>
              </ul>
            </p>
            <h3 className={"legalSubHead"}>
              We may make changes to these terms
            </h3>
            <p className={"legalCopy"}>
              We amend these terms from time to time. Every time you wish to use
              our site, please check these terms to ensure you understand the
              terms that apply at that time. These terms were created on 28
              September 2018.
            </p>
            <h3 className={"legalSubHead"}>
              We may make changes to our site
            </h3>
            <p className={"legalCopy"}>
              We may update and change our site from time to time to reflect
              changes to our products, our users' needs and our business
              priorities. We will try to give you reasonable notice of any major
              changes.
            </p>
            <h3 className={"legalSubHead"}>
              We may suspend or withdraw our site
            </h3>
            <p className={"legalCopy"}>
              Our site is made available free of charge.
              <br />
              We do not guarantee that our site, or any content on it, will
              always be available or be uninterrupted. We may suspend or
              withdraw or restrict the availability of all or any part of our
              site for business and operational reasons. We will try to give you
              reasonable notice of any suspension or withdrawal.
              <br />
              You are also responsible for ensuring that all persons who access
              our site through your internet connection are aware of these terms
              of use and other applicable terms and conditions, and that they
              comply with them.
            </p>
            <h3 className={"legalSubHead"}>
              You must keep your account details safe
            </h3>
            <p className={"legalCopy"}>
              If you choose, or you are provided with, a user login credential
              (e.g. email address), and corresponding password or any other
              piece of information as part of our security procedures, you must
              treat such information as confidential. You must not disclose it
              to any third party. By supplying us with an email address as login
              credential, you warrant that you are the true and rightful owner
              this email address.
              <br />
              You may be required by our website to prove / verify that you are
              the owner of this email address. We have the right to disable such
              information, whether chosen by you or allocated by us, at any
              time, if in our reasonable opinion you have failed to comply with
              any of the provisions of these terms of use.
              <br />
              If you know or suspect that anyone other than you knows your user
              identification credentials or password, you must promptly notify
              us at <a href="mailto:support@sprxs.io">support@sprxs.io</a>
            </p>
            <h3 className={"legalSubHead"}>
              How you may use material on our site
            </h3>
            <p className={"legalCopy"}>
              We are the owner and licensee of all intellectual property rights
              in respect of the SPRXS.io website, services. These works (as well
              as works used by us to provide a service from affiliated partners)
              are protected by copyright laws and treaties around the world. All
              such rights are reserved. Any material submitted into the website
              shall be owned by the user that posted it.
              <br />
              Our status (and that of any identified contributors) as the
              authors of content on our site must always be acknowledged.
              <br />
              You must not use any part of the content on our site for
              commercial purposes without obtaining a licence to do so from us
              or our licensors.
              <br />
              If you copy or download any part of our site in breach of these
              terms of use, your right to use our site will cease immediately
              and you must, at our option, return or destroy any copies of the
              materials you have made.
            </p>
            <h3 className={"legalSubHead"}>
              Do not rely on information on this site
            </h3>
            <p className={"legalCopy"}>
              Although we make reasonable efforts to update the information on
              our site, we make no representations, warranties or guarantees,
              whether express or implied, that the content on our site is
              accurate, complete or up to date.
            </p>
            <h3 className={"legalSubHead"}>
              We are not responsible for websites we link to
            </h3>
            <p className={"legalCopy"}>
              Where our site contains links to other sites and resources
              provided by third parties, these links are provided for your
              information only. Such links should not be interpreted as approval
              by us of those linked websites or information you may obtain from
              them.
              <br />
              We have no control over the contents of those sites or resources.
            </p>
            <h3 className={"legalSubHead"}>
              User-generated content is not approved by us
            </h3>
            <p className={"legalCopy"}>
              This website may include information and materials uploaded by
              other users of the site, including to bulletin boards and chat
              rooms. This information and these materials have not been verified
              or approved by us. The views expressed by other users on our site
              do not represent our views or values.
              <br />
              If you wish to complain about information and materials uploaded
              by other users please contact us on{" "}
              <a href="mailto:enquiries@sprxs.io">enquiries@sprxs.io</a>
            </p>
            <h3 className={"legalSubHead"}>
              Our responsibility for loss or damage suffered by you
            </h3>
            <p className={"legalCopy"}>
              <ul>
                <li>
                  We do not exclude or limit in any way our liability to you
                  where it would be unlawful to do so. This includes liability
                  for death or personal injury caused by our negligence or the
                  negligence of our employees, agents or subcontractors and for
                  fraud or fraudulent misrepresentation.
                </li>
                <li>
                  We exclude all implied conditions, warranties, representations
                  or other terms that may apply to our site or any content on
                  it.
                </li>
                <li>
                  We will not be liable to you for any loss or damage, whether
                  in contract, tort (including negligence), breach of statutory
                  duty, or otherwise, even if foreseeable, arising under or in
                  connection with:
                  <ul>
                    <li>use of, or inability to use, our site; or</li>
                    <li>
                      use of or reliance on any content displayed on our site.
                    </li>
                  </ul>
                </li>
                <li>
                  In particular, <span>IN NO EVENT SHALL SPRXS BE LIABLE TO YOU OR ANY
                  THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
                  INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST
                  PROFIT, SALES, BUSINESS REVENUE DAMAGES, BUSINESS
                  INTERRUPTION, GOODWILL, REPUTATION AND ANTICIPATD SAVINGS,
                  ARISING FROM YOUR USE OF THE SERVICES, EVEN IF SPRXS HAS BEEN
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  <br />
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                  SPRX’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
                  REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
                  LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO SPRXS FOR THE
                  SERVICES DURING THE TERM OF SUBSCRIPTION.</span>
                </li>
                <li>
                  Please note that we only provide our site for domestic and
                  private use. You agree not to use our site for any commercial
                  or business purposes, and we have no liability to you for any
                  loss of profit, loss of business, business interruption, or
                  loss of business opportunity.
                </li>
                <li>
                  SPRXS is not responsible for any incorrect or inaccurate
                  content posted on the SPRXS Website or in connection with the
                  Services. Profiles created and posted on the SPRXS Website may
                  contain links to other websites, and we are not responsible
                  for the content, accuracy or opinions expressed on such
                  websites. Under no circumstances shall SPRXS be responsible
                  for any loss or damage, resulting from use of the Services,
                  attendance at any SPRXS event, from any content posted on or
                  through the Services, or from the conduct of any users of the
                  Services, whether online or offline. The Services are provided
                  “AS-IS” and as available and SPRXS expressly disclaims any
                  warranty of fitness for a particular purpose or
                  non-infringement. SPRXS cannot guarantee and does not promise
                  any specific results from use of the Services.
                </li>
                <li>
                  You agree to indemnify and hold SPRXS, its subsidiaries, and
                  affiliates, and their respective officers, agents, partners
                  and employees, harmless from any loss, liability, claim, or
                  demand, including reasonable legal fees, made by any third
                  party due to or arising out of your use of the Services in
                  violation of this Agreement, any breach of third party
                  intellectual property rights and/or any breach and/or arising
                  from a breach of this Agreement and/or any breach of your
                  representations and warranties set forth above and/or if any
                  content that you post on the SPRXS Website or through the
                  Services causes SPRXS to be liable to another.
                </li>
              </ul>
            </p>
            <h3 className={"legalSubHead"}>
              How we may use your personal information
            </h3>
            <p className={"legalCopy"}>
              We will only use your personal information as set out in our <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "privacy");
              }}
            >Privacy&nbsp;Policy</a>.
            </p>
            <h3 className={"legalSubHead"}>
              Uploading content to our site
            </h3>
            <p className={"legalCopy"}>
              Whenever you make use of a feature that allows you to upload
              content to our site, or to make contact with other users of our
              site, you must comply with the content standards set out in our {" "}
              <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "acceptUse");
              }}
            >Acceptable&nbsp;Use&nbsp;Policy</a>.
              <br />
              You warrant that any such contribution does comply with those
              standards, and you will be liable to us and indemnify us for any
              breach of that warranty. This means you will be responsible for
              any loss or damage we suffer as a result of your breach of
              warranty or upload of malicious or virus software).
              <br />
              You may choose to post certain text, files, images, photos, video,
              sounds, musical works, works of authorship, or other materials to
              the Services. With regard to any content posted on the Services,
              you represent and warrant that:
              <ul className="legalAlt">
                <li>
                  you own the content or otherwise have the right to grant
                  the license set forth in this section, and
                </li>
                <li>
                  the posting of your content on or through the Services
                  does not violate the privacy rights, publicity rights,
                  copyrights, contract rights or any other rights of any person.
                </li>
              </ul>
              We reserve the right to delete any content that violates this
              Agreement or which may be offensive, illegal, malicious or violate
              the rights, harm, or threaten the safety of any person.
              <br />
              Where you retain all of your ownership rights in your content, you
              are required to grant us (and other users of our site) a limited
              licence, free of charge, to use, store and copy that content and
              to distribute and make it available to third parties.
              <br />
              We also have the right to disclose your identity to any third
              party who is claiming that any content posted or uploaded by you
              to our site constitutes a violation of their intellectual property
              rights, or of their right to privacy.
              <br />
              We have the right to remove any posting you make on our site if,
              in our opinion, your post does not comply with the content
              standards set out in our <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "acceptUse");
              }}
            >Acceptable&nbsp;Use&nbsp;Policy</a>. You are solely responsible for securing
              and backing up your content.
            </p>
            <h3 className={"legalSubHead"}>
              Rights you are giving us to use material you upload
            </h3>
            <p className={"legalCopy"}>
              When you upload or post content to our site, you grant us the
              rights to use that content.
            </p>
            <h3 className={"legalSubHead"}>
              We are not responsible for viruses and you must not introduce them
            </h3>
            <p className={"legalCopy"}>
              We do not guarantee that our site will be secure or free from bugs
              or viruses.
              <br />
              You are responsible for configuring your information technology,
              computer programmes and platform to access our site.
              <br />
              You should use your own virus protection software.
              <br />
              You must not misuse our site by knowingly introducing viruses,
              trojans, worms, logic bombs or other material that is malicious or
              technologically harmful. You must not attempt to gain unauthorised
              access to our site, the server on which our site is stored or any
              server, computer or database connected to our site. You must not
              attack our site via a denial-of-service attack or a distributed
              denial-of service attack. By breaching this provision, you would
              commit a criminal offence under the Computer Misuse Act 1990. We
              will report any such breach to the relevant law enforcement
              authorities and we will co-operate with those authorities by
              disclosing your identity to them. In the event of such a breach,
              your right to use our site will cease immediately.
            </p>
            <h3 className={"legalSubHead"}>
              Rules about linking to our site
            </h3>
            <p className={"legalCopy"}>
              You may link to our home page, provided you do so in a way that is
              fair and legal and does not damage our reputation or take
              advantage of it.
              <br />
              You must not establish a link in such a way as to suggest any form
              of association, approval or endorsement on our part where none
              exists.
              <br />
              You must not establish a link to our site in any website that is
              not owned by you.
              <br />
              Our site must not be framed on any other site, nor may you create
              a link to any part of our site other than the home page.
              <br />
              We reserve the right to withdraw linking permission without
              notice.
              <br />
              The website in which you are linking must comply in all respects
              with the content standards set out in our {" "}
            <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "acceptUse");
              }}
            >Acceptable&nbsp;Use&nbsp;Policy</a>.
              <br />
              If you wish to link to or make any use of content on our site
              other than that set out above, please contact{" "}
              <a href="mailto:enquiries@sprxs.io">enquiries@sprxs.io</a>.
            </p>
            <h3 className={"legalSubHead"}>
              Which country's laws apply to any disputes?
            </h3>
            <p className={"legalCopy"}>
              If you are a consumer, please note that these terms of use, their
              subject matter and their formation, are governed by English law.
              You and we both agree that the courts of England and Wales will
              have exclusive jurisdiction.
              <br />
              If you are a business, these terms of use, their subject matter
              and their formation (and any non-contractual disputes or claims)
              are governed by English law. We both agree to the exclusive
              jurisdiction of the courts of England and Wales.
            </p>
          </div>
      </div>
    );
  }

  export default withStyles(styles)(TermsConds);

