import React from "react";
import { Timeline, TimelineEvent, TimelineBlip } from "react-event-timeline";
import MilestoneForm from "../MilestoneForm/MilestoneForm";
import TimeCardCollab from "../TimeCardCollab/TimeCardCollab";
import s from "../../../static/s.png";


class MilestoneCollabList extends React.Component {
    state = { openMilestoneForm: false, milestone: {} };
    render() {
        console.log("vivian4", this.props.data);
        // debugger;
        return (
            <div>
                {this.props.data.length && <div className="timelineBar">
                    <Timeline>
                        {this.props.data.map((item, key) => (
                            <TimelineEvent
                                key={key}
                                iconColor="#ef8406"
                                icon={
                                    <img
                                        src={s}
                                        style={{
                                            width: 18,
                                            transform: "translate(0%, 0%)",
                                            position: "absolute"
                                        }}
                                    />
                                }
                                bubbleStyle={{ backgroundColor: "#fff" }}
                            >
                                <TimeCardCollab
                                    milestone={item}
                                    cancelMilestone={this.props.handleCancelMilestone}
                                    launchMilestoneForm={this.handleLaunchMSForm}
                                />
                            </TimelineEvent>
                        ))}
                    </Timeline>
                </div>}
                <MilestoneForm
                    jwtToken={this.props.jwtToken}
                    ideaName={this.props.ideaName}
                    milestoneData={this.state.milestone}
                    open={this.state.openMilestoneForm}
                    closeMilestoneForm={this.closeMSForm}
                    edit={true}
                    updateMilestoneData={this.props.updateMilestoneData}
                />
            </div>
        );
    }
}

export default MilestoneCollabList;
