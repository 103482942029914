import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import firebase from "../../constants";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const styles = theme => ({
  root: {
    display: "table",
    justifyContent: "left",
    flexWrap: "wrap"
  },
  outgoing: {
    display: "table",
    marginLeft: "auto",
    marginRight: 0,
    justifyContent: "left",
    flexWrap: "wrap",
    maxWidth: "90%"
  },
  incomming: {
    display: "table",
    justifyContent: "left",
    flexWrap: "wrap",
    maxWidth: "90%",
    color: "#e8e8e8"
  },
  chip: {
    margin: theme.spacing.unit,
    maxWidth: ""
  },
  messageOut: {
    textAlign: "left",
    justifyContent: "left",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    marginRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 1,
    borderRadius: "25px",
    borderTopRightRadius: 0,
    boxShadow: "3px 2px 10px #888888",
    position: "relative"
  },
  "& $date": {
    textAlign: "right"
  },
  messageIn: {
    textAlign: "left",
    justifyContent: "left",
    backgroundColor: "white",
    color: "#000",
    marginLeft: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 1,
    borderRadius: "25px",
    borderTopLeftRadius: 0,
    boxShadow: "3px 2px 10px #888888"
  },
  dateIncoming: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    fontSize: "12px",
    paddingTop: "4px",
    textAlignLast: "left"
  },
  dateOutgoing: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    fontSize: "12px",
    paddingTop: "4px",
    textAlignLast: "right"
  }
});

function convertTimestamp(timestamp) {
  var d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
    yyyy = d.getFullYear(),
    mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
    dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
    hh = d.getHours(),
    h = hh,
    min = ("0" + d.getMinutes()).slice(-2), // Add leading 0.
    ampm = "AM",
    time;

  if (hh > 12) {
    h = hh - 12;
    ampm = "PM";
  } else if (hh === 12) {
    h = 12;
    ampm = "PM";
  } else if (hh == 0) {
    h = 12;
  }

  // ie: 12-11-2018, 8:35 AM
  time = `${dd}-${mm}-${yyyy}, ${h}:${min} ${ampm}`;

  return time;
}

class ChatMessage extends React.Component {
  state = {
    url: "",
    totalPath: "",
    file: null
  };

  componentWillMount() {
    const { message, type, fromID } = this.props;
    const { url } = this.state;
    var that = this;

    if (type !== "text" && !url && firebase.auth().currentUser) {
      //console.log("FROMID:::!", fromID);
      var uid = firebase.auth().currentUser.uid;
      // Points to the root reference
      var storageRef = firebase.storage().ref();

      // Points to 'All user files'
      var filename = storageRef
        .child("AllUsersFileData")
        .child(fromID)
        .child(message);
      storageRef
        .child(filename.location.path)
        .getDownloadURL()
        .then(url => {
          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";

          xhr.onload = event => {
            var blob = xhr.response;
            // console.log("FILE:::", blob, window.URL.createObjectURL(blob));
            this.setState({ file: window.URL.createObjectURL(blob), blob });
          };
          xhr.open("GET", url);
          xhr.send();
          this.setState({ url });

          // console.log("THE NEW URL:::", url);
        })
        .catch(error => {
          //console.log("ERROR:", error);
        });
    }
  }

  render() {
    const { classes, message, timestamp, outgoing, type } = this.props;
    const { file, url } = this.state;
    return (
      <Fade in={true} timeout={1000}>
        <div className={outgoing ? classes.outgoing : classes.incomming}>
          <div>
            <div>
              {message && (
                <div
                  className={outgoing ? classes.messageOut : classes.messageIn}
                >
                  {type === "text" && (
                    <Typography color="inherit">{message}</Typography>
                  )}
                  {type === "photo" && (
                    <img style={{ maxWidth: "100%" }} src={file} />
                  )}

                  {type == "file" && (
                    <a href={url}>
                      {" "}
                      <AttachFileIcon />
                      {message}
                    </a>
                  )}
                </div>
              )}
              <Typography
                className={
                  outgoing ? classes.dateOutgoing : classes.dateIncoming
                }
                variant="caption"
              >
                {convertTimestamp(timestamp)}
              </Typography>
            </div>
            {message.showAvatar && (
              <div className="MuiAvatar-root-796 ChatMain-bigAvatar-524">
                <img
                  alt="Profile Picture"
                  src="/static/media/avatar1.5cfe6490.jpeg"
                  className="MuiAvatar-img-798"
                />
              </div>
            )}
          </div>
        </div>
      </Fade>
    );
  }
}
{
  /* <img style={{ maxWidth: "100%" }} src={file} /> */
}
// ChatMessage.propTypes = {
//   classes: PropTypes.object.isRequired,
//   sender: PropTypes.object.isRequired,
//   message: PropTypes.object.isRequired,
//   timestamp: PropTypes.object.isRequired,
//   outgoing: PropTypes.object.isRequired
// };

export default withStyles(styles)(ChatMessage);
