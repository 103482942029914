import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import cubeIcon from "../../static/cube.png";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  lightTooltip: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 11
  },
  gridList: {
    width: "auto",
    height: "auto"
  },
  subheader: {
    width: "100%"
  },
  button: {
    margin: theme.spacing.unit
  },
  icon: {
    position: "relative",
    color: "rgba(255, 255, 255, 0.54)"
  }
});

/*
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 */
const tileData = [
  {
    img: "https://source.unsplash.com/800x600?transport",
    title: "#AS2",
    author: "0xfeee...",
    cols: 2
  },
  {
    img: "https://source.unsplash.com/800x600?travel",
    title: "#AS2",
    author: "0xfeea...",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/800x600?design",
    title: "#AS2",
    author: "0xeeda...",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/800x600?art",
    title: "#AS2",
    author: "0xffff...",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/800x600?film",
    title: "#AS2",
    author: "0xfedf...",
    cols: 2
  },
  {
    img: "https://source.unsplash.com/800x600?business",
    title: "#AS2",
    author: "0xffda...",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/800x600?agriculture",
    title: "#AS2",
    author: "0xfeaa...",
    cols: 2
  },
  {
    img: "https://source.unsplash.com/800x600?technology",
    title: "#AS2",
    author: "0xfeda...",
    cols: 2
  }
];

function GridIdeas(props) {
  const { classes, openCreate } = props;

  return (
    <div className={classes.root}>
      <GridList
        cellHeight={160}
        spacing={1}
        className={classes.gridList}
        cols={4}
      >
        <GridListTile key="Subheader" cols={4} style={{ height: "auto" }}>
          <ListSubheader component="div">
            Some amazing ideas are already on the blockchain seeking investment.
            Come and join in!{" "}
            <span>
              <Button
              onClick={() => {openCreate()}}
                variant="extendedFab"
                color="primary"
                className={classes.button}
              >
                Create Idea
              </Button>
            </span>
          </ListSubheader>
        </GridListTile>
        {tileData.map(tile => (
          <GridListTile key={tile.author} cols={tile.cols || 1}>
            <img src={tile.img} alt={tile.title} />
            <GridListTileBar
              title={tile.title}
              subtitle={<span>by: {tile.author}</span>}
              actionIcon={
                <Tooltip
                  title="Secured on blockchain"
                  placement="left"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <IconButton className={classes.icon}>
                    <img src={cubeIcon} width={20} alt="cube" />
                  </IconButton>
                </Tooltip>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}

GridIdeas.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(GridIdeas);
