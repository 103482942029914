import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ChatMessage from "../ChatMessage/ChatMessage";
import * as ReactDOM from "react-dom";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      minWidth: "500px"
    }
  }
});

class ChatMessages extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  scrollToBottom = () => {
    const ref = this.myRef.current;
    const scrollHeight = (ref && ref.scrollHeight) || 0;
    const height = (ref && ref.clientHeight) || 0;
    const maxScrollTop = scrollHeight - height;
    if (ref) {
      ReactDOM.findDOMNode(ref).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  componentDidMount() {
    // console.log(`componentDidMount`);
    this.scrollToBottom();
  }

  componentWillMount() {
    //console.log(`componentWillMount`);
    this.scrollToBottom();
  }

  componentDidUpdate() {
    //console.log(`componentDidUpdate`);
    this.scrollToBottom();
  }

  render() {
    const { classes, messageList } = this.props;

    let currentFromId = null;
    return (
      <div ref={this.myRef} className={classes.root}>
        {messageList.map((item, i) => {
          // //console.log("ITEM::: ", item);
          return (
            <ChatMessage
              key={i}
              sender={`${item.firstName} ${item.lastName}`}
              message={item.text}
              timestamp={item.timestamp}
              outgoing={item.outgoing}
              fromID={item.fromID}
              {...item}
            />
          );
        })}
      </div>
    );
  }
}

ChatMessages.propTypes = {
  classes: PropTypes.object.isRequired,
  messageList: PropTypes.array.isRequired
};

export default withStyles(styles)(ChatMessages);
