import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PublicIcon from "@material-ui/icons/Public";
import { SupervisedUserCircle } from "@material-ui/icons";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket"; // NC 2018/12/13
import TimelineFeed from "../TimelineFeed/TimelineFeedTest";
import {
  ideaStatus,
  ideaClassification,
  genesisMaturity,
  purposeClassification,
  woiRole,
  woiCapacity,
  woiRemun,
  hostApi
} from "../../constants";
import axios from "axios";
import { withCookies } from "react-cookie";
import MyIdeas from "../TableView/MyIdeas";
import MyCollabs from "../TableView/MyCollabs"; // KN 2018.11.05
import MyNotifications from "../TableView/MyNotifications"; // KN 2018.12.21
import MyActivities from "../TableView/MyActivities"; // KN 2018.12.21
import IdeaProgressDiag from "../IdeaProgressDiag/IdeaProgressDiag";
import SocialMediaFeed from "../../components/SocialMediaFeed/SocialMediaFeed";
import classNames from "classnames";
import { setMySprxsTab } from "../../store/actions/actions";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 0 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
    marginTop: theme.spacing.unit * 2
  },
  tabs: {
    borderRadius: 5
  },
  tabSelect: {
    color: "white"
  }
});

class FullWidthTabs extends React.Component {
  state = {
    value: this.props.selectedSparkTab,
    data_items: [],
    data_items2: [],
    data_items3: [],
    data_items4: []
  };

  componentDidMount() {
    const { cookies, token } = this.props;
    const cookie_email = cookies.get("emailAddress");
    const jwtToken = cookies.get("bearerToken");
    //console.log("cookie email", cookie_email);
    this.setState({ isLoading: true }); //Start the spinner

    // AXIOS // MY IDEAS  ---------------------------------------------------------
    axios
      .post(`${hostApi}/myIdeas`, 
        {
          lkp_email: cookie_email,
          idea_name: this.props.searchName || "",
          idea_description: this.props.searchDesc || ""
        },
        {
          headers: {
            'Authorization': `Bearer ${jwtToken}`
          },
        }
      )
      .then(response => {
        //console("axios response (MyIdeas)", response);
        let counter = 0;
        let reformattedArray = [];
        if (response.data !== "") {
          reformattedArray = response.data.map(obj => {
            var rObj = {};
            counter += 1;
            //console("counter:", counter);    // debug
            rObj = { id: counter, ...obj };
            rObj.lkpIdeaStatus = ideaStatus[obj.lkpIdeaStatus].label;
            rObj["lkpIdeaCat1"] =
              ideaClassification[obj["lkpIdeaCat1"] - 1].label;
            rObj["lkpIdeaCat2"] = genesisMaturity[obj["lkpIdeaCat2"] - 1].label;
            rObj["lkpIdeaCat3"] =
              purposeClassification[obj["lkpIdeaCat3"] - 1].label;
            return rObj;
          });

          //console("reformated array (MyIdeas)", reformattedArray);
          this.setState({
            data_items: [...reformattedArray],
            isLoading: false
          });
        }
      });

    // AXIOS // MY COLLABORATORS  ---------------------------------------------------------
    //console("Activity Feed Line 108: cookie_email: ", cookie_email);
    axios
      .post(`${hostApi}/getCollaboratorsByProfile`, 
        {
          owner_profile: cookie_email
        },
        {
          headers: {
            'Authorization': `Bearer ${jwtToken}`
          }
        }
      )
      .then(response => {
        //console("axios response (MyCollaborators)", response);
        let counter = 0;
        let reformattedArray = [];
        if (response.data !== "") {
          reformattedArray = response.data.map(obj => {
            var rObj = {};
            counter += 1;
            //console("counter:", counter);    // debug
            rObj = { id: counter, ...obj };
            rObj["lkpWoiRole"] = woiRole[obj["lkpWoiRole"] - 1].label;
            rObj["lkpWoiCapacity"] =
              woiCapacity[obj["lkpWoiCapacity"] - 1].label;
            rObj["lkpWoiRemun"] = woiRemun[obj["lkpWoiRemun"] - 1].label;
            return rObj;
          });

          //console("reformated array (MyCollaborators):", reformattedArray);
          this.setState({
            data_items2: [...reformattedArray],
            isLoading: false
          });
        }
      });

    // AXIOS // NOTIFICATIONS  ---------------------------------------------------------
    //console.log("View Notifications Line 152: cookie_email: ", cookie_email);
    axios
      .post(`${hostApi}/viewEvent`,         
        {
        id: 0,
        event_type: "Notification",
        idea_id: "",
        event_description: "",
        created_profile_recipient: cookie_email,
        created_profile_sender: ""
      },
    {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      },
    })
      .then(response => {
        //console("axios response (MyNotifications)", response);
        let counter = 0;
        let reformattedArray = [];
        if (response.data !== "") {
          reformattedArray = response.data.map(obj => {
            var rObj = {};
            counter += 1;
            rObj = { id: counter, ...obj };
            return rObj;
          });

          this.setState({
            data_items3: [...reformattedArray],
            isLoading: false
          });
        }
      });

    // AXIOS // ACTIVITIES  ---------------------------------------------------------
    // console.log("View Activities Line 183: cookie_email: ", cookie_email);
    axios
      .post(`${hostApi}/viewEvent`,         
        {
        id: 0,
        event_type: "Activity",
        idea_id: "",
        event_description: "",
        created_profile_recipient: cookie_email,
        created_profile_sender: ""
      },
    {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then(response => {
        //console("axios response (MyActivities)", response);
        let counter = 0;
        let reformattedArray = [];
        if (response.data !== "") {
          reformattedArray = response.data.map(obj => {
            var rObj = {};
            counter += 1;
            rObj = { id: counter, ...obj };
            return rObj;
          });

          this.setState({
            data_items4: [...reformattedArray],
            isLoading: false
          });
        }
      });

    this.setState({ isAuthenticated: cookies.get("isAuthenticated") });
  }

  handleChange = (event, value) => {
    this.props.setMySprxsTab(value);
    // this.setState({ value });
  };

  handleChangeIndex = index => {
    this.props.setMySprxsTab(index);
    // this.setState({ value: index });
  };

  // RENDER
  // ----------------------------------------------------------------------------------------------------------------------------------------------------------
  render() {
    const { classes, theme } = this.props;

    return (
      <Paper elevation={10} className={classNames(classes.root, classes.tabs)}>
        <AppBar className={classes.tabs} position="static" color="primary">
          <Tabs
            className={classes.tabs}
            value={this.props.selectedSparkTab}
            onChange={this.handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            fullWidth
          >
            {/* NEWS */}
            <Tab
              className={classes.tabSelect}
              label={"News"}
              icon={<PublicIcon />}
            />
          </Tabs>
        </AppBar>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={this.props.selectedSparkTab}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer dir={theme.direction}>
            <SocialMediaFeed />
          </TabContainer>
        </SwipeableViews>
      </Paper>
    );
  }
}

FullWidthTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    selectedSparkTab: state.selectedSparkTab,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => ({
  setMySprxsTab: payload => dispatch(setMySprxsTab(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(withStyles(styles, { withTheme: true })(FullWidthTabs)));
