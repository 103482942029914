import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import axios from "axios";
import { hostApi } from "../../../constants";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class MilestoneAcknowledgeDelete extends React.Component {



    handleAcknowledgeDelete = () => {
        const { milestone, onCancelAcknowledgeDelete, token } = this.props;
        console.log("milestone data before axios", milestone)

        axios
            .post(`${hostApi}/lodgeConsensus`, {
                milestone_id: milestone.id,
                approval: 0,
                approval: 1
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                  },
            }).then(({ data }) => {
                onCancelAcknowledgeDelete();
            })
    };
    render() {
        const { open, onClose, onCancelAcknowledgeDelete, onConfirmAcknowledgeDelete } = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Acknowledge Delete"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            By acknowledging the following delete, you agree to the reason for the deletion, agreed that you have discussed it with the ideator, and confirm that the milestone will now be deleted and recorded on the blockchain.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onCancelAcknowledgeDelete}
                            color="primary">
                            Cancel
                     </Button>
                        <Button
                            onClick={this.handleAcknowledgeDelete}
                            color="primary">
                            Reject
                     </Button>
                        <Button
                            onChange={onConfirmAcknowledgeDelete}
                            onClick={this.handleAcknowledgeDelete}
                            color="primary" >
                            Confirm
                     </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
  token: state.jwtToken
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps)(MilestoneAcknowledgeDelete);
