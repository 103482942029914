import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import ExitIcon from "@material-ui/icons/ExitToApp";
import MessageIcon from "@material-ui/icons/Message";
import PersonIcon from "@material-ui/icons/Person";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ChatIcon from "@material-ui/icons/ChatBubbleOutline";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import ChatDialog from "../../containers/ChatDialog/ChatDialog";
import { connect } from "react-redux";
import { showChatModal, showAccountModal } from "../../store/actions/actions";
import ChatMain from "../../containers/ChatMain/ChatMain";
import { FormLabel } from "@material-ui/core";
import {
  collaborator_dashboard_test_flag,
  IDEATOR,
  blogLink
} from "../../constants";
import { withCookies } from "react-cookie";
import AccountModal from "../../components/AccountSettings/AccountSettings";

const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  label: {
    width: "100%"
  }
};

class SPRXSDrawer extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
    showChat: false,
    showAccount: false
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  launchChat = () => {
    this.setState({ showChat: true });
    this.props.showChat();
  };

  handleCloseChat = () => {
    this.setState({ showChat: false });
  };

  launchAccountSettings = () => {
    this.setState({ showAccountSettings: true });
    this.props.showAccount();
  };

  handleCloseAccountSettings = () => {
    this.setState({ showAccountSettings: false });
  };

  blog = e => {
    window.open(blogLink, "_blank");
  };

  render() {
    const {
      classes,
      userType,
      open,
      onClose,
      logout,
      home,
      handleSearch,
      handleSearchCovid,
      handleMarketPlace,
      searchResults,
      handleHome,
      handleDashboard,
      cookies
    } = this.props;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const { firstName, lastName, email } = userInfo
      ? userInfo
      : { email: "", firstName: "", lastName: "" };
    const { showChat } = this.state;
    const _userType = userType || cookies.get("user_type");

    const sideList = (
      <div className={classes.list}>
        <List>
          <ListItem>
            <Typography color="primary">
              {firstName} {lastName}
              <Typography style={{ color: "grey" }}>{email}</Typography>
            </Typography>
          </ListItem>
          <ListItem
            button
            key={"dashboard"}
            onClick={this.launchAccountSettings}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={"Account Settings"} />
          </ListItem>
          <Divider />

          {/* HOME */}
          <ListItem button key={"home"} onClick={home}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItem>

          {/* Dashboard */}
          <ListItem button key={"dashboard"} onClick={handleDashboard}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>

          {/* SEARCH */}
          <ListItem button key={"publicideas"} onClick={handleSearch}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={"Search Ideas"} />
          </ListItem>
          <ListItem
            button
            key={"searchIdea_covid19"}
            onClick={handleSearchCovid}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={"Search Covid"} />
          </ListItem>

          <ListItem button key={"marketplace"} onClick={handleMarketPlace}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={"Market Place"} />
          </ListItem>
          {/* CHAT */}
          <FormLabel htmlFor="toggle-chat" className={classes.label}>
            <ListItem button key={"messages"} onClick={this.launchChat}>
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText primary={"Chat"} />
            </ListItem>
          </FormLabel>

          <Divider />

          {/* LOGOUT */}
          <ListItem
            button
            key={"blog"}
            onClick={() => {
              this.blog();
            }}
          >
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText primary={"Blog"} />
          </ListItem>
          <ListItem
            button
            key={"logout"}
            onClick={() => {
              logout();
            }}
          >
            <ListItemIcon>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
        </List>
      </div>
    );

    const fullList = (
      <div className={classes.fullList}>
        <List>
          {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <div>
        <Drawer open={open} onClose={onClose} anchor="right">
          <div tabIndex={0} role="button" onClick={onClose} onKeyDown={onClose}>
            {sideList}
          </div>
        </Drawer>
        {/* <ChatDialog open={showChat} onClose={this.handleCloseChat} /> */}
        <AccountModal
          home={home}
          cookies={this.props.cookies}
          onClose={this.handleCloseAccountSettings}
        />
        <ChatMain cookies={this.props.cookies} />
      </div>
    );
  }
}

SPRXSDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userType: state.userType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showChat: () => dispatch(showChatModal()),
    showAccount: () => dispatch(showAccountModal())
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SPRXSDrawer))
);
