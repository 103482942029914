import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Typography } from "@material-ui/core";
import { hostApi } from "../../../constants";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { getCollaboratorsForMilestone, getRemainingTokens, getMilestones } from "../../../store/actions/actions";
import { connect } from "react-redux";
import MilestoneEditAlert from "../MilestoneEditAlert/MilestoneEditAlert";
import MilestoneConsentAlert from "../MilestoneConsentAlert/MilestoneConsentAlert";
import Modal from "@material-ui/core/Modal";
import "./MilestoneForm.css";
import FormHeader from "../../FormHeader/FormHeader";
import moment from "moment";

//browser detect

const complete_milestone = 1;
const incomplete_milestone = 2;

const noCollabsMsg = "This idea does not have any Collaborators.";
const assignCoinMsg = "Assign Coins to Collaborator";
const assignCoinWarning = "*Please select a Collaborator";

function convert_month(month) {
  switch (month) {
    case "Jan":
      return "01";
    case "Feb":
      return "02";
    case "Mar":
      return "03";
    case "Apr":
      return "04";
    case "May":
      return "05";
    case "Jun":
      return "06";
    case "Jul":
      return "07";
    case "Aug":
      return "08";
    case "Sep":
      return "09";
    case "Oct":
      return "10";
    case "Nov":
      return "11";
    case "Dec":
      return "12";
    default:
      return "99";
  }
}

function convert_month_to_backend_format(month) {
  switch (month) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "Apr";
    case "05":
      return "May";
    case "06":
      return "Jun";
    case "07":
      return "Jul";
    case "08":
      return "Aug";
    case "09":
      return "Sep";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    case "12":
      return "Dec";
    default:
      return "X";
  }
}

function convert_date(date) {
  if (date) {
    let parts = date.split(" ");
    let dateParts = parts[0].split("-");

    return `${dateParts[2]}-${convert_month(dateParts[1])}-${dateParts[0]}`;
  } else {
    return "";
  }
}

function convert_date_to_backend_format(date) {
  if (date) {
    let parts = date.split("-");

    return `${parts[2]}-${convert_month_to_backend_format(parts[1])}-${
      parts[0]
    } 09:00:00`;
  } else {
    return "";
  }
}

function validateMilestoneInput(state) {
  /* If anything is invalid then return true else return false */
  if (state.isTransfer) {
    if (
      state.coinAmountInvalid === true ||
      state.collaborator_id === null ||
      state.collaborator_id === undefined
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    if (
      state.date === "" ||
      state.coinAmountInvalid === true ||
      state.name === "" ||
      state.desc === ""
    ) {
      return true;
    } else {
      return false;
    }
  }
}

// To notify when a milestone is set in the past
function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

class MilestoneForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      milestones: [],
      name: "",
      desc: "",
      collaborators: [],
      collaborator_id: 0,
      collaborator_name: "",
      amount: 0,
      date: "",
      dateUnformatted: "",
      checked: false,
      ideaId: "",
      milestoneId: 0,
      ideaName: "",
      openAlert: false,
      openConsentAlert: false,
      coinAmountInvalid: true,
      enableSubmit: false,
      closeDashboard: false,
      helpMessage: "",
      noCollab: true,
      formTitle: " ",
      isTransfer: false,
    };
  }

  componentWillMount(){
    const { ideaID, ideaName, jwtToken} = this.props;
    if (ideaID !== undefined) {
      this.props.getCollaboratorsForMilestone(jwtToken, ideaID);
      this.props.getRemainingTokens(jwtToken, ideaID);
    } else {
    }

    this.setState({
      coinAmountInvalid: this.state.amount <= 100 ? false : true
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.collaboratorsByIdeaForMilestone !== this.props.collaboratorsByIdeaForMilestone) {
      if(this.props.collaboratorsByIdeaForMilestone.length > 0){
       this.setState({noCollab: false, helpMessage: assignCoinMsg});
      }else{
        this.setState({noCollab: true, helpMessage: noCollabsMsg});
      }
      this.setState({collaborators: this.props.collaboratorsByIdeaForMilestone});
    };
  }

  handleEnter = () => {
    if (this.props.transfer) {
      this.setState({
        ideaId: this.props.ideaID,
        name: "Equity Transfer",
        desc:
          "This action will transfer the assigned equity to the collaborators and cannot be undone.",
        date: formatDate(new Date()),
        checked: true,
        coinAmountInvalid: true,
        formTitle: "Transfer Equity",
        isTransfer: this.props.transfer,
        collaborator_id: this.props.collabID,
        collaborator_name: this.props.collabName
      });
    } else if (this.props.edit) {
      let collabName = "";
      if (this.props.milestoneData.firstname === undefined){
        collabName = "Collaborator"
      } else{
        collabName =  this.props.milestoneData.firstname + " "  + this.props.milestoneData.surname;
      }
      console.log("What is this? ", this.props.milestoneData.id, " idea id? ", this.props.milestoneData.ideaId);
      this.setState({
        ideaId: this.props.milestoneData.ideaId,
        milestoneId: this.props.milestoneData.id,
        name: this.props.milestoneData.milestoneName,
        desc: this.props.milestoneData.milestoneDescription,
        date:  moment.unix(this.props.milestoneData.agreedCompletionTimestamp).format("YYYY-MM-DD"),
        dateUnformatted: this.props.milestoneData.agreedCompletionTimestamp,
        amount: this.props.milestoneData.offeredTokens,
        collaborator_name: collabName,
        collaborator_id: 1,
        checked: false,
        coinAmountInvalid: false,
        formTitle: "Edit Milestone",
        isTransfer: false,
      });
    } else {
      this.setState({
        formTitle: `Create Milestone for ${this.props.ideaName}`
      });
    }
  };

  handleCloseDashboard = () => {
    this.setState({ closeDashboard: true });
  };

  handleOpenAlert = () => {
    this.setState({ openAlert: true });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  handleOpenConsent = () => {
    this.setState({ openConsentAlert: true });
  };
  handleCancelConsent = () => {
    this.setState({ openConsentAlert: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClearForm = () => {
    this.setState({
      name: "",
      desc: "",
      collaborator_id: 0,
      collaborator_name: "",
      date: "",
      checked: false,
      amount: 0
    });
  };

  handleClose = () => {
    this.handleClearForm();
    this.setState({ open: false });
    this.props.closeMilestoneForm();
  };

  handleName = e => {
    this.setState({ name: e.target.value });
  };

  handleDesc = e => {
    this.setState({ desc: e.target.value });
  };

  handleAmount = e => {
    if(e.target.value > Math.floor(e.target.value)){
      this.setState({helpMessage: "Error Amount must be a whole number"});
    }
    else {
      this.setState({helpMessage: "Assign Coins to Collaborator"});
      if (e.target.value > this.props.availableTokens || e.target.value < 0) {
        this.setState({ coinAmountInvalid: true });
      } else {
        this.setState({ coinAmountInvalid: false });
        this.setState({ amount: e.target.value });
      }
    }
  };

  handleChange = (e, id) => {
    this.setState({
      collaborator_id: id,
      collaborator_name: e.target.value
    });
  };

  handleDate = e => {
    const dateSelected = e.target.value;
    const dateNow = formatDate(new Date());

    if (dateNow > dateSelected) {
    } else {
      this.setState({ date: e.target.value });
    }
  };

  handleChecked = () => {
    this.handleCloseAlert();
    this.setState({ checked: true });
  };

  handleUnChecked = () => {
    this.handleCloseAlert();
    this.setState({ checked: false });
  };

  handlePreSubmit = edit => {
    if (this.state.amount > 0 && this.state.collaborator_id === 0) {
      this.setState({ helpMessage: assignCoinWarning });
      document.getElementById("helpMsg").style.color = "red";
    } else {
      this.handleSubmit(edit);
    }
  };

  handleSubmit = edit => {

    const { name, desc, collaborator_id, date, checked, amount } = this.state;
    const { closeMilestoneForm, updateMilestoneData, token} = this.props;

    let tmp_milestones = [...this.state.milestones];

    tmp_milestones.push({ name, desc, collaborator_id, date, checked, amount });
    this.setState({ milestones: tmp_milestones });

    if (edit) {
      const data = {
        id: this.props.milestoneData.id,
        milestone_name: name,
        milestone_description: desc,
        equity_approved: checked,
        approval: checked
          ? complete_milestone
          : incomplete_milestone,
        agreed_completion_date: convert_date_to_backend_format(date)
      };
      if (data.approval === 2) {
        this.handleOpenConsent();
        this.handleClose();
      }
      axios
        .post(`${hostApi}/editMilestone_v2`, data, {
          headers: {
            'Authorization': `Bearer ${this.props.jwtToken}`
          },
        })
        .then(response => {
          if (response.data.CM_response === "PASS") {
            if (response.data.approval === 2) {
              this.handleOpenConsent();
              this.handleClose();
            } else {
              closeMilestoneForm();
            }
            // clear the form
            NotificationManager.success(
              "Milestone successfully edited",
              "Milestone Edit",
              4000
            );
            closeMilestoneForm();
          }
          // Refresh the milestone list.
          this.props.getMilestoneData(this.props.jwtToken, this.state.ideaId, 0 /* FIXME: Have to set this as 0 */);
      });
    } else {
      /* axios post to createMilestone */
      // ------------------------------------------------------------------------------------------------------------------------
      const dataToPass = {
        collab_id: collaborator_id,
        idea_id: this.props.ideaID,
        milestone_name: name,
        milestone_description: desc,
        agreed_completion_date: convert_date_to_backend_format(date),
        agreed_tokens: amount || 0,
        equity_approved: checked
      };
      axios
        .post(`${hostApi}/createMilestone_v2`, dataToPass, {
          headers: {
            'Authorization': `Bearer ${this.props.jwtToken}`
          },
        })
        .then(({ data }) => {
          if (data.CM_response === "PASS") {
            const equityApproved = this.state.checked;

            this.handleClearForm();

            NotificationManager.success(
              "Milestone Created",
              "Milestone successfully created",
              4000
            );
            closeMilestoneForm();
          // Refresh the milestone list.
          this.props.getMilestoneData(this.props.jwtToken, this.props.ideaID, 0 /* FIXME: Have to set this as 0 */);
          }
        })
    }
  };

  render() {
    const {
      edit,
      transfer,
      milestoneData,
      open,
      closeMilestoneForm,
      ideaName,
      fullScreen,
      collabID,
      collabName,
      jwtToken
    } = this.props;
    const {
      name,
      desc,
      date,
      collaborators,
      collaborator_name,
      amount,
      noCollab,
      checked
    } = this.state;

    let momentDate = "";

    return (
      <div>
        <form noValidate autoComplete="off">
         <Dialog
            open={open}
            onClose={closeMilestoneForm}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
            fullScreen={fullScreen}
            onEnter={() => this.handleEnter()}
          >
            <FormHeader>{this.state.formTitle}</FormHeader>

            <DialogContent>
              {(edit ||
                transfer) && (
                  <FormControlLabel
                    label="Mark as completed"
                    labelPlacement="end"
                    value={checked}
                    control={
                      <Checkbox
                        onChange={this.handleOpenAlert}
                        value={checked}
                        checked={checked}
                        color="primary"
                      />
                    }
                  />
                )}
              ;
              <TextField
                id="standard-full-width"
                style={{ margin: 4, marginTop: 15 }}
                placeholder={"Your title here"}
                fullWidth
                autoFocus
                disabled={transfer}
                error={name === ""}
                margin="dense"
                variant="outlined"
                label={"Title"}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={this.handleName}
                value={name}
              />
              <TextField
                id="outlined-multiline-static"
                placeholder="Please write a description..."
                multiline
                rows="8"
                disabled={transfer}
                error={desc === ""}
                margin="normal"
                variant="outlined"
                onChange={this.handleDesc}
                value={desc}
                fullWidth
                label={"Description"}
                style={{ marginBottom: 15 }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <div className="date">
                <TextField
                  id="date"
                  label="Due date"
                  type="date"
                  fullWidth
                  disabled={transfer}
                  error={this.state.date === ""}
                  defaultValue={momentDate}
                  variant="outlined"
                  value={date}
                  style={{ marginBottom: 15 }}
                  onChange={this.handleDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <Typography className="helpMsg" id="helpMsg" gutterBottom>
                {this.state.helpMessage}
              </Typography>
              <div className="row">
                <TextField
                  className="coin"
                  autoFocus={transfer}
                  error={this.state.coinAmountInvalid}
                  helperText={
                    this.state.coinAmountInvalid
                      ? `Please choose from 0 to ${this.props.availableTokens}`
                      : " "
                  }
                  id="outlined-number"
                  disabled={edit || noCollab}
                  label={`Coin Amount / ${this.props.availableTokens}`}
                  type="text"
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="dense"
                  variant="outlined"
                  onChange={this.handleAmount}
                  value={amount}
                />
                <p className="assignArrow"> > </p>
                {(edit || transfer ) && (
                <TextField
                  style={{ marginTop: -12 }}
                  className="collab"
                  id="outlined-select"
                  disabled={edit}
                  label="Collaborator"
                  margin="dense"
                  variant="outlined"
                  value={collaborator_name}
                />
                )}
                {(!edit && !transfer ) && (
                <TextField
                  className="collab"
                  id="outlined-select"
                  disabled={edit || noCollab}
                  select
                  label="Collaborator"
                  value={collaborator_name}
                  helperText={" "}
                  onChange={e => {
                    this.handleChange(e, e.target.value);
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: "menu"
                    }
                  }}
                  margin="dense"
                  variant="outlined"
                >
                  {collaborators.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {`${option.firstname} ${option.surname}`}
                    </MenuItem>
                  ))}
                </TextField>
                )}
              </div>
            </DialogContent>

            <DialogActions style={{ marginBottom: 15, marginTop: 0 }}>
              <Button
                color="primary"
                onClick={this.handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={validateMilestoneInput(this.state)}
                color="secondary"
                onClick={
                  edit
                    ? () => {
                        this.handlePreSubmit(true);
                      }
                    : () => {
                        this.handlePreSubmit(false);
                      }
                }
                onCloseDashboard={() => this.handleCloseDashboard()}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog> 
        </form>
         <Modal open={this.state.openAlert}>
            <MilestoneEditAlert
              open={true}
              onCancelChecked={this.handleUnChecked}
              onConfirmChecked={this.handleChecked}
            />
          </Modal>
      </div>
    );
  }
}

MilestoneForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    collaboratorsByIdeaForMilestone: state.collaboratorsByIdeaForMilestone,
    availableTokens: state.availableTokens,
    thisCollaboratorID: state.collaboratorID,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCollaboratorsForMilestone: (jwtToken, payload) => {
      getCollaboratorsForMilestone(jwtToken, payload, dispatch);
    },
    getRemainingTokens: (jwtToken, ideaID) => {
      getRemainingTokens(jwtToken, ideaID, dispatch);
    },
    getMilestoneData: (jwtToken, ideaID, collaboratorID) => {
      getMilestones(jwtToken, ideaID, collaboratorID, dispatch);
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(MilestoneForm));
