import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { FaGithub, FaFacebook, FaLinkedin } from "react-icons/fa";
import logo from "../../static/fav.png";
import mainlogo from "../../static/sprxsmainlogo.png";
import logoname from "../../static/sprxslogo.png";
import chain from "../../static/chain.png";
import currency from "../../static/currency.png";
import idea from "../../static/idea.png";
import brain from "../../static/brain.png";
import spark from "../../static/spark.png";
import sparksname from "../../static/sparksname.png";
import bgimage from "../../static/hero.jpg";
import howimage from "../../static/Collaborate.jpg";
import GridIdeas from "../../components/GridIdeas/GridIdeas";
import Hidden from "@material-ui/core/Hidden";
import LandingDrawer from "./LandingDrawer";
import VideoModal from "./VideoModal";
import ReactPlayer from "react-player";
import { renderHeaderDesktop, renderHeaderMobile } from "./SharedComponents";

import { connect } from "react-redux";
import {
  SHOW_CREATE_IDEA_MODAL,
  SHOW_TERMS_MODAL,
  SHOW_PRIV_MODAL
} from "../../store/actions/actions";
import { captureEvent } from "../../utility/google_analytics";

const bgColor = "transparent";
const bgImage = "linear-gradient(to bottom right, #fc734a 20%, yellow)";
/* const bgImage = "radial-gradient(white ,#ff513a,  #FF9D3A)"; */
const howbgColor = "white";

const contactHeight = "160px";
const footerHeight = "35px";

const styles = theme => ({
  root: {
    backgroundColor: bgColor,
    backgroundImage: bgImage,
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  menuItems: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  logo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    textAlign: "center"
  },
  mainButton: {
    position: "relative",
    width: "200px"
    /* backgroundColor: "transparent" */
  },
  menu: {
    flexGrow: 1,
    textAlign: "right"
  },
  header: {
    backgroundColor: "#212526",
    backgroundImage: "#212526",
    filter: "alpha(opacity=30)"
    /* boxShadow: "none" */
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "white",
    boxShadow: "none"
  },
  subTitle: {
    color: "white",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      letterSpacing: "7px",
      lineHeight: "1.2"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px",
      letterSpacing: "7px"
    }
  },
  whatTitle: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: bgColor,
    boxShadow: "none"
  },
  what: {
    padding: theme.spacing.unit * 2,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: bgColor,
    boxShadow: "none",
    height: "auto"
  },
  whatText: {
    fontSize: "21px",
    fontWeight: "bold"
  },
  video: {
    padding: theme.spacing.unit * 2,
    alignItems: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: bgColor,
    boxShadow: "none",
    height: "auto",
    position: "relative"
    // paddingTop: "46%",
    // marginTop: "2rem",
    // marginLeft: "4rem",
    // marginRight: "4rem"
  },
  howTitle: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    // color: theme.palette.secondary.contrastText,
    // color: howbgColor,
    backgroundColor: "transparent",
    boxShadow: "none",
    fontSize: "31px",
    fontWeight: "700",
    letterSpacing: "4px"
  },
  howSection: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url(${howimage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "top",
      backgroundSize: "cover"
    }
  },
  how: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.secondary.contrastText,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "250px"
  },
  howIconText: {
    fontSize: "30px",
    fontWeight: "bold"
  },
  contact1: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: contactHeight
  },
  contact: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: contactHeight
  },
  footer: {
    paddingTop: theme.spacing.unit * 0,
    paddingLeft: theme.spacing.unit * 1,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none"
    /*  height: "100px" */
  },
  copyright: {
    paddingTop: theme.spacing.unit * 0,
    paddingLeft: theme.spacing.unit * 1,
    textAlign: "right",
    verticalAlign: "text-bottom",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: footerHeight
  },
  version: {
    paddingTop: theme.spacing.unit * 0,
    paddingLeft: theme.spacing.unit * 1,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: footerHeight
  },
  contactText: {
    textDecoration: "none",
    color: "grey",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "none"
    }
  },
  container: {
    paddingTop: theme.spacing.unit * 0
  },
  hero: {
    paddingTop: theme.spacing.unit * 0,
    boxShadow: "none",
    backgroundImage: `url(${bgimage})`,
    /* backgroundImage: "url(https://source.unsplash.com/1200x600?innovation)", */
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("xs")]: {
      height: "105vh"
    }
  },
  iconHover: {
    margin: theme.spacing.unit * 0,
    marginRight: "10px",
    color: "grey",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  playerWrapper: {
    padding: "20px",
    alignItems: "center",
    float: "center"
  },
  reactPlayer: {
    padding: "20px"
  }
});

const renderWhatMobile = classes => {
  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Paper square className={classes.howTitle}>
            <Typography color="inherit" variant="h3">
              WHAT IS SPRXS?
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper square className={classes.video}>
            <div className={classes.playerWrapper}>
              <ReactPlayer
                url="https://player.vimeo.com/video/292816980"
                width="100%"
                height="100%"
                controls
                className={classes.reactPlayer}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid>
        <Grid item xs={12}>
          <Paper square className={classes.what}>
            <Typography
              color="inherit"
              variant="body1"
              align="center"
              className={classes.whatText}
            >
              Whatever happened to that{" "}
              <strong style={{ color: "black" }}>
                brilliant spark of an idea
              </strong>{" "}
              you scribbled down in a hurry that one time?
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper square className={classes.what}>
            <Typography
              color="inherit"
              variant="body1"
              align="center"
              className={classes.whatText}
            >
              Many people suffer from what we at SPRXS call the ‘idea flop’ - it
              is often impossible to know where to start when you’re trying to
              get your idea off the ground, let alone build a team to work with,
              find investment and then get it to market...
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper square className={classes.what}>
            <Typography
              color="inherit"
              variant="body1"
              align="center"
              className={classes.whatText}
            >
              ...that is, <strong style={{ color: "black" }}>until now</strong>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper square className={classes.what}>
            <Typography
              color="inherit"
              variant="body1"
              align="center"
              className={classes.whatText}
            >
              With SPRXS, your ideas can develop and grow in a secure
              environment, filled with potential collaborators and investors to
              provide you with the resources you need to succeed.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const renderWhatDesktop = classes => {
  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Paper square className={classes.howTitle}>
            <Typography color="inherit" variant="h1">
              WHAT IS SPRXS?
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl="auto">
          <Paper square className={classes.video}>
            <div className={classes.playerWrapper}>
              <ReactPlayer
                url="https://player.vimeo.com/video/292816980"
                // width="300%"
                // height="500%"
                controls
                className={classes.reactPlayer}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container direction="row" style={{ paddingBottom: "20px" }}>
        <Grid item xs={3}>
          <Paper square className={classes.what}>
            <Typography
              color="inherit"
              variant="body1"
              align="left"
              className={classes.whatText}
            >
              Whatever happened to that{" "}
              <strong style={{ color: "black" }}>
                brilliant spark of an idea
              </strong>{" "}
              you scribbled down in a hurry that one time?
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper square className={classes.what}>
            <Typography
              color="inherit"
              variant="body1"
              align="left"
              className={classes.whatText}
            >
              Many people suffer from what we at SPRXS call the ‘idea flop’ - it
              is often impossible to know where to start when you’re trying to
              get your idea off the ground, let alone build a team to work with,
              find investment and then get it to market...
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper square className={classes.what}>
            <Typography
              color="inherit"
              variant="body1"
              align="left"
              className={classes.whatText}
            >
              ...that is, <strong style={{ color: "black" }}>until now</strong>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper square className={classes.what}>
            <Typography
              color="inherit"
              variant="body1"
              align="left"
              className={classes.whatText}
            >
              With SPRXS, your ideas can develop and grow in a secure
              environment, filled with potential collaborators and investors to
              provide you with the resources you need to succeed.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const renderHowDesktop = classes => {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        spacing={24}
        className={classes.howSection}
      >
        <Grid item xs={12}>
          <Paper square className={classes.howTitle}>
            <Typography color="inherit" variant="h1">
              HOW IT WORKS
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper square className={classes.how}>
            <img
              src={chain}
              width={120}
              style={{
                marginBottom: 5
              }}
            />
            <Typography
              color="inherit"
              variant="h3"
              className={classes.howIconText}
            >
              Upload your ideas securely onto the SPRXS Blockchain
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper square className={classes.how}>
            <img
              src={idea}
              width={120}
              style={{
                marginBottom: 5
              }}
            />
            <Typography
              color="inherit"
              variant="h3"
              className={classes.howIconText}
            >
              Collaborate with experts from all over the world
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper square className={classes.how}>
            <img
              src={brain}
              width={120}
              style={{
                marginBottom: 5
              }}
            />
            <Typography
              color="inherit"
              variant="h3"
              className={classes.howIconText}
            >
              Develop your ideas in our co-creative playground
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper square className={classes.how}>
            <img
              src={currency}
              width={120}
              style={{
                marginBottom: 5
              }}
            />
            <Typography
              color="inherit"
              variant="h3"
              className={classes.howIconText}
            >
              Connect with funders who are willing to turn your ideas into
              reality
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const renderHowMobile = classes => {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        spacing={24}
        className={classes.howSection}
      >
        <Grid item xs={12}>
          <Paper square className={classes.howTitle}>
            <Typography color="inherit" variant="h3">
              HOW IT WORKS
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper square className={classes.how}>
            <img
              src={chain}
              width={120}
              style={{
                marginBottom: 5
              }}
            />
            <Typography color="inherit" variant="h6">
              Upload your ideas securely onto the SPRXS Blockchain
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper square className={classes.how}>
            <img
              src={idea}
              width={120}
              style={{
                marginBottom: 5
              }}
            />
            <Typography color="inherit" variant="h6">
              Collaborate with experts from all over the world
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper square className={classes.how}>
            <img
              src={brain}
              width={120}
              style={{
                marginBottom: 5
              }}
            />
            <Typography color="inherit" variant="h6">
              Develop your ideas in our co-creative playground
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper square className={classes.how}>
            <img
              src={currency}
              width={120}
              style={{
                marginBottom: 5
              }}
            />
            <Typography color="inherit" variant="h6">
              Connect with funders who are willing to turn your ideas into
              reality
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

class FrontPage extends React.Component {
  state = {
    openDrawer: false,
    openVideo: false,
    showCreateIdeaModal: false
  };

  handleCloseDrawer = () => {
    this.setState({ openDrawer: false });
  };

  handleOpenDrawer = () => {
    this.setState({ openDrawer: true });
  };

  handleOpenVideo = () => {
    this.setState({ openVideo: true });
  };

  handleCloseVideo = () => {
    this.setState({ openVideo: false });
  };

  showCreateIdeaModal = e => {
    e.preventDefault();
    console.log("show create idea modal");
    captureEvent("Create Idea button clicked from homepage");
    this.props.showCreateIdeaModal();
  };

  showTermsPolicyModal = e => {
    e.preventDefault();
    console.log("show terms policy");
    this.props.showTermsPolicyModal();
  };

  showPrivModal = e => {
    e.preventDefault();
    console.log("show privacy policy");
    this.props.showPrivModal();
  };

  render() {
    const { classes, history } = this.props;
    const { openDrawer, openVideo } = this.state;

    return (
      <div className={classes.root}>
        <LandingDrawer
          anchor="top"
          open={openDrawer}
          onClose={this.handleCloseDrawer}
        />
        <VideoModal open={openVideo} onClose={this.handleCloseVideo} />
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={12}>
            <Paper square className={classes.hero}>
              <div className={classes.logo}>
                <Hidden smUp>
                  <img src={mainlogo} width={180} />
                </Hidden>
                <Hidden xsDown>
                  <img src={mainlogo} width={400} />
                </Hidden>
                <Typography
                  variant="h2"
                  gutterBottom
                  className={classes.subTitle}
                >
                  MAKE THE MOST OF <br />
                  YOUR IDEAS
                </Typography>
                <Button
                  variant="extendedFab"
                  aria-label="Create"
                  onClick={this.props.showCreateIdeaModal}
                  className={classes.mainButton}
                  color="primary"
                  gutterTop
                >
                  <Typography variant="h6" color="inherit">
                    Create Idea
                  </Typography>
                </Button>
              </div>
            </Paper>
          </Grid>


          {/* Video + How it Works */}
          <Hidden smUp>{renderWhatMobile(classes)}</Hidden>
          <Hidden xsDown>{renderWhatDesktop(classes)}</Hidden>
          <Hidden smUp>{renderHowMobile(classes)}</Hidden>
          <Hidden xsDown>{renderHowDesktop(classes)}</Hidden>


          {/* Grid */}
        
          { /* <Grid item xs={12}><GridIdeas openCreate={this.props.showCreateIdeaModal} /></Grid> */ }

          {/* Footer */}

          <Grid item xs={4}>
          
          <Paper square className={classes.contact1}>
              <img
                src={logoname}
                width={120}
                style={{
                  marginBottom: 5
                }}
              />
            </Paper>

          </Grid>

          <Grid item xs={4}>
              <Paper square className={classes.contact}>
              <Typography variant="body2" className={classes.contactText}>
              </Typography>
              <Typography variant="body2" className={classes.contactText}>
              </Typography>
              <Typography variant="body2" className={classes.contactText}>
              </Typography>
              <Typography variant="body2" className={classes.contactText}>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper square className={classes.contact}>
              <Typography variant="body2" className={classes.contactText}>
              </Typography>
              <Typography
                variant="body2"
                className={classes.contactText}
                onClick={this.props.showTermsPolicyModal}>
                Terms and Conditions
              </Typography>
              <Typography
                variant="body2"
                className={classes.contactText}
                onClick={this.props.showPrivModal}
              >
                Privacy Policy
              </Typography>
              <Typography variant="body2" className={classes.contactText}>
                <a
                  className={classes.contactText}
                  href="mailto:support@sprxs.io?">
                  Contact Us
                </a>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper square className={classes.footer}>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper square className={classes.version}>
              <Typography
                variant="caption"
                style={{ color: "grey", marginRight: "5px" }}
              >
                V.2.0.0
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper square className={classes.copyright}>
              <Typography
                variant="caption"
                style={{ color: "grey", marginRight: "5px" }}
              >
                Copyright &copy; 2018 Fifth-9 Ltd.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showCreateIdeaModal: () => dispatch({ type: SHOW_CREATE_IDEA_MODAL }),
    showTermsPolicyModal: () => dispatch({ type: SHOW_TERMS_MODAL }),
    showPrivModal: () => dispatch({ type: SHOW_PRIV_MODAL })
  };
};

FrontPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(styles)(FrontPage))
);
