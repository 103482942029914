import * as actionTypes from "./actions/actions";
import {
  COLLABORATOR,
  IDEATOR,
  collaborator_dashboard_test_flag
} from "../constants";
import { deep_copy } from "../utility/utility_funcs";

/**
 * Test data
 *
 * Test data for collaborator journey.
 */
const bioText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc id cursus metus aliquam eleifend mi in nulla. Sodales ut eu sem integer vitae justo eget. Purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus. Dignissim enim sit amet venenatis urna cursus eget nunc scelerisque. Tellus integer feugiat scelerisque varius morbi. Sollicitudin nibh sit amet commodo nulla facilisi. Elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at. Quam quisque id diam vel quam elementum pulvinar. Accumsan tortor posuere ac ut consequat semper viverra nam. Ornare arcu dui vivamus arcu felis bibendum ut. Amet nulla facilisi morbi tempus iaculis urna id. Et ligula ullamcorper malesuada proin.";

/* @TODO: split this reducer into multiple reducers.
 */

const initialState = {
  // userID: null,
  waiting: false /* <-- Used to signal the busy indicator */,
  showSearchModal: false,
  showEnquiryModal: false,
  showCollabRequestModal: false,
  showCreateIdeaModal: false,
  showEditProfileModal: false,
  showRequestForUniqueModal: false,
  searchName: "",
  searchDesc: "",
  searchID: "",
  termsAlert: false,
  isAuthenticated: false,
  isRegistered: false,
  emailAddress: "",
  firstname: "",
  lastname: "",
  animation: true,
  lkp_woi_capacity: 0,
  lkp_woi_role: 0,
  lkp_woi_remun: 0,
  collab_valueadd: "",
  collab_reason: "",
  collab_filepath: "",
  note_details: "",
  note_summary: "",
  newlkpIdeaStatus: 0,
  selectedSparkTab: 0,
  collabRequestEmail: "",
  collabRequestIdeaID: "",
  searchResults: null,
  globalSearchResults: null,
  globalSearchCvResults: null,
  showLegalModal: false,
  policyType: "",
  cookiePol: false,
  privPol: false,
  termsPol: false,
  cookieToaster: false,
  compPol: false,
  showCompEntered: false,
  showAcceptUsePol: false,
  showIdeaSubmittedNTF: false,
  showCreatIdeaExisting: false,
  showChatModal: false,
  startChatWithPerson: null,
  showIdeaDetails: false,
  showEditIdea: false,
  themeType: false,
  collaboratorsByIdea: [], // update this <---
  firebaseUid: null,
  showCardView: true,
  milestoneData: [],
  ideaToEdit: null,
  availableTokens: 0,
  equityHolders: [],
  pendingEquity: [],
  firebaseFiles: null,
  userType: collaborator_dashboard_test_flag ? COLLABORATOR : null,
  collaboratorData: {},
  profileID: collaborator_dashboard_test_flag ? 21 : null,
  marketplaceData: null,
  invitationRTWOIData: [],
  invitationData: [],
  myProfile: [],
  launchAlert: false,
  launchTimeout: false,
  launchSessionWarning: false,
  alertData: {
    intent: "success",
    title: "",
    message: ""
  },
  myIdeaSummaryData: [],
  jwtToken: null,
  rtwoiProfile: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_COLLABORATOR_ID:
      return {
        ...state,
        profileID: action.payload
      };

    case actionTypes.SHOW_RTWOI_PROFILE:
      return {
        ...state,
        rtwoiProfile: action.payload,
        showRTWOIProfile: true
      };

    case actionTypes.LAUNCH_SESSION_WARNING:
      return {
        ...state,
        launchSessionWarning: true
      };
    case actionTypes.CLOSE_SESSION_WARNING:
      return {
        ...state,
        launchSessionWarning: false
      };
    case actionTypes.LAUNCH_TIMEOUT:
      return {
        ...state,
        launchSessionWarning: false,
        launchTimeout: true
      };
    case actionTypes.CLOSE_TIMEOUT:
      return {
        ...state,
        launchTimeout: false
      };
    case actionTypes.LAUNCH_ALERT:
      return {
        ...state,
        ...state.alertData,
        launchAlert: true,
        alertData: { ...action.payload }
      };
    case actionTypes.CLOSE_ALERT:
      return {
        ...state,
        launchAlert: false
      };
    case actionTypes.GET_INVITATION_RTWOI_DATA:
      // console.log('========RTWOI INVITATION============');
      // console.log("action.payload", action.payload);
      // console.log('====================================');
      return {
        ...state,
        invitationDataRTWOI: action.payload
      };
    case actionTypes.GET_MY_PROFILE:
      // console.log("MY PROFILE FOR ACCOUNT SETTINGS", action.payload);
      return {
        ...state,
        myProfile: action.payload
      };
    case actionTypes.GET_INVITATION_DATA:
      // console.log('========PUT_COLLABORATOR============');
      // console.log("action.payload", action.payload);
      // console.log('====================================');
      return {
        ...state,
        invitationData: action.payload
      };
    case actionTypes.JWT_TOKEN:
      // console.log('========JWT_TOKEN============');
      // console.log("action.payload", action.payload);
      // console.log('====================================');
      return {
        ...state,
        jwtToken: action.payload
      };
    case actionTypes.SEARCH_COLLABORATOR:
    case actionTypes.GET_ALL_COLLABORATOR:
      console.log("========GET_ALL_COLLABORATOR============");
      console.log("action.payload", action.payload);
      console.log("====================================");
      return {
        ...state,
        marketplaceData: deep_copy(action.payload)
      };
    case actionTypes.PUT_COLLABORATOR:
    case actionTypes.GET_COLLABORATOR:
      //console.log('========PUT_COLLABORATOR============');
      //console.log("action.payload", action.payload);
      //console.log('====================================');
      return {
        ...state,
        collaboratorData: deep_copy(action.payload)
      };
    case actionTypes.COLLABORATOR_AUTHENTICATED:
      //console.log('=====COLLABORATOR_AUTHENTICATED=====');
      //console.log("action.payload", action.payload);
      //console.log('====================================');
      return {
        ...state,
        userType: COLLABORATOR,
        profileID: action.payload
      };
    case actionTypes.IDEATOR_AUTHENTICATED:
      //console.log('=====IDEATOR_AUTHENTICATED=====');
      //console.log("action.payload", action.payload);
      //console.log('====================================');
      return {
        ...state,
        userType: IDEATOR,
        profileID: action.payload
      };
    case actionTypes.POST_COLLABORATOR:
      //console.log('========POST_COLLABORATOR============');
      //console.log("action.payload", action.payload);
      //console.log('====================================');
      return {
        ...state,
        collaboratorData: action.payload
      };
    case actionTypes.GET_FILE_LIST:
      return {
        ...state,
        firebaseFiles: { ...state.firebaseFiles, ...action.payload }
      };
    case actionTypes.SET_IDEA_TO_EDIT:
      return { ...state, ideaToEdit: action.payload };
    case actionTypes.UNSET_IDEA_TO_EDIT:
      return { ...state, ideaToEdit: null };
    case actionTypes.UPDATE_EQUITY_HOLDERS:
      //console.log("UPDATE_EQUITY_HOLDERS", action);
      return {
        ...state,
        equityHolders: action.payload
      };
    case actionTypes.GET_EQUITY_BY_IDEA:
      //console.log("GET_EQUITY_BY_IDEA", action);
      return {
        ...state,
        equityHolders: action.payload
      };
    case actionTypes.GET_EQUITY_PER_IDEA:
      //console.log("GET_EQUITY_PER_IDEA", action);
      return {
        ...state,
        equityPerIdea: action.payload
      };

    case actionTypes.GET_PENDING_EQUITY_BY_IDEA:
      //console.log("GET_PENDING_EQUITY_BY_IDEA", action);
      return {
        ...state,
        pendingEquity: action.payload
      };
    case actionTypes.GET_AVAILABLE_TOKENS_BY_IDEA:
      //console.log("GET_AVAILABLE_TOKENS_BY_IDEA", action);
      return {
        ...state,
        availableTokens: action.payload
      };
    case actionTypes.GET_MY_IDEAS_FOR_DASHBOARD:
      //console.log("GET_MY_IDEAS_FOR_DASHBOARD", action.payload);
      return {
        ...state,
        myIdeaSummaryData: action.payload
      };
    case actionTypes.GET_MILESTONES_BY_IDEA:
      //console.log("GET_MILESTONES_BY_IDEA", action);
      return {
        ...state,
        milestoneData: action.payload
      };
    case actionTypes.TOGGLE_CARD_VIEW:
      //console.log("TOGGLE_CARD_VIEW", action);
      return {
        ...state,
        showCardView: !state.showCardView
      };
    case actionTypes.SHOW_CARD_VIEW:
      return {
        ...state,
        showCardView: false
      };
    case actionTypes.SET_MY_SPRKS_TAB:
      // reduce the collaborators
      return { ...state, selectedSparkTab: action.payload };
    case actionTypes.GET_COLLABORATORS_BY_IDEA:
      // reduce the collaborators
      return { ...state, collaboratorsByIdea: action.payload };
    case actionTypes.GET_COLLABORATORS_BY_IDEA_FOR_MILESTONE:
      // reduce the collaborators for milestone
      return { ...state, collaboratorsByIdeaForMilestone: action.payload };
    case actionTypes.IS_AUTHENTICATED:
      let eAddress = "";

      if (action.payload === null) {
        eAddress = state.emailAddress;
      } else {
        eAddress = action.payload.email;
      }

      return {
        ...state,
        isAuthenticated: true,
        emailAddress: eAddress
      };
    case actionTypes.FIREBASE_UID:
      //console.log("FIREBASE_UID", action, action.payload);
      return {
        ...state,
        // ...state.firebaseUid,
        firebaseUid: action.payload
      };
    case actionTypes.TERMS_ALERT:
      //console.log("TERMS_ALERT", action);
      return {
        ...state,
        termsAlert: !state.termsAlert
      };
    case actionTypes.TOGGLE_THEME:
      //console.log("TOGGLE_THEME", action);
      return {
        ...state,
        themeType: !state.themeType
      };
    case actionTypes.ANIMATION:
      //console.log("ANIMATION", action);
      return {
        ...state,
        animation: !state.animation
      };
    case actionTypes.LOG_OUT:
      return {
        ...state,
        isAuthenticated: false
      };
    case actionTypes.BUSY:
      //console.log("BUSY", action);
      return {
        ...state,
        waiting: true
      };
    case actionTypes.STOP_BUSY:
      //console.log("STOP_BUSY", action.val);
      return {
        ...state,
        waiting: false
      };
    case actionTypes.SHOW_SEARCH_MODAL:
      //console.log("SHOW_SEARCH_MODAL", action);
      return {
        ...state,
        showSearchModal: true
      };
    case actionTypes.SHOW_COMP_MODAL:
      //console.log("SHOW_COMP_MODAL", action);
      return {
        ...state,
        compPol: true
      };
    case actionTypes.CLOSE_COMP_MODAL:
      //console.log("CLOSE_COMP_MODAL", action);
      return {
        ...state,
        compPol: false
      };
    case actionTypes.SHOW_ACCPT_USE_POL:
      //console.log("SHOW_ACCPT_USE_POL", action);
      return {
        ...state,
        showAcceptUsePol: true
      };
    case actionTypes.CLOSE_ACCPT_USE_POL:
      //console.log("CLOSE_ACCPT_USE_POL", action);
      return {
        ...state,
        showAcceptUsePol: false
      };
    case actionTypes.SHOW_COMP_ENTERED_MODAL:
      //console.log("SHOW_COMP_ENTERED_MODAL", action);
      return {
        ...state,
        showCompEntered: true
      };
    case actionTypes.CLOSE_COMP_ENTERED_MODAL:
      //console.log("CLOSE_COMP_ENTERED_MODAL", action);
      return {
        ...state,
        showCompEntered: false
      };
    case actionTypes.SHOW_PRIV_MODAL:
      //console.log("SHOW_PRIV_MODAL", action);
      return {
        ...state,
        privPol: true
      };
    case actionTypes.CLOSE_PRIV_MODAL:
      //console.log("CLOSE_PRIV_MODAL", action);
      return {
        ...state,
        privPol: false
      };
    case actionTypes.SHOW_COOKIE_TOASTER:
      //console.log("SHOW_COOKIE_TOASTER", action);
      return {
        ...state,
        cookieToaster: true
      };
    case actionTypes.CLOSE_COOKIE_TOASTER:
      //console.log("CLOSE_COOKIE_TOASTER", action);
      return {
        ...state,
        cookieToaster: false
      };
    // NC 18/02/19
    case actionTypes.SHOW_LEGAL_MODAL:
      return {
        ...state,
        showLegalModal: true,
        policyType: action.payload.policyType
      };
    case actionTypes.CLOSE_LEGAL_MODAL:
      return {
        ...state,
        showLegalModal: false
      };
    //
    case actionTypes.SHOW_COOKIE_MODAL:
      //console.log("SHOW_COOKIE_MODAL", action);
      return {
        ...state,
        cookiePol: true
      };
    case actionTypes.CLOSE_COOKIE_MODAL:
      //console.log("CLOSE_COOKIE_MODAL", action);
      return {
        ...state,
        cookiePol: false
      };
    case actionTypes.SHOW_TERMS_MODAL:
      //console.log("SHOW_TERMS_MODAL", action);
      return {
        ...state,
        termsPol: true
      };
    case actionTypes.CLOSE_TERMS_MODAL:
      //console.log("CLOSE_TERMS_MODAL", action);
      return {
        ...state,
        termsPol: false
      };
    case actionTypes.SHOW_IDEA_SUBMITTED_NTF:
      //console.log("SHOW_IDEA_SUBMITTED_NTF", action);
      return {
        ...state,
        showIdeaSubmittedNTF: true
      };
    case actionTypes.CLOSE_IDEA_SUBMITTED_NTF:
      //console.log("CLOSE_IDEA_SUBMITTED_NTF", action);
      return {
        ...state,
        showIdeaSubmittedNTF: false
      };
    case actionTypes.SHOW_CREATE_IDEA_EXISTING:
      //console.log("SHOW_CREATE_IDEA_EXISTING", action);
      return {
        ...state,
        showCreatIdeaExisting: true
      };
    case actionTypes.CLOSE_CREATE_IDEA_EXISTING:
      //console.log("CLOSE_CREATE_IDEA_EXISTING", action);
      return {
        ...state,
        showCreatIdeaExisting: false
      };
    case actionTypes.SHOW_CHAT_MODAL:
      //console.log("SHOW_CHAT_MODAL", action);
      return {
        ...state,
        showChatModal: true
      };
    case actionTypes.START_CHAT_WITH_PERSON:
      return {
        ...state,
        showChatModal: true,
        startChatWithPerson: action.payload
      };
    case actionTypes.CLOSE_CHAT_MODAL:
      //console.log("CLOSE_CHAT_MODAL", action);
      return {
        ...state,
        showChatModal: false
      };

    case actionTypes.SHOW_ACCOUNT_MODAL:
      return {
        ...state,
        showAccountModal: true
      };

    case actionTypes.CLOSE_ACCOUNT_MODAL:
      //console.log("CLOSE_ACCOUNT_MODAL", action);
      return {
        ...state,
        showAccountModal: false
      };

    case actionTypes.SHOW_IDEA_DETAILS:
      //console.log("SHOW_IDEA_DETAILS", action);
      return {
        ...state,
        showIdeaDetails: true
      };

    case actionTypes.CLOSE_IDEA_DETAILS:
      //console.log("CLOSE_IDEA_DETAILS", action);
      return {
        ...state,
        showIdeaDetails: false
      };

    case actionTypes.SHOW_EDIT_IDEA:
      //console.log("SHOW_EDIT_IDEA", action);
      return {
        ...state,
        showEditIdea: true
      };

    case actionTypes.CLOSE_EDIT_IDEA:
      //console.log("CLOSE_EDIT_IDEA", action);
      return {
        ...state,
        showEditIdea: false
      };

    case actionTypes.SHOW_ENQUIRY_MODAL:
      //console.log("SHOW_ENQUIRY_MODAL", action);
      return {
        ...state,
        showEnquiryModal: true,
        enquiryRequestEmail: action.payload.lkpEmail,
        enquiryRequestIdeaID: action.payload.ideaUniqueID
      };
    case actionTypes.SHOW_REQUEST_FOR_UNIQUE_MODAL:
      //console.log("SHOW_REQUEST_FOR_UNIQUE_MODAL", action);
      return {
        ...state,
        showRequestForUniqueModal: true,
        requestForUniqueEmail: action.payload.lkpEmail,
        requestForUniqueIdeaID: action.payload.ideaUniqueID
      };
    case actionTypes.SHOW_CREATE_IDEA_MODAL:
      //console.log("SHOW_CREATE_IDEA_MODAL", action);
      return {
        ...state,
        showCreateIdeaModal: true
      };
    case actionTypes.SHOW_EDIT_PROFILE_MODAL:
      //console.log("SHOW_CREATE_IDEA_MODAL", action);
      return {
        ...state,
        showEditProfileModal: true
      };
    case actionTypes.SHOW_COLLAB_REQUEST_MODAL:
      //console.log("SHOW_COLLAB_REQUEST_MODAL", action);
      return {
        ...state,
        showCollabRequestModal: true,
        collabRequestEmail: action.payload.lkpEmail,
        collabRequestIdeaID: action.payload.ideaUniqueID
      };
    case actionTypes.STORE_SEARCH_RESULTS:
      //console.log("STORE_SEARCH_RESULTS", action);
      return {
        ...state,
        ...state.searchResults,
        searchResults: action.payload
      };
    case actionTypes.STORE_GLOBAL_SEARCH_RESULTS:
      //console.log("STORE_GLOBAL_SEARCH_RESULTS", action);
      return {
        ...state,
        ...state.globalSearchResults,
        globalSearchResults: action.payload
      };
    case actionTypes.STORE_GLOBAL_SEARCH_CV_RESULTS:
      //console.log("STORE_GLOBAL_SEARCH_RESULTS", action);
      return {
        ...state,
        ...state.globalSearchResults,
        globalSearchCvResults: action.payload
      };
    case actionTypes.CLOSE_MODAL:
      //console.log("CLOSE_MODAL", action);
      return {
        ...state,
        showSearchModal: false,
        showCreateIdeaModal: false,
        showEditProfileModal: false,
        showCollabRequestModal: false,
        showEnquiryModal: false,
        showRequestForUniqueModal: false,
        showIdeaDetails: false,
        showEditIdea: false,
        showLegalModal: false,
        cookiePol: false,
        privPol: false,
        showRTWOIProfile: false
      };
    case actionTypes.SEARCH_CRITERIA:
      //console.log("SEARCH_CRITERIA", action.payload);
      //console.log("searchName", action.payload.searchCriteria.searchName);

      return {
        ...state,
        searchName: action.payload.searchCriteria.searchName,
        searchDesc: action.payload.searchCriteria.searchDesc,
        searchID: action.payload.searchCriteria.searchID
      };

    case actionTypes.COLLAB_REQUEST:
      //console.log("COLLAB_REQUEST", action.payload);

      return {
        ...state,
        collabRequestIdeaID: action.payload.collabRequest.idea_id,
        profile_id: action.payload.collabRequest.profile_id,
        lkp_woi_role: action.payload.collabRequest.lkp_woi_role,
        lkp_woi_capacity: action.payload.collabRequest.lkp_woi_capacity,
        lkp_woi_remun: action.payload.collabRequest.lkp_woi_remun,
        collab_reason: action.payload.collabRequest.collab_reason,
        collab_valueadd: action.payload.collabRequest.collab_valueadd,
        collab_filepath: action.payload.collabRequest.collab_filepath
      };

    case actionTypes.ENQUIRY_REQUEST:
      //console.log("ENQUIRY_REQUEST", action.payload);

      return {
        ...state,
        enquiryRequestIdeaID: action.payload.enquiryRequest.idea_ID,
        newlkpIdeaStatus: action.payload.enquiryRequest.newlkpIdeaStatus,
        note_summary: action.payload.enquiryRequest.note_summary,
        note_details: action.payload.enquiryRequest.note_details,
        note_attachments: action.payload.enquiryRequest.note_attachments,
        reviewer_profile_id: action.payload.enquiryRequest.reviewer_profile_id
      };

    case actionTypes.REQUEST_FOR_UNIQUE:
      //console.log("REQUEST_FOR_UNIQUE", action.payload);

      return {
        ...state,
        requestForUniqueIdeaID: action.payload.requestForUnique.idea_ID,
        newlkpIdeaStatus: action.payload.requestForUnique.newlkpIdeaStatus,
        note_summary: action.payload.requestForUnique.note_summary,
        note_details: action.payload.requestForUnique.note_details,
        note_attachments: action.payload.requestForUnique.note_attachments,
        reviewer_profile_id: action.payload.requestForUnique.reviewer_profile_id
      };

    default:
      //console.log("default");
      return state;
  }
};

export default reducer;
