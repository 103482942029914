import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import LaunchIcon from "@material-ui/icons/Launch";
import FilterListIcon from "@material-ui/icons/FilterList";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { connect } from "react-redux";
import {
  SHOW_COLLAB_REQUEST_MODAL,
  showSearchModal
} from "../../store/actions/actions";

let counter = 0;
function createData(
  ideaUniqueID,
  ideaName,
  ideaDescription,
  lkpIdeaStatus,
  lkpIdeaCat1,
  lkpEmail,
  dateCreated
) {
  counter += 1;
  return {
    id: counter,
    ideaUniqueID,
    ideaName,
    ideaDescription,
    lkpIdeaStatus,
    lkpIdeaCat1,
    lkpEmail,
    dateCreated
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

let rows = [
  { id: "ideaUniqueID", numeric: false, disablePadding: true, label: "ID #" },

  { id: "ideaName", numeric: false, disablePadding: false, label: "Idea Name" },
  {
    id: "ideaDescription",
    numeric: false,
    disablePadding: false,
    label: "Description"
  },
  {
    id: "lkpIdeaStatus",
    numeric: false,
    disablePadding: false,
    label: "Status"
  },
  {
    id: "lkpIdeaCat1",
    numeric: false,
    disablePadding: false,
    label: "Category"
  },
  { id: "lkpEmail", numeric: false, disablePadding: false, label: "Created by" },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: true,
    label: "Created on"
  },
  { id: "tokenId", numeric: false, disablePadding: false, label: "Token #" }
];
const detailRows = [
  { id: "ideaUniqueID", numeric: false, disablePadding: true, label: "ID #" },

  { id: "ideaName", numeric: false, disablePadding: false, label: "Idea Name" },
  {
    id: "ideaDescription",
    numeric: false,
    disablePadding: false,
    label: "Description"
  },
  {
    id: "lkpIdeaStatus",
    numeric: false,
    disablePadding: false,
    label: "Status"
  },
  {
    id: "lkpIdeaCat1",
    numeric: false,
    disablePadding: false,
    label: "Category"
  },
  { id: "lkpEmail", numeric: false, disablePadding: false, label: "Created by" },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Created on"
  },
  { id: "tokenId", numeric: false, disablePadding: false, label: "Token #" }
];

const summary_rows = [
  { id: "ideaUniqueID", numeric: false, disablePadding: true, label: "ID #" },

  { id: "ideaName", numeric: false, disablePadding: false, label: "Idea Name" },
  {
    id: "lkpIdeaStatus",
    numeric: false,
    disablePadding: true,
    label: "Status"
  }
];

let colCount = 9;
const detailColCount = 9;
const summaryColCount = 5;

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      summary
    } = this.props;


    if(summary == true){
      
      colCount = summaryColCount;
      rows = summary_rows;
    } else {
      colCount = detailColCount;
      rows = detailRows;

    }

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
            color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          /* backgroundColor: lighten(theme.palette.secondary.light, 0.85) */
        }
      : {
          color: theme.palette.text.primary,
          /* backgroundColor: theme.palette.secondary.dark */
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, summary } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          {/* <Typography variant="title" id="tableTitle">
            My Ideas
          </Typography> */}
        )}
      </div>
      <div className={classes.spacer} />
      
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  /* palette: {
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000"
    }
  }, */
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 0,
    marginBottom: theme.spacing.unit * 0,
    zIndex: "100",
   /*  backgroundColor: fade(theme.palette.secondary.dark, 0.2) */
  },
  table: {
    minWidth: "100%",
    /* backgroundColor: fade(theme.palette.secondary.dark, 0.2) */
  },
  tableWrapper: {
    overflowX: "auto"
  }
});



class EnhancedTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "asc",
      orderBy: "dateCreated",
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5
    };
  }

  componentDidMount() {
    //console.log("componentDidMount", this.props);
    //this.setState({data: this.props.data_items.map((item) => {createData(item)})});
    

    //console.log("reFormat", this.props.reFormat);
    if (this.props.reFormat === true && this.props.data_items) {
      let counter2 = 0;

      /* Reformat the data for table view */
      let reformattedArray = this.props.data_items.map(obj => {
        var rObj = {};
        counter2 += 1;

        rObj = { id: counter2, ...obj };

        return rObj;
      });

      this.setState({ data: reformattedArray });
      //console.log("reformatted array", reformattedArray);
    }
    else {
      //console.log("+++++++this.props.data_items",this.props.data_items)
      this.setState({ data: this.props.data_items });
    }
  }

  handleRequestSort = (event, property) => {
    //console.log("property", property);
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleFilter = e => {
    e.preventDefault();
    this.props.showSearchModal();
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { data_items, resizeWindow, summary } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

      //console.log("DATA, summary", data, summary);

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          showSearchModal={this.props.showSearchModal}
          numSelected={selected.length}
          summary={summary}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              summary={summary}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  if(summary === true){
                    return (
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, n.id)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        {
                          <TableCell padding="checkbox">
                            <Checkbox checked={isSelected} color="primary"/>
                          </TableCell>
                        }
                        <TableCell component="th" scope="row" padding="none">
                          {n.ideaUniqueID}
                        </TableCell>
                        <TableCell>{n.ideaName}</TableCell>
  
                        <TableCell>{n.lkpIdeaStatus}</TableCell>
                        {/* <TableCell>{n.tokenId}</TableCell> */}
                      </TableRow>
                    );
  
                  } else {
                    return (
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, n.id)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        {
                          <TableCell padding="checkbox">
                            <Checkbox checked={isSelected} color="primary"/>
                          </TableCell>
                        }
                        <TableCell component="th" scope="row" padding="none">
                          {n.ideaUniqueID}
                        </TableCell>
                        <TableCell >{n.ideaName}</TableCell>
                        <TableCell >{n.ideaDescription}</TableCell>
                        <TableCell >{n.lkpIdeaStatus}</TableCell>
                        <TableCell >{n.lkpIdeaCat1}</TableCell>
                        <TableCell >{n.lkpEmail}</TableCell>
                        <TableCell >{n.dateCreated}</TableCell>
                        <TableCell >{n.tokenId}</TableCell>
                      </TableRow>
                    );

                  }
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={colCount} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    showSearchModal: () => dispatch(showSearchModal()),
    dispatchCollabModal: () => dispatch({ type: SHOW_COLLAB_REQUEST_MODAL })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EnhancedTable));
