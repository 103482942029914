import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getAllCollaborator } from "../../store/actions/actions";
import { Typography, Tooltip, Divider } from "@material-ui/core";
import Progress from "../../components/ProgressBar/Progress";
import Card from "@material-ui/core/Card";  
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import green from "@material-ui/core/colors/green";
import person from "../../static/collaborator.png";
import { ExpertiseMap } from "../../constants";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";

import Expand from "./Expand";
import InviteCollaborator from "./InviteCollaborator";
import MarketplaceWelcome from "./MarketplaceWelcome";
import { withCookies } from "react-cookie";


const styles = theme => ({
  root: {
    marginTop: 100,
    width: "100%",
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  demo: {
    height: 200
  },
  control: {
    padding: theme.spacing.unit * 2
  },
  card: {
    maxWidth: 400,
    minWidth: 400
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    width: 120
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: green[500]
  },
  bigAvatar: {
    display: "inline-grid",
    margin: 0,
    width: 110,
    height: 110,
    left: "50%",
    transform: "translateX(-50%)"
  },
  content: {
    minHeight: 300
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
    marginTop: 1
  },
  skillName: {
    fontSize: "0.8rem",
    border: "0px solid red",
    width: "280px"
  },
  skillLevel: {
    border: "0px solid blue",
    float: "Right"
  },
  requestCollabIcon: {
    color: "var(--orange)",
    cursor: "pointer",
    height: 26,
    width: 26,
    margin: "10px"
  }

});

const experience = [
  {
    value: 1,
    label: "Beginner"
  },
  {
    value: 2,
    label: "Intermediate"
  },
  {
    value: 3,
    label: "Expert"
  }
];

function getRating(rating) {
  switch (rating) {
    case 2:
      return (
        <Tooltip title={"Intermediate"}>
          <span>
            <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
            <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
            <IconButtonComponent  type={"starBorder"} iconStyle={"iconDefault iconCodeBlue"} />
          </span>
        </Tooltip>
      );

    case 3:
      return (
        <Tooltip title={"Expert"}>
          <span>
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          </span>
        </Tooltip>
      );

    default:
      //includes rating of 1
      return (
        <Tooltip title={"Beginner"}>
          <span>
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"starBorder"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"starBorder"} iconStyle={"iconDefault iconCodeBlue"} />
          </span>
        </Tooltip>
      );
  }
}

class Marketplace extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      spacing: "16",
      openInvite: false,
      collabData: null,
      openWelcome: !props.cookies.get("marketplace_welcome")
    };
  
  }

  componentDidMount() {    
    const jwtToken = this.props.cookies.get("bearerToken");

    this.props.getAllCollaborator(jwtToken,
      this.props.cookies.get("profile_id"),
    );
  }

  handleInvite = data => {
    this.setState({ collabData: data }, () => {
      this.setState({ openInvite: true });
    });
  };

  setWelcomeCookie = () => {
    const { cookies } = this.props;

    cookies.set("marketplace_welcome", true);
    this.setState({openWelcome: false});
  }

  render() {
    const { classes, marketplaceData, cookies } = this.props;
    const { spacing } = this.state;

    return (
      <>
        <Grid container className={classes.root} spacing={16}>
          <Grid item xs={12}>
            <Grid
              container
              className={classes.demo}
              justify="center"
              spacing={Number(spacing)}
            >
              {marketplaceData ? (
                marketplaceData.map((record, i) => (
                  <Grid key={i} item>
                    <Card key={i} className={classes.card}>
                      <CardHeader
                        avatar={
                          <Avatar
                          alt="avatar"
                          src={person}
                          className={classes.smallAvatar}
                        />
                        }
                        action={
                          <IconButtonComponent 
                            type={"invite"}
                            iconStyle={"iconAction iconRightCorner"}
                            toolTip={true}
                            title={"Invite to collaborate"}
                            link={() => {
                              this.handleInvite(record)
                            }}
                          />
                        }
                        title={`${record.firstname} ${record.surname}`}
                        subheader={`${ExpertiseMap[record.primaryExpertise]}`}
                      />
                      
                      <CardContent className={classes.content}>
                        <Typography component="p">
                          {record.profileDesc}
                        </Typography>
                        <Expand
                          title="Skills / Resources"
                          icon={
                            <IconButtonComponent type={"build"} iconStyle={"iconDefault iconPadRight"} />
                          }
                        >
                          {record.skills.map(skill => {
                            return <List dense={true}>
                              <ListItem>
                                <ListItemText
                                  className={classes.skillName}
                                  primary={
                                    <Typography >{skill.skillName}</Typography>
                                  }
                                />
                                <ListItemSecondaryAction className={classes.skillLevel}>
                                  {getRating(skill.skillLevel)}
                                </ListItemSecondaryAction>
                              </ListItem>
                            </List>
                          })}
                        </Expand>
                        <Expand
                          title="Weblinks"
                          icon={
                            <IconButtonComponent type={"public"} iconStyle={"iconDefault iconPadRight"} />
                          }
                        >
                          {record.weblinks.map(weblink => {
                            return (
                              <div id={"permalink_section"}>
                                <a href={weblink.url}>{weblink.url}</a>
                              </div>
                            );
                          })}
                        </Expand>
                        <Expand
                          title="Education"
                          icon={
                            <IconButtonComponent type={"school"} iconStyle={"iconDefault iconPadRight"} />
                          }
                        >
                          {record.educations.map(edu => {
                            return (
                              <>
                                <Typography gutterBottom>
                                  {`${edu.schoolName}, ${edu.qualification}, ${
                                    edu.major
                                  }, Graduated: ${edu.year}`}
                                </Typography>
                                <Divider />
                              </>
                            );
                          })}
                        </Expand>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <>
                  <paper className={classes.root}>
                    <Progress />
                  </paper>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <InviteCollaborator
          jwtToken={this.props.cookies.get("bearerToken")}
          open={this.state.openInvite}
          collabData={this.state.collabData}
          handleClose={() => {
            this.setState({ openInvite: false });
          }}
        />
        <MarketplaceWelcome
          open={this.state.openWelcome}
          handleClose={() => {
            this.setState({ openWelcome: false });
          }}
          setCookie={this.setWelcomeCookie}
        />
      </>
    );
  }
}

Marketplace.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  marketplaceData: state.marketplaceData
});

const mapDispatchToProps = dispatch => ({
  getAllCollaborator: (jwtToken, payload, callback) => {
    getAllCollaborator(jwtToken, payload, callback, dispatch);
  }
});

export default withCookies(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Marketplace)));
