import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import {
  hostApi,
  ideaStatus,
  ideaClassification,
  genesisMaturity,
  purposeClassification,
  IDEATOR
} from "../../constants";
import { userIsAuthenticated } from "../../authentication/authentication";

import "../../App.css";
import { TERMS_ALERT } from "../../store/actions/actions";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { MyParticles } from "../../components/MyParticles/MyParticles";
import CardView from "../../components/CardView/CardView";
import { Spinner } from "../../components/Spinner/Spinner";
import TableView from "../../components/TableView/TableView";
import ReactCardFlip from "react-card-flip";
import { Paper } from "@material-ui/core";
import SpeedDials from "../../components/SpeedDials/SpeedDials";
import MyIdeas from "../../components/TableView/MyIdeas";
import { withStyles } from "@material-ui/core/styles";
import ChatDialog from "../ChatDialog/ChatDialog";
import "./SearchCovidPage.css";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 10
  }
});

/* Create a customised style for this instance of MyIdeas because we
 * want a margin at the top for this view
 */

const MyIdeas2 = withStyles(styles, { withTheme: true })(MyIdeas);
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

class SearchCovidPage extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    this.state = {
      name: cookies.get("name") || "",
      isAuthenticated: cookies.get("isAuthenticated") || false,
      cookie_email: cookies.get("emailAddress"),
      height: props.height,
      width: props.width,
      isLoading: false,
      cardView: true,
      isFlipped: false,
      data_items: [],
      openChat: false
    };
  }

  componentDidMount() {
    //console.log("WINDOW : ", window);
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
    const { cookies, searchID, searchName, searchDesc } = this.props;

    const cookie_email = cookies.get("emailAddress");

    this.setState({ isLoading: true });

    if (this.props.data_items) {
      //console.log("UPDATE DATA");
      this.setState({ data_items: this.props.data_items });
    }
    this.setState({ isLoading: false });
    axios
      .post(
        `${hostApi}/searchIdea_covid19`,
        {
          profile_id: cookies.get("profile_id"),
          idea_uniqueid: searchID,
          idea_name: searchName || "",
          idea_description: searchDesc || ""
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("bearerToken")}`
          }
        }
      )
      .then(response => {
        console.log("axios response ajb", response);

        this.setState({ data_items: response.data, isLoading: false });
      });

    this.setState({ isAuthenticated: cookies.get("isAuthenticated") });
  }

  handleLike = e => {
    e.preventDefault();
  };

  handleChangeView = e => {
    this.setState({ cardView: !this.state.cardView });
  };

  handleFlip = e => {
    e.preventDefault();
    this.setState({ isFlipped: !this.state.isFlipped });
  };

  launchChat = value => {
    console.log("value", value);
    this.setState({ showChat: true, ideaRef: value });
  };
  handleCloseChat = () => {
    this.setState({ showChat: false });
  };

  render() {
    const { userType, cookies } = this.props;
    const _userType = userType || cookies.get("user_type");
    const resizeWindow = this.state.width < 500;
    const sName = this.props.searchName;
    //console.log("this.props.searchResults", this.props.data_items);

    let counter2 = 0;
    let reformattedArray = [];

    let _data = null;

    if (this.props.data_items) {
      _data = this.props.data_items;
    } else if (this.state.data_items) {
      _data = this.state.data_items;
    }

    if (_data) {
      /* Reformat the data for table view */
      reformattedArray = _data.map(obj => {
        var rObj = {};
        counter2 += 1;
        rObj = { id: counter2, ...obj };
        //console.log("ideaStatus", obj["lkpIdeaStatus"]);
        rObj.lkpIdeaStatus =
          ideaStatus[obj.lkpIdeaStatus] && ideaStatus[obj.lkpIdeaStatus].label;
        rObj["lkpIdeaCat1"] =
          ideaClassification[obj["lkpIdeaCat1"] - 1] &&
          ideaClassification[obj["lkpIdeaCat1"] - 1].label;
        rObj["lkpIdeaCat2"] =
          genesisMaturity[obj["lkpIdeaCat2"] - 1] &&
          genesisMaturity[obj["lkpIdeaCat2"] - 1].label;
        rObj["lkpIdeaCat3"] =
          purposeClassification[obj["lkpIdeaCat3"] - 1] &&
          purposeClassification[obj["lkpIdeaCat3"] - 1].label;

        return rObj;
      });
    }

    return (
      <Paper
        elevation={0}
        style={{
          backgroundColor: "transparent"
        }}
      >
        <Spinner showSpinner={this.state.isLoading} />
        <div style={{ paddingTop: "0px", zIndex: "100" }}>
          {reformattedArray && (
            <div>
              {this.state.cardView ? (
                /* Note: have to set the div keys to force re-render when props change. */
                <div
                  key={rand()}
                  style={{ marginTop: "80px", marginBottom: "10%" }}
                >
                  <h2 className="covidpagetitle">
                    Welcome to CV-19 related ideas
                  </h2>
                  <CardView
                    jwtToken={cookies.get("bearerToken")}
                    pub={true}
                    id="CardView"
                    data_items={reformattedArray}
                    sName={sName}
                    resizeWindow={resizeWindow}
                    cookie_email={this.state.cookie_email}
                  />
                </div>
              ) : (
                <div
                  key={rand()}
                  style={{
                    marginRight: "1%",
                    marginLeft: "1%",
                    position: "relative",
                    zIndex: "100"
                  }}
                >
                  {
                    //console.log("ABOUT TO RENDER TABLE", reformattedArray)
                  }
                  {/* <MyIdeas2
                    pub={true}
                    tableTitle={"Searchable Ideas"}
                    data={reformattedArray}
                    openChat={this.launchChat}
                  /> */}
                </div>
              )}
            </div>
          )}
        </div>
        {/* {_userType === IDEATOR && <SpeedDials direction="up" fixed={true} openChat={this.launchChat} />} */}
        {/* <ChatDialog

          open={this.state.showChat}
          onClose={this.handleCloseChat}
          ideaRef={this.state.ideaRef}
        /> */}
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchName: state.searchName,
    searchDesc: state.searchDesc,
    searchID: state.searchID,
    isAuthenticated: state.isAuthenticated,
    animate: state.animation,
    data_items: state.globalSearchCvResults,
    emailAddress: state.emailAddress,
    userType: state.userType,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchToggleTermsAlert: () => dispatch({ type: TERMS_ALERT })
  };
};

export default withRouter(
  withCookies(connect(mapStateToProps, mapDispatchToProps)(SearchCovidPage))
);
