import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import logo from "../../static/fav.png";
import BlockChainSmallIcon from "../../static/blockchainNavIcon.svg";
import AIONIcon from "../../static/aion.png";
import { AddCircle } from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { blogLink } from "../../constants";
import { captureEvent } from "../../utility/google_analytics"


import "./HomeNav.css";

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

class HomeNav extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    searchString: "",
    showCardIcon: true,
    loading: false,
    openDrawer: false
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  blog = e => {
    window.open(blogLink,'_blank');
  };

  setJourney = () => {
    document.cookie = "joinType = NONE";
    this.props.history.push("/signup");
  }

  render() {
    const {
      classes,
      title,
      how,
      media,
      journey,
      roles,
      showCreateIdeaModal,
      mail,
      signup
    } = this.props;
    const { anchorEl, mobileMoreAnchorEl } = this.state;

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
        className="homeMobileMenu"
      >
        <MenuItem>
          <Typography
            variant="p"
            onClick={() => {
              this.props.history.push("/faq");
              this.handleMobileMenuClose();
            }}
          >
            FAQ
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography
            variant="p"
            className="special"
            onClick={() => {
              this.blog();
              captureEvent("Blog button clicked from homescreen");
            }}
          >
            BLOG
          </Typography>
        </MenuItem>
        {this.props.isAuthenticated &&(
        <MenuItem>
          <Typography
            variant="p"
            className="special"
            onClick={() => {
              this.props.history.push("/dashboard");
              this.handleMobileMenuClose();
            }}
          >
            My SPRXS
          </Typography>
        </MenuItem>
        )};
         {!this.props.isAuthenticated &&(
           <React.Fragment>
        <MenuItem>
          <Typography
            variant="p"
            className="special"
            onClick={() => {
              this.props.history.push("/login");
              this.handleMobileMenuClose();
              captureEvent("Login button clicked from homescreen");
            }}
          >
            LOGIN
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography
            variant="p"
            className="special"
            onClick={() => {
              this.setJourney();
              this.handleMobileMenuClose();
              captureEvent("Sign-up button clicked from homescreen");
            }}
          >
            SIGN UP
          </Typography>
        </MenuItem>
        </React.Fragment>
         )};
      </Menu>
    );

    return (
      <div className="header">
        <div className="homeNavWrapper">
          <div className="menuIcons">
            <img
              src={logo}
              width={40}
              onClick={() => {
                this.props.history.push("/");
              }}
              className="logoBtn"
            />
            
            <span>
              <img
                src={BlockChainSmallIcon}
                width={50}
                className="blockChainIcon"
              />
              <p className="blockChainText">Powered by SPRXS DLT</p>
            </span>


          </div>
          <div className="navLinks">
            <a
              href="#"
              onClick={() => {
                this.props.history.push("/faq");
                captureEvent("FAQ button clicked from homescreen");
              }}
            >
              FAQ
            </a>
            <a
              href="#"
              onClick={() => {
                this.blog();
                captureEvent("Blog button clicked from homescreen");
              }}
            >
              BLOG
            </a>
          </div>
          <div className="navLinks">
          {this.props.isAuthenticated &&(
            <a
              href="#"
              onClick={() => {
                this.props.history.push("/dashboard");
              }}
              className="special"
            >
              My SPRXS
            </a>
            )};
             {!this.props.isAuthenticated &&(
              <React.Fragment>
            <a
              href="#"
              onClick={() => {
                this.props.history.push("/login");
                captureEvent("Login button clicked from homescreen");
              }}
              className="special"
            >
              LOGIN
            </a>
            <a
              href="#"
              onClick={() => {
                this.setJourney();
                captureEvent("Sign-up button clicked from homescreen");
              }}
              className="special"
            >
              SIGN UP
            </a>
            </React.Fragment>
             )};
          </div>
        </div>
        <div className="navLinksMobile">
          <IconButton
            aria-haspopup="true"
            onClick={this.handleMobileMenuOpen}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </div>
        {renderMobileMenu}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated
  };
};

export default withRouter(
  connect(
    mapStateToProps
  )(withStyles(styles)(HomeNav))
  );
