/**
|--------------------------------------------------
| Utility fuctions
| 
| Purpose: this module contains useful utility
| functions.
|--------------------------------------------------
*/
import {
  ideaStatus,
  ideaClassification,
  genesisMaturity,
  purposeClassification
} from "../constants";

/**
 * Function: reformat_idea_data(ideaData)
 *
 * Param: ideaData
 * Description:-
 *  An object that looks like this
 * {
 *  allowCollaborate: true
 *  allowPublic: true
 *  allowSale: false
 *  allowSearch: true
 *  collabSkillsRequired: "lawer"
 *  collabSynopsis: "test"
 *  ideaDateCreated: "2018-10-25 06:14:06.0"
 *  ideaDescription: "test"
 *  ideaFilepath: null
 *  ideaName: "fashion"
 *  ideaUniqueID: "3MNTJC"
 *  lkpEmail: "andrew.baxter@fifth-9.com"
 *  lkpIdeaCat1: 6 //<-- this is what we need to convert
 *  lkpIdeaCat2: 2 //<-- this is what we need to convert
 *  lkpIdeaCat3: 4 //<-- this is what we need to convert
 *  lkpIdeaStatus: 1 //<-- this is what we need to convert
 *  tokenId: "6393780568196633982473148cf79ca9"
 * }
 *
 *
 * Convert Idea Data that contains values to human
 * readable.
 *
 * e.g. {IdeaCat1: 1} into {IdeaCat1: "Technology"}
 */
export function reformat_idea_data(ideaData) {
  let counter2 = 0;

  var rObj = {};
  counter2 += 1;

  /* Add an id */
  rObj = { id: counter2, ...ideaData };

  /* Make sure this conversion is done on the copy! */
  //console.log("ideaStatus", obj["lkpIdeaStatus"]);
  rObj.lkpIdeaStatus = ideaStatus[ideaData.lkpIdeaStatus].label;
  rObj["lkpIdeaCat1"] = ideaClassification[ideaData["lkpIdeaCat1"] - 1].label;
  rObj["lkpIdeaCat2"] = genesisMaturity[ideaData["lkpIdeaCat2"] - 1].label;
  rObj["lkpIdeaCat3"] =
    purposeClassification[ideaData["lkpIdeaCat3"] - 1].label;

  return rObj;
}

/**
 * function deep_copy()
 * 
 * returns a deep copy of an object.
 * 
 * params: source object
 * 
 * returns: copy of object
 */
export function deep_copy(obj){
  return( JSON.parse(JSON.stringify(obj)) )
}