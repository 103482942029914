import React from 'react';

const SessionHoc = (WrappedComponent) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        checkTime: 2000 * 60 * 10,
        warningTime: 2000 * 60 * 11,
        signoutTime: 2000 * 60 * 12,
        events: [
          'load',
          'mousemove',
          'mousedown',
          'click',
          'scroll',
          'keypress'
        ]
      };
    }

    componentDidMount() {
      this.setTimeout();
    }
    componentWillUnmount(){
      this.clearTimeoutFunc();
      for (var i in this.state.events) {
        window.removeEventListener(this.state.events[i], this.resetTimeout);
      }
    }

    clearTimeoutFunc = () => {
      if (this.checkTimeout) clearTimeout(this.checkTimeout);
      if (this.warnTimeout) clearTimeout(this.warnTimeout);
      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
      for (var i in this.state.events) {
        window.removeEventListener(this.state.events[i], this.resetTimeout);
      }
    };

    setTimeout = () => {
      this.checkTimeout = setTimeout(this.check, this.state.checkTime);
      this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    check = () => {
      for (var i in this.state.events) {
        window.addEventListener(this.state.events[i], this.resetTimeout);
      }
    }

    warn = () => {
      // this.clearTimeoutFunc();
      // this.setTimeout();
      this.props.warnSession();
    };

    logout = () => {
      this.destroy();
    };

    destroy = () => {
      this.props.endSession();
    };

    render(){
      return (
        <WrappedComponent {...this.props} cookies={this.props.cookies}/>
      );
    }
  }
  return HOC;
};

export default SessionHoc;