import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  CLOSE_MODAL,
  SEARCH_CRITERIA,
  showCompModal,
  showPrivModal,
  showTermsPolicy,
  showCookiePolicy,
  showCompEntered,
  showIdeaSubmittedNTF
} from "../../store/actions/actions";
import {
  hostApi,
  ideaClassification,
  genesisMaturity,
  purposeClassification
} from "../../constants";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { FormLabel } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Icon from "@material-ui/core/Icon";
import { AccessAlarm, ThreeDRotation, Send } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import classNames from "classnames";
import ProgressButton from "../ProgressButton/ProgressButton";
import CompDialog from "./CompDialog";
import Chip from "@material-ui/core/Chip";
import firebase from "../../constants";
import { firebasePassword } from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";

const styles = theme => ({
  root: {
    width: "90%"
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2
  },
  resetContainer: {
    padding: theme.spacing.unit * 3
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },

  menu: {
    width: 400
  },

  input: {
    display: "none"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  chip: {
    margin: theme.spacing.unit
  }
});

function getSteps() {
  return [
    "I have a ...",
    "based on my ...",
    "That will impact ...",
    "Idea name and description",
    "Submit a file",
    "Your name",
    "Your email",
    "Terms amd Conditions"
  ];
}

function validateStep5(context) {
  const {
    firstname,
    lastname,
    lkp_email,
    lkp_email2,
    emailValidation,
    emailValidation2,
    checkedA,
    checkedB
  } = context.state;

  if (
    firstname === "" ||
    lastname === "" ||
    lkp_email === "" ||
    lkp_email2 === "" ||
    emailValidation === true ||
    emailValidation2 === true ||
    checkedA === false ||
    checkedB === false ||
    lkp_email !== lkp_email2
  ) {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

function getStepContent(step, classes, context, handleCat1, handleCat2) {
  switch (step) {
    case 0:
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="select-category"
              select
              /* label="Select Idea" */
              fullWidth={true}
              className={classes.textField}
              value={context.state.lkp_idea_cat1}
              onChange={handleCat1}
              /* InputLabelProps={{ shrink: true }} */
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              variant="outlined"
              margin="normal"
            >
              {ideaClassification.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {`${option.label} idea`}
                </MenuItem>
              ))}
            </TextField>
          </form>
        </div>
      );
    case 1:
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="select-category"
              select
              /* label="Select Idea/Invention" */
              fullWidth={true}
              className={classes.textField}
              value={context.state.lkp_idea_cat2}
              onChange={handleCat2}
              InputLabelProps={{ shrink: true }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              variant="outlined"
              margin="normal"
            >
              {genesisMaturity.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {`${option.label}`}
                </MenuItem>
              ))}
            </TextField>
          </form>
        </div>
      );
    case 2:
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="select-category"
              select
              fullWidth={true}
              /* label="Purpose Classification" */
              className={classes.textField}
              value={context.state.lkp_idea_cat3}
              onChange={context.handleCat3}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              variant="outlined"
              margin="normal"
            >
              {purposeClassification.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
        </div>
      );
    case 3:
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              required
              error={context.state.invalidIdeaName}
              id="required"
              label="Idea Name"
              placeholder="e.g. Flux Capacitor"
              helperText="Enter the name of your idea"
              className={classes.textField}
              value={context.state.idea_name}
              onChange={context.handleIdeaName}
              fullWidth={true}
              variant="outlined"
              margin="normal"
            />

            <TextField
              required
              error={context.state.invalidDesc}
              id="multiline-flexible"
              label="Description"
              multiline
              rows="6"
              value={context.state.idea_description}
              placeholder="e.g. time travel"
              helperText={
                context.state.invalidDesc
                  ? "Please enter 500 characters or less"
                  : "Provide a short description of your idea"
              }
              onChange={context.handleDesc}
              className={classes.textField}
              fullWidth={true}
              variant="outlined"
              margin="normal"
            />

            <input
              accept="xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.odt,.ods,.svg,.csv"
              className={classes.input}
              onChange={context.updateFilename}
              id="contained-button-file"
              multiple
              type="file"
            />
            <div style={{ display: "inline-block", marginLeft: "0%" }}>
              <label htmlFor="contained-button-file">
                <Button
                  color="secondary"
                  variant="contained"
                  component="span"
                  className={classes.button}
                >
                  Submit a file
                </Button>
              </label>
            </div>
            <TextField
              placeholder=""
              variant="outlined"
              fullWidth={true}
              className={classes.textField}
              /* style={{fontSize:"10pt"}} */
              label="Filename"
              value={context.state.filename}
              inputProps={{
                "aria-label": "Description"
              }}
            />
          </form>
        </div>
      );
    case 4:
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              required
              error={context.state.invalidFirstName}
              id="firstname"
              label="First name"
              placeholder="First name"
              className={classes.textField}
              value={context.state.firstname}
              onChange={context.handleFirstname}
              helperText={
                context.state.invalidFirstName
                  ? "Please enter your first name"
                  : ""
              }
              margin="normal"
              variant="outlined"
              fullWidth={true}
            />
            <TextField
              required
              error={context.state.invalidLastName}
              id="lastname"
              label="Last name"
              placeholder="Last name"
              className={classes.textField}
              value={context.state.lastname}
              onChange={context.handleLastname}
              helperText={
                context.state.invalidLastName
                  ? "Please enter your last name"
                  : ""
              }
              margin="normal"
              variant="outlined"
              fullWidth={true}
            />
            <TextField
              required
              error={context.state.emailValidation}
              id="email1"
              label="Email"
              placeholder="e.g. you@email.com"
              className={classes.textField}
              value={context.state.lkp_email}
              onChange={context.handleEmail}
              margin="normal"
              variant="outlined"
              fullWidth={true}
              helperText={
                context.state.emailValidation
                  ? "Please enter a valid email address"
                  : ""
              }
            />
            <TextField
              required
              error={
                context.state.emailValidation2 ||
                context.state.lkp_email !== context.state.lkp_email2
              }
              id="email2"
              label="Confirm email"
              placeholder="e.g. you@email.com"
              className={classes.textField}
              value={context.state.lkp_email2}
              onChange={context.handleEmail2}
              margin="normal"
              variant="outlined"
              fullWidth={true}
              helperText={
                context.state.emailValidation2
                  ? "Please enter a valid email address"
                  : context.state.lkp_email !== context.state.lkp_email2
                  ? "Email does not match"
                  : ""
              }
            />
            <FormControlLabel
              style={{ marginLeft: "0%", marginTop: "13px" }}
              control={
                <Checkbox
                  checked={context.state.checkedA}
                  onChange={context.handleCheckA}
                  value="checkedA"
                  color="primary"
                />
              }
              //label="I have read and accepted the Privacy Policy and Terms and Conditions"
              label={
                <div>
                  <span>I have read and accepted the </span>
                  <Link to="#" onClick={context.props.showPriv}>
                    Privacy Policy
                  </Link>
                  <span> and </span>
                  <Link to="#" onClick={context.props.showTerms}>
                    Terms and Conditions
                  </Link>
                </div>
              }
            />

            <FormControlLabel
              style={{ marginLeft: "0%", marginTop: "13px" }}
              control={
                <Checkbox
                  checked={context.state.checkedB}
                  onChange={context.handleCheckB}
                  value="checkedB"
                  color="primary"
                />
              }
              label="I want to stay in the loop for information on SPRXS and agree to accept notifications about the competition
                    "
            />
          </form>
        </div>
      );
    default:
      return "Unknown step";
  }
}

class CreateIdeaFirstTime extends Component {
  state = {
    activeStep: 0,
    disableNext: true,
    lkp_idea_cat1: "",
    lkp_idea_cat2: "",
    lkp_idea_cat3: "",
    steps: [
      "I have a ...",
      "based on my ...",
      "That will impact ...",
      "Idea details",
      "Your details"
    ],
    idea_name: "",
    idea_description: "",
    filename: "",
    fileDetails: [],
    firstname: "",
    lastname: "",
    checkedA: false,
    checkedB: false,
    openComp: false
  };

  handleNext = e => {
    e.preventDefault();

    //console.log("activeStep", this.state.activeStep);
    //console.log("state", this.state);

    let _steps = [...this.state.steps];

    /* Disble the 'NEXT' button if no entry selected */
    if (this.state.activeStep + 1 === 0 && this.state.lkp_idea_cat1 === "") {
      this.setState({ disableNext: true });
    } else if (
      this.state.activeStep + 1 === 1 &&
      this.state.lkp_idea_cat2 === ""
    ) {
      //console.log("disable next step 1", this.state.activeStep);
      this.setState({ disableNext: true });
    } else if (
      this.state.activeStep + 1 === 2 &&
      this.state.lkp_idea_cat3 === ""
    ) {
      //console.log("disable next step 2", this.state.activeStep);
      this.setState({ disableNext: true });
    } else if (
      this.state.activeStep + 1 === 3 &&
      (this.state.idea_name === "" ||
        this.state.idea_description === "" ||
        this.state.invalidDesc === true)
    ) {
      //console.log("disable next step 3", this.state.activeStep);
      this.setState({ disableNext: true });
    } else if (this.state.activeStep + 1 === 4 && validateStep5(this)) {
      //console.log("disable next step 4", this.state.activeStep);
      this.setState({ disableNext: true });
    } else {
      //console.log("enable next step", this.state.activeStep);
      this.setState({ disableNext: false });
      //console.log("state", this.state);
    }

    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));

    /* Display the selected item in the step label */
    switch (this.state.activeStep) {
      case 0:
        _steps[this.state.activeStep] = `${_steps[this.state.activeStep]}
          ${ideaClassification[this.state.lkp_idea_cat1 - 1].label} idea`;
        this.setState({ steps: [..._steps] });
        return;
      case 1:
        _steps[this.state.activeStep] = `${_steps[this.state.activeStep]}
          ${genesisMaturity[this.state.lkp_idea_cat2 - 1].label}`;
        this.setState({ steps: [..._steps] });
        return;
      case 2:
        _steps[this.state.activeStep] = `${_steps[this.state.activeStep]}
          ${purposeClassification[this.state.lkp_idea_cat3 - 1].label}`;
        this.setState({ steps: [..._steps] });
        return;
      default:
        return;
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));

    this.setState({ disableNext: false });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      lkp_idea_cat1: "",
      lkp_idea_cat2: "",
      lkp_idea_cat3: "",
      idea_name: "",
      idea_description: "",
      filename: "",
      fileDetails: [],
      steps: [
        "I have a ...",
        "based on my ...",
        "That will impact ...",
        "Idea details",
        "Your details"
      ]
    });
  };

  handleCat1 = event => {
    //console.log("handleCat1", event.target.value);
    this.setState({
      lkp_idea_cat1: event.target.value,
      disableNext: false
    });
  };

  handleCat2 = event => {
    //console.log("handleCat2", event.target.value);
    this.setState({
      lkp_idea_cat2: event.target.value,
      disableNext: false
    });
  };

  handleCat3 = event => {
    //console.log("handleCat3", event.target.value);
    this.setState({
      lkp_idea_cat3: event.target.value,
      disableNext: false
    });
  };

  handleIdeaName = event => {
    //console.log("handleIdeaName", event.target.value);
    this.setState({ idea_name: event.target.value });

    if (event.target.value === "" || this.state.idea_description === "") {
      this.setState({ disableNext: true });
    } else {
      this.setState({ disableNext: false });
    }
  };

  handleDesc = event => {
    //console.log("handleDesc", event.target.value);

    //console.log("length", event.target.value.length);

    this.setState({ idea_description: event.target.value });

    if (event.target.value.length > 500) {
      this.setState({ invalidDesc: true });
    } else {
      this.setState({ invalidDesc: false });
    }

    if (
      this.state.invalidDesc === true ||
      event.target.value === "" ||
      this.state.idea_name === ""
    ) {
      this.setState({ disableNext: true });
    } else {
      this.setState({ disableNext: false });
    }
  };

  updateFilename = event => {
    if (event.target.files.length) {
      //console.log("event", event.target.files);

      this.setState({
        filename: event.target.files[0].name,
        fileDetails: event.target.files[0]
      });
    } else {
      //console.log("no file");
    }
  };

  handleFirstname = e => {
    //console.log("handle firstname", e.target.value);
    this.setState(
      {
        firstname: e.target.value,
        firstNameValid: true,
        invalidFirstName: false
      },
      () => {
        this.setState({ disableNext: validateStep5(this) });
      }
    );
  };

  handleLastname = e => {
    //console.log("handle lastname", e.target.value);

    this.setState(
      {
        lastname: e.target.value,
        lastNameValid: true,
        invalidLastName: false
      },
      () => {
        this.setState({ disableNext: validateStep5(this) });
      }
    );
  };

  handleEmail = e => {
    const emailValid = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );

    //console.log("handleEmail", emailValid);

    this.setState({
      emailValidation: emailValid === null ? true : false
    });

    this.setState({ lkp_email: e.target.value }, () => {
      this.setState({ disableNext: validateStep5(this) });
    });
  };

  handleEmail2 = e => {
    const emailValid2 = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );

    //console.log("handleEmail2", emailValid2);

    this.setState({
      emailValidation2: emailValid2 === null ? true : false
    });

    this.setState({ lkp_email2: e.target.value }, () => {
      this.setState({ disableNext: validateStep5(this) });
    });
  };

  handleCheckA = event => {
    //console.log("handleCheckA", event.target.checked);
    this.setState({ checkedA: !this.state.checkedA }, () => {
      this.setState({ disableNext: validateStep5(this) });
    });
    //console.log("STATE", this.state);
  };
  handleCheckB = event => {
    //console.log("handleCheckB", event.target.checked);
    this.setState({ checkedB: !this.state.checkedB }, () => {
      this.setState({ disableNext: validateStep5(this) });
    });

    //console.log("STATE", this.state, !this.state.checkedB);

    //this.setState({ disableNext: validateStep5(this) });
  };

  handleCloseComp = () => {
    this.setState({ openComp: false });
  };

  onSubmitRecaptcha = value => {
    // let recaptchaRef = React.createRef();
    // let recaptchaValue = recaptchaRef.current.getValue();
    // // this.props.onSubmit(recaptchaValue);
    // this.setState({
    //   recaptchaRef
    // });
    console.clear();
    console.log("ONSUBMITRECAPTCHA:", value);
  };

  handleSubmit = event => {
    const { token } = this.props;
    event.preventDefault();
    var formData = new FormData();

    //console.log("CreateIdeaFirstTime -Line 696:");
    //console.log("handleSubmit", this.state);
    ////console.log("this.props.emailAddress.email", this.props.emailAddress.email || "null");
    //console.log("this.state.lkp_email", this.state.lkp_email);
    //console.log("this.state.lkp_email", this.state.lkp_email2);
    //console.log("this.props.emailAddress", this.props.emailAddress); // Catch this to determine if user is logged in order not

    //this.setState({ isLoading: true });

    //console.log("STATE", this.state);
    /* push to backend then display success/failure event */

    // 2018.11.16 - KN - Update to route to relevant backend API
    // --------------------------------------------------------------------------------------------------------------------------

    /*

      @RequestParam(value = "lkp_idea_cat1", 		required = false) Integer lkp_idea_cat1,                  TEST OK            
			@RequestParam(value = "lkp_idea_cat2", 		required = false) Integer lkp_idea_cat2,                  TEST OK
			@RequestParam(value = "lkp_idea_cat3", 		required = false) Integer lkp_idea_cat3,                  TEST OK
      
      @RequestParam(value = "idea_name", 			  required = false) String idea_name,                       TEST OK
			@RequestParam(value = "idea_description", required = false) String idea_description,                TEST OK
			@RequestParam(value = "ideaFilepath", 		required = false) MultipartFile ideaFilepath,
      @RequestParam(value = "lkp_email", 			  required = false) String lkp_email,                       TEST OK

      @RequestParam(value = "firstname", 			  required = false) String firstname,                       TEST OK
			@RequestParam(value = "surname", 			    required = false) String surname,                         TEST OK
			@RequestParam(value = "confirm_email", 		required = false) String confirm_email,                   TEST OK
      @RequestParam(value = "terms_cond", 		  required = false) boolean terms_cond,                     TEST OK           
			@RequestParam(value = "opt_in_notif", 		required = false) boolean opt_in_notif,                   TEST OK
			@RequestParam(value = "opt_in_comp", 		  required = false) boolean opt_in_comp                     

   */

    var createIdeaURL = "createIdea_v2"; // User is logged in
    if (this.state.lkp_email != "") {
      createIdeaURL = "createIdeaWithoutSignup";
    } // User is not logged in

    // formData.append(
    //   "lkp_email",
    //   this.state.lkp_email || this.props.emailAddress.email
    // );
    // formData.append("idea_name", this.state.idea_name);
    // formData.append("lkp_idea_cat1", this.state.lkp_idea_cat1);
    // formData.append("lkp_idea_cat2", this.state.lkp_idea_cat2);
    // formData.append("lkp_idea_cat3", this.state.lkp_idea_cat3);
    // formData.append("idea_description", this.state.idea_description);

    // if (this.state.lkp_email != "") {
    //   // User is not logged in
    //   formData.append("firstname", this.state.firstname);
    //   formData.append("surname", this.state.lastname);
    //   formData.append("confirm_email", this.state.lkp_email2);
    //   formData.append("terms_cond", this.state.checkedA);
    //   formData.append("opt_in_notif", this.state.checkedB); //
    // }

    // if (this.state.fileDetails.filename !== "") {
    //   formData.append(
    //     "ideaFilepath",
    //     new Blob([this.state.fileDetails]),
    //     this.state.fileDetails.name
    //   );
    // } else {
    //   formData.append("ideaFilepath", "", "");
    // }

    //console.log("Posting to: ", hostApi, ",", createIdeaURL);

    firebase
      .auth()
      .createUserWithEmailAndPassword(this.state.lkp_email, firebasePassword)

      .then(u => {
        //console.clear();
        //console.log("FIREBASE REGISTRATION---->", u);
        var user = firebase.auth().currentUser;
        firebase
          .database()
          .ref()
          .child("users")
          .child(user.uid)
          .child("credentials")
          .update({
            firstName: this.state.firstname,
            lastName: this.state.lastname,
            email: this.state.lkp_email,
            password: firebasePassword,
            profilePicLink: ""
          });

        if (this.state.lkp_email != "") {
          var signupData = {
            lkp_email: this.state.lkp_email || this.props.emailAddress.email,
            idea_name: this.state.idea_name,
            lkp_idea_cat1: this.state.lkp_idea_cat1,
            lkp_idea_cat2: this.state.lkp_idea_cat2,
            lkp_idea_cat3: this.state.lkp_idea_cat3,
            idea_description: this.state.idea_description,
            firstname: this.state.firstname,
            surname: this.state.lastname,
            confirm_email: this.state.lkp_email2,
            terms_cond: this.state.checkedA,
            opt_in_notif: this.state.checkedB,
            filename: this.state.filename,
            firebase_uid: user.uid,
            opt_in_comp: true
          };
        } else {
          var signupData = {
            lkp_email: this.state.lkp_email || this.props.emailAddress.email,
            idea_name: this.state.idea_name,
            lkp_idea_cat1: this.state.lkp_idea_cat1,
            lkp_idea_cat2: this.state.lkp_idea_cat2,
            lkp_idea_cat3: this.state.lkp_idea_cat3,
            idea_description: this.state.idea_description,
            filename: this.state.filename,
            firebase_uid: user.uid,
            opt_in_comp: true
          };
        }

        //console.log("FIRE::::::::", user.uid);
        // formData.append("firebase_uid", user.uid); // KN 2018.12.20 // Fix

        axios
          .post(
            `${hostApi}/${createIdeaURL}`,
            signupData,

            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Athorization": `Bearer ${token}`
              }
            }
          )
          .then(response => {
            //console.log("CreateIdeaWithoutSignup: ", response);

            if (response.data.createIdea_response === "FAIL") {
              NotificationManager.error(
                response.data.login_message,
                "Create Idea Error",
                4000
              );
            } else {
              NotificationManager.success(
                `Idea minted on blockchain with ID ${response.data.idea_ID}`,
                "Create Idea",
                5000
              );
              let filename = this.state.filename;
              var storageRef = firebase.storage().ref();
              var myIdeaFolder = storageRef
                .child("AllUsersFileData")
                .child(user.uid)
                .child("myIdeas")
                .child(filename);
              var file = this.state.fileDetails;
              myIdeaFolder.put(file).then(function(snapshot) {
                console.log("Uploaded a file for the idea");
              });
            }
            //this.toggle(event);
          })
          .catch(error => {
            //console.log("ERROR", error);
            NotificationManager.error("API Error", "Create Idea Error", 4000);
          });

        NotificationManager.success(
          "Idea submitted to blockchain",
          "Create Idea",
          5000
        );

        //this.props.closeModal();
        //this.props.showIdeaSubmittedNTF();
        this.setState({ openComp: true });
        this.props.handleSetCloseButton();
      })
      .catch(error => {
        console.log(" ERROR--->", error);
        NotificationManager.error(
          "User account already Exists.",
          "Please Login",
          4000
        );
      });
  };

  render() {
    const { classes } = this.props;
    /* const steps = getSteps(); */

    const {
      activeStep,
      disableNext,
      steps,
      lkp_idea_cat1,
      lkp_idea_cat2,
      lkp_idea_cat3
    } = this.state;

    const returnChips = (lkp_idea_cat1, lkp_idea_cat2, lkp_idea_cat3) => {
      return (
        <div>
          {lkp_idea_cat1 && (
            <Typography>
              I have a
              <Chip
                color="primary"
                label={`${ideaClassification[lkp_idea_cat1 - 1].label}`}
                className={classes.chip}
              />{" "}
              idea, <br />
              {lkp_idea_cat2 && "based on my "}
              {lkp_idea_cat2 && (
                <Chip
                  color="primary"
                  label={`${
                    genesisMaturity[this.state.lkp_idea_cat2 - 1].label
                  }`}
                  className={classes.chip}
                />
              )}{" "}
              {lkp_idea_cat3 && "that will impact "}
              {lkp_idea_cat3 && (
                <Chip
                  color="primary"
                  label={`${
                    purposeClassification[this.state.lkp_idea_cat3 - 1].label
                  }`}
                  className={classes.chip}
                />
              )}
            </Typography>
          )}
        </div>
      );
    };

    return (
      <div className={classes.root}>
        {returnChips(lkp_idea_cat1, lkp_idea_cat2, lkp_idea_cat3)}
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {getStepContent(
                    index,
                    classes,
                    this,
                    this.handleCat1,
                    this.handleCat2
                  )}
                  {/* <Typography>{getStepContent(index)}</Typography> */}

                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        disabled={disableNext}
                        color="secondary"
                        onClick={this.handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>
              All steps completed - you&#39;re finished. Click on submit to
              submit your idea to the blockchain
            </Typography>
            <ReCAPTCHA
              sitekey="6LcMh4sUAAAAAIEnnnoxY7NBrywkVmtpgXZOZcxJ"
              onChange={this.onSubmitRecaptcha}
            />

            <Button onClick={this.handleReset} className={classes.button}>
              Reset
            </Button>

            <ProgressButton
              submitHandler={this.handleSubmit}
              closeModal={this.props.closeModal}
              circle={true}
              fullwidth={false}
            />
          </Paper>
        )}
        <CompDialog
          open={this.state.openComp}
          handleClose={this.handleCloseComp}
        />
      </div>
    );
  }
}

CreateIdeaFirstTime.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  return {
    emailAddress: state.emailAddress,
    isAuthenticated: state.isAuthenticated,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: CLOSE_MODAL }),
    showCompEnteredModal: () => dispatch(showCompEntered()),
    showPriv: () => dispatch(showPrivModal()),
    showTerms: () => dispatch(showTermsPolicy()),
    showCompModal: () => dispatch(showCompModal()),
    showIdeaSubmittedNTF: () => dispatch(showIdeaSubmittedNTF()),
    dispatchSearchCriteria: searchCriteria => {
      dispatch({
        type: SEARCH_CRITERIA,
        payload: {
          searchCriteria: searchCriteria
        }
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CreateIdeaFirstTime));