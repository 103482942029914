import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PublicIcon from "@material-ui/icons/Public";
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle";
import ChatIcon from "@material-ui/icons/ChatBubbleOutline";
import LaunchIcon from "@material-ui/icons/Launch";
import DashIcon from "@material-ui/icons/Dashboard";
import EditIcon from "@material-ui/icons/Edit";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PrivateIcon from "@material-ui/icons/VpnLock";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { SHOW_IDEA_DETAILS } from "../../store/actions/actions";
import IdeaDetails from "../IdeaDetails/PublishIdea";
import EditIdeaDialog from "../EditIdeaModal/EditIdeaDialog";
import MilestoneForm from "../Milestone/MilestoneForm/MilestoneForm";
import MilestoneDashboard from "../Milestone/MilestoneDashboard/MilestoneDashboard";
import MilestoneCollbaDashboard from "../Milestone/MilestoneCollbaDashboard/MilestoneCollbaDashboard";
import RequestWOI from "../IdeaDetails/RequestWOI";
import ChatDialog from "../../containers/ChatDialog/ChatDialog";
import { getCollaborators } from "../../store/actions/actions";
import PlaceIcon from "@material-ui/icons/Place";
import { hostApi } from "../../constants";
import axios from "axios";
import { database } from "firebase";

let counter = 0;

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "idea_id", numeric: false, disablePadding: false, label: "Idea ID" },
  { id: "idea_name", numeric: false, disablePadding: false, label: "Idea Name" },
  { id: "full_name", numeric: false, disablePadding: false, label: "Idea Owner" },
  { id: "actions", numeric: false, disablePadding: false, label: "Milestones" },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;
    return (
      <TableHead>
        <TableRow>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    backgroundColor: "transparent"
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  spacer: {
    flex: "1 1 auto"
  },
  actions: {
    boxShadow: "none",
    backgroundColor: "transparent",
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto",
    backgroundColor: "transparent"
  },
  button: {
    margin: theme.spacing.unit
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, lauchPublish, launchEdit, tableTitle } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        <Typography color="secondary" variant="h6" id="tableTitle">
          {tableTitle}
        </Typography>
      </div>
      <div className={classes.spacer} />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 0
  },
  table: {
    minWidth: "100%",
    backgroundColor: "transparent"
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class MyIdeas extends React.Component {
  state = {
    order: "asc",
    orderBy: "calories",
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 5,
    showPublish: false,
    selectedData: [],
    showEdit: false,
    showRWOI: false,
    showChat: false,
    myCollaborators: []
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    const { data } = this.props;
    if (event.target.checked) {
      this.setState(state => ({ selected: data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id, data) => {
    //console.log("CLICK ROW", event, id);
    //console.log("CLICK ROW data", data);
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    //console.log("selected data", newSelected, this.state);
    this.setState({ selected: newSelected });
  };

  launchIdeaDash = (event, data) => {
    //console.log("launchIdeaDash", data);
    this.props.launchIdeaDash(event, { ...data });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  lauchPublish = (e, data) => {
    this.setState(
      { selectedData: { ...data } },
      this.setState({ showPublish: true })
    );
  };

  launchEdit = (e, data) => {
    this.setState(
      { selectedData: { ...data } },
      this.setState({ showEdit: true })
    );
  };

  handleCloseDetails = () => {
    this.setState({ showPublish: false });
  };

  handleCloseEdit = () => {
    this.setState({ showEdit: false });
  };

  handleShowEdit = () => {
    this.setState({ showEdit: true });
  };

  // KN 2018.12.04
  launchAddMilestone = (e, data) => {
    const { getCollaborators1 } = this.props;

    getCollaborators1(this.props.jwtToken, data.ideaUniqueID);

    this.setState(
      { selectedData: { ...data } },
      this.setState({ showAddMilestone: true })
    );
  };

  // KN 2018.12.04
  handleShowAddMilestone = () => {
    this.setState({ showAddMilestone: true });
  };

  //VG
  launchMilestones = (e, data) => {
    //console.log("Vivian", data);
    this.setState(
      { selectedData: { ...data } },
      this.setState({ showMilestones: true })
    );
  };

  //KN 2019.01.18 >>>> TODO: Vivian, this is the integration point for Milestones for Collaborators // use API /getMyCollaborations
  // VG 2019.01/23 >>>> TODO: VG, make data dynamic
  launchMilestonesforCollab = (e, data) => {
    //console.log('data from backend', data, this.props.emailAddress)
    const dataToPass = {
      id: 0,
      idea_id: data.idea_id,
      collab_profile: this.props.emailAddress,
      owner_profile: ""
    };

    axios
      .post(`${hostApi}/viewMilestone`, 
        dataToPass,{
          headers:{
            "Authorization": `Bearer ${this.props.jwtToken}`
          },
        }
      )
      .then(({ data }) => {
        console.log('collab response', data)
        this.setState({
          myCollaborators: data,
          showCollabMilestones: true
        });
      })
  };

  // VG 2018.01.23
  handleCloseCollabMilestones = () => {
    this.setState({ showCollabMilestones: false });
  };

  // VG 2018.12.07
  handleCloseMilestones = () => {
    this.setState({ showMilestones: false });
    console.log("Line 323 - handleCloseMilestone");
    this.props.history.push(`/home`); // KN 2018.12.24 / Refreshes after milestone creation
  };

  launchRWOI = () => {
    this.setState({ showRWOI: true });
  };
  handleCloseRWOI = () => {
    this.setState({ showRWOI: false });
  };
  launchChat = () => {
    this.setState({ showChat: true });
  };
  handleCloseChat = () => {
    this.setState({ showChat: false });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, data, tableTitle, openChat, jwtToken } = this.props;
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      showChat
    } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper square className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id, n)}
                      role="checkbox"
                      tabIndex={-1}
                      key={n.id}
                    >
                      <TableCell component="th" scope="row" padding="dense">{n.idea_id}</TableCell>
                      <TableCell>{n.idea_name}</TableCell>
                      <TableCell>{n.first_name} {n.surname}</TableCell>
                      <TableCell>
                        <Tooltip title="View My Milestones">
                          <IconButton
                            className={classes.button}
                            aria-label="view"
                            color="primary"
                            onClick={event => { this.launchMilestonesforCollab(event, n); }}
                          >
                            <PlaceIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>

          <EditIdeaDialog
            open={this.state.showEdit}
            onClose={this.handleCloseEdit}
            data={this.state.selectedData}
          />



          {/*** kn 2018.12.04 */}
          {
            <MilestoneForm
              open={this.state.showAddMilestone}
              closeMilestoneForm={this.handleCloseMilestone}
              data={{
                ...this.state.selectedData,
                collaboratorsByIdea: this.props.collaboratorsByIdea
              }}
            />
          }
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <IdeaDetails
          jwtToken={this.props.jwtToken}
          open={this.state.showPublish}
          onClose={this.handleCloseDetails}
          data={this.state.selectedData}
        />
        <RequestWOI
          jwtToken={this.props.jwtToken}
          open={this.state.showRWOI}
          onClose={this.handleCloseRWOI}
          data={this.state.selectedData}
          cookies_email="cookies@fifth-9.com"
        />
      </Paper>
    );
  }
}

MyIdeas.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    showIdeaDetails: state.showIdeaDetails,
    collaboratorsByIdea: state.collaboratorsByIdea,
    emailAddress: state.emailAddress,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showIdeaDetails: () => dispatch({ type: SHOW_IDEA_DETAILS }),
    getCollaborators1: (jwtToken, payload) => {
      getCollaborators(jwtToken, payload, dispatch);
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MyIdeas))
);
