import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import SprxsLogo from "./Images/sprxs_logo.svg";
import NavItem from "../../../components/Home/NavItem/NavItem";
import MyParticles from "../../../components/MyParticles/MyParticles";
import Section from "../../../components/Home/Section";
import { HomeFooter } from "../../../components/HomeFooter/HomeFooter";
import Competition from "../../../components/CompetitionBar/CompetitionBar";
import Scrollchor from "react-scrollchor";
import { timeOut } from "../../../constants";

import { connect } from "react-redux";
import { SHOW_CREATE_IDEA_MODAL } from "../../../store/actions/actions";
import "../../../styles/keyFrames.css";
import "./FrontPage.css";
import { red } from "@material-ui/core/colors";
import { captureEvent } from "../../../utility/google_analytics";

const contactHeight = "160px";
const footerHeight = "35px";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  }
});

class FrontPage extends React.Component {
  state = {
    openDrawer: false,
    openVideo: false,
    showCreateIdeaModal: false,
    showExpander: false,
    scrollSpeed: 600
  };

  handleCloseDrawer = () => {
    this.setState({ openDrawer: false });
  };

  handleOpenDrawer = () => {
    this.setState({ openDrawer: true });
  };

  handleOpenVideo = () => {
    this.setState({ openVideo: true });
  };

  handleCloseVideo = () => {
    this.setState({ openVideo: false });
  };

  showCreateIdeaModal = e => {
    e.preventDefault();
    this.props.showCreateIdeaModal();
  };

  setJourney = (e, value) => {
    const { cookies } = this.props;
    let joinType = value;
    if (value === "CREATE COMP") {
      captureEvent("Create button clicked from competition bar");
      joinType = "CREATE";
    } else if (value === undefined) {
      // If value is undefined then it originated from a click on the "Join SPRXS" link
      // on the homepage.
      captureEvent("Join SPRXS clicked from homepage");
    }

    cookies.set("joinType", joinType, { maxAge: timeOut });

    this.props.history.push("/signup");
  };

  signup = e => {
    window.location.href = "/signup";
  };

  mail = e => {
    window.location.href = "mailto:" + "support@sprxs.io?";
  };

  render() {
    const { classes, history } = this.props;
    const { openDrawer, openVideo, scrollSpeed } = this.state;
    return (
      <div className={classes.root}>
        <MyParticles />
        <div className="gridSectionHeader">
          <div className="gridSection">
            <img src={SprxsLogo} className="logoImage" />
          </div>
          <div className="gridSection">
            <h3 className="title">MAKE THE MOST OF YOUR IDEAS</h3>
            <button type="button" onClick={() => this.setJourney()}>
              Join SPRXS
            </button>
          </div>
          <Scrollchor
            to="#sectionWhatIs"
            animate={{ offset: 0, duration: scrollSpeed }}
          >
            <span className="sectionSpan" />
          </Scrollchor>
        </div>

        <div className="gridSection100 sectionAltColor" id="sectionWhatIs">
          <Section
            title={"WHAT IS SPRXS?"}
            media={true}
            showCreateIdeaModal={this.showCreateIdeaModal}
            signup={this.signup}
            mail={this.mail}
          />
          <Scrollchor
            to="#sectionJourney"
            animate={{ offset: 0, duration: scrollSpeed }}
          >
            <span className="sectionSpan" />
          </Scrollchor>
        </div>

        <div className="gridSection100" id="sectionJourney">
          <Section
            title={"SPRXS Digital Ideation Economy"}
            journey={true}
            showCreateIdeaModal={this.showCreateIdeaModal}
            signup={this.signup}
            mail={this.mail}
          />
          <Scrollchor
            to="#sectionEnd"
            animate={{ offset: 0, duration: scrollSpeed }}
          >
            <span className="sectionSpan" />
          </Scrollchor>
        </div>

        <div
          className="gridSection3Col sectionAltColor gridSection80"
          id="sectionEnd"
        >
          <Section
            title={"The SPRXS Digital Innovation Community"}
            roles={true}
            link={this.setJourney}
            signUpJourney={""}
          />
        </div>
        <div className="gridSection20">
          {HomeFooter(
            classes,
            this.showTermsPolicyModal,
            this.showPrivModal,
            this
          )}
        </div>
        <Competition setJourney={this.setJourney} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showCreateIdeaModal: () => dispatch({ type: SHOW_CREATE_IDEA_MODAL })
  };
};

FrontPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(
  connect(null, mapDispatchToProps)(withStyles(styles)(FrontPage))
);
