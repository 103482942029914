import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  CLOSE_MODAL,
  SEARCH_CRITERIA,
  showCompModal,
  showPrivModal,
  showTermsPolicy,
  showCompEntered,
  showIdeaSubmittedNTF,
  getMyIdeasForDashBoard
} from "../../store/actions/actions";
import {
  hostApi,
  ideaClassification,
} from "../../constants";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import firebase from "../../constants";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";


const styles = theme => ({
  root: {
    width: "90%"
  },
  button: {
    marginTop: 10,
    marginRight: theme.spacing.unit
  },
  actionsContainer: {
    marginTop: 10,
    marginBottom: theme.spacing.unit * 2
  },
  end:{
    marginBottom: 16,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },

  menu: {
    width: 400
  },

  input: {
    display: "none"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  chip: {
    margin: theme.spacing.unit
  }
});


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 2999;
`;


function validateIdea(context) {
  const { lkp_idea_cat1, idea_name, idea_description } = context.state;
  //console.log("DOING SOMETHING");
  if (lkp_idea_cat1 === "" || idea_name === "" || idea_description === "") {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}



class CreateIdeaExistingUser extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    lkp_idea_cat1: "",
    lkp_idea_cat2: "",
    lkp_idea_cat3: "",
    idea_name: "",
    idea_description: "",
    filename: "",
    fileDetails: [],
    disableSubmit: true,
    successNtf: false,
    invalidDesc: false,
    pendingResponse: false
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      lkp_idea_cat1: "",
      lkp_idea_cat2: "",
      lkp_idea_cat3: "",
      idea_name: "",
      idea_description: "",
      filename: "",
      fileDetails: []
    });
  };

  handleCat1 = event => {
    //console.log("handleCat1", event.target.value);
    this.setState({
      lkp_idea_cat1: event.target.value,
    });
  };

  handleIdeaName = event => {
    this.setState({ idea_name: event.target.value });
    if (event.target.value != "") {
      this.setState({ disableSubmit: validateIdea(this) });
    }
  };

  handleDesc = event => {
    this.setState({ idea_description: event.target.value });
    if (event.target.value.length > 500) {
      this.setState({ invalidDesc: true });
    }

    if (
      this.state.invalidDesc === false
    ) {
      this.setState({ disableSubmit: validateIdea(this) });
    }
  };

  updateFilename = event => {
    console.clear();

    if (event.target.files.length) {
      //console.log("event", event.target.files);

      this.setState({
        filename: event.target.files[0].name,
        fileDetails: event.target.files[0]
      });
    } else {
      console.log("no file");
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    const { cookies } = this.props;
    this.setState({disableSubmit: true});
    this.setState({pendingResponse: true});
    var formData = new FormData();
    axios
      .post(
        `${hostApi}/createIdea_v2`,
        {
          lkp_email: this.props.emailAddress || cookies.get("emailAddress"),
          idea_name: this.state.idea_name,
          lkp_idea_cat1: this.state.lkp_idea_cat1,
          lkp_idea_cat2: 1,
          lkp_idea_cat3: 2,
          idea_description: this.state.idea_description,
          filename: this.state.filename
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${cookies.get("bearerToken")}`
          }
        }
      )
      .then(response => {
        //console.log("axios response", response);

        if (response.data.createIdea_response === "FAIL") {
          NotificationManager.error(
            response.data.login_message,
            "Create Idea Error",
            4000
          );
        } else {
          NotificationManager.success(
            `Idea minted on blockchain with ID ${response.data.idea_ID}`,
            "Create Idea",
            5000
          );
          this.props.closeModal();
          this.props.updateData();
        }
      })
      .catch(error => {
        NotificationManager.error("API Error", "Create Idea Error", 4000);
      });

    if (this.state.filename != "") {
      console.log("CIEU Fileshare START");
      let filename = this.state.filename;
      var user = firebase.auth().currentUser;
      // Points to the root reference
      var storageRef = firebase.storage().ref();

      // Points to 'All user files'

      var myIdeaFolder = storageRef
        .child("AllUsersFileData")
        .child(user.uid)
        .child("myIdeas")
        .child(filename);
      var file = this.state.fileDetails;
      myIdeaFolder
        .put(file)
        .then(function(snapshot) {
          console.log("Uploaded a file for the idea");
        })
        .catch(error => {
          console.log("ERROR UPLOADING FILE in CIEU:", error);
        });
    }
    this.setState({ successNtf: true });
  };

  render() {
    const { classes } = this.props;
    const {
      lkp_idea_cat1,
    } = this.state;

    return (
      <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="select-category"
              select
              label="I have a"
              fullWidth={true}
              className={classes.textField}
              value={this.state.lkp_idea_cat1}
              onChange={this.handleCat1}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              variant="outlined"
              margin="dense"
            >
              {ideaClassification.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {`${option.label} idea`}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              required
              error={this.state.invalidIdeaName}
              id="required"
              label="Idea Name"
              placeholder="e.g. Flux Capacitor"
              helperText="Enter the name of your idea"
              className={classes.textField}
              value={this.state.idea_name}
              onChange={this.handleIdeaName}
              fullWidth={true}
              variant="outlined"
              margin="dense"
            />
            <TextField
              required
              error={this.state.invalidDesc}
              id="multiline-flexible"
              label="Description"
              multiline
              rows="6"
              value={this.state.idea_description}
              placeholder="e.g. time travel"
              helperText={
                this.state.invalidDesc
                  ? "Please enter 500 characters or less"
                  : "Provide a short description of your idea"
              }
              onChange={this.handleDesc}
              className={`${classes.textField} ${classes.end}`}
              fullWidth={true}
              variant="outlined"
              margin="dense"
            />
            {/*  */}
            <input
              accept="xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.odt,.ods,.svg,.csv"
              className={classes.input}
              onChange={this.updateFilename}
              id="contained-button-file"
              multiple
              type="file"
            />
            <TextField
              placeholder=""
              variant="outlined"
              fullWidth={false}
              className={classes.textField}
              label="Attach a file (optional)"
              value={this.state.filename}
              inputProps={{
                "aria-label": "Description"
              }}
            />
            <div style={{ display: "inline-block", marginLeft: "1%", marginTop: "3%" }}>
              <label htmlFor="contained-button-file">
                <IconButtonComponent 
                  type={"attach"}
                  iconStyle={"iconAction"}
                />
              </label>
            </div>
            {/*  */}
          </form>
          <div className={classes.actionsContainer}>
            {this.state.pendingResponse ?
              <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={"#123abc"}
              loading={this.state.isLoading}
              /> :
              <Button
                variant="contained"
                disabled={this.state.disableSubmit}
                color="secondary"
                onClick={(e) => this.handleSubmit(e)}
              >
                Submit
              </Button>
            }
          </div>
        </div>
    );
  }
}

CreateIdeaExistingUser.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  return {
    emailAddress: state.emailAddress,
    isAuthenticated: state.isAuthenticated,
    token: state.jwtToken,
    update: state.update
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMyIdeasForDashBoard: (jwtToken, emailAddress) => {
      getMyIdeasForDashBoard(jwtToken, emailAddress, dispatch);
    },
    closeModal: () => dispatch({ type: CLOSE_MODAL }),
    showCompEnteredModal: () => dispatch(showCompEntered()),
    showPriv: () => dispatch(showPrivModal()),
    showTerms: () => dispatch(showTermsPolicy()),
    showCompModal: () => dispatch(showCompModal()),
    showIdeaSubmittedNTF: () => dispatch(showIdeaSubmittedNTF()),
    dispatchSearchCriteria: searchCriteria => {
      dispatch({
        type: SEARCH_CRITERIA,
        payload: {
          searchCriteria: searchCriteria
        }
      });
    }
  };
};

export default withCookies(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CreateIdeaExistingUser))
);
