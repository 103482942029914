import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

function PrivPol({ handleChange }) {
  return (
    <div className="legalWrapper">
      <h1 className="legalHeader">Privacy&nbsp;Policy</h1>
      <div className="legalContent">
        <h3 className="legalSubHead">What's in these terms?</h3>
        <p className="legalCopy">
          SPRXS respects your privacy and is committed to protecting your
          personal data. This Privacy&nbsp;Policy will inform you as to how we
          look after your personal data when you visit our website (regardless
          of where you visit it from) and tell you about your privacy rights and
          how the law protects you.
          <br />
          This Privacy&nbsp;Policy is provided in a layered format so you can
          scroll through to the specific areas set out below.
        </p>
        <h3 className="legalSubHead">
          1. Important information and who we are, Purpose of this privacy
          policy
        </h3>
        <p className="legalCopy">
          This Privacy&nbsp;Policy aims to give you information on how SPRXS
          collects and processes your personal data through your use of this
          website, including any data you may provide through this website when
          you use our site.
          <br />
          This website is not intended for children and we do not knowingly
          collect data relating to children.
          <br />
          It is important that you read this Privacy&nbsp;Policy together with
          any other Privacy&nbsp;Policy or fair processing policy we may provide
          on specific occasions when we are collecting or processing personal
          data about you so that you are fully aware of how and why we are using
          your data.
          <br />
          This Privacy&nbsp;Policy supplements other notices and privacy
          policies and is not intended to override them.
          <span className="copySubHead">Controller</span>
          The Fifth 9 Limited, who owns SPRXS, is the controller and responsible
          for your personal data (collectively referred to as ("COMPANY"), "we",
          "us" or "our" in this Privacy&nbsp;Policy). We have appointed a data
          protection officer (DPO) who is responsible for overseeing questions
          in relation to this Privacy&nbsp;Policy.
          <br />
          For any data protection enquiries, you can email us at{" "}
          <a href="mailto:data.protection@sprxs.io?subject=Data Subject Access Request">
            data.protection@sprxs.io
          </a>
          .<br />
          If you have any questions about this Privacy Policy or our privacy
          practices, including any requests to exercise your legal rights,
          please send your request to enquiries@sprxs.io. You have the right to
          make a complaint at any time to the Information Commissioner's Office
          (ICO), the UK supervisory authority for data protection issues
          (www.ico.org.uk). We would, however, appreciate the chance to deal
          with your concerns before you approach the ICO so please contact us in
          the first instance.
          <span className="copySubHead">
            Changes to the Privacy&nbsp;Policy and your duty to inform us of
            changes
          </span>
          We keep our Privacy&nbsp;Policy under regular review. This version was
          created on 28 September 2018. It is important that the personal data
          we hold about you is accurate and current. Please keep us informed if
          your personal data changes during your relationship with us.
          <span className="copySubHead">Hosting Partners</span>
          <li>Microsoft Azure</li>
          <p>When you visit our website, the hosting provider of our website,
          Microsoft Azure automatically collects and stores various information
          in so-called server log files that your browser transmits to us. The
          information/data mentioned is neither assigned to specific persons nor
          linked to data from other sources. The following technical data will
          be recorded by us, as usual with every connection with a web server,
          without your intervention, and stored by us until automatic deletion
          after no later than two days.</p>
          <li>IP Address</li>
          <p>
            The collection and processing of this technical data is for the
            purpose of enabling the use of our website, continuously ensuring
            system security and stability, optimising our website, and for
            internal statistical purposes. This is our legitimate interest in
            the processing of data.
          </p>
          <p>
            Furthermore, the IP addresses may be evaluated, together with other
            data, in case of attacks on the network infrastructure or other
            unauthorised use or misuse of the website, for the purpose of
            intelligence and protection, and if appropriate, used in criminal
            proceedings for identification and civil and criminal proceedings
            against the relevant users. This is our legitimate interest in the
            processing of data.
          </p>
          <li>Google Analytics</li>
          <p>
            This website also uses Google Analytics, a web analysis service
            provided by Google Inc. ("Google"). Google Inc. is an enterprise of
            the holding company Alphabet Inc., domiciled in the USA. The
            information generated by the cookie about your use of the websites
            (including your IP address) will be transmitted to and stored by
            Google on servers in the United States. Google will use this
            information for the purpose of evaluating your use of the website,
            compiling reports on websites activity and providing other services
            relating to websites activity and internet usage. Google may also
            transfer this information to third parties if this is required by
            law or if third parties process this data on behalf of Google.
          </p>
          <p>
            In addition to the data listed in section 3.1, we may receive the
            following information of Google Analytics:
          </p>
          <li>Navigation path</li>
          <li>Length of stay on the website</li>
          <li>Returning or new user</li>
          <li>End device</li>
          <br/>
          <p>
            We do not use the information and personal data collected by Google
            Analytics to identify individuals unless we become aware of specific
            indications of illegal use.
          </p>
          <li>Social Media</li>
          <p>
            We use plug-ins from social networks such as Facebook, Google and
            Github for the purpose of single sign on in order to improve our
            user experience. When you activate them (by clicking on them), the
            operators of the respective social networks may record that you are
            on our websites and may use this information. This processing of
            your personal data lays in the responsibility of these individual
            social media platforms and occurs according to their privacy policy.
            Please check with these individual social media platforms regarding
            their privacy policies. SPRXS.io is not responsible for data
            collected by these individual social media platforms.
          </p>
          <p>
            <strong>Third-party Links</strong>
          </p>
          <p>
            This website may include links to third-party websites, plug-ins and
            applications. We do not control these third-party websites and are
            not responsible for their privacy statements. When you leave our
            website, we encourage you to read the Privacy Policy of every
            website you visit.
          </p>
        </p>
        <h3 className="legalSubHead">
          2. The data we collect about you Personal data, or personal
          information, means any information about an individual from which that
          person can be identified. It does not include data where the identity
          has been removed (anonymous data).
        </h3>
        <p className="legalCopy">
          We may collect, use, store and transfer different kinds of personal
          data about you which we have grouped together as follows:
          <ul>
            <li>
              Identity Data includes first name, maiden name, last name,
              username or similar identifier
            </li>
            <li>Contact Data includes email address and telephone numbers.</li>
            <li>
              Transaction Data may include details about payments to and from
              you and other details of products and services you have purchased
              from us.
            </li>
            <li>
              Technical Data includes internet protocol (IP) address, your login
              data, browser type and version, time zone setting and location,
              browser plug-in types and versions, operating system and platform,
              and other technology on the devices you use to access this
              website.
            </li>
            <li>
              Profile Data includes your username and password, purchases or
              orders made by you, your interests, preferences, feedback and
              survey responses.
            </li>
            <li>
              Usage Data includes information about how you use our website,
              products and services.
            </li>
            <li>
              Marketing and Communications Data includes your preferences in
              receiving marketing from us and our third parties and your
              communication preferences.
            </li>
          </ul>
          <p>We also collect, use and share Aggregated Data such as statistical or
          demographic data for any purpose. Aggregated Data could be derived
          from your personal data but is not considered personal data in law as
          this data will not directly or indirectly reveal your identity.</p>
          <p>For example, we may aggregate your Usage Data to calculate the
          percentage of users accessing a specific website feature. However, if
          we combine or connect Aggregated Data with your personal data so that
          it can directly or indirectly identify you, we treat the combined data
          as personal data which will be used in accordance with this privacy
          policy.</p>
          <p>We do not collect any Special Categories of Personal Data about you
          (this includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health, and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offences.</p>
        </p>
        <h3 className="legalSubHead">If you fail to provide personal data</h3>
        <p className="legalCopy">
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you, and you fail to provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with goods
          or services). In this case, we may have to cancel a product or service
          you have with us but we will notify you if this is the case at the
          time.
        </p>
        <h3 className="legalSubHead">
          3. How is your personal data collected?
        </h3>
        <p className="legalCopy">
          We use different methods to collect data from and about you including
          through:
          <ul>
            <li>
              Direct interactions. You may give us your Identity and Contact
              Data by filling in forms or by corresponding with us
              electronically, phone, email or otherwise. This includes personal
              data you provide when you :
              <ul>
                <li>apply for our products or services;</li>
                <li>create an account on our website;</li>
                <li>subscribe to our service or publications;</li>
                <li>request marketing to be sent to you;</li>
                <li>enter a competition, promotion or survey; or</li>
                <li>give us feedback or contact us.</li>
              </ul>
            </li>
          </ul>
          Automated technologies or interactions. As you interact with our
          website, we will automatically collect Technical Data about your
          equipment, browsing actions and patterns. We collect this personal
          data by using cookies, server logs and other similar technologies.
          Please see our{" "}
          <a
            href="#"
            className={" "}
            onClick={e => {
              handleChange(e, "cookie");
            }}
          >
            cookie&nbsp;policy
          </a>{" "}
          for further details.
        </p>
        <h3 className="legalSubHead">4. How we use your personal data</h3>
        <p className="legalCopy">
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:
          <ul>
            <li>
              Where we need to perform the contract we are about to enter into
              or have entered into with you.
            </li>

            <li>
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests.
            </li>
            <li>Where we need to comply with a legal obligation.</li>
          </ul>
          Generally, we do not rely on consent as a legal basis for processing
          your personal data although we will get your consent before sending
          third party direct marketing communications to you via email or text
          message. You have the right to withdraw consent to marketing at any
          time by contacting us.
          <span className="copySubHead">
            Purposes for which we will use your personal data
          </span>
          We have set out below a description of all the ways we plan to use
          your personal data, and which of the legal bases we rely on to do so.
          We have also identified what our legitimate interests are where
          appropriate. Note that we may process your personal data for more than
          one lawful ground depending on the specific purpose for which we are
          using your data.
          <br />
          Please contact us if you need details about the specific legal ground
          we are relying on to process your personal data where more than one
          ground has been set out below.
          <span className="copySubHead highLight">Purpose/Activity</span>
          To register you as a new customer Type of data
          <ol className="legalAlpha">
            <li>Identity</li>
            <li>Contact</li>
          </ol>
          <span className="copySubHead">
            {" "}
            Lawful basis for processing including basis of legitimate interest
          </span>
          Performance of a contract with you
          <span className="copySubHead highLight">Purpose/Activity</span>
          To manage our relationship with you which will include:
          <ol className="legalAlpha">
            <li>
              Notifying you about changes to our terms or Privacy&nbsp;Policy
            </li>
            <li>Asking you to leave a review or take a survey</li>
          </ol>
          <span className="copySubHead">Type of data</span>
          <ol className="legalAlpha">
            <li>Identity</li>
            <li>Contact</li>
            <li>Profile</li>
            <li>Marketing and Communications</li>
          </ol>
          <span className="copySubHead">
            Lawful basis for processing including basis of legitimate interest
          </span>
          <ol className="legalAlpha">
            <li>Performance of a contract with you</li>
            <li>Necessary to comply with a legal obligation</li>
            <li>
              Necessary for our legitimate interests (to keep our records
              updated and to study how customers use our products/services)
            </li>
          </ol>
          <span className="copySubHead highLight">Purpose/Activity</span>
          To enable you to partake in a prize draw, competition or complete a
          survey
          <span className="copySubHead">Type of data</span>
          <ol className="legalAlpha">
            <li>Identity</li>
            <li>Contact</li>
            <li>Profile</li>
            <li>Usage</li>
            <li>Marketing and Communications</li>
          </ol>
          <span className="copySubHead">
            Lawful basis for processing including basis of legitimate interest
          </span>
          <ol className="legalAlpha">
            <li>Performance of a contract with you</li>
            <li>
              Necessary for our legitimate interests (to study how customers use
              our products/services, to develop them and grow our business)
            </li>
          </ol>
          <span className="copySubHead highLight">Purpose/Activity</span>
          To administer and protect our business and this website (including
          troubleshooting, data analysis, testing, system maintenance, support,
          reporting and hosting of data)
          <span className="copySubHead">Type of data</span>
          <ol className="legalAlpha">
            <li>Identity</li>
            <li>Contact</li>
            <li>Technical</li>
          </ol>
          <span className="copySubHead">
            Lawful basis for processing including basis of legitimate interest
          </span>
          <ol className="legalAlpha">
            <li>
              Necessary for our legitimate interests (for running our business,
              provision of administration and IT services, network security, to
              prevent fraud and in the context of a business reorganisation or
              group restructuring exercise)
            </li>
            <li>Necessary to comply with a legal obligation</li>
          </ol>
          <span className="copySubHead highLight">Purpose/Activity</span>
          To deliver relevant website content and advertisements to you and
          measure or understand the effectiveness of the advertising we serve to
          you
          <span className="copySubHead">Type of data</span>
          <ol className="legalAlpha">
            <li>Identity</li>
            <li>Contact</li>
            <li>Profile</li>
            <li>Usage</li>
            <li>Marketing and Communications</li>
            <li>Technical</li>
          </ol>
          <span className="copySubHead">
            Lawful basis for processing including basis of legitimate interest
          </span>
          Necessary for our legitimate interests (to study how customers use our
          products/services, to develop them, to grow our business and to inform
          our marketing strategy)
          <span className="copySubHead highLight">Purpose/Activity</span>
          To use data analytics to improve our website, products/services,
          marketing, customer relationships and experiences
          <span className="copySubHead">Type of data</span>
          <ol className="legalAlpha">
            <li>Technical</li>
            <li>Usage</li>
          </ol>
          <span className="copySubHead">
            Lawful basis for processing including basis of legitimate interest
          </span>
          Necessary for our legitimate interests (to define types of customers
          for our products and services, to keep our website updated and
          relevant, to develop our business and to inform our marketing
          strategy)
          <span className="copySubHead highLight">Purpose/Activity</span>
          To make suggestions and recommendations to you about goods or services
          that may be of interest to you
          <span className="copySubHead">Type of data</span>
          <ol className="legalAlpha">
            <li>Identity</li>
            <li>Contact</li>
            <li>Technical</li>
            <li>Usage</li>
            <li>Profile</li>
            <li>Marketing and Communications</li>
          </ol>
          <span className="copySubHead">
            Lawful basis for processing including basis of legitimate interest
          </span>
          Necessary for our legitimate interests (to develop our
          products/services and grow our business)
          <span className="copySubHead">Marketing</span>
          Where and when necessary, we strive to provide you with choices
          regarding certain personal data uses, particularly around marketing
          and advertising.
          <span className="copySubHead">Promotional offers from us</span>
          We may use your Identity, Contact, Technical, Usage and Profile Data
          to form a view on what we think you may want or need, or what may be
          of interest to you. This is how we decide which products, services and
          offers may be relevant for you (we call this marketing). You may
          receive marketing communications from us if you have requested
          information from us or purchased services from us and you have not
          opted out of receiving that marketing.
          <span className="copySubHead">Third-party</span>
          marketing We will get your express opt-in consent before we share your
          personal data with any third party for marketing purposes.
          <span className="copySubHead">Opting out</span>
          You can ask us or third parties to stop sending you marketing messages
          at any time by following the opt-out links on any marketing message
          sent to you. Where you opt out of receiving these marketing messages,
          this will not apply to personal data provided to us as a result of a
          product/service purchase, warranty registration, product/service
          experience or other transactions.
          <span className="copySubHead">Cookies</span>
          You can set your browser to refuse all or some browser cookies, or to
          alert you when websites set or access cookies. If you disable or
          refuse cookies, please note that some parts of this website may become
          inaccessible or not function properly. For more information about the
          cookies we use, please see our{" "}
          <a
            href="#"
            className={" "}
            onClick={e => {
              handleChange(e, "cookie");
            }}
          >
            cookie&nbsp;policy
          </a>
          .<span className="copySubHead">Change of purpose</span>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us.
          <br />
          If we need to use your personal data for an unrelated purpose, we will
          notify you and we will explain the legal basis which allows us to do
          so. Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
        </p>
        <h3 className="legalSubHead">5. Disclosures of your personal data</h3>
        <p className="legalCopy">
          Other than as set out in this policy, we currently do not share your
          personal data with the third parties.
        </p>
        <h3 className="legalSubHead">6. International transfers</h3>
        <p className="legalCopy">
          We do not transfer your personal data outside the European Economic
          Area (EEA). If ever we transfer your personal data out of the EEA, we
          will notify you in advance and will ensure a similar degree of
          protection is afforded to it by ensuring at least one of the following
          safeguards is implemented :
          <ul>
            <li>
              We will only transfer your personal data to countries that have
              been deemed to provide an adequate level of protection for
              personal data by the European Commission. For further details, see
              European Commission: Adequacy of the protection of personal data
              in non-EU countries.
            </li>
            <li>
              Where we use certain service providers, we may use specific
              contracts approved by the European Commission which give personal
              data the same protection it has in Europe. For further details,
              see European Commission: Model contracts for the transfer of
              personal data to third countries.
            </li>
            <li>
              Where we use providers based in the US, we may transfer data to
              them if they are part of the Privacy Shield which requires them to
              provide similar protection to personal data shared between Europe
              and the US. For further details, see European Commission: EU-US
              Privacy Shield.
            </li>
          </ul>
          Please contact us if you want further information on the specific
          mechanism used by us when transferring your personal data out of the
          EEA.
        </p>
        <h3 className="legalSubHead">7. Data security</h3>
        <p className="legalCopy">
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality. We have put in place procedures to deal
          with any suspected personal data breach and will notify you and any
          applicable regulator of a breach where we are legally required to do
          so.
        </p>
        <h3 className="legalSubHead">8. Data retention</h3>
        <p className="legalCopy">
          <span className="copySubHead">
            How long will you use my personal data for?
          </span>
          By law we have to keep basic information about our customers
          (including Contact, Identity and Transaction Data) for six years after
          they cease being customers for tax purposes. In some circumstances you
          can ask us to delete your data: see [your legal rights] below for
          further information. In some circumstances we will anonymise your
          personal data (so that it can no longer be associated with you) for
          research or statistical purposes, in which case we may use this
          information indefinitely without further notice to you.
        </p>
        <h3 className="legalSubHead">9. Your legal rights</h3>
        <p className="legalCopy">
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data.
          <span className="copySubHead">You have the right to:</span>
          Request access to your personal data (commonly known as a "data
          subject access request"). This enables you to receive a copy of the
          personal data we hold about you and to check that we are lawfully
          processing it.
          <br />
          Request correction of the personal data that we hold about you. This
          enables you to have any incomplete or inaccurate data we hold about
          you corrected, though we may need to verify the accuracy of the new
          data you provide to us.
          <br />
          Request erasure of your personal data. This enables you to ask us to
          delete or remove personal data where there is no good reason for us
          continuing to process it. You also have the right to ask us to delete
          or remove your personal data where you have successfully exercised
          your right to object to processing (see below), where we may have
          processed your information unlawfully or where we are required to
          erase your personal data to comply with local law. Note, however, that
          we may not always be able to comply with your request of erasure for
          specific legal reasons which will be notified to you, if applicable,
          at the time of your request.
          <br />
          Object to processing of your personal data where we are relying on a
          legitimate interest (or those of a third party) and there is something
          about your particular situation which makes you want to object to
          processing on this ground as you feel it impacts on your fundamental
          rights and freedoms. You also have the right to object where we are
          processing your personal data for direct marketing purposes. In some
          cases, we may demonstrate that we have compelling legitimate grounds
          to process your information which override your rights and freedoms.
          <br />
          Request restriction of processing of your personal data. This enables
          you to ask us to suspend the processing of your personal data in the
          following scenarios:
          <ul>
            <li>If you want us to establish the data's accuracy.</li>
            <li>
              Where our use of the data is unlawful but you do not want us to
              erase it.
            </li>
            <li>
              Where you need us to hold the data even if we no longer require it
              as you need it to establish, exercise or defend legal claims.
            </li>
            <li>
              You have objected to our use of your data but we need to verify
              whether we have overriding legitimate grounds to use it.
            </li>
          </ul>
          Request the transfer of your personal data to you or to a third party.
          We will provide to you, or a third party you have chosen, your
          personal data in a structured, commonly used, machine-readable format.
          Note that this right only applies to automated information which you
          initially provided consent for us to use or where we used the
          information to perform a contract with you.
          <br />
          Withdraw consent at any time where we are relying on consent to
          process your personal data. However, this will not affect the
          lawfulness of any processing carried out before you withdraw your
          consent.
          <br />
          If you withdraw your consent, we may not be able to provide certain
          products or services to you. We will advise you if this is the case at
          the time you withdraw your consent. If you wish to exercise any of the
          rights set out above, please contact us on{" "}
          <a href="mailto:enquiries@sprxs.io?subject=Data Subject Access Request">
            enquiries@sprxs.io
          </a>
          .<span className="copySubHead">No fee usually required</span>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we could refuse to comply with your request in these
          circumstances.
          <span className="copySubHead">What we may need from you</span>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it.
          <br />
          We may also contact you to ask you for further information in relation
          to your request to speed up our response.
          <span className="copySubHead">Time limit to respond</span>
          We try to respond to all legitimate requests within one month.
          Occasionally it could take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </p>
        <h3 className="legalSubHead">10. Glossary</h3>
        <p className="legalCopy">
          <span className="copySubHead">LAWFUL BASIS</span>
          Legitimate Interest means the interest of our business in conducting
          and managing our business to enable us to give you the best
          service/product and the best and most secure experience.
          <br />
          We make sure we consider and balance any potential impact on you (both
          positive and negative) and your rights before we process your personal
          data for our legitimate interests.
          <br />
          We do not use your personal data for activities where our interests
          are overridden by the impact on you (unless we have your consent or
          are otherwise required or permitted to by law).
          <br />
          You can obtain further information about how we assess our legitimate
          interests against any potential impact on you in respect of specific
          activities by contacting us.
          <br />
          Performance of Contract means processing your data where it is
          necessary for the performance of a contract to which you are a party
          or to take steps at your request before entering into such a contract.
          <br />
          Comply with a legal obligation means processing your personal data
          where it is necessary for compliance with a legal obligation that we
          are subject to.
        </p>
      </div>
    </div>
  );
}

export default withStyles(styles)(PrivPol);
