import MilestoneForm from "../MilestoneForm/MilestoneForm";
import React from "react";
import Slide from "@material-ui/core/Slide";
import {
  Typography,
  Tooltip,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButtonComponent from "../../../components/IconButtonComponent/IconButton.jsx";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { hostApi, IDEATOR, COLLABORATOR } from "../../../constants";
import { getMilestones } from "../../../store/actions/actions";
import { connect } from "react-redux";

import "./MilestoneList.css";

const approvalStatus = [
  "Unknown", // 0
  "Complete", // 1
  "Pending", // 2
  "Active", // 3
  "Cancel Request" // 4
];

const iconStatus = type => {
  switch (type) {
    case 1:
      return <IconButtonComponent type={"approve"} iconStyle={"iconDefault iconCodeGreen iconPadRight"} />
      break;
    case 2:
      return <IconButtonComponent type={"pending"} iconStyle={"iconDefault iconPadRight"} toolTip={true} title={"Pending Collaborator Approval"} />
      break;
    case 3:
      return <IconButtonComponent type={"token"} iconStyle={"iconDefault iconPadRight"} />
      break;
    case 4:
      return <IconButtonComponent type={"alert"} iconStyle={"iconDefault iconWarning iconPadRight"} />
      break;
    default:  
      return <IconButtonComponent type={"token"} iconStyle={"iconDefault iconPadRight"} />
      break;
  }
};

const chipStyle = [
  { backgroundColor: "blue", color: "white" },
  { backgroundColor: "blue", color: "white" },
  { backgroundColor: "orange", color: "white" },
  { backgroundColor: "green", color: "white" },
  { backgroundColor: "orange", color: "white" }
];

function Transition(props) {
  return <Slide direction="right" {...props} />;
}

const styles = theme => ({});

class MilestoneList extends React.Component {
  state = { openMilestoneForm: false, milestone: {} };


  componentDidUpdate = () => {
    console.log("This Milestone component did update");
  }

  handleLaunchMSForm = (e, data) => {
    this.setState({
      milestone: data,
      openMilestoneForm: true
    });
  };

  handleCancel = (e, milestone) => {
    axios
      .post(`${hostApi}/cancelMilestone`, {
        id: milestone.id
      },{
        headers: {
          'Authorization': `Bearer ${this.props.jwtToken}`
        },
      }).then(({ data }) => {
      console.log("milestone deleted", data);
      //this.props.cancelMilestone(data.id)
      NotificationManager.success(
        "Milestone sucessfully cancelled.",
        "Success",
        4000
      );
      this.props.getMilestoneData(this.props.jwtToken, this.props.ideaID, 0 /* FIXME: Have to set this as 0 */);

    })
  }

  closeMSForm = () => {
    this.setState({ openMilestoneForm: false });
  };

  approveCallback = response => {
    const { getMilestones, ideaID, launchAlert, collaboratorID, jwtToken } = this.props;
    getMilestones(jwtToken, ideaID, collaboratorID);

    if (response < 300) {
      launchAlert({
        intent: "success",
        title: "Approve/Reject",
        message: (
          <Typography variant="Subheading" color="inherit">
            You have successfully approved/rejected this milestone.
          </Typography>
        )
      });
    } else {
      launchAlert({
        intent: "error",
        title: "Approve/Reject",
        message: (
          <Typography variant="Subheading" color="inherit">
            There was a problem....status code {response}
          </Typography>
        )
      });
    }
  };

  render() {
    const {
      jwtToken,
      userType,
      data,
      lodgeConsensus,
      ideaID
    } = this.props;

    console.log("NICK", ideaID);

    return (
      <div>
        <List dense={false} className="milestone-list__wrapper">
          {data.length &&
            data.map((item, key) => {
              return (
                <>
                  <ListItem>
                    <ListItemText
                      className="milestone-list__item"
                      primary={
                        <Typography variant="subtitle1">
                          {iconStatus(item.approval)}
                          {item.milestoneName}
                          {userType === IDEATOR && item.approval > 2 && (
                            <>
                            <IconButtonComponent 
                              type={"delete"} 
                              iconStyle={"iconAction iconRight"} 
                              toolTip={true} 
                              title={"Delete Milestone"}
                              link={e => this.handleCancel(e, item)} 
                            />
                            <IconButtonComponent 
                              type={"edit"} 
                              iconStyle={"iconAction iconRight iconPadRight"} 
                              toolTip={true} 
                              title={"Edit Milestone"}
                              link={e => this.handleLaunchMSForm(e, item)} 
                            />
                            </>
                          )}
                          {userType === COLLABORATOR && (item.approval === 2 || item.approval === 4) && (
                            <>
                            <IconButtonComponent 
                                  type={"approve"} 
                                  iconStyle={"iconDefault iconCodeGreen iconRight"}
                                  toolTip={true}
                                  title={"Approve"}
                                  link={() => {
                                    this.props.lodgeConsensus(
                                      1 /*Approved*/,
                                      this.props.collaboratorID,
                                      item.id,
                                      this.approveCallback
                                    );
                                  }}
                                />
                                <IconButtonComponent 
                                  type={"reject"} 
                                  iconStyle={"iconDefault iconWarning iconRight iconPadRight"}
                                  toolTip={true}
                                  title={"Reject"}
                                  link={() => {
                                    this.props.lodgeConsensus(
                                      0 /*Rejected*/,
                                      this.props.collaboratorID,
                                      item.id,
                                      this.approveCallback
                                    );
                                  }}
                                />
                            </>
                          )}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography variant="caption">
                            <span className="milestone-list__bold">
                              Status:
                            </span>{" "}
                            {approvalStatus[item.approval]}
                          </Typography>
                          <Typography variant="caption">
                            <span className="milestone-list__bold">
                              Created:
                            </span>{" "}
                            {item.milestoneDateCreated}
                          </Typography>
                          <Typography variant="caption">
                            <span className="milestone-list__bold">
                              Planned Completion:
                            </span>{" "}
                            {item.agreedCompletionDate}
                          </Typography>
                          <Typography variant="caption">
                            <span className="milestone-list__bold">
                              Collaborator:
                            </span>{" "}
                            {item.firstname == undefined ? 
                            `${"" }`
                            :`${item.firstname +  ` ` + item.surname}`
                          }
                          </Typography>
                          <Typography variant="caption">
                            <span className="milestone-list__bold">
                              Offered Tokens:
                            </span>{" "}
                            {item.offeredTokens}
                          </Typography>
                          <Typography variant="caption">
                            <span className="milestone-list__bold">
                              Description:
                            </span>{" "}
                            {item.milestoneDescription}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <div className="milestone-list__divider" />
                </>
              );
            })}
        </List>

        <MilestoneForm
          jwtToken={this.props.jwtToken}
          ideaName={this.props.ideaName}
          milestoneData={this.state.milestone}
          open={this.state.openMilestoneForm}
          closeMilestoneForm={this.closeMSForm}
          edit={true}
        />
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    getMilestoneData: (jwtToken, ideaID, collaboratorID) => {
      getMilestones(jwtToken, ideaID, collaboratorID, dispatch);
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)((MilestoneList));