import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { FaGithub, FaFacebook, FaLinkedin } from "react-icons/fa";
import logo from "../../static/fav.png";
import mainlogo from "../../static/sprxsmainlogo.png";
import logoname from "../../static/sprxslogo.png";
import chain from "../../static/chain.png";
import currency from "../../static/currency.png";
import idea from "../../static/idea.png";
import brain from "../../static/brain.png";
import spark from "../../static/spark.png";
import sparksname from "../../static/sparksname.png";
import bgimage from "../../static/mainbodypic_max.jpg";
import howimage from "../../static/Collaborate.jpg";
import GridIdeas from "../../components/GridIdeas/GridIdeas";
import Hidden from "@material-ui/core/Hidden";
import LandingDrawer from "./LandingDrawer";
import VideoModal from "./VideoModal";
import ReactPlayer from "react-player";
import Scrollchor from "react-scrollchor";
import techImage from "../../static/TECHNICALTEST.jpg";
import sprxlogo from "../../static/sprxslogo.png";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Tooltip from "@material-ui/core/Tooltip";
import { renderHeaderDesktop, renderHeaderMobile } from "./SharedComponents";
import {HomeFooter} from "../../components/HomeFooter/HomeFooter";

import { connect } from "react-redux";
import { SHOW_TERMS_MODAL, SHOW_PRIV_MODAL } from "../../store/actions/actions";

const bgColor = "white";
const bgImage = "linear-gradient(to bottom right, #fc734a 20%, yellow)";
/* const bgImage = "radial-gradient(white ,#ff513a,  #FF9D3A)"; */
const howbgColor = "white";

const contactHeight = "160px";
const footerHeight = "35px";

const styles = theme => ({
  root: {
    backgroundColor: bgColor,
    position: "relative",
    // backgroundImage: bgImage,
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  menuItems: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  logo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    textAlign: "center"
  },
  mainButton: {
    position: "relative"
    /* backgroundColor: "transparent" */
  },
  menu: {
    flexGrow: 1,
    textAlign: "right"
  },
  header: {
    backgroundColor: "#212526",
    backgroundImage: "#212526",
    filter: "alpha(opacity=30)"
    /* boxShadow: "none" */
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "white",
    boxShadow: "none"
  },
  subTitle: {
    color: "white",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      letterSpacing: "7px",
      lineHeight: "1.2"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px",
      letterSpacing: "7px"
    }
  },
  whatTitle: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: bgColor,
    boxShadow: "none"
  },
  what: {
    padding: theme.spacing.unit * 2,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: bgColor,
    boxShadow: "none",
    height: "auto"
  },
  whatText: {
    fontSize: "19px",
    fontWeight: "bold"
  },
  video: {
    padding: theme.spacing.unit * 2,
    alignItems: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: bgColor,
    boxShadow: "none",
    height: "auto",
    position: "relative"
    // paddingTop: "46%",
    // marginTop: "2rem",
    // marginLeft: "4rem",
    // marginRight: "4rem"
  },
  howTitle: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    // color: theme.palette.secondary.contrastText,
    // color: howbgColor,
    backgroundColor: "transparent",
    boxShadow: "none",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "4px"
  },
  howSection: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url(${howimage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "top",
      backgroundSize: "cover"
    }
  },
  how: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.secondary.contrastText,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "250px"
  },
  howIconText: {
    fontSize: "22px",
    fontWeight: "bold"
  },
  contact1: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: contactHeight
  },
  contact: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: contactHeight
  },
  footer: {
    paddingTop: theme.spacing.unit * 0,
    paddingLeft: theme.spacing.unit * 1,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none"
    /*  height: "100px" */
  },
  copyright: {
    paddingTop: theme.spacing.unit * 0,
    paddingLeft: theme.spacing.unit * 1,
    textAlign: "right",
    verticalAlign: "text-bottom",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: footerHeight
  },
  version: {
    paddingTop: theme.spacing.unit * 0,
    paddingLeft: theme.spacing.unit * 1,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: footerHeight
  },
  contactText: {
    color: "grey",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  container: {
    paddingTop: theme.spacing.unit * 0
  },
  hero: {
    paddingTop: theme.spacing.unit * 0,
    boxShadow: "none",
    backgroundImage: `url(${bgimage})`,
    /* backgroundImage: "url(https://source.unsplash.com/1200x600?innovation)", */
    height: "80vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("xs")]: {
      height: "105vh"
    }
  },
  iconHover: {
    margin: theme.spacing.unit * 0,
    marginRight: "10px",
    color: "grey",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  headingBox: {
    marginLeft: "50px",
    [theme.breakpoints.up("xl")]: {
      marginLeft: "160px"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px"
    }
  },
  faqHeading: {
    fontWeight: "bold",
    textAlign: "left",
    color: "#36464c",
    fontFamily: "Helvetica Neue, sans-serif"
  },
  techHeading: {
    fontWeight: "bold",
    textAlign: "left",
    // paddingTop: "110px",
    color: "#36464c",
    fontFamily: "Helvetica Neue, sans-serif"
  },
  subHeading: {
    fontSize: "22px",
    textAlign: "center",
    lineHeight: "1.5",
    color: "#36464c",
    fontFamily: "Helvetica Neue, sans-serif"
  },

  logoImage: {
    width: "18%",
    height: "18%",
    marginLeft: "-190px",
    float: "left",
    [theme.breakpoints.down("md")]: {
      marginLeft: "-75px",
      // marginRight: "-50px",
      marginTop: "-60px"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  speechImage: {
    width: "40%",
    float: "right",
    marginRight: "-260px",
    // marginBottom: "10px",
    marginTop: "-20px",
    [theme.breakpoints.down("md")]: {
      marginRight: "-130px",
      marginTop: "-70px"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  techImage: {
    width: "20%",
    float: "right",
    marginLeft: "10px",
    marginRight: "-190px",
    marginTop: "-10px",
    // marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      marginRight: "-130px",
      marginTop: "-70px"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  questionList: {
    marginTop: "25px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "5px"
    }
  },
  questionImage: {
    color: "#ff7b2f",
    fontFamily: "Helvetica Neue, sans-serif",
    lineHeight: "1.6",
    fontWeight: "bold",
    fontSize: "20px !important",
    [theme.breakpoints.down("xl")]: {
      float: "left",
      marginRight: "15px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      marginLeft: "-7px",
      marginBottom: "-10px"
    }
  },

  question: {
    textAlign: "left",
    fontSize: "20px !important",
    marginBottom: "14px",
    fontFamily: "Helvetica Neue, sans-serif",
    lineHeight: "1.6",
    listStyle: "none",
    fontWeight: "bold",
    color: "#ff7b2f",
    marginLeft: "35px",
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      marginLeft: "13px",
      marginRight: "0px"
    }
  },
  answer: {
    color: "#36464c !important",
    fontFamily: "Helvetica Neue",
    textAlign: "left",
    fontSize: "14px",
    lineHeight: "2.1",
    marginLeft: "35px",
    marginRight: "10px",
    marginTop: "10px",
    listStyle: "none",
    textJustify: "inter-word",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-5px",
      marginRight: "0px",
      fontSize: "14px"
    }
  },
  answerItem: {
    marginLeft: "17px"
  },
  answerLink: {
    textDecoration: "none",
    color: "#ff7b2f",
    "&:hover": {
      textDecoration: "none",
      color: "#f09819"
    }
  },
  contentsList: {
    listStyleType: "none",
    listStylePosition: "inside",
    textAlign: "left !important",
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
    lineHeight: "2.4",
    marginBottom: "80px",
    color: "#ff7b2f",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px !important"
    }
  },
  contentsEntry: {
    color: "#ff7b2f",
    fontFamily: "Helvetica Neue, sans-serif",
    fontSize: "20px",
    fontWeight: "bold",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "#f09819"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  contentsNumber: {
    color: "#ff7b2f",
    fontFamily: "Helvetica Neue, sans-serif",
    fontWeight: "bold",
    fontSize: "20px !important",
    [theme.breakpoints.down("xl")]: {
      float: "left",
      marginRight: "15px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      marginLeft: "-30px"
    }
  },
  imageContainer: {
    marginTop: "140px",
    [theme.breakpoints.down("xl")]: {
      marginBottom: "170px"
    },
    [theme.breakpoints.down("lg")]: {
      marginBottom: "70px"
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px"
    }
  },
  faq: {
    minHeight: "100vh",
    color: "none",
    boxShadow: "none",
    backgroundColor: "#fff",
    // color: theme.palette.text.secondary,
    height: "100%",
    marginLeft: "80px",
    marginRight: "80px",
    marginTop: "-50px",
    marginBottom: "50px",
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
      marginLeft: "0px",
      marginTop: "0px"
    }
  },
  buttonScroll: {
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3
  }
});

const renderTechMobile = classes => {
  return (
    <React.Fragment>
      <Grid container spacing={6} justify="center" alignItems="center">
        <Grid item xs={9}>
          <Paper className={classes.faq}>
            <div className={classes.imageContainer}>
              <img className={classes.logoImage} src={sprxlogo} />
              <img className={classes.techImage} src={techImage} />
              <br />
            </div>
            <div>
              <div className={classes.headingBox}>
                <h1 className={classes.techHeading} id="headingAnchor1">
                  Technical Information
                </h1>
                <br />
                <ol className={classes.contentsList}>
                  <li>
                    <Scrollchor
                      to="#techquestion1"
                      animate={{ offset: -50, duration: 400 }}
                      className={classes.contentsEntry}
                    >
                      1.&nbsp;What is a Blockchain?
                    </Scrollchor>
                  </li>
                  <li>
                    <Scrollchor
                      to="#techquestion2"
                      animate={{ offset: -50, duration: 400 }}
                      className={classes.contentsEntry}
                    >
                      2.&nbsp;Why Blockchain?
                    </Scrollchor>
                  </li>
                  <li>
                    <Scrollchor
                      to="#techquestion3"
                      animate={{ offset: -50, duration: 400 }}
                      className={classes.contentsEntry}
                    >
                      3.&nbsp;Which blockchain technology does SPRX use?
                    </Scrollchor>
                  </li>
                  <li>
                    <Scrollchor
                      to="#techquestion4"
                      animate={{ offset: -50, duration: 400 }}
                      className={classes.contentsEntry}
                    >
                      4.&nbsp;What is a Smart Contract?
                    </Scrollchor>
                  </li>
                  <li>
                    <Scrollchor
                      to="#techquestion5"
                      animate={{ offset: -50, duration: 400 }}
                      className={classes.contentsEntry}
                    >
                      5.&nbsp;What is the AION coin?
                    </Scrollchor>
                  </li>
                </ol>
                <div className={classes.row}>
                  <div className={classes.questionList}>
                    <span>
                      <p className={classes.questionImage}>1.</p>
                      <h3 className={classes.question} id="techquestion1">
                        What is a Blockchain?
                      </h3>
                    </span>
                    <p className={classes.answer}>
                      A blockchain is a digitized, decentralized, public ledger
                      of transactions. Constantly growing as ‘completed’ blocks
                      (the most recent transactions) are recorded and added to
                      it in chronological order, it allows market participants
                      to keep track of digital currency transactions without
                      central recordkeeping. Each node (a computer connected to
                      the network) gets a copy of the blockchain, which is
                      downloaded automatically.
                      <br />
                      <br />
                      Originally the blockchain was developed as the accounting
                      method for virtual currencies, now it is becoming more
                      widely used in commercial operations. The new technology
                      is used to verify transactions, and with the use of
                      digital currencies we can store practically any document
                      in the blockchain and prove its validity.
                    </p>
                    <br />

                    <span>
                      <p className={classes.questionImage}>2.</p>
                      <h3 className={classes.question} id="techquestion2">
                        Why Blockchain?
                      </h3>
                    </span>
                    <p className={classes.answer}>
                      One key difference between Blockchain and traditional
                      distributed systems is that distributed systems assume
                      that all devices on the system are trustworthy and will
                      not harm or attack the data. Blockchain however trusts a
                      much smaller percentage of devices on the network, making
                      it much more secure and robust. Blockchain provides
                      synchronisation as a built-in feature, as opposed to
                      distributed systems which require synchronisation to be
                      done manually. This helps to reduce the complexity
                      involved in setting up a database and thus can
                      significantly reduce costs.
                      <br />
                      <br />
                      <strong>Additional features of blockchain:</strong>
                      <ul>
                        <li className={classes.answerItem}>
                          Every transaction contains its own proof of validity
                          and authorisation, so there is no need for centralised
                          proof.
                        </li>
                        <li className={classes.answerItem}>
                          Every node processes every transaction, so no node is
                          crucial to system.
                        </li>
                        <li className={classes.answerItem}>
                          Data on the blockchain can’t be altered, making it
                          immutable.
                        </li>
                      </ul>
                    </p>
                    <br />

                    <span>
                      <p className={classes.questionImage}>3.</p>
                      <h3 className={classes.question} id="techquestion3">
                        Which blockchain technology does SPRX use?
                      </h3>
                    </span>
                    <p className={classes.answer}>
                      SPRX uses Aion's{" "}
                      <a
                        className={classes.answerLink}
                        href="https://aion.network/"
                      >
                        blockchain technology
                      </a>
                      .
                    </p>
                    <br />
                    <span>
                      <p className={classes.questionImage}>4.</p>
                      <h3 className={classes.question} id="techquestion4">
                        What is a Smart Contract?
                      </h3>
                    </span>
                    <p className={classes.answer}>
                      A contract is an agreement between two or more parties
                      that often involves a set of promises enforced by a
                      trusted authority. These agreements usually require a
                      third party to write up, verify, and terminate the
                      contract.
                      <br />
                      <br />A smart contract eliminates the need of a third
                      party agent to enforce terms within a contract. Instead of
                      having individuals act as intermediaries, the role of a
                      "middleman" is assumed by a computer protocol. The
                      contract and agreements are written directly into code,
                      then deployed and distributed across the decentralized
                      blockchain network. Once a smart contract is deployed, any
                      transactions within it are transparent and immutable.
                    </p>
                    <br />
                    <span>
                      <p className={classes.questionImage}>5.</p>
                      <h3 className={classes.question} id="techquestion5">
                        What is the AION coin?
                      </h3>
                    </span>
                    <p className={classes.answer}>
                      AION is a cryptographic currency used to power the Aion
                      Network. It is a currency because its market value changes
                      according to market forces of demand and supply. It is a
                      new form of asset class the world has not seen before and
                      as a result, there is a lot of misinformation about it.
                    </p>
                    
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const renderTechDesktop = classes => {
  return (
    <React.Fragment>
      <Grid container spacing={6} justify="center" alignItems="center">
        <Grid item xs={9}>
          <Paper className={classes.faq}>
            <div className={classes.imageContainer}>
              <img className={classes.logoImage} src={sprxlogo} />
              <img className={classes.techImage} src={techImage} />
              <br />
            </div>
            <div>
              <div className={classes.headingBox}>
                <h1 className={classes.techHeading} id="headingAnchor1">
                  Technical Information
                </h1>
                <br />
                <ol className={classes.contentsList}>
                  <li>
                    <Scrollchor
                      to="#techquestion1"
                      animate={{ offset: -50, duration: 400 }}
                      className={classes.contentsEntry}
                    >
                      1.&nbsp;What is a Blockchain?
                    </Scrollchor>
                  </li>
                  <li>
                    <Scrollchor
                      to="#techquestion2"
                      animate={{ offset: -50, duration: 400 }}
                      className={classes.contentsEntry}
                    >
                      2.&nbsp;Why Blockchain?
                    </Scrollchor>
                  </li>
                  <li>
                    <Scrollchor
                      to="#techquestion3"
                      animate={{ offset: -50, duration: 400 }}
                      className={classes.contentsEntry}
                    >
                      3.&nbsp;Which blockchain technology does SPRX use?
                    </Scrollchor>
                  </li>
                  <li>
                    <Scrollchor
                      to="#techquestion4"
                      animate={{ offset: -50, duration: 400 }}
                      className={classes.contentsEntry}
                    >
                      4.&nbsp;What is a Smart Contract?
                    </Scrollchor>
                  </li>
                  <li>
                    <Scrollchor
                      to="#techquestion5"
                      animate={{ offset: -50, duration: 400 }}
                      className={classes.contentsEntry}
                    >
                      5.&nbsp;What is the AION coin?
                    </Scrollchor>
                  </li>
                </ol>
                <div className={classes.row}>
                  <div className={classes.questionList}>
                    <span>
                      <p className={classes.questionImage}>1.</p>
                      <h3 className={classes.question} id="techquestion1">
                        What is a Blockchain?
                      </h3>
                    </span>
                    <p className={classes.answer}>
                      A blockchain is a digitized, decentralized, public ledger
                      of transactions. Constantly growing as ‘completed’ blocks
                      (the most recent transactions) are recorded and added to
                      it in chronological order, it allows market participants
                      to keep track of digital currency transactions without
                      central recordkeeping. Each node (a computer connected to
                      the network) gets a copy of the blockchain, which is
                      downloaded automatically.
                      <br />
                      <br />
                      Originally the blockchain was developed as the accounting
                      method for virtual currencies, now it is becoming more
                      widely used in commercial operations. The new technology
                      is used to verify transactions, and with the use of
                      digital currencies we can store practically any document
                      in the blockchain and prove its validity.
                    </p>
                    <br />

                    <span>
                      <p className={classes.questionImage}>2.</p>
                      <h3 className={classes.question} id="techquestion2">
                        Why Blockchain?
                      </h3>
                    </span>
                    <p className={classes.answer}>
                      One key difference between Blockchain and traditional
                      distributed systems is that distributed systems assume
                      that all devices on the system are trustworthy and will
                      not harm or attack the data. Blockchain however trusts a
                      much smaller percentage of devices on the network, making
                      it much more secure and robust. Blockchain provides
                      synchronisation as a built-in feature, as opposed to
                      distributed systems which require synchronisation to be
                      done manually. This helps to reduce the complexity
                      involved in setting up a database and thus can
                      significantly reduce costs.
                      <br />
                      <br />
                      <strong>Additional features of blockchain:</strong>
                      <ul>
                        <li className={classes.answerItem}>
                          Every transaction contains its own proof of validity
                          and authorisation, so there is no need for centralised
                          proof.
                        </li>
                        <li className={classes.answerItem}>
                          Every node processes every transaction, so no node is
                          crucial to system.
                        </li>
                        <li className={classes.answerItem}>
                          Data on the blockchain can’t be altered, making it
                          immutable.
                        </li>
                      </ul>
                    </p>
                    <br />

                    <span>
                      <p className={classes.questionImage}>3.</p>
                      <h3 className={classes.question} id="techquestion3">
                        Which blockchain technology does SPRX use?
                      </h3>
                    </span>
                    <p className={classes.answer}>
                      SPRX uses Aion's{" "}
                      <a
                        className={classes.answerLink}
                        href="https://aion.network/"
                      >
                        blockchain technology
                      </a>
                      .
                    </p>
                    <br />
                    <span>
                      <p className={classes.questionImage}>4.</p>
                      <h3 className={classes.question} id="techquestion4">
                        What is a Smart Contract?
                      </h3>
                    </span>
                    <p className={classes.answer}>
                      A contract is an agreement between two or more parties
                      that often involves a set of promises enforced by a
                      trusted authority. These agreements usually require a
                      third party to write up, verify, and terminate the
                      contract.
                      <br />
                      <br />A smart contract eliminates the need of a third
                      party agent to enforce terms within a contract. Instead of
                      having individuals act as intermediaries, the role of a
                      "middleman" is assumed by a computer protocol. The
                      contract and agreements are written directly into code,
                      then deployed and distributed across the decentralized
                      blockchain network. Once a smart contract is deployed, any
                      transactions within it are transparent and immutable.
                    </p>
                    <br />
                    <span>
                      <p className={classes.questionImage}>5.</p>
                      <h3 className={classes.question} id="techquestion5">
                        What is the AION coin?
                      </h3>
                    </span>
                    <p className={classes.answer}>
                      AION is a cryptographic currency used to power the Aion
                      Network. It is a currency because its market value changes
                      according to market forces of demand and supply. It is a
                      new form of asset class the world has not seen before and
                      as a result, there is a lot of misinformation about it.
                    </p>
                    
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

class TechnicalPage extends React.Component {
  state = {
    openDrawer: false,
    openVideo: false
  };

  handleCloseDrawer = () => {
    this.setState({ openDrawer: false });
  };

  handleOpenDrawer = () => {
    this.setState({ openDrawer: true });
  };

  handleOpenVideo = () => {
    this.setState({ openVideo: true });
  };

  handleCloseVideo = () => {
    this.setState({ openVideo: false });
  };

  render() {
    const { classes } = this.props;
    const { openDrawer, openVideo } = this.state;

    return (
      <div className={classes.root}>
        <LandingDrawer open={openDrawer} onClose={this.handleCloseDrawer} />
        <Grid container spacing={0} className={classes.container}>
          <Hidden smUp>{renderTechMobile(classes)}</Hidden>
          <Hidden xsDown>{renderTechDesktop(classes)}</Hidden>
          {HomeFooter(classes, this.props.showTermsPolicyModal, this.props.showPrivModal,  this)}
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showTermsPolicyModal: () => dispatch({ type: SHOW_TERMS_MODAL }),
    showPrivModal: () => dispatch({ type: SHOW_PRIV_MODAL })
  };
};

TechnicalPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(styles)(TechnicalPage))
);
