import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Arrow from "../../containers/LandingPage/FrontPageV2/Images/arrow.svg";
import Two from "../../containers/LandingPage/FrontPageV2/Images/2.svg";
import Three from "../../containers/LandingPage/FrontPageV2/Images/3.svg";
import IdeaIcon from "../../containers/LandingPage/FrontPageV2/Images/ideaIcon.svg";
import BlockChainIcon from "../../containers/LandingPage/FrontPageV2/Images/blockchainSecureIcon.svg";
import TokenIcon from "../../containers/LandingPage/FrontPageV2/Images/tokenIcon.svg";
import CollaborateIcon from "../../containers/LandingPage/FrontPageV2/Images/collaborateIcon.svg";
import MonetizeIcon from "../../containers/LandingPage/FrontPageV2/Images/monetizeIcon.svg";

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

class DigitalEconomy extends React.Component {
  render() {
    const { classes, title, text, buttonText } = this.props;

    return (
      <div className="digiWrapper">
        <div className="digiContainerItem">
          <div className="digiContainer">
            <div className="digiContainerItem">
              <img src={IdeaIcon} />
            </div>
            <div className="digiContainerItem ">
              <h4>IDEA</h4>
            </div>
            <div className="digiContainerItem">
              <p>Create and log your idea on the SPRXS Blockchain platform</p>
            </div>
          </div>
        </div>
        <div className="digiContainerItem ideaArrow" />
        <div className="digiContainerItem">
          <div className="digiContainer">
            <div className="digiContainerItem">
              <img src={BlockChainIcon} />
            </div>
            <div className="digiContainerItem">
              <h4>SECURE</h4>
            </div>
            <div className="digiContainerItem">
              <p>
                Ideas are written to the SPRXS Blockchain proving an immutable
                record that the idea was logged
              </p>
            </div>
          </div>
        </div>
        <div className="digiContainerItem blockArrow" />
        <div className="digiContainerItem">
          <div className="digiContainer">
            <div className="digiContainerItem">
              <img src={TokenIcon} />
            </div>
            <div className="digiContainerItem">
              <h4>TOKENIZE</h4>
            </div>
            <div className="digiContainerItem">
              <p>
                Each idea allocated 100 SPRXS Tokens which can be used to trade
                for services &amp; resources
              </p>
            </div>
          </div>
        </div>
        <div className="digiContainerItem collaborateArrow" />
        <div className="digiContainerItem">
          <div className="digiContainer">
            <div className="digiContainerItem">
              <img src={CollaborateIcon} />
            </div>
            <div className="digiContainerItem">
              <h4>COLLABORATE</h4>
            </div>
            <div className="digiContainerItem">
              <p>
                Exchange SPRXS Tokens in your idea for services and resources
              </p>
            </div>
          </div>
        </div>
        <div className="digiContainerItem empty" />
        <div className="digiContainerItem empty" />
        <div className="digiContainerItem empty" />
        <div className="digiContainerItem empty" />
        <div className="digiContainerItem monetizeArrow full" />
        <div className="digiContainerItem empty" />
        <div className="digiContainerItem empty" />
        <div className="digiContainerItem empty" />
        <div className="digiContainerItem empty" />
        <div className="digiContainerItem empty" />
        <div className="digiContainerItem empty" />
        <div className="digiContainerItem">
          <div className="digiContainer end">
            <div className="digiContainerItem">
              <h4>MONETIZE</h4>
            </div>
            <div className="digiContainerItem">
              <p>
                Connect with investors &amp; exchange SPRXS Tokens in your idea
                for funding
              </p>
            </div>
            <div className="digiContainerItem">
              <img src={MonetizeIcon} />
            </div>
          </div>
        </div>
        <div className="digiContainerItem " />
        <div className="digiContainerItem " />

        {/* <div className="digiContainerItem">
                        <div className="digiContainerMin">
                            <div className="digiContainerItem">
                                <img
                                    src={MonetizeIcon}
                                ></img>
                            </div>
                            <div className="digiContainerItem">
                                <h4>MONETIZE</h4>
                            </div>
                            <div className="digiContainerItem">
                                <p>Connect with investors &amp; exchange SPRXS Tokens in your idea for funding</p>
                            </div>
                        </div>
                    </div> */}
      </div>
    );
  }
}

DigitalEconomy.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(DigitalEconomy));
