import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import logo from "../../static/fav.png";
import {HomeFooter} from "../../components/HomeFooter/HomeFooter";
import "./ErrorPage.css";
import { connect } from "react-redux";

const bgColor = "white";

const styles = theme => ({
  root: {
    backgroundColor: bgColor,
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  }
});

class ErrorPage extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.container}>
        <div className="errorWrapper">
          <h1 className="warning">404</h1>
          <h4>We're sorry, the page you're looking for could not be found or no longer exists.</h4>
          <h4>Take me to the <a href="/" className="errorLink">homepage</a></h4>
        </div>
          {HomeFooter(classes,  this)}
        </Grid>
      </div> 
    );
  }
}

ErrorPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(
  connect(
    null,
  )(withStyles(styles)(ErrorPage))
);
