import React, { Component, Fragment } from "react";
import { PropTypes, instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as firebase from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import "firebase/auth";

import { gotoMySPRXS } from "../../authentication/authentication";

import CollaborateIcon from "../../static/collaborateIdeasIcon.png";
import CreateIdeaIcon from "../../static/uploadIdeasIcon.png";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Typography } from "@material-ui/core";
import SprxsButton from "../../components/SprxsButton";
//import logo from "../../static/fav.png";
import "./Signup.css"; //
//import * as firebaseui from './firebaseui-styling.global.css';
import "./firebaseui-styling.global.css"; // Import globally.
import {
  CLOSE_MODAL,
  SEARCH_CRITERIA,
  showLegalModal,
  showIdeaSubmittedNTF,
  isAuthenticated,
  dispatchFirebaseUid,
  postCollaborator,
  dispatchAuth,
  saveToken
} from "../../store/actions/actions";

import axios from "axios";

import fire, { COLLABORATOR, IDEATOR, timeOut } from "../../constants";
import {
  firebasePassword,
  hostApi,
  ideaClassification,
  covid19Classification,
  industry
} from "../../constants";

import { withStyles } from "@material-ui/core/styles";
import {
  ideatorAccountCreated,
  collaboratorAccountCreated
} from "../../utility/google_analytics";

const styles = theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  authButton: {
    height: "auto",
    maxWidth: "200px",
    borderRadius: "6px",
    marginTop: "10px"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  }
});

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 2999;
`;

function validateCreate(context) {
  const {
    firstname,
    lastname,
    lkp_email,
    lkp_email2,
    emailValidation,
    emailValidation2
  } = context.state;

  if (
    firstname === "" ||
    lastname === "" ||
    lkp_email === "" ||
    lkp_email2 === "" ||
    emailValidation === true ||
    emailValidation2 === true ||
    lkp_email !== lkp_email2
  ) {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

function validateUser(context) {
  const {
    firstname,
    lastname,
    lkp_email,
    lkp_email2,
    emailValidation,
    emailValidation2,
    passwrd,
    passwrd2
  } = context.state;

  if (
    firstname === "" ||
    lastname === "" ||
    lkp_email === "" ||
    lkp_email2 === "" ||
    emailValidation === true ||
    emailValidation2 === true ||
    lkp_email !== lkp_email2 ||
    passwrd === "" ||
    passwrd2 === "" ||
    passwrd !== passwrd2
  ) {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

function validateIdea(context) {
  const { lkp_idea_cat1, idea_name, idea_description } = context.state;

  if (lkp_idea_cat1 === "" || idea_name === "" || idea_description === "") {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

function validateSkills(context) {
  const { lkp_idea_cat2, collab_bio } = context.state;

  if (lkp_idea_cat2 === "" || collab_bio === "") {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

function validateLegal(context) {
  const { checkedTerms } = context.state;
  if (checkedTerms === false) {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

export function getStepContent(
  classes,
  currentStep,
  setFormTitle,
  setJourney,
  journey,
  joinTypeValue,
  otherPrimary,
  otherSecondary,
  context
) {
  switch (currentStep) {
    case 0:
      return (
        <div className="formWrapper">
          <div className="formInner" style={{ height: "2%" }}>
            <SprxsButton click={context.handleNext.bind(this)}>
              Sign up on SPRXS
            </SprxsButton>
          </div>
        </div>
      );
    case 1:
      setFormTitle("A bit about you");
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            {joinTypeValue !== "organisation" && (
              <Fragment>
                <TextField
                  required
                  error={context.state.invalidFirstName}
                  id="firstname"
                  label="First name"
                  placeholder="First name"
                  className={classes.textField}
                  value={context.state.firstname}
                  onChange={context.handleFirstname}
                  helperText={
                    context.state.invalidFirstName
                      ? "Please enter your first name"
                      : ""
                  }
                  margin="dense"
                  variant="outlined"
                  fullWidth={true}
                />
                <TextField
                  required
                  error={context.state.invalidLastName}
                  id="lastname"
                  label="Last name"
                  placeholder="Last name"
                  className={classes.textField}
                  value={context.state.surname}
                  onChange={context.handleLastname}
                  helperText={
                    context.state.invalidLastName
                      ? "Please enter your last name"
                      : ""
                  }
                  margin="dense"
                  variant="outlined"
                  fullWidth={true}
                />
              </Fragment>
            )}
            <TextField
              required
              error={context.state.emailValidation || context.state.authUser}
              id="email1"
              label="Email"
              type="email"
              autoComplete="email"
              placeholder="e.g. you@email.com"
              className={classes.textField}
              value={context.state.lkp_email}
              onChange={context.handleEmail}
              onBlur={context.handleAuthenticateUser}
              margin="dense"
              variant="outlined"
              fullWidth={true}
              helperText={
                context.state.emailValidation
                  ? "Please enter a valid email address"
                  : context.state.authUser
                  ? "*This email is already registered. Please Log in to your account."
                  : ""
              }
            />
            {context.state.authUser !== true && (
              <TextField
                required
                error={
                  context.state.emailValidation2 ||
                  context.state.lkp_email !== context.state.lkp_email2
                }
                id="email2"
                label="Confirm email"
                type="email"
                autoComplete="email"
                placeholder="e.g. you@email.com"
                className={classes.textField}
                value={context.state.lkp_email2}
                onChange={context.handleEmail2}
                margin="dense"
                variant="outlined"
                fullWidth={true}
                helperText={
                  context.state.emailValidation2
                    ? "Please enter a valid email address"
                    : context.state.lkp_email !== context.state.lkp_email2
                    ? "Email does not match"
                    : ""
                }
              />
            )}
            {context.state.authUser !== true && (
              <Fragment>
                <TextField
                  required
                  id="outlined-adornment-password"
                  margin="dense"
                  fullWidth={true}
                  className={classes.textField}
                  variant="outlined"
                  type={context.state.showPassword ? "text" : "password"}
                  label="Password"
                  value={context.state.passwrd}
                  onChange={context.handlePassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={context.handleClickShowPassword}
                        >
                          {context.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  required
                  error={
                    context.state.passWordValidation ||
                    context.state.passwrd !== context.state.passwrd2
                  }
                  id="outlined-adornment-password2"
                  margin="dense"
                  fullWidth={true}
                  className={classes.textField}
                  variant="outlined"
                  type={context.state.showPassword2 ? "text" : "password"}
                  label="Confirm Password"
                  value={context.state.passwrd2}
                  onChange={context.handlePassword2}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={context.handleClickShowPassword2}
                        >
                          {context.state.showPassword2 ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  helperText={
                    context.state.passwordValidation
                      ? " "
                      : context.state.passwrd !== context.state.passwrd2
                      ? "Password does not match"
                      : ""
                  }
                />
              </Fragment>
            )}
          </form>
        </div>
      );
    case 2:
      setFormTitle("Your idea");
      return (
        // Todo remove idea step
        <>
          <Typography color="error">
            You can skip this step for now and create your first idea later.
          </Typography>
          <br />
          <div>
            <form className={classes.container} noValidate autoComplete="off">
              <FormControlLabel
                style={{ marginLeft: "0%", marginTop: "10px" }}
                control={
                  <Checkbox
                    checked={context.state.covid_19_idea}
                    onChange={context.handleCovidIdea}
                    value="covid_19_idea"
                    color="primary"
                  />
                }
                label={
                  <div>
                    <span
                      className="covidspan"
                      style={{ backgroundColor: "var(--red)" }}
                    >
                      This idea is realated to the current Covid-19 Situation.
                    </span>
                  </div>
                }
              />
              {context.state.covid_19_idea && (
                <TextField
                  id="select-category"
                  select
                  label="Select Category"
                  fullWidth={true}
                  className={classes.textField}
                  value={context.state.lkp_idea_cat1}
                  onChange={context.handleCat1}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  variant="outlined"
                  margin="dense"
                >
                  {covid19Classification.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {`${option.label} idea`}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {!context.state.covid_19_idea && (
                <TextField
                  id="select-category"
                  select
                  label="I have a"
                  fullWidth={true}
                  className={classes.textField}
                  value={context.state.lkp_idea_cat1}
                  onChange={context.handleCat1}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  variant="outlined"
                  margin="dense"
                >
                  {ideaClassification.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {`${option.label} idea`}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <TextField
                required
                error={context.state.invalidIdeaName}
                id="required"
                label="Idea Name"
                placeholder="e.g. Flux Capacitor"
                helperText="Enter the name of your idea"
                className={classes.textField}
                value={context.state.idea_name}
                onChange={context.handleIdeaName}
                fullWidth={true}
                variant="outlined"
                margin="dense"
              />
              <TextField
                required
                error={context.state.invalidDesc}
                id="multiline-flexible"
                label="Description"
                multiline
                rows="6"
                value={context.state.idea_description}
                placeholder="e.g. time travel"
                helperText={
                  context.state.invalidDesc
                    ? "Please enter 500 characters or less"
                    : "Provide a short description of your idea"
                }
                onChange={context.handleDesc}
                className={classes.textField}
                fullWidth={true}
                variant="outlined"
                margin="dense"
              />
            </form>
          </div>
        </>
      );
    case 3:
      setFormTitle("A bit about your skills");
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="select-industry"
              select
              label="I'm experienced in"
              fullWidth={true}
              className={classes.textField}
              value={context.state.lkp_skills_cat1}
              onChange={context.handleSkillCat1}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              variant="outlined"
              margin="dense"
            >
              {industry.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {`${option.label}`}
                </MenuItem>
              ))}
            </TextField>

            {otherPrimary && (
              <TextField
                required
                error={context.state.invalidOtherSkill1}
                id="primary_other"
                label="Other (please specify)"
                placeholder="Other (please specify)"
                className={classes.textField}
                value={context.state.otherSkill1}
                onChange={context.handleOtherSkill1}
                helperText={
                  context.state.invalidOtherSkill1
                    ? "Please enter your skill"
                    : ""
                }
                margin="dense"
                variant="outlined"
                fullWidth={true}
              />
            )}
            <TextField
              id="select-industry2"
              select
              label="I'm interested in"
              fullWidth={true}
              className={classes.textField}
              value={context.state.lkp_skills_cat2}
              onChange={context.handleSkillCat2}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              variant="outlined"
              margin="dense"
            >
              {industry.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {`${option.label}`}
                </MenuItem>
              ))}
            </TextField>
            {otherSecondary && (
              <TextField
                required
                error={context.state.invalidOtherSkill2}
                id="secondary_other"
                label="Other (please specify)"
                placeholder="Other (please specify)"
                className={classes.textField}
                value={context.state.otherSkill2}
                onChange={context.handleOtherSkill2}
                helperText={
                  context.state.invalidOtherSkill2
                    ? "Please enter your skill"
                    : ""
                }
                margin="dense"
                variant="outlined"
                fullWidth={true}
              />
            )}
            <TextField
              required
              error={context.state.invalidDesc}
              id="multiline-flexible"
              label="Bio"
              multiline
              rows="4"
              value={context.state.collab_bio}
              placeholder="Share a bit about your skills, work experience and projects you've worked on."
              helperText={
                context.state.invalidDesc
                  ? "Please enter 150 characters or less"
                  : ""
              }
              onChange={context.handleBio}
              onBlur={context.handleBio}
              className={classes.textField}
              fullWidth={true}
              variant="outlined"
              margin="dense"
            />
          </form>
        </div>
      );
    case 4:
      setFormTitle("Legal");
      return (
        <div>
          <form>
            <FormControlLabel
              style={{ marginLeft: "0%", marginTop: "10px" }}
              control={
                <Checkbox
                  checked={context.state.opt_in_comp}
                  onChange={context.handleCheckComp}
                  value="opt_in_comp"
                  color="primary"
                />
              }
              label={
                <div>
                  <span>
                    I want to enter the <strong>SPRXS competition</strong> for a
                    chance to win <strong>£9,250</strong> and have read and
                    accepted the
                  </span>
                  <Link to="#" onClick={context.props.showCompTerms}>
                    {" "}
                    competition rules
                  </Link>
                </div>
              }
            />
            <FormControlLabel
              style={{ marginLeft: "0%", marginTop: "10px" }}
              control={
                <Checkbox
                  checked={context.state.opt_in_notif}
                  onChange={context.handleCheckNotification}
                  value="opt_in_notif"
                  color="primary"
                />
              }
              label="I want to stay in the loop for information on SPRXS"
            />
            <FormControlLabel
              style={{ marginLeft: "0%", marginTop: "10px" }}
              control={
                <Checkbox
                  checked={context.state.checkedTerms}
                  onChange={context.handleCheckTerms}
                  value="checkedA"
                  color="primary"
                />
              }
              label={
                <div>
                  <span>I have read and accepted the </span>
                  <Link to="#" onClick={context.props.showPriv}>
                    Privacy Policy
                  </Link>
                  <span>, </span>
                  <Link to="#" onClick={context.props.showTerms}>
                    Terms and Conditions
                  </Link>
                  <span> and </span>
                  <Link to="#" onClick={context.props.showCollab}>
                    Tokens and Collaboration Policy
                  </Link>
                </div>
              }
            />
          </form>
        </div>
      );
    case 5:
      return (
        <div>
          <h4>WELCOME TO SPRXS</h4>
        </div>
      );
    default:
      return "Unknown step";
  }
}
class RegStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      profileId: "",
      firstname: "",
      surname: "",
      passwrd: "",
      passwrd2: "",
      lkp_email: "",
      lkp_email2: "",
      showPassword: false,
      showPassword2: false,
      lkp_idea_cat1: 0,
      idea_name: "",
      idea_description: "",
      lkp_skills_cat1: 0,
      otherSkill1: "",
      lkp_skills_cat2: 0,
      otherSkill2: "",
      collab_bio: "",
      otherPrimary: false,
      otherSecondary: false,
      checkedTerms: false,
      opt_in_comp: false,
      opt_in_notif: false,
      disableButton: " ",
      disableNext: true,
      joinTypeValue: "individual",
      authUser: false,
      pendingResponse: false,
      createIdea: true,
      userType: "",
      tokenId: "",
      isSubmit: false,
      covid_19_idea: true
    };
  }

  componentDidMount(currentStep) {
    this.props.setFormTitle("Join SPRXS");
  }

  // USER DETAILS
  handleJoinTypeChange = e => {
    this.setState({ joinTypeValue: e.target.value });
  };

  handleFirstname = e => {
    this.setState({
      firstname: e.target.value,
      firstNameValid: true,
      invalidFirstName: false
    });
  };

  handleLastname = e => {
    this.setState({
      surname: e.target.value,
      lastNameValid: true,
      invalidLastName: false
    });
  };

  handleEmail = e => {
    this.setState({ authUser: false });
    const emailValid = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    this.setState({
      emailValidation: emailValid === null ? true : false
    });
    this.setState({ lkp_email: e.target.value });
  };

  handleEmail2 = e => {
    const emailValid2 = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    this.setState({
      emailValidation2: emailValid2 === null ? true : false
    });
    this.setState({ lkp_email2: e.target.value }, () => {
      if (this.props.journey === "CREATE") {
        this.setState({ disableNext: validateCreate(this) });
      }
    });
  };

  handlePassword = e => {
    this.setState({ passwrd: e.target.value }, () => {});
  };

  handlePassword2 = e => {
    this.setState({ passwrd2: e.target.value }, () => {
      this.setState({ disableNext: validateUser(this) });
    });
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value }, () => {});
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleClickShowPassword2 = () => {
    this.setState(state => ({ showPassword2: !state.showPassword2 }));
  };

  // IDEA
  handleCat1 = event => {
    this.setState({
      lkp_idea_cat1: event.target.value
    });
  };

  handleIdeaName = event => {
    this.setState({ idea_name: event.target.value });
  };

  handleDesc = event => {
    this.setState({ idea_description: event.target.value });

    if (event.target.value.length > 500) {
      this.setState({ invalidDesc: true });
    } else {
      this.setState({ invalidDesc: false });
    }
    if (
      this.state.invalidDesc === true ||
      event.target.value === "" ||
      this.state.idea_name === ""
    ) {
      console.log("error");
    } else {
      this.setState({ disableNext: validateIdea(this) });
    }
  };

  // SKILLS
  handleSkillCat1 = event => {
    this.setState({
      lkp_skills_cat1: event.target.value
    });
    if (event.target.value === industry.length) {
      this.setState({ otherPrimary: true });
    } else {
      this.setState({ otherPrimary: false });
    }
  };

  handleSkillCat2 = event => {
    this.setState({
      lkp_skills_cat2: event.target.value
    });
    if (event.target.value === industry.length) {
      this.setState({ otherSecondary: true });
    } else {
      this.setState({ otherSecondary: false });
    }
  };

  handleBio = event => {
    this.setState({ collab_bio: event.target.value });
    if (event.target.value.length > 150) {
      this.setState({ invalidDesc: true });
    } else {
      this.setState({ invalidDesc: false });
    }
    if (this.state.invalidDesc === true || event.target.value === "") {
      console.log("error");
    } else {
      this.setState({ disableNext: validateSkills(this) });
    }
  };

  handleOtherSkill1 = e => {
    this.setState({
      otherSkill1: e.target.value,
      otherSkill1Valid: true,
      invalidOtherSkill1: false
    });
    console.log(this.state.otherSkill);
  };

  handleOtherSkill2 = e => {
    this.setState({
      otherSkill2: e.target.value,
      otherSkill2Valid: true,
      invalidOtherSkill2: false
    });
  };

  // LEGAL
  handleCheckTerms = event => {
    this.setState({ checkedTerms: !this.state.checkedTerms }, () => {
      this.setState({ disableNext: validateLegal(this) });
    });
  };
  handleCheckNotification = event => {
    this.setState({ opt_in_notif: !this.state.opt_in_notif });
  };
  handleCheckComp = event => {
    this.setState({ opt_in_comp: !this.state.opt_in_comp });
  };
  handleCovidIdea = event => {
    this.setState({ covid_19_idea: !this.state.covid_19_idea });
  };

  handleNext = e => {
    e.preventDefault();
    this.props.handleNext();
    this.setState({ disableNext: true });
    if (this.state.currentStep === 2) {
      this.setState({ createIdea: true });
    }
  };

  handleSkip = e => {
    e.preventDefault();
    this.props.handleNext();
    console.log("Skipping Idea Creation.");
    this.setState({ disableNext: true, createIdea: false });
  };

  handlePrev = e => {
    e.preventDefault();
    this.setState({ disableNext: false });
    this.props.handlePrev();
  };

  handleAuthenticateUser = e => {
    const that = this;
    fire
      .auth()
      .fetchSignInMethodsForEmail(this.state.lkp_email)
      .then(providers => {
        if (providers.length === 0) {
          // this email hasn't signed up yet
          this.setState({ authUser: false });
        } else {
          // has signed up
          this.setState({ authUser: true });
          //console.log(this.state.lkp, "Regstep authUser")
        }
      });
  };

  handlePassToLogin = e => {
    this.props.handlePassToLogin(this.state.lkp_email);
  };

  handleSubmit(e) {
    const that = this;
    const { userType } = this.state;
    console.log(userType, "the submit userType");
    this.setState({ pendingResponse: true });
    const { cookies, saveToken } = this.props;

    // console.log("REACHED SUBMIT", saveToken);
    fire
      .auth()
      .createUserWithEmailAndPassword(this.state.lkp_email, firebasePassword)
      .then(u => {
        let user = fire.auth().currentUser;
        if (user != null) {
          user.providerData.forEach(function(profile) {
            console.log("Sign-in provider: " + profile.providerId);
            console.log("  Provider-specific UID: " + profile.uid);
            console.log("  Name: " + profile.displayName);
            console.log("  Email: " + profile.email);
          });
        }
        fire
          .database()
          .ref()
          .child("users")
          .child(user.uid)
          .child("credentials")
          .update({
            firstName: this.state.firstname,
            lastName: this.state.surname,
            email: this.state.lkp_email,
            password: firebasePassword,
            profilePicLink: ""
          });

        that.setState({
          userId: user.uid
        });
        let dataPackage = {
          firstname: that.state.firstname,
          surname: that.state.surname,
          lkp_country: 0,
          mobile_cc: 0,
          mobile_no: "0",
          email_add: that.state.lkp_email,
          passwrd: that.state.passwrd,
          passwrd2: that.state.passwrd2,
          opt_in_comp: that.state.opt_in_comp,
          opt_in_notif: that.state.opt_in_notif,
          firebase_uid: that.state.userId,
          lkp_idea_cat1: that.state.lkp_idea_cat1,
          lkp_idea_cat2: 2,
          lkp_idea_cat3: 3,
          idea_name: that.state.idea_name,
          idea_description: that.state.idea_description,
          ideaFilepath: "",
          creating_idea: that.state.createIdea,
          covid_19_idea: that.state.covid_19_idea
        };

        //axios Post
        axios
          .post(`${hostApi}/createProfile_v2`, dataPackage, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.token}`
            }
          })
          .then(response => {
            console.log("createProfile: ", response);
            console.log("createProfile_token: ", response.token);
            if (response.data.createProfile_response === "FAIL") {
              console.log("fail");
            } else {
              saveToken(cookies, response.data.token);
              that.setState({
                pendingResponse: false,
                profileId: response.data.profile_id
              });
              this.props.dispatchAuth("0", 0);
              ideatorAccountCreated(); // Google analytics event capture.
              this.moveToDashboard();
            }
          })
          .catch(error => {
            console.log("AXIOS ERROR", error);
          });
      })
      .catch(error => {
        console.log("FIREBASE ERROR--->", error);
      });
  }

  moveToDashboard = () => {
    this.props.dispatchFirebaseUid(this.state.userId);
    this.props.isAuthenticated(this.state.lkp_email);
    this.props.navigateToMySPRXS(
      this.state.userId,
      this.state.profileId,
      this.state.lkp_email,
      this.state.firstname,
      this.state.surname,
      this.state.userType
    );
  };

  render() {
    const {
      classes,
      journey,
      currentStep,
      setFormTitle,
      setJourney,
      handleNext,
      handlePrev,
      navigateToMySPRXS,
      handlePassToLogin
    } = this.props;

    const {
      disableNext,
      disableButton,
      joinTypeValue,
      otherPrimary,
      otherSecondary,
      authUser,
      pendingResponse
    } = this.state;

    return (
      <div className={classes.root}>
        {getStepContent(
          classes,
          currentStep,
          setFormTitle,
          setJourney,
          journey,
          joinTypeValue,
          otherPrimary,
          otherSecondary,
          this
        )}
        <div className={classes.actionsContainer}>
          <div>
            {currentStep <= 4 && currentStep > 1 && (
              <Button
                disabled={false}
                onClick={this.handlePrev.bind(this)}
                className={`${classes.button} ${disableButton}`}
              >
                Back
              </Button>
            )}
            {currentStep < 4 && currentStep > 0 && !authUser && (
              <Button
                variant="contained"
                disabled={disableNext}
                color="secondary"
                onClick={this.handleNext.bind(this)}
                className={`${classes.button} ${disableButton}`}
              >
                Next
              </Button>
            )}
            {currentStep < 4 && currentStep > 0 && authUser && (
              <Button
                variant="contained"
                disabled={false}
                color="secondary"
                onClick={this.handlePassToLogin.bind(this)}
                className={`${classes.button} ${disableButton}`}
              >
                Login
              </Button>
            )}
            {currentStep === 2 && (
              <Button
                variant="contained"
                disabled={false}
                onClick={this.handleSkip.bind(this)}
                className={`${classes.button} ${disableButton}`}
              >
                Skip
              </Button>
            )}
            {currentStep === 4 && (
              <>
                {pendingResponse ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={35}
                    color={"#123abc"}
                    loading={this.state.isLoading}
                  />
                ) : (
                  <Button
                    variant="contained"
                    disabled={disableNext}
                    color="secondary"
                    onClick={e => this.handleSubmit(e)}
                    className={`${classes.button} ${disableButton}`}
                  >
                    Join
                  </Button>
                )}
              </>
            )}
            )}
          </div>
        </div>
      </div>
    );
  }
}

RegStep.propTypes = {
  classes: PropTypes.object,
  cookies: instanceOf(Cookies).isRequired
};

const mapStateToProps = state => {
  return {
    emailAddress: state.emailAddress,
    Authenticated: state.isAuthenticated,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // POST
    postCollaborator: (payload, callback) => {
      postCollaborator(payload, callback, dispatch);
    },
    //
    dispatchAuth: (type, collaboratorID) => {
      dispatchAuth(type, collaboratorID, dispatch);
    },
    //
    closeModal: () => dispatch({ type: CLOSE_MODAL }),
    dispatchFirebaseUid: user => dispatch(dispatchFirebaseUid(user)),
    isAuthenticated: (emailAddress = null) =>
      dispatch(isAuthenticated(emailAddress)),
    showPriv: () => dispatch(showLegalModal("privacy")),
    showTerms: () => dispatch(showLegalModal("terms")),
    showCollab: () => dispatch(showLegalModal("collab")),
    showCompTerms: () => dispatch(showLegalModal("comp")),
    showIdeaSubmittedNTF: () => dispatch(showIdeaSubmittedNTF()),
    saveToken: (cookies, token) => saveToken(cookies, token, dispatch),
    dispatchSearchCriteria: searchCriteria => {
      dispatch({
        type: SEARCH_CRITERIA,
        payload: {
          searchCriteria: searchCriteria
        }
      });
    }
  };
};

export default withRouter(
  withCookies(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegStep))
  )
);
