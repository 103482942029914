import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CollaborateIcon from "../../static/collaborateIcon.svg";
import MonotizeIcon from "../../static/monotizeIcon.svg";
import CreateIdeaIcon from "../../static/createIdeaIcon.svg";

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

class boxItem extends React.Component {
  render() {
    const { classes, title, text, buttonText, link, signUpJourney } = this.props;
    const getImage = category => {
      switch (category) {
        case "CollaborateIcon":
          return CollaborateIcon;
        case "MonotizeIcon":
          return MonotizeIcon;
        case "CreateIdeaIcon":
          return CreateIdeaIcon;
        default:
          return CreateIdeaIcon;
      }
    };

    return (
      <div className="boxContainer">
        {/* <div className={classes.boxItem}>
                        <img
                            src={getImage(this.props.image)}
                            width={80}
                            className={classes.img}
                        />
                    </div> */}
        <div className="boxItem">
          <h4 className="boxTitle">{title}</h4>
        </div>
        <div className="boxItem">{text}</div>
        <div className="boxItem buttonRow">
          {buttonText === "coming soon" && (
            <button className="sectionButton inactive">{buttonText}</button>
          )}

          {buttonText != "coming soon" && (
            <button className="sectionButton" onClick={e => link(e, signUpJourney)}>
              {buttonText}
            </button>
          )}
        </div>
      </div>
    );
  }
}

boxItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(boxItem));
