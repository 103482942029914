import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

function CollabTokensPolicy({ handleChange }) {
  return (
    <div className="legalWrapper">
      <h1 className="legalHeader">Tokens and Collaboration</h1>
      <div className="legalContent">
        <h3 className="legalSubHead">1. SPRXS Users</h3>
        <p className="legalCopy">
          The SPRXS platform allows for two types of functions for all users,
          namely:
          <ol className="legalAlpha">
            <p>
              1.1 <strong>The Ideator</strong> – the individual/company that
              creates, logs and submits ideas onto the platform; and
            </p>
            <p>
              1.2 <strong>The Collaborator</strong> – the individual/company
              that registers to use the platform with the intention of
              collaborating that s with the Ideator or another collaborator
              registered on the SPRXS platform.The Collaborator – the
              individual/company that registers to use the platform with the
              intention of collaborating with the Ideator or another
              collaborator registered on the SPRXS platform.
            </p>
            <p>1.3 1.1 &amp; 1.2 above are collectively called ‘Users’.</p>
            <p>
              1.4 Upon registration, all Users agree to be bound by the terms
              contained herein (insofar as they apply) in addition to the
              Website Terms of Use; the Acceptable Use Policy; Privacy Policy
              and Cookie Policy.
            </p>
          </ol>
        </p>
        <h3 className="legalSubHead">2. Tokens</h3>
        <p className="legalCopy">
          <ol className="legalAlpha">
            <p>
              <span className="copySubHead">Allocation of Tokens</span>
              <ul>
                <p>
                  2.1 Upon registration of a valid idea, the idea owner
                  (“Ideator) will be allocated 100 SPRXS tokens (“Idea Tokens”),
                  stored in a personal SPRXS wallet specifically created by
                  SPRXS for the Ideator (“Idea Wallet”).
                </p>
                <p>
                  2.2 These tokens are a representation of equity shares in an
                  idea and shall have no monetary value until the idea gains
                  investment, however they may be exchanged for collaborator
                  skills and resources.
                </p>
                <p>
                  2.3 Each token shall represent one percent of the equity in
                  the idea. Therefore, 100 Idea Tokens will represent 100% of
                  the equity in the idea.
                </p>
              </ul>
            </p>
            <p>
              <span className="copySubHead">Use of Tokens</span>
              Tokens can be used as follows:
              <span className="copySubHead">2.4 Self- Ownership of Tokens</span>
              <ul>
                <p>
                  a. Tokens are allocated as a means of proof of ownership and
                  stake-holding in the idea. An Ideator can choose to keep all
                  100 tokens which will represent 100% of ownership in the
                  equity of the idea.
                </p>
              </ul>
              <span className="copySubHead">
                2.5 Token Exchange for Collaboration
              </span>
              <ul>
                <p>
                  a. The Ideator may seek to collaborate with other idea owners
                  or collaborators registered on the SPRXS.io website, with the
                  intent to enter into an association for mutual benefit, and
                  may agree to change ideas, or contribute to combining ideas to
                  create a new idea, concept, service and/or product.
                </p>
                <p>
                  b. Where the above applies, the Ideator must enter into a
                  collaboration arrangement with another idea owner/collaborator
                  (“Collaborator”).
                </p>
                <p>
                  c. Upon registration, a SPRXS User Wallet will be created for
                  the Collaborators for the purposes of entering into
                  collaboration arrangements.
                </p>
                <p>
                  d. Where users agree to collaborate with one another on the
                  SPRXS platform they must progress to use the ‘Milestone and
                  Token Transfer’ functionality which will require the parties
                  to agree and set milestones for the collaboration against
                  timelines; as well as agree the number of tokens (if
                  applicable) that will be transferred on achievement of the
                  said milestones.
                </p>
              </ul>
              {/* Terms of Use for Collaboration using the SPRXS platform can be found here (INSERT LINK). */}
            </p>
            <p>
              <span className="copySubHead">Transfer of Tokens</span>
              Tokens shall be transferred to the SPRXS Collaborator Wallet by
              the SPRXS platform when one or a combination of the below have
              been executed:
              <ul>
                <p>
                  2.6 The collaboration activity has been completed and approved
                  by the Ideator using the SPRXS milestone functionality, or
                  where 14 days have passed since the milestone completion date
                  and the Collaboration has been automatically approved by the
                  platform;
                </p>
                <p>
                  2.7 Resources have been provided as part of a collaboration
                  agreement and this has been approved by the Ideator using the
                  SPRXS milestone functionality, or where 14 days have passed
                  since the milestone completion date and the Collaboration has
                  been automatically approved by the platform; or
                </p>
                <p>
                  2.8 Where otherwise agreed by the parties in writing in a
                  collaboration agreement.
                </p>
              </ul>
            </p>
          </ol>
        </p>
        <h3 className="legalSubHead">3. Collaboration</h3>
        <p className="legalCopy">
          <ol>
            <p>
              <span className="copySubHead">Starting Collaboration</span>
              3.1 By entering into collaboration on the SPRXS platform, the
              Users confirm their intent to enter into an association of mutual
              benefit and agree to jointly invest, exchange ideas, contribute
              and enter into a collaboration agreement using the functionalities
              provided.
              <br />
              <br />
              3.2 Collaboration will begin when the Ideator and Collaborator
              agree to collaborate using the functionalities of the SPRXS
              platform, where:
              <ul>
                <p>
                  a. The Ideator requests collaboration from a registered SPRXS
                  Collaborator and the Collaborator accepts using the ‘Ideator
                  Request functionality’ on the SPRXS Platform; or
                </p>
                <p>
                  b. The Collaborator requests to collaborate on an idea and
                  this is accepted by the Ideator using the ‘Collaborator
                  Request functionality’ on the SPRXS Platform.
                </p>
              </ul>
            </p>
            <p>
              <span className="copySubHead">
                Setting the Token Allocation for Collaboration
              </span>
              3.3 SPRXS Idea Token consideration for the collaboration should be
              set and agreed using the ‘Milestone and Token Transfer’
              functionality.
              <br />
              <br />
              3.4 Where the parties have agreed to collaborate, SPRXS will
              automatically be awarded one (x1) SPRXS Idea Token by the Ideator
              as commission for hosting Collaboration, Token and Monetization
              services on the platform.
            </p>
            <p>
              <span className="copySubHead">Ending Collaboration</span>
              3.5 The collaboration will be deemed to have ended when:
              <ul>
                <p>
                  a. The milestones associated with the collaboration have been
                  completed and agreed by both Ideator and Collaborator; and
                </p>
                <p>
                  b. The Idea Tokens agreed in the milestones have been
                  transferred; or
                </p>
                <p>
                  c. There has been a breach of the collaboration agreement.
                </p>
              </ul>
            </p>
            <p>
              <span className="copySubHead">Extending Collaboration</span>
              3.6 This collaboration may be extended where both parties agree
              and amend the milestone timelines/information using the ’Milestone
              and Token Transfer’ functionality’ on the SPRXS Platform using the
              functionalities on the SPRXS platform for collaboration, you
              confirm that you accept the Terms for Collaboration and agree to
              comply with them.
              <br />
              <br />
              3.7 If you do not agree to these terms, you cannot use the
              collaboration functionality on the site.
            </p>
            <p>
              <span className="copySubHead">Collaboration Management</span>
              3.8 The Collaboration will be directed, controlled and managed by
              the Ideator using the ’Milestone and Token Transfer’ functionality
              on the SPRXS platform.
              <br />
              <br />
              3.9 The Ideator will have full authority to bind the Parties in
              all matters relating to the direction, control and management of
              the venture, provided that at all times, the Ideator retains the
              controlling interest in the idea (over 50 SPRXS Idea Tokens in the
              idea).
            </p>
            <p>
              <span className="copySubHead">Duties of Collaborators</span>
              3.10 Collaborators must strictly adhere to the SPRXS Platform
              terms of use and associated policies.
              <ul>
                <p>
                  3.11 Each Collaborator will be responsible for his/her
                  respective duties as set out in the idea milestones in the
                  ‘Milestone and Token Transfer’ functionality.
                </p>
                <p>
                  3.12 Duties of Collaborators may be amended, from time to
                  time, by decision of the Parties, provided that the Parties
                  interests are not affected except with the unanimous consent
                  of the Parties, and this must be reflected by changing the
                  milestone arrangements using the ‘Milestones and Token
                  Transfer’ functionality on the SPRXS Platform.
                </p>
                <p>
                  3.13 Collaborators shall accept cancellation of token transfer
                  agreements in milestones where the collaboration has failed to
                  complete or materialize. If this is not acknowledged by the
                  Collaborator in 5 days of cancellation request by an Idea
                  owner, the agreement and token transfer will be automatically
                  cancelled.
                </p>
                <p>
                  3.14 Collaborators shall not seek to file a patent or secure
                  intellectual property rights independent of follow
                  collaborators on the project, or in their exclusive capacity,
                  where the idea is the product of a collaboration or was
                  disclosed to him/her during the course of a collaboration
                  exercise.
                </p>
              </ul>
            </p>
            <p>
              <span className="copySubHead">
                Admitting new Parties to Collaborate
              </span>
              3.15 New Parties may be admitted into the Collaboration where
              accepted to collaborate using the ‘Collaboration’ functionality on
              SPRXS platform by the Ideator. The new collaborator must be
              registered as a Collaborator on the platform.
            </p>
            <p>
              <span className="copySubHead">
                Sale of Interest to a third Party
              </span>
              3.16 Users registering ideas registered on the SPRXS Platform will
              be allocated 100 SPRXS Idea Tokens (less 1 token to the SPRXS
              platform), which will represent equity. Sale of equity must be
              updated on the platform using the ‘Milestones and Token Transfer’
              functionality, to reflect any new ownership distribution.
              <ul>
                <p>
                  3.17 No Party may sell its interest to a third party without
                  granting the Ideator the first of first refusal.
                </p>
                <p>
                  3.18 Where the original Ideator no longer exists (or has
                  deleted his / her profile), the party with a controlling
                  interest shall have the right of first refusal.
                </p>
                <p>
                  3.19 Where the Ideator or the party with a controlling
                  interest is unable to exercise the option, the party desiring
                  a sale shall be free to sell to the highest bidder on the open
                  market.
                </p>
              </ul>
            </p>
            <p>
              <span className="copySubHead">
                Change in direction or nature of the Collaboration exercise
              </span>
              3.20 The Ideator shall retain direction, control and management of
              the Idea as long as he/she retains the controlling interest (over
              50 SPRXS Idea Tokens in the idea).
              <ul>
                <p>
                  3.21 Where the controlling interest has been lost, the
                  direction, control and management of the idea shall be
                  determined by the party having a controlling interest.
                </p>
                <p>
                  3.22 Where no party holds a controlling interest the
                  direction, control and management of the nature of the
                  Collaboration shall be determined, by the unanimous consent of
                  the Parties.
                </p>
              </ul>
            </p>
            <p>
              <span className="copySubHead">Liability</span>
              3.23 SPRXS takes no responsibility and assumes no liability for
              any user conduct or for any user content stored, published,
              uploaded or otherwise made available via the platform.
              <ul>
                <p>
                  3.24 SPRXS has the right to voluntarily review content to
                  determine whether it is illegal or violates the
                  above-mentioned policies and may remove or refuse to display
                  content. SPRXS further has the right to, at its sole
                  discretion, determine whether or not any user conduct is
                  appropriate and complies with these terms of use.
                </p>
                <p>
                  3.25 If not or a user creates problems or possible legal
                  liabilities, restrictions will improve the security of the
                  platform and its users or the user infringes third party
                  intellectual property rights SPRXS may, with or without prior
                  notice limit, suspend, or terminate the user account, restrict
                  or prohibit access to, and activities on, the platform and its
                  services, cancel transfers, sales, bids, remove or restrict
                  the visibility of offers, delay or remove hosted content and
                  take technical and legal steps to keep users from using SPRXS.
                  SPRXS will have no obligation to provide a refund of any
                  amounts previously paid.
                </p>
              </ul>
            </p>
            <p>
              <span className="copySubHead">Intellectual Property</span>
              3.26 For any intellectual property, asset, information, document,
              file, image, footage, text, audio, or any other content that the
              User stores, uploads, publishes, shares, sells or makes available
              using SPRXS, the User represents and warrants that:
              <ul>
                <p>
                  a. The User possesses all necessary rights to submit the
                  content to the SPRXS platform and take further actions in
                  connection with the services of the platform;
                </p>
                <p>
                  b. With respect to the content SPRXS does not need to obtain
                  licenses from any third party or pay royalties to any third
                  party;
                </p>
                <p>
                  c. The downloading, copying, and use of the content does not
                  infringe the proprietary rights, including but not limited to
                  the copyright, patent, trademark or trade secret rights, of
                  any third party;
                </p>
                <p>
                  d. All content complies with SPRXS’s Terms of Use and all
                  applicable laws;
                </p>
                <p>
                  e. The User accurately categorized and/or described the type,
                  nature, uses, and effects of the materials and content
                  provided.
                </p>
              </ul>
              3.27 For content created by SPRXS on the dashboards, the ownership
              of any content that is transmitted, stored, or processed in the
              User’s account lies with the User, who retains all ownership of,
              control of, and responsibility for his content. The User controls
              access to his content through settings in his account.
              <ul>
                <p>
                  3.28 The User owns its content, but gives the right to SPRXS,
                  to display and share the content it wishes to publish and the
                  right to remove content if required. Any liability, loss or
                  damage that occurs as a result of content provided or created
                  by the User and made available or accessed via SPRXS is solely
                  the responsibility of the User.
                </p>
                <p>
                  3.29 Accessing content of other users and third parties on
                  SPRXS does not give the User ownership of any intellectual
                  property rights, or grant the right to use any information,
                  branding or logos.
                </p>
                <p>
                  3.30 SPRXS takes no responsibility and assumes no liability
                  for any User interaction or transfer of content between Users
                  or any payments between Users, even if such actions are/may be
                  handled via the SPRXS platform.
                </p>
                <p>
                  3.31 The User agrees to accept the terms set forth in this
                  document and, if interacting with each other, to read,
                  acknowledge and accept the terms provided by SPRXS for the
                  sharing, transferring and sale of content which is the basis
                  for contractual obligations between Users
                </p>
                <p>
                  3.32 The User acknowledges that any liability, loss or damage
                  that occurs as a result of said interactions between users or
                  the reliance on the terms SPRXS defines for these interactions
                  is solely the responsibility of the user.
                </p>
              </ul>
            </p>
            <p>
              <span className="copySubHead">Third Party Interactions</span>
              3.33 The User acknowledges that the use of the SPRXS platform and
              its services and its contact, interaction, or dealings with other
              user or any other third parties arising out of its use of the
              platform and its services happens solely at the User’s own risk.
              <br />
              <br />
              3.34 The User acknowledges and agrees that SPRXS is not
              responsible or liable in any manner for any loss, damage or harm
              of any sort incurred as a result of your use of the services such
              as storage, publication, transfer, sharing, selling and licensing
              of content.
            </p>
          </ol>
        </p>
        <h3 className="legalSubHead">4. Legal Disclaimer</h3>
        <p className="legalCopy">
          The User acknowledges that SPRXS does not provide legal advice, and
          nothing constitutes legal advice, in relation to the terms of
          collaboration between Ideators and Collaborators. If a User requires
          legal advice and/or legal assistance in relation to the collaboration
          arrangements or drafting of a collaboration agreement, it will seek
          the assistance of an appropriate specialist lawyer at its own cost.
        </p>
        <h3 className="legalSubHead">5. Other Terms May Apply</h3>
        <p className="legalCopy">
          The terms below will also apply to you in the course of collaborating
          and allocating tokens on the SPRXS platform:
          <ul>
            <p>5.1 Our Website Terms of Use</p>
            <p>5.2 Acceptable Use Policy</p>
            <p>5.3 Terms of Use - Tokens</p>
            <p>5.4 Privacy Policy</p>
            <p>5.5 Cookie Policy</p>
          </ul>
        </p>
        <h3 className="legalSubHead">
          6. Changes to Terms of Use for Tokens and Collaboration
        </h3>
        <p className="legalCopy">
          SPRXS reserves the right to make changes to the terms of this policy
          <ul>
            <p>
              6.1 We may amend these terms from time to time. Every time you
              wish to use the SPRXS platform for collaboration, please ensure
              that you understand the terms that apply at that time. These terms
              were created on 1 August 2019.
            </p>
            <p>
              6.2 By entering into collaboration on the SPRXS platform, the Users
              confirm their intent to enter into an association of mutual
              benefit and agree to jointly invest, exchange ideas, contribute
              and enter into a collaboration agreement using the functionalities
              provided.
            </p>
          </ul>
        </p>
      </div>
    </div>
  );
}

CollabTokensPolicy.propTypes = {
  handleChange: PropTypes.func.isRequired
};

export default withStyles(styles)(CollabTokensPolicy);
