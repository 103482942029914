import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { FaInstagram, FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa";
import logoname from "../../static/sprxslogo.png";
import AIONIcon from "../../static/aion.png";
import "./HomeFooter.css";

export const HomeFooter = (
  classes,
  showTermsPolicyModal,
  showPrivModal,
  context
) => {
  return (
    <div className="footerContainer">
      <div className="footerSection fill">
        <img src={logoname} />        
      </div>
      {/* <div className="footerSection fill">
            <ul>
              <li>
                <a
                  href="#"
                >
                 Features
                </a>
              </li>
              <li>
                <a
                  href="#"
                >
                  Get Started
                </a>
              </li>
              <li>
                <a
                  href="#"
                >
                  Docs
                </a>
              </li>
              <li>
                <a
                  href="#"
                >
                  Services
                </a>
              </li>
            </ul>
          </div> */}
      <div className="footerSection fill">
        <ul>
          <li>
            <a href="/legal" >
              Legal
            </a>
          </li>
          
          <li>
            <a href="mailto:support@sprxs.io?">Contact Us</a>
          </li>
          <li>
          </li>
        </ul>
      </div>
      <div className="footerSection footerEnd">
        <a href="https://aion.network/" target="_blank">
          <img src={AIONIcon} width={"32px"} />
        </a>
        <a href="https://www.instagram.com/sprxs_app/" target="_blank">
          <FaInstagram className="iconHover" size={24} />
        </a>
        <a href="https://www.facebook.com/SPRXS" target="_blank">
          <FaFacebook className="iconHover" size={24} />
        </a>
        <a href="https://twitter.com/sprxsapp?lang=en" target="_blank">
          <FaTwitter className="iconHover" size={24} />
        </a>
      </div>
      <div className="footerSection footerEnd">
        <p>V.2.0.0</p>
        <p>Copyright &copy; 2018 Fifth-9 Ltd.</p>
      </div>
    </div>
  );
};
