import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import avatarImage from "../../static/profile.png";
import SpeedDials from "../SpeedDials/SpeedDials";
import TextField from '@material-ui/core/TextField';
import PublicIcon from "@material-ui/icons/Public";
import InputLabel from "@material-ui/core/InputLabel";


const styles = theme => ({
  container: {
    position: "relative",
    maxWidth: "100%"
  },
  text: {
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  main: {
    height: 430,
    overflowY: "auto",
    position: "relative",
    backgroundColor: "transparent",
    marginTop: theme.spacing.unit * 0.0
  },
  list: {
    marginBottom: theme.spacing.unit * 2
  },
  subHeader: {
    backgroundColor: "#fff"
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "space-between"
  },
  fabButton: {
    position: "absolute",
    top: -30,
    left: "50%",
    right: 0,
    margin: "0 auto"
  },
  messageWrapper: {
    position: "relative",
    width: "80%",
    minHeight: "80px",
    marginLeft: "10%",
    marginRight: "10%",
    marginTop: "60px",
    border: "1px solid #383736",
    borderRadius: "15px 15px 15px 15px"
  },
  messageHeader: {
      position: "absolute",
      display: "flex",
      left: "-10px",
      top: "-20px",
      height: "40px",
      backgroundColor: "#ffffff",
      float: "left",
      align: "left",
      textAlign: "left",
      paddingRight: "6px",
      border: "0px solid orange"
  },
  messageTitle: {
      lineHeight: "40px"
  },

  messageBody: {
      position: "relative",
      display: "inline-block",
      color: "#383736",
      height: "40px",
      border: "0px solid blue",
      align: "left",
      textAlign: "left",
      marginTop: "30px",
      width: "100%",
      paddingLeft: "8%",
      paddingRight: "2%",
      marginBottom: "8px",
      float: "left"
  },

  icon: {
      float: "left",
      margin: "5px 6px 0px 0px"
  }
});

const messages = [
  {
    id: 1,
    primary: "Collaboration Request on Idea #GFE23",
    secondary:
      "I'll be in the neighbourhood this week. Let's grab a bite to eat",
    person: avatarImage
  },
  {
    id: 2,
    primary: "Idea #YTGD1 Minted on Blockchain",
    secondary: `Do you have a suggestion for a good present for John on his work 
      anniversary. I am really confused & would love your thoughts on it.`,
    person: avatarImage
  },
  {
    id: 3,
    primary: "Idea #WSDA2 Created",
    secondary:
      "I am try out this new BBQ recipe, I think this might be amazing",
    person: avatarImage
  },
  {
    id: 4,
    primary: "Your Idea #12SDE Published",
    secondary: "I have the tickets to the ReactConf for this year.",
    person: avatarImage
  },
  {
    id: 5,
    primary: "Collaboration Approval for #12FRD",
    secondary:
      "My appointment for the doctor was rescheduled for next Saturday.",
    person: avatarImage
  },
  {
    id: 6,
    primary: "Request to chat",
    secondary: `Menus that are generated by the bottom app bar (such as a bottom 
      navigation drawer or overflow menu) open as bottom sheets at a higher elevation 
      than the bar.`,
    person: avatarImage
  },
  {
    id: 7,
    primary: "SPRXS User 0x235453 has purchased your idea #34DES",
    secondary: `Who wants to have a cookout this weekend? I just got some furniture
      for my backyard and would love to fire up the grill.`,
    person: avatarImage
  }
];

function BottomAppBar(props) {
  const {classes} = props;
  return (
    <div className={classes.container}>
      {/* <Paper square className={classes.main}> */}
        <List className={classes.list}>
          {messages.map(({id, primary, secondary, person}) => (
            <Fragment key={id}>
              {id === 1 && (
                <ListSubheader className={classes.subHeader}>
                  Today
                </ListSubheader>
              )}
              {id === 3 && (
                <ListSubheader className={classes.subHeader}>
                  Yesterday
                </ListSubheader>
              )}
              {/* <ListItem className={classes.messageWrapper}> */}
              {/* <InputLabel htmlFor="age-helper">Age</InputLabel> */}
                <div className={classes.messageWrapper}>
                    <div className={classes.messageHeader}>
                        <PublicIcon className={classes.icon}/>
                        <p className={classes.messageTitle}>{primary}</p>
                    </div>
                    <div className={classes.messageBody}>
                        {/* <Avatar alt="Profile Picture" src={person} /> */}
                        <p>{secondary}</p>
                    </div>
              </div>
            </Fragment>
          ))}
        </List>
      {/* </Paper> */}
    </div>
  );
}

BottomAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BottomAppBar);
