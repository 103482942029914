import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Divider from "@material-ui/core/Divider";
import firebase from "../../constants";
import { Fab } from "@material-ui/core";

const styles = theme => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  buttonWrapper: {
    display: "flex",
    position: "relative"
  },
  textField: {
    width: "70%" /* Fall back */,
    width: "calc(100% - 88px);",
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing.unit * 1,
    marginTop: "15px"
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  input: {
    position: "absolute",
    opacity: "0",
    width: "50px",
    right: "10px",
    top: "15px",
    zIndex: "1",
    height: "50px"
  }
});

class ChatInput extends React.Component {
  state = {
    message: ""
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  sendLocalMessage = () => {
    const message = this.state.message.trim();
    const { sendMessage } = this.props;
    if (message.length) {
      sendMessage(message);
      this.setState({ message: "" });
    }
  };

  handleEnter = e => {
    if (e.key === "Enter") {
      const message = this.state.message.trim();
      this.sendLocalMessage();
      e.preventDefault();
    }
  };

  sendAttachment = e => {
    console.clear();
    console.log("Send Works");
  };

  updateFilename2 = event => {
    const { sendMessage } = this.props;
    if (event.target.files.length) {
      console.clear();
      console.log("FILENAME EVENT", event.target.files[0].type);

      this.setState({
        filename: event.target.files[0].name,
        fileDetails: event.target.files[0]
      });
      let filename = event.target.files[0].name;
      let type = event.target.files[0].type;
      console.log("File Name", filename);
      var uid = firebase.auth().currentUser.uid;
      // Points to the root reference
      var storageRef = firebase.storage().ref();

      // Points to 'All user files'

      var allUserFiles = storageRef
        .child("AllUsersFileData")
        .child(uid)
        .child(filename);

      var file = event.target.files[0];
      allUserFiles.put(file).then(function(snapshot) {
        // use the Blob or File API
        console.log("Uploaded a blob or file!");
        if (type.match(/^image\/[a-z]{1,4}/)) {
          type = "photo";
          sendMessage(filename, type);
        } else {
          sendMessage(filename, "file");
        }
      });
    } else {
      console.log("no file ");
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Divider />
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            id="outlined-multiline-flexible"
            label="Compose message"
            multiline
            rows={2}
            rowsMax="4"
            value={this.state.message}
            onChange={this.handleChange("message")}
            onKeyDown={this.handleEnter}
            className={classes.textField}
            margin="normal"
            /* helperText="hello" */
            variant="outlined"
          />
          <span className={classes.buttonWrapper}>
            <Fab
              variant="fab"
              size="small"
              color="primary"
              className={classes.button}
              onClick={event => {
                this.sendLocalMessage();
              }}
            >
              <SendIcon className={classes.rightIcon} />
            </Fab>
            <input
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.odt,.ods,.svg,.csv"
              className={classes.input}
              onChange={this.updateFilename2}
              id="contained-button-file2"
              multiple
              type="file"
            />
            <Fab
              variant="fab"
              size="small"
              color="primary"
              className={classes.button}
            >
              <div>
                <AttachFileIcon onClick={this.sendAttachment}>
                  <label htmlFor="contained-button-file" />
                </AttachFileIcon>
              </div>
            </Fab>
          </span>
        </form>
      </React.Fragment>
    );
  }
}

ChatInput.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChatInput);
