import React, { Component } from "react";
import "./Dashboard.css";
import "./MyProfile.css";
import IdeaList from "../../components/IdeaList";
import MyCollaborations from "../../components/MyCollaborations";
import MyWallet from "../../components/MyWallet";
import CreateIdeaModal from "../../components/CreateIdeaModal/CreateIdeaModal";
import { hostApi, COLLABORATOR, IDEATOR, ideaStatus } from "../../constants";
import axios from "axios";
import InboxList from "../../components/InboxList";
import { connect } from "react-redux";
import {
  getMyIdeasForDashBoard,
  setIdeaToEdit,
  showCardView,
  startChatWithPerson,
  dispatchAuth,
  launchCreateIdea,
  getInvitations,
  getInvitationsRTWOI,
  launchAlert,
  launchEditProfile
} from "../../store/actions/actions";
import { withRouter } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import Fuse from "fuse.js";
import MyProfile from "./MyProfile";
import { withCookies } from "react-cookie";
import { withStyles } from "@material-ui/core";
import IdeaDash from "../IdeaDash/IdeaDash";
import InviteList from "../../components/InviteList/InviteList";
import Typography from "@material-ui/core/Typography";
import IdeaDashLite from "../IdeaDash/IdeaDashLite";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import CollabProfile from "../../components/Profile/CollabProfile";
import InviteListRTWOI from "../../components/InviteList/InviteListRTWOI";

const styles = theme => ({
  root: {
    position: "relative",
    paddingTop: 40
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  }
});

class Dashboard extends Component {
  state = {
    collaborationWallet: [],
    myWallet: [],
    myCollaborations: [],
    myCollaborationsCollaboratorsByIdeaId: [],
    collaboratorsByIdeaList: [],
    inbox: [],
    myIdeas: [],
    collabIdeas: [],
    myInboxSearchTerm: "",
    myCollaboratorsTerm: "",
    myIdeaSearchTerm: "",
    openIdeaDash: false,
    openIdeaDashLite: false,
    ideaDashData: [],
    collabIds: [],
    collaboratorsList: []
  };

  componentDidMount() {
    const { cookies, dispatchAuth, userType } = this.props;
    const profile_id = cookies.get("profile_id");
    const _userType = userType || cookies.get("user_type");
    const _emailAddress =
      this.props.emailAddress || cookies.get("emailAddress");
    const jwtToken = cookies.get("bearerToken");

    // Build Idea Summery data
    _emailAddress && this.getData(_emailAddress, jwtToken);
    if (this.props.profileID) {
      this.axiosGetMyCollaborations(this.props.profileID);
      this.props.getInvitations(
        this.props.cookies.get("bearerToken"),
        profile_id,
        "collaborator",
        data => {}
      );
      this.props.getInvitationsRTWOI(
        this.props.cookies.get("bearerToken"),
        profile_id,
        "ideator",
        data => {}
      );
    } else {
      this.axiosGetMyCollaborations(profile_id);
      this.props.getInvitations(
        this.props.cookies.get("bearerToken"),
        profile_id,
        data => {}
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.userType === COLLABORATOR &&
      prevProps.profileID !== this.props.profileID
    ) {
      this.axiosGetMyCollaborations(this.props.profileID);
    }
  }

  axiosGetMyCollaborations = profileID => {
    const baseUrl = `${hostApi}`;

    axios
      .get(`${baseUrl}/collaborations/${profileID}`, {
        headers: {
          Authorization: `Bearer ${this.props.cookies.get("bearerToken")}`
        }
      })
      .then(response => {
        this.setState({
          collabIdeas:
            response.data.ideas.length !== 0
              ? response.data.ideas.map(idea => ({
                  ideaName: idea.ideaName,
                  IdeaDateCreated: idea.IdeaDateCreated,
                  ideaId: idea.ideaId,
                  ideaDescription: idea.ideaDescription,
                  equitySplit: idea.equity,
                  IdeaStatus: ideaStatus[parseInt(idea.IdeaStatus)].label,
                  OwnerFirstname: idea.OwnerFirstname,
                  OwnerFirebaseId: idea.OwnerFirebaseId,
                  Searchable: idea.Searchable,
                  blockchainId: idea.blockchainId,
                  OwnerLastname: idea.OwnerLastname,
                  numberOfCollaborators: 0
                }))
              : [],
          collaborationWallet: {
            progressValue: response.data.totalEquity,
            totalValue:
              response.data.ideas.length !== 0
                ? response.data.ideas.length * 100
                : 0
          }
        });
      });
  };

  getData(emailAddress, jwtToken) {
    if (emailAddress) {
      this.props.getMyIdeasForDashBoard(jwtToken, emailAddress);
      axios
        .all([
          axios.post(
            `${hostApi}/myIdeasSummary`,
            {
              lkp_email: emailAddress
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`
              }
            }
          ),
          axios.post(
            `${hostApi}/myTokensSummary`,
            {
              lkp_email: emailAddress
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`
              }
            }
          ),
          axios.post(
            `${hostApi}/viewEvent`,
            {
              id: 0,
              event_type: "Notification",
              idea_id: "",
              event_description: "",
              created_profile_recipient: emailAddress,
              created_profile_sender: ""
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`
              }
            }
          ),
          axios.post(
            `${hostApi}/viewEvent`,
            {
              id: 0,
              event_type: "Activity",
              idea_id: "",
              event_description: "",
              created_profile_recipient: emailAddress,
              created_profile_sender: ""
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`
              }
            }
          )
        ])
        .then(([myIdeas, myWallet, inboxNotification, inboxActivity]) => {
          // myCollaborations,
          const myIdeasList = myIdeas.data.map(idea => ({
            ideaName: idea.idea_name,
            ideaId: idea.idea_id,
            equitySplit: idea.tokens_owned,
            numberOfCollaborators: idea.no_of_collaborators,
            ideaStatus: idea.idea_status,
            lkpIdeaCat1: idea.lkpIdeaCat1,
            ideaDescription: idea.ideaDescription,
            tokenId: idea.tokenId,
            allowSearch: idea.allowSearch,
            IdeaDateCreated: idea.date_created,
            coverPhoto: idea.coverPhoto,
            coverPhotoUrl: idea.coverPhotoUrl //New variables added for cover photo
          }));
          // axios
          //   .all([
          //     ...myIdeasList.map(({ ideaId: idea_ID }) =>
          //       axios.post(
          //         `${hostApi}/getCollaboratorsByIdea`,
          //         { idea_ID },
          //         {
          //           headers: {
          //             Authorization: `Bearer ${this.props.cookies.get("bearerToken")}`
          //           }
          //         }
          //       )
          //     )
          //   ])
          //   .then((...collaboratorsList) => {
          //       let collaboratorsByIdeaList = collaboratorsList[0].map(
          //         collaborators => {
          //           return collaborators.data;
          //         }
          //       );
          //       if (collaboratorsByIdeaList.length) {
          //         collaboratorsByIdeaList = collaboratorsByIdeaList.reduce(
          //           (a, b) => a.concat(b) // This will flatten the array e.g. [[1], [2,3]] will be [1,2,3]
          //         );
          //       }
          // axios.get(
          //   `${hostApi}/collaborations/invitation/1056?pending=collaborator`,
          //   {
          //     headers: {
          //       Authorization: `Bearer ${jwtToken}`
          //     }
          //   }
          // )
          axios
            .get(
              `${hostApi}/collaborations/invitation/${this.props.cookies.get(
                "profile_id"
              )}?pending=ideator`,
              {
                headers: {
                  Authorization: `Bearer ${jwtToken}`
                }
              }
            )
            .then(response => {
              console.log("PENDING+IDEATOR:: ", response.data);
              this.setState({
                myIdeas: myIdeasList,
                myWallet: myWallet.data,
                // collaboratorsByIdeaList: collaboratorsByIdeaList,
                collaboratorsByIdeaList: response.data,
                // myCollaborations: myCollaborations.data,
                inbox: [...inboxNotification.data, ...inboxActivity.data].sort(
                  (a, b) => b.id - a.id
                )
              });
            });
        });
      // });
    }
  }

  showCollaboratorsByIdea = idea_ID => {
    axios
      .post(
        `${hostApi}/getCollaboratorsByIdea`,
        { idea_ID },
        {
          headers: {
            Authorization: `Bearer ${this.props.cookies.get("bearerToken")}`
          }
        }
      )
      .then(({ data }) => {
        console.log("///////////////////", data);
        this.setState({ myCollaborationsCollaboratorsByIdeaId: data });
      });
  };

  navigateToIdea = (ideaId, _userType) => {
    const { profileID } = this.props;
    const { myIdeas, collabIdeas } = this.state;

    if (_userType === COLLABORATOR) {
      collabIdeas.find(idea => {
        if (idea.ideaId === ideaId) {
          this.setState({ ideaDashData: { ...idea }, userType: _userType });
        } else {
          console.log("Could not find idea");
        }
        return idea.ideaId === ideaId; //this return check makes the find function exit when true.
      });

      this.setState({ openIdeaDashLite: true });

      axios
        .post(
          `${hostApi}/getAllIdeaFiles`,
          {
            ideaUniqueID: ideaId
          },
          {
            headers: {
              Authorization: `Bearer ${this.props.cookies.get("bearerToken")}`
            }
          }
        )
        .then(response => {
          if (response.status === 200) {
            this.setState({ selectedIdeaFiles: response.data });
          }
        });
    } else {
      console.log("MyIdeas", myIdeas);
      myIdeas.find(idea => {
        if (idea.ideaId === ideaId) {
          this.setState({ ideaDashData: { ...idea }, userType: IDEATOR });
        } else {
          console.log("Couldn't find idea");
        }
        return idea.ideaId === ideaId; //this return check makes the find function exit when true.
      });
      this.setState({ openIdeaDash: true });
      this.props.showCardView();
      this.props.setIdeaToEdit(ideaId);
    }
    // if(_userType === IDEATOR){
    // } else {
    //   this.setState({ openIdeaDashLite: true });
    // }
    //}
  };

  handleCloseIdeaDash = () => {
    this.setState({ openIdeaDash: false });
  };

  handleCloseIdeaDashLite = () => {
    this.setState({ openIdeaDashLite: false });
  };

  handleSearchTerm = (term, type) => {
    this.setState({ [type]: term });
  };

  handleSearchMyIdea = e => {
    const { value } = e.target;
    this.handleSearchTerm(value, "myIdeaSearchTerm");
  };

  handleSearchMyCollaborators = e => {
    const { value } = e.target;
    this.handleSearchTerm(value, "myCollaboratorsTerm");
  };

  handleSearchMyInbox = e => {
    const { value } = e.target;
    this.handleSearchTerm(value, "myInboxSearchTerm");
  };

  ////
  expand = () => {
    console.log("EXPAND THIS");
  };

  getMyCollaborations = () => {
    const { myCollaboratorsTerm, myIdeas } = this.state;

    // filter ideas by search term
    if (myCollaboratorsTerm.length) {
      const searchSettings = {
        keys: ["ideaName", "ideaId"]
      };
      const searchResult = new Fuse(myIdeas, searchSettings);
      return searchResult.search(myCollaboratorsTerm);
    }

    return myIdeas;
  };

  getMyIdeas = () => {
    const { myIdeaSearchTerm, myIdeas } = this.state;

    // filter ideas by search term
    if (myIdeaSearchTerm.length) {
      const searchSettings = {
        keys: ["ideaName", "ideaId"]
      };
      const searchResult = new Fuse(myIdeas, searchSettings);
      return searchResult.search(myIdeaSearchTerm);
    }

    return myIdeas;
  };

  getInbox = () => {
    const { myInboxSearchTerm, inbox } = this.state;

    // filter ideas by search term
    if (myInboxSearchTerm.length) {
      const searchSettings = {
        keys: [
          "created_profile_recipient",
          "created_profile_sender",
          "description",
          "firstname",
          "title",
          "surname"
        ]
      };
      const searchResult = new Fuse(inbox, searchSettings);
      return searchResult.search(myInboxSearchTerm);
    }

    return inbox;
  };

  forceUpdateHandler = () => {
    console.log("FORCED UPDATE");
    this.getData(
      this.props.emailAddress,
      this.props.cookies.get("bearerToken")
    );
  };

  render() {
    const { cookies, userType } = this.props;
    const _userType = userType || cookies.get("user_type");
    const {
      inbox,
      myIdeas,
      myWallet,
      collaborationWallet,
      myCollaborationsCollaboratorsByIdeaId,
      collaboratorsByIdeaList,
      myIdeaSearchTerm,
      myInboxSearchTerm,
      myCollaboratorsTerm
    } = this.state;

    const {
      handleSearchMyIdea,
      handleSearchMyInbox,
      handleSearchMyCollaborators,
      getMyIdeas,
      getInbox,
      getMyCollaborations
    } = this;

    let myIdeasSearchInputPropList = {
      placeholder: "Search ideas",
      value: myIdeaSearchTerm,
      handleSearchTerm: handleSearchMyIdea
    };

    if (_userType && _userType === COLLABORATOR) {
      myIdeasSearchInputPropList.placeholder = "Search my collaborations";
    }

    const myCollaboratorsSearchInputPropList = {
      placeholder: "Search collaborators",
      value: myCollaboratorsTerm,
      handleSearchTerm: handleSearchMyCollaborators
    };

    const myIdeasSearchInboxPropList = {
      placeholder: "Search inbox",
      value: myInboxSearchTerm,
      handleSearchTerm: handleSearchMyInbox
    };

    return (
      <>
        <div className="dashboard-wrapper">
          <div className="dashboard__item--my-profile">
            <div className="dashboard__title-wrapper">
              <div className="dashboard__title">
                <span>MY Profile</span>
                <IconButtonComponent
                  type={"edit"}
                  toolTip={true}
                  title={"Edit Profile"}
                  link={this.props.launchEditProfile}
                  iconStyle={"iconDefault"}
                />
              </div>
            </div>
            <div className="dashboard__body">
              <MyProfile jwtToken={this.props.cookies.get("bearerToken")} />
            </div>
          </div>
          <div className="dashboard__item--my-wallet-collab">
            <div className="dashboard__title-wrapper">
              <div className="dashboard__title">
                <span>My Wallet</span>
                <span>
                  <IconButtonComponent
                    type={"help"}
                    toolTip={true}
                    title={"Wallet Help"}
                    iconStyle={"iconDefault"}
                    alert={true}
                    alertTitle={"Wallet"}
                    intent={"help"}
                    message={
                      "Here you can view the total amount of equity you have in your own and others’ ideas."
                    }
                  />
                </span>
              </div>
            </div>
            <div className="dashboard__body">
              <MyWallet
                collaborationWallet={this.state.collaborationWallet}
                myWallet={this.state.myWallet}
                numberOfIdeas={myIdeas.length}
              />
            </div>
          </div>
          <div className="dashboard__item--my-ideas">
            <div className="dashboard__title-wrapper">
              <div className="dashboard__title">
                <span>My Ideas</span>
                <span>
                  <IconButtonComponent
                    type={"create"}
                    toolTip={true}
                    title={"Create Idea"}
                    link={this.props.launchCreateIdea}
                    iconStyle={"iconDefault"}
                  />
                </span>
              </div>
            </div>
            <div className="dashboard__body">
              <IdeaList
                myIdeas={this.props.myIdeaSummaryData}
                navigateToIdea={this.navigateToIdea}
              />
            </div>
            {/* Expand  / collapse idea*/}
            {/* <div onClick={() => this.expand()}>+</div> */}
          </div>

          <div className="dashboard__item--rtwo-ideas">
            <div className="dashboard__title-wrapper">
              <div className="dashboard__title">
                <span>Collaborator Requests</span>
                <span>
                  <IconButtonComponent
                    type={"help"}
                    toolTip={true}
                    title={"Request to work on idea Help"}
                    iconStyle={"iconDefault"}
                    alert={true}
                    alertTitle={"Request to work on idea help"}
                    intent={"help"}
                    message={
                      "Here you can view requests from users to work on your ideas."
                    }
                  />
                </span>
              </div>
            </div>
            <div className="dashboard__body">
              <InviteListRTWOI
                jwtToken={this.props.cookies.get("bearerToken")}
                cookies={this.props.cookies}
                invitationDataRTWOI={collaboratorsByIdeaList}
                updateData={this.forceUpdateHandler}
              />
            </div>
          </div>

          <div className="dashboard__item--my-collaborations">
            <div className="dashboard__title-wrapper">
              <div className="dashboard__title">
                <span>My Collaborations</span>
                <span>
                  <IconButtonComponent
                    type={"help"}
                    toolTip={true}
                    title={"Collaboration Help"}
                    iconStyle={"iconDefault"}
                    alert={true}
                    alertTitle={"My Collaborations"}
                    intent={"help"}
                    message={
                      "Here you can view collaborations with ideas that are not your own. "
                    }
                  />
                </span>
              </div>
            </div>
            <div className="dashboard__body">
              <IdeaList
                collaborator={true}
                myIdeas={this.state.collabIdeas}
                navigateToIdea={this.navigateToIdea}
              />
            </div>
          </div>

          <div className="dashboard__item--collab-request">
            <div className="dashboard__title-wrapper">
              <div className="dashboard__title">
                <span>Ideator Requests</span>
                <span>
                  <IconButtonComponent
                    type={"help"}
                    toolTip={true}
                    title={"Collaboration Help"}
                    iconStyle={"iconDefault"}
                    alert={true}
                    alertTitle={"My Collaborations"}
                    intent={"help"}
                    message={
                      "Here you can view requests from users to work on their ideas."
                    }
                  />
                </span>
              </div>
            </div>
            <div className="dashboard__body">
              <InviteList
                jwtToken={this.props.cookies.get("bearerToken")}
                invitationData={this.props.invitationData}
                updateData={this.axiosGetMyCollaborations}
              />
            </div>
          </div>
        </div>
        <IdeaDash
          jwtToken={this.props.cookies.get("bearerToken")}
          open={this.state.openIdeaDash}
          handleClose={this.handleCloseIdeaDash}
          startChatWithPerson={this.props.startChatWithPerson}
          data={this.state.ideaDashData}
          userType={this.state.userType}
        />
        <IdeaDashLite
          jwtToken={this.props.cookies.get("bearerToken")}
          open={this.state.openIdeaDashLite}
          handleClose={this.handleCloseIdeaDashLite}
          startChatWithPerson={this.props.startChatWithPerson}
          data={this.state.ideaDashData}
        />
        <CollabProfile />
        <CreateIdeaModal
          title={"Create Idea"}
          centered={true}
          size="sm"
          updateData={this.forceUpdateHandler}
        />
      </>
    );
  }
}

// emailAddress
const mapStateToProps = state => ({
  emailAddress: state.emailAddress,
  userType: state.userType,
  profileID: state.profileID,
  invitationData: state.invitationData,
  invitationDataRTWOI: state.invitationDataRTWOI,
  myIdeaSummaryData: state.myIdeaSummaryData
  //token: state.jwtToken
});

const mapDispatchToProps = dispatch => ({
  dispatchAuth: (type, profileID) => {
    dispatchAuth(type, profileID, dispatch);
  },
  getInvitations: (jwtToken, profileID, filter, callback) => {
    getInvitations(jwtToken, profileID, filter, callback, dispatch);
  },
  getInvitationsRTWOI: (jwtToken, profileID, filter, callback) => {
    getInvitationsRTWOI(jwtToken, profileID, filter, callback, dispatch);
  },
  getMyIdeasForDashBoard: (jwtToken, emailAddress) => {
    getMyIdeasForDashBoard(jwtToken, emailAddress, dispatch);
  },
  setIdeaToEdit: payload => dispatch(setIdeaToEdit(payload)),
  startChatWithPerson: payload => dispatch(startChatWithPerson(payload)),
  showCardView: payload => dispatch(showCardView()),
  launchAlert: payload => dispatch(launchAlert(payload)),
  launchCreateIdea: () => {
    dispatch(launchCreateIdea());
  },
  launchEditProfile: () => {
    dispatch(launchEditProfile());
  }
});

export default withRouter(
  withCookies(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(styles)(Dashboard))
  )
);
