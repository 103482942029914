import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CollaborateIdeasIcon from "../../static/collaborateIdeasIcon.png";
import DevelopIdeasIcon from "../../static/developIdeasIcon.png";
import FundIdeasIcon from "../../static/fundIdeasIcon.png";
import UploadIdeasIcon from "../../static/uploadIdeasIcon.png";

const styles = theme => ({
    root: {
      flexGrow: 1
    },

    howContainer: {
        display: "grid",
        height: "auto",
        width: "600px",
        height: "140px",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "auto auto",
        [theme.breakpoints.down("sm")]: {
            gridTemplateRows: "auto",
            width: "auto",          
        },
        [theme.breakpoints.down("xs")]: {
            gridTemplateRows: "auto",
            width: "100vw",          
        },
    },

    howItem: {
        color: "#383736",
        width: "auto",
        margin: "0px 0px 0px 0px",
        padding: "10px",
        verticalAlign: "middle",
        [theme.breakpoints.down("xs")]: {
            backgroundColor: "blue"         
        },
    },

    howText: {
        width: "auto",
      },

    firstItem: {
        gridArea: "1 / 1 / 1 / 1",
    },

    secondItem: {
        gridArea: "1 / 2 / 1 / 2",
    },
});

class howItem extends React.Component {

        render() {
            const {classes, reverse} = this.props;

            const getImage = category => {
                switch (category) {
                  case "CollaborateIdeaIcon":
                    return CollaborateIdeasIcon;
                  case "DevelopIdeaIcon":
                    return DevelopIdeasIcon;
                  case "FundIdeaIcon":
                    return FundIdeasIcon;
                    case "UploadIdeaIcon":
                    return UploadIdeasIcon;
                  default:
                    return UploadIdeasIcon;
                }
              };


            return(

                <div className={classes.howContainer}>
                    <div className={`${classes.howItem} ${reverse ? classes.firstItem : classes.secondItem}`}>
                        <p className={classes.howText}>{this.props.text}</p>
                    </div> 
                    <div className={`${classes.howItem} ${reverse ? classes.secondItem : classes.firstItem}`}>
                        <img
                            src={getImage(this.props.image)}
                            width={80}
                        />
                    </div>
                </div>                
            );
        };
    }

    howItem.propTypes = {
        classes: PropTypes.object.isRequired
      };

      export default withRouter(
        (withStyles(styles)(howItem))
      );