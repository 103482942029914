import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import FaceIcon from "@material-ui/icons/Face";
import AddIcon from "@material-ui/icons/Add";
import { Fab } from "@material-ui/core";
import PersonAdd from "@material-ui/icons/PersonAdd";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      minWidth: "500px"
    }
  },
  listItem: {
    cursor: "pointer"
  },
  listItemActive: {
    backgroundColor: "var(--orange)"
  }
});

function AllSprxsUsers({ classes, userList, launchChat }) {
  return (
    <div className={classes.root}>
      <List>
        {userList.map((item, i) => {
          return (
            <ListItem
              key={i}
              onClick={event => {
                launchChat(item);
              }}
              className={
                item.active ? classes.listItemActive : classes.listItem
              }
            >
              <ListItemText primary={`${item.firstName} ${item.lastName}`} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

AllSprxsUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  launchChat: PropTypes.func.isRequired
};

export default withStyles(styles)(AllSprxsUsers);
