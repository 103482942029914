import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Badge from "@material-ui/core/Badge";
import ChatUser from "./ChatUser";

/* test data */
const userList = [
  {
    name: "Andy",
    id: "0x12ed"
  },
  {
    name: "Asad",
    id: "0x12ed"
  },
  {
    name: "Akib",
    id: "0x12ed"
  }
];

function TabContainer(props) {
  return (
    <Fade in={true} timeout={500}>
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    </Fade>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 500
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 0}px `
  }
});

class ChatMenu extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  launchChat = (event, value) => {
    console.log("launchChat value", value);
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <Paper square className={classes.root}>
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          fullWidth
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            label={
              <Badge
                className={classes.padding}
                color="primary"
                badgeContent={4}
              >
                ONLINE CHATS
              </Badge>
            }
          />
          <Tab
            label={
              <Badge
                className={classes.padding}
                color="primary"
                badgeContent={4}
              >
                CHAT HISTORY
              </Badge>
            }
          />
        </Tabs>

        {value === 0 && (
          <TabContainer>
            <ChatUser userList={userList} />
          </TabContainer>
        )}
        {value === 1 && <TabContainer>Page Two</TabContainer>}
      </Paper>
    );
  }
}

ChatMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChatMenu);
