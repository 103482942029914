import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core";
import { CheckCircle, Error, Help } from "@material-ui/icons";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import { closeTimeout } from "../../store/actions/actions";
import FormHeader from "../FormHeader/FormHeader";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
    textAlign: "center"
  },
  successIcon: {
    fontSize: 70,
    color: green[500]
  },
  errorIcon: {
    fontSize: 70,
    color: red[500]
  },
  helpIcon: {
    fontSize: 70,
    color: green[500]
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AlertSessionTimeout extends React.Component {
  state = {};

  returnIcon = intent => {
    const { classes } = this.props;
    switch (intent) {
      case "success":
        return <CheckCircle className={classes.successIcon} />
      case "help":
        return <Help className={classes.helpIcon} />
    
      default:
      return <Error className={classes.errorIcon} />
    }
  }

  render() {
    const { classes, open, onClose, title, message } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
          onClose={onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <FormHeader>Session Timed Out</FormHeader>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              You have been logged out because your session has expired.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: state.launchTimeout
});

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => dispatch(closeTimeout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AlertSessionTimeout));
