import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Particles from "react-particles-js";
import "./MyParticles.css";
import {spark} from "../../static/spark.svg";

export class MyParticles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animation: true
    };

  }

  render() {
    console.log("this.props.animation",this.props.animation)
    const animate = this.props.animation || true;
    return (
        <div>
      {animate &&
        <Particles
          className="particle-style"
          params={{
            particles: {
              number: {
                value: 4,
                density: {
                  enable: true,
                  value_area: 800
                }
              },
              color: {
                value: "#ff512f"
              },
              shape: {
                type: "image",
                stroke: {
                  width: 0,
                  color: "#ff512f"
                },
                image: {
                  src: "img/spark.svg",
                  width: 20,
                  height: 20
                },
                polygon: {
                  nb_sides: 5
                }
                
              },
              opacity: {
                value: 0.5,
                random: false,
                anim: {
                  enable: false,
                  speed: 0.5,
                  opacity_min: 0.1,
                  sync: false
                }
              },
              size: {
                value: 6,
                random: true,
                anim: {
                  enable: false,
                  speed: 5,
                  size_min: 0.1,
                  sync: false
                }
              },
              line_linked: {
                enable: false,
                distance: 10,
                color: "#080705",
                opacity: 1,
                width: 2
              },
              move: {
                enable: true,
                speed: 2,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200
                }
              }
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: true,
                  mode: "bubble"
                },
                onclick: {
                  enable: false,
                  mode: "push"
                },
                resize: true
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1
                  }
                },
                bubble: {
                  distance: 100,
                  size: 8,
                  duration: 1.5,
                  opacity: 0.8,
                  speed: 2
                },
                repulse: {
                  distance: 200,
                  duration: 0.4
                },
                push: {
                  particles_nb: 4
                },
                remove: {
                  particles_nb: 2
                }
              }
            },
            retina_detect: true
          }}
        />
      }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    animation: state.animation
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyParticles);
