import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import ChatInput from "../../components/ChatInput/ChatInput";
import ChatMessages from "../../components/ChatMessages/ChatMessages";
import { withStyles } from "@material-ui/core/styles";
import RootRef from "@material-ui/core/RootRef";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
    height: "-webkit-fill-available",
    overflowY: "scroll",
    border: "none"
  }
});

class ChatMessageFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      chatLoc: [],
      messages: []
    };
    this.domRef = React.createRef();
  }

  handleSendMessage = (message, type = "text") => {
    const { uid, chatID } = this.props;

    if (!uid) return;

    var currentUser = uid;
    const dateTime = Date.now();
    const timestamp = Math.floor(dateTime / 1000);
    var value = {
      type,
      content: message,
      fromID: currentUser /*currentUserID*/,
      toID: chatID.userId /*toID*/,
      timestamp: timestamp,
      isRead: false
    };
    this.props.addChatMessage(value);
  };

  componentDidUpdate() {
    //console.log("componentDidUpdate");
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    //this.myRef.current.scrollIntoView({ behavior: "smooth" });
    //this.myRef.current.focus();
    const ref = this.domRef.current;
    if (ref) {
      const scrollHeight = ref.scrollHeight;
      const height = ref.clientHeight;
      const maxScrollTop = scrollHeight - height;
      ReactDOM.findDOMNode(ref).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  render() {
    const { classes } = this.props;

    this.scrollToBottom();

    return (
      <div>
        <RootRef rootRef={this.domRef}>
          <div className={classes.root}>
            <ChatMessages messageList={this.props.chatMessageList} />
          </div>
        </RootRef>
        <ChatInput sendMessage={this.handleSendMessage} />
      </div>
    );
  }
}

ChatMessageFeed.propTypes = {
  fullScreen: PropTypes.bool.isRequired
  // open: PropTypes.bool.isRequired
  // onClose: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    uid: state.firebaseUid
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withMobileDialog()(ChatMessageFeed))
);
