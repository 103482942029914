import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import person from "../../static/collaborator.png";
import { withStyles } from "@material-ui/core/styles";
import "./MyProfile.css";
import {
  Typography,
  Paper,
  CardHeader,
  IconButton,
  CardContent,
  TextField,
  Divider,
  Tooltip
} from "@material-ui/core";
import { Translate, Edit, Save, Cancel, Add } from "@material-ui/icons";
import { getCollaborator, putCollaborator } from "../../store/actions/actions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import StarIcon from "@material-ui/icons/Star";
import DeleteIcon from "@material-ui/icons/Delete";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import { deep_copy } from "../../utility/utility_funcs";
import country_codes from "./country_codes.json";
import { withCookies } from "react-cookie";
import {
  COLLABORATOR,
  ExpertiseMap,
  primaryExpertise,
  timeOut
} from "../../constants";
import { isNull } from "util";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    textAlign: "left",
    position: "relative"
  },
  menu: {
    width: 200
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  textFieldBio: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: "100%"
  },
  textFieldAdd: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit * 5
  },
  linearColorPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  linearBarColorPrimary: {
    backgroundColor: theme.palette.secondary.main
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  iconButton: {
    marginBottom: theme.spacing.unit * 2
  },
  rightAlign: {
    textAlign: "left",
    minWidth: "30%"
  }
});

const experience = [
  {
    value: 1,
    label: "Beginner"
  },
  {
    value: 2,
    label: "Intermediate"
  },
  {
    value: 3,
    label: "Expert"
  }
];

function getRating(rating) {
  switch (rating) {
    case 2:
      return (
        <Tooltip title={"Intermediate"}>
          <span>
            <IconButtonComponent
              type={"star"}
              iconStyle={"iconDefault iconCodeBlue"}
            />
            <IconButtonComponent
              type={"star"}
              iconStyle={"iconDefault iconCodeBlue"}
            />
            <IconButtonComponent
              type={"starBorder"}
              iconStyle={"iconDefault iconCodeBlue"}
            />
          </span>
        </Tooltip>
      );

    case 3:
      return (
        <Tooltip title={"Expert"}>
          <span>
            <IconButtonComponent
              type={"star"}
              iconStyle={"iconDefault iconCodeBlue"}
            />
            <IconButtonComponent
              type={"star"}
              iconStyle={"iconDefault iconCodeBlue"}
            />
            <IconButtonComponent
              type={"star"}
              iconStyle={"iconDefault iconCodeBlue"}
            />
          </span>
        </Tooltip>
      );

    default:
      //includes rating of 1
      return (
        <Tooltip title={"Beginner"}>
          <span>
            <IconButtonComponent
              type={"star"}
              iconStyle={"iconDefault iconCodeBlue"}
            />
            <IconButtonComponent
              type={"starBorder"}
              iconStyle={"iconDefault iconCodeBlue"}
            />
            <IconButtonComponent
              type={"starBorder"}
              iconStyle={"iconDefault iconCodeBlue"}
            />
          </span>
        </Tooltip>
      );
  }
}

class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  static propTypes = {
    prop: PropTypes
  };

  componentDidMount(props) {
    const { collaboratorData } = this.props;
    const { cookies, profileID } = this.props;

    const _collabID = profileID ? profileID : cookies.get("profile_id");
    if (_collabID !== undefined || _collabID !== isNull) {
      this.props.getCollaborator(this.props.jwtToken, _collabID);
    }
  }
  componentDidUpdate = () => {};

  displaySkills = () => {
    const { classes, collaboratorData } = this.props;

    return (
      <div className="my-profile__card">
        <p className="my-profile__card-header">Skills</p>
        <p className="my-profile__card-body">
          <List dense={true}>
            {collaboratorData && // check that there is some data
            collaboratorData.skills && // and that it contains a skills section
              collaboratorData.skills.map((item, key) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography className="my-profile__card-skill">
                          {item.skillName}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      {getRating(item.skillLevel)}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        </p>
      </div>
    );
  };

  displayBio = () => {
    const { classes, collaboratorData } = this.props;

    return (
      <div className="my-profile__card">
        <p className="my-profile__card-header">Professional Bio</p>
        <p className="my-profile__card-body">{collaboratorData.profileDesc}</p>
      </div>
    );
  };

  displayEdu = () => {
    const { classes, collaboratorData } = this.props;
    return (
      <>
        <div className="my-profile__card">
          <p className="my-profile__card-header">Education</p>
          <p className="my-profile__card-body">
            <List dense={true}>
              {collaboratorData && // check that there is some data
              collaboratorData.educations && // and that it contains an educations section
                collaboratorData.educations.map((item, key) => {
                  return (
                    <>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography className="my-profile__card-header">
                              {item.major}
                            </Typography>
                          }
                        />
                        <div className={classes.rightAlign}>
                          <Typography variant="caption">
                            {item.schoolName}
                          </Typography>
                          <Typography variant="caption">
                            {item.country}
                          </Typography>
                          <Typography variant="caption">
                            {item.qualification}
                          </Typography>
                          <Typography variant="caption">{`Graduated: ${item.year}`}</Typography>
                        </div>
                      </ListItem>
                    </>
                  );
                })}
            </List>
          </p>
        </div>
      </>
    );
  };

  displayWeblinks = () => {
    const { classes, collaboratorData } = this.props;
    return (
      <>
        <div className="my-profile__card">
          <p className="my-profile__card-header">Web Links</p>
          <p className="my-profile__card-body">
            <List dense={true}>
              {collaboratorData && // check that there is some data
              collaboratorData.weblinks && // and that it contains an educations section
                collaboratorData.weblinks.map((item, key) => {
                  return (
                    <>
                      <ListItem>
                        <a
                          href={`http://${item.url}`}
                          target="blank"
                        >{`http://${item.url}`}</a>
                        <div
                          id={"permalink_section"}
                          className={classes.rightAlign}
                        ></div>
                      </ListItem>
                    </>
                  );
                })}
            </List>
          </p>
        </div>
      </>
    );
  };

  displayExpertise = () => {
    const { classes, collaboratorData } = this.props;
    return (
      <>
        <p className="my-profile__primaryExpertise">
          {collaboratorData.primaryExpertise === 7
            ? collaboratorData.primaryOther
            : ExpertiseMap[collaboratorData.primaryExpertise]}
        </p>
        <p className="my-profile__secondaryExpertise">
          {collaboratorData.secondaryExpertise === 7
            ? collaboratorData.secondaryOther
            : ExpertiseMap[collaboratorData.secondaryExpertise]}
        </p>
      </>
    );
  };

  render() {
    const { isLoading } = this.state;
    const { classes, collaboratorData } = this.props;
    const { firstname, surname } = collaboratorData;

    return !isLoading ? (
      <div className={"my-profile"}>
        <div className="my-profile__header">
          <span className="my-profile__public">
            {collaboratorData.hidden ? (
              <IconButtonComponent
                toolTip={true}
                type={"locked"}
                iconStyle={"iconDisabled"}
                title={"Profile Private"}
              />
            ) : (
              <IconButtonComponent
                toolTip={true}
                type={"public"}
                iconStyle={"iconDisabled"}
                title={"Profile Public"}
              />
            )}
          </span>
          <Avatar alt="avatar" src={person} className="avatar" />
          <p className="my-profile__name">
            {firstname && `${firstname} ${surname}`}
          </p>
          {this.displayExpertise()}
        </div>
        {this.displayBio()}
        {this.displaySkills()}
        {this.displayWeblinks()}
        {this.displayEdu()}
      </div>
    ) : (
      <div>Loading your profile...</div>
    );
  }
}

const mapStateToProps = state =>
  //console.log(state.name, "-----------"),
  ({
    collaboratorData: state.collaboratorData,
    profileID: state.profileID,
    name: state.name
  });

const mapDispatchToProps = dispatch => ({
  getCollaborator: (jwtToken, profileID) => {
    getCollaborator(jwtToken, profileID, dispatch);
  },

  putCollaborator: (jwtToken, payload, callback) => {
    putCollaborator(jwtToken, payload, callback, dispatch);
  }
});

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyProfile))
);
