const skillList = [
  {
    skillName: "Developer",
    skillIcon: "embed2"
  },
  {
    skillName: "Legal",
    skillIcon: "hammer2"
  },
  {
    skillName: "Blockchain",
    skillIcon: "library"
  },
  {
    skillName: "Finance",
    skillIcon: "coin-dollar"
  },
  {
    skillName: "Other",
    skillIcon: "question"
  },
  {
    skillName: "Category",
    skillIcon: "svg"
  },
  {
    skillName: "Category",
    skillIcon: "svg"
  },
  {
    skillName: "Category",
    skillIcon: "svg"
  },
  {
    skillName: "Category",
    skillIcon: "svg"
  },
  {
    skillName: "Category",
    skillIcon: "svg"
  }
];
const collaboratorList = [
  {
    _id: "5c4e7ea7bbe770c7a9412b0d",
    guid: "7b5edfc3-3942-464b-addf-8ace29b3e598",
    picture: "https://picsum.photos/200/80/?image=78",
    name: {
      first: "Christensen",
      last: "Petersen"
    },
    about:
      "enim dolor exercitation elit deserunt elit ex officia laboris fugiat mollit quis sit adipisicing velit commodo sunt",
    email: "christensen.petersen@undefined.tv",
    skill: "blockchain"
  },
  {
    _id: "5c4e7ea7b51be721bf5468b4",
    guid: "c1d3839f-eb30-45ab-bce7-2b0a2d6591f2",
    picture: "https://picsum.photos/200/80/?image=172",
    name: {
      first: "Austin",
      last: "Kane"
    },
    about:
      "Lorem commodo reprehenderit est do est laborum aliquip mollit eu eu ullamco reprehenderit dolore aliquip eu veniam",
    email: "austin.kane@undefined.biz",
    skill: "blockchain"
  },
  {
    _id: "5c4e7ea7b5a00721e663d5b9",
    guid: "f59815c4-4990-43d7-be57-36a6c208ed91",
    picture: "https://picsum.photos/200/80/?image=139",
    name: {
      first: "Padilla",
      last: "Foley"
    },
    about:
      "laboris magna deserunt sit laboris voluptate esse mollit fugiat proident exercitation dolore sunt cillum commodo consectetur proident",
    email: "padilla.foley@undefined.org",
    skill: "developer"
  },
  {
    _id: "5c4e7ea745653caecfb118c3",
    guid: "028cdbe6-3eba-4ee5-ab2c-2a6b33fbf599",
    picture: "https://picsum.photos/200/80/?image=157",
    name: {
      first: "Fields",
      last: "Wong"
    },
    about:
      "laboris voluptate laborum eiusmod fugiat laboris dolor in est est occaecat elit officia labore eu ad id",
    email: "fields.wong@undefined.name",
    skill: "legal"
  },
  {
    _id: "5c4e7ea7e5f2c0ea9ec22348",
    guid: "847f8f47-c956-4500-89a9-b3d730ad8845",
    picture: "https://picsum.photos/200/80/?image=34",
    name: {
      first: "Latasha",
      last: "Chase"
    },
    about:
      "sunt officia labore sit proident aliquip tempor dolor incididunt aliquip ex mollit aute occaecat in eu irure",
    email: "latasha.chase@undefined.me",
    skill: "finance"
  },
  {
    _id: "5c4e7ea7b739f3cd5a5820c7",
    guid: "2e68e293-536a-4632-86e2-c261b82f22d8",
    picture: "https://picsum.photos/200/80/?image=143",
    name: {
      first: "Peterson",
      last: "Salas"
    },
    about:
      "veniam ex sit aliqua ad id occaecat amet reprehenderit nostrud proident in non nostrud velit aute eiusmod",
    email: "peterson.salas@undefined.us",
    skill: "finance"
  },
  {
    _id: "5c4e7ea7d30390e9a90e8815",
    guid: "42864ae9-f1ef-40b8-9abe-66ba1eb1f9d4",
    picture: "https://picsum.photos/200/80/?image=45",
    name: {
      first: "Bell",
      last: "Faulkner"
    },
    about:
      "magna ea tempor ullamco reprehenderit proident duis esse eiusmod magna adipisicing duis velit sit laborum duis labore",
    email: "bell.faulkner@undefined.info",
    skill: "other"
  },
  {
    _id: "5c4e7ea78264dede91476264",
    guid: "a551be8b-ec3b-4230-ac82-377963e1a37b",
    picture: "https://picsum.photos/200/80/?image=87",
    name: {
      first: "Ortiz",
      last: "Stevenson"
    },
    about:
      "commodo ut tempor pariatur culpa consectetur consectetur quis ullamco qui elit nisi nulla Lorem mollit nostrud ad",
    email: "ortiz.stevenson@undefined.com",
    skill: "blockchain"
  },
  {
    _id: "5c4e7ea783db645ae030eec4",
    guid: "35b4a7f2-0485-4e06-85e7-0b247bc0139e",
    picture: "https://picsum.photos/200/80/?image=37",
    name: {
      first: "Deana",
      last: "Casey"
    },
    about:
      "ea consequat deserunt amet reprehenderit excepteur esse ea in ad exercitation do in aliqua dolor tempor qui",
    email: "deana.casey@undefined.net",
    skill: "finance"
  },
  {
    _id: "5c4e7ea79982c74da99f9bb4",
    guid: "1b12fe25-dfef-41c4-a2e1-9ca4c5e99066",
    picture: "https://picsum.photos/200/80/?image=140",
    name: {
      first: "Candice",
      last: "Foreman"
    },
    about:
      "incididunt nulla aute tempor incididunt non fugiat incididunt ea veniam culpa non et nostrud sint in sunt",
    email: "candice.foreman@undefined.co.uk",
    skill: "developer"
  },
  {
    _id: "5c4e7ea73353c29c4af017e3",
    guid: "d69aec92-c369-4948-b137-73bf7f7643b3",
    picture: "https://picsum.photos/200/80/?image=31",
    name: {
      first: "Reese",
      last: "Evans"
    },
    about:
      "minim commodo elit irure minim excepteur fugiat nostrud sint ullamco deserunt cillum eu nostrud sit nisi voluptate",
    email: "reese.evans@undefined.ca",
    skill: "legal"
  },
  {
    _id: "5c4e7ea74b6dd4f1ba8d3b67",
    guid: "e1590201-009e-4ae8-8565-22d19a6ea059",
    picture: "https://picsum.photos/200/80/?image=190",
    name: {
      first: "Doreen",
      last: "Wright"
    },
    about:
      "adipisicing voluptate officia esse Lorem amet quis magna cupidatat ad ut dolor sint consequat esse magna ad",
    email: "doreen.wright@undefined.biz",
    skill: "blockchain"
  },
  {
    _id: "5c4e7ea7fd7a0ffb2119ea13",
    guid: "450806e3-7d9a-4faa-b9aa-51c541c5abd4",
    picture: "https://picsum.photos/200/80/?image=156",
    name: {
      first: "Fulton",
      last: "Webb"
    },
    about:
      "commodo qui aute cupidatat velit cupidatat commodo laboris amet aute occaecat est duis occaecat voluptate id cillum",
    email: "fulton.webb@undefined.tv",
    skill: "finance"
  },
  {
    _id: "5c4e7ea70ea76e19d145d851",
    guid: "e7832acd-b8db-4f65-84b5-dbe00a62a76c",
    picture: "https://picsum.photos/200/80/?image=66",
    name: {
      first: "Aisha",
      last: "Pittman"
    },
    about:
      "laborum veniam cupidatat amet nisi magna voluptate ad minim occaecat consectetur ex anim minim et dolore consequat",
    email: "aisha.pittman@undefined.biz",
    skill: "finance"
  },
  {
    _id: "5c4e7ea71665314ba0ccd2b4",
    guid: "96935781-85b8-489b-af8c-2e7c8181f9a6",
    picture: "https://picsum.photos/200/80/?image=174",
    name: {
      first: "Bowen",
      last: "Reilly"
    },
    about:
      "eu culpa duis deserunt eu duis nulla anim aute proident officia cillum anim aute laboris magna est",
    email: "bowen.reilly@undefined.org",
    skill: "finance"
  },
  {
    _id: "5c4e7ea759347af3fcced89b",
    guid: "a84a3c3e-76c0-497b-9c80-d30a24ba440d",
    picture: "https://picsum.photos/200/80/?image=149",
    name: {
      first: "Kirk",
      last: "Logan"
    },
    about:
      "tempor ut Lorem sint deserunt deserunt sit deserunt elit labore sunt minim cillum voluptate dolor Lorem mollit",
    email: "kirk.logan@undefined.name",
    skill: "developer"
  },
  {
    _id: "5c4e7ea77094da20b0a8e143",
    guid: "a3b4789c-bd82-48cf-a813-005600295482",
    picture: "https://picsum.photos/200/80/?image=74",
    name: {
      first: "Chase",
      last: "Beck"
    },
    about:
      "voluptate fugiat fugiat magna voluptate proident nulla exercitation laboris non nulla eu deserunt pariatur aliqua sunt veniam",
    email: "chase.beck@undefined.me",
    skill: "developer"
  },
  {
    _id: "5c4e7ea7b2aedbc0c0e7d771",
    guid: "4a1d8d5b-5850-4ff0-95a6-5cd8312b76cb",
    picture: "https://picsum.photos/200/80/?image=56",
    name: {
      first: "Rhodes",
      last: "Bray"
    },
    about:
      "duis irure magna consequat ipsum minim laboris ipsum Lorem magna nulla occaecat labore occaecat anim proident ullamco",
    email: "rhodes.bray@undefined.us",
    skill: "legal"
  },
  {
    _id: "5c4e7ea79193f322891e5674",
    guid: "da8e79c1-6f2e-47ed-b34e-e88cd97b00ff",
    picture: "https://picsum.photos/200/80/?image=165",
    name: {
      first: "Osborn",
      last: "Prince"
    },
    about:
      "ea ad amet fugiat consectetur non nulla adipisicing ullamco ea laborum esse fugiat aliquip aute est aute",
    email: "osborn.prince@undefined.info",
    skill: "other"
  },
  {
    _id: "5c4e7ea7949dfba5f2501bcf",
    guid: "49b1f85f-8cfa-48e5-87fc-7b5bfd3c0c90",
    picture: "https://picsum.photos/200/80/?image=169",
    name: {
      first: "Lane",
      last: "Doyle"
    },
    about:
      "et qui fugiat consequat pariatur ea duis tempor aute minim ut labore ullamco non occaecat nulla ad",
    email: "lane.doyle@undefined.com",
    skill: "developer"
  },
  {
    _id: "5c4e7ea7e211954803d7ec39",
    guid: "8eeef72e-5f4d-4e56-9408-e01a09481e7c",
    picture: "https://picsum.photos/200/80/?image=7",
    name: {
      first: "Bernice",
      last: "Wilkinson"
    },
    about:
      "deserunt velit nulla velit deserunt ea laboris do deserunt occaecat pariatur culpa sunt et ad dolor magna",
    email: "bernice.wilkinson@undefined.net",
    skill: "other"
  },
  {
    _id: "5c4e7ea782141d88149db664",
    guid: "04a6a2c1-4471-4ce9-b277-828088610110",
    picture: "https://picsum.photos/200/80/?image=105",
    name: {
      first: "Parker",
      last: "Pitts"
    },
    about:
      "ad amet aliquip irure velit occaecat et reprehenderit proident nostrud voluptate reprehenderit aliquip anim incididunt magna esse",
    email: "parker.pitts@undefined.co.uk",
    skill: "finance"
  },
  {
    _id: "5c4e7ea71a3939c8cb95aa94",
    guid: "2a7e09f1-4a8d-46ee-983c-90bfcfb69a3e",
    picture: "https://picsum.photos/200/80/?image=131",
    name: {
      first: "Gallagher",
      last: "Lindsey"
    },
    about:
      "culpa occaecat incididunt laborum deserunt sint ipsum nulla laboris pariatur et Lorem qui cupidatat eu eiusmod ad",
    email: "gallagher.lindsey@undefined.ca",
    skill: "other"
  },
  {
    _id: "5c4e7ea79df388f3cd26140f",
    guid: "ced38ad4-bb79-4ecd-a524-945218ab7d7f",
    picture: "https://picsum.photos/200/80/?image=54",
    name: {
      first: "Donovan",
      last: "Manning"
    },
    about:
      "fugiat irure nostrud in laboris ex nostrud officia tempor do nostrud tempor nisi quis enim eu fugiat",
    email: "donovan.manning@undefined.biz",
    skill: "finance"
  },
  {
    _id: "5c4e7ea737bd52e996986ab5",
    guid: "fa5e29b9-e982-4bb1-aeb3-b50c2f67f37a",
    picture: "https://picsum.photos/200/80/?image=1",
    name: {
      first: "Workman",
      last: "Callahan"
    },
    about:
      "laboris fugiat velit incididunt magna aliqua aliqua mollit duis tempor esse dolor exercitation incididunt velit ullamco non",
    email: "workman.callahan@undefined.tv",
    skill: "legal"
  },
  {
    _id: "5c4e7ea7f2a8dd5c254ee969",
    guid: "5bbcb1e5-4af6-42ff-8cc0-883b499d8446",
    picture: "https://picsum.photos/200/80/?image=1",
    name: {
      first: "Adams",
      last: "Weeks"
    },
    about:
      "id eu et mollit est laborum commodo cupidatat adipisicing consequat ex ad velit cillum magna duis magna",
    email: "adams.weeks@undefined.biz",
    skill: "legal"
  },
  {
    _id: "5c4e7ea7b3778b39e3b45c3f",
    guid: "dcaafab6-1f47-4e71-9393-ed91fbf99060",
    picture: "https://picsum.photos/200/80/?image=90",
    name: {
      first: "Wilder",
      last: "Lancaster"
    },
    about:
      "aute deserunt adipisicing mollit cillum occaecat culpa ut adipisicing aute aute qui et aliquip nulla id veniam",
    email: "wilder.lancaster@undefined.org",
    skill: "finance"
  },
  {
    _id: "5c4e7ea7ad516c850997e21d",
    guid: "8e2bcd28-cbf1-4e88-8229-7304f8b08bc7",
    picture: "https://picsum.photos/200/80/?image=102",
    name: {
      first: "Julianne",
      last: "Hoover"
    },
    about:
      "aliquip aliqua aliquip laborum dolor et duis ea commodo do consectetur voluptate aliqua incididunt veniam velit ex",
    email: "julianne.hoover@undefined.name",
    skill: "developer"
  },
  {
    _id: "5c4e7ea7c9d847ced8bb6c2f",
    guid: "2378398c-b79c-4f11-8ec7-d6a9b22b084f",
    picture: "https://picsum.photos/200/80/?image=46",
    name: {
      first: "Gardner",
      last: "Mckenzie"
    },
    about:
      "labore amet fugiat fugiat anim dolor incididunt eu nostrud minim excepteur sunt deserunt pariatur laborum aute cillum",
    email: "gardner.mckenzie@undefined.me",
    skill: "developer"
  },
  {
    _id: "5c4e7ea7b9ca71f2b339b6e1",
    guid: "465870d1-fd0d-4453-93e3-c7b80a643b67",
    picture: "https://picsum.photos/200/80/?image=145",
    name: {
      first: "Cole",
      last: "Richard"
    },
    about:
      "officia eu anim sint consectetur consectetur nostrud consectetur exercitation culpa nisi duis esse ad sit ut est",
    email: "cole.richard@undefined.us",
    skill: "legal"
  },
  {
    _id: "5c4e7ea830efbdc5852643bb",
    guid: "6c675a2b-b213-4821-aec7-0d2c01912234",
    picture: "https://picsum.photos/200/80/?image=54",
    name: {
      first: "Dee",
      last: "Watkins"
    },
    about:
      "cillum non dolor esse mollit esse aliquip quis dolor nostrud ex nisi qui occaecat consectetur non cupidatat",
    email: "dee.watkins@undefined.info",
    skill: "blockchain"
  },
  {
    _id: "5c4e7ea8e0d1d71afc8f7321",
    guid: "b11dba1c-d80d-4ea0-8a14-15bae3fe5821",
    picture: "https://picsum.photos/200/80/?image=72",
    name: {
      first: "Galloway",
      last: "Kramer"
    },
    about:
      "deserunt magna tempor irure nisi sit mollit ut velit do eu ad nostrud sunt amet id do",
    email: "galloway.kramer@undefined.com",
    skill: "blockchain"
  },
  {
    _id: "5c4e7ea883c4e1ee78815df4",
    guid: "e6ead81d-9ba8-4e33-b63d-66706f592b5a",
    picture: "https://picsum.photos/200/80/?image=155",
    name: {
      first: "Conley",
      last: "Gentry"
    },
    about:
      "consequat nisi voluptate deserunt veniam sunt incididunt id ullamco dolore aliqua magna ea eu fugiat anim esse",
    email: "conley.gentry@undefined.net",
    skill: "blockchain"
  },
  {
    _id: "5c4e7ea89dc24537dff49050",
    guid: "3d140c75-84dc-40a3-b576-2fd4c7877521",
    picture: "https://picsum.photos/200/80/?image=186",
    name: {
      first: "Trudy",
      last: "Mccall"
    },
    about:
      "in occaecat et eiusmod ipsum esse ut cillum laboris irure excepteur occaecat aliqua do aliquip cillum enim",
    email: "trudy.mccall@undefined.co.uk",
    skill: "finance"
  },
  {
    _id: "5c4e7ea8c1effe6be3304ac0",
    guid: "4e75b4de-f91a-4609-a2ca-61b4d14c63f6",
    picture: "https://picsum.photos/200/80/?image=126",
    name: {
      first: "Jannie",
      last: "Booker"
    },
    about:
      "esse minim in sunt laborum duis reprehenderit eu id cupidatat id elit nisi mollit enim voluptate culpa",
    email: "jannie.booker@undefined.ca",
    skill: "developer"
  },
  {
    _id: "5c4e7ea88864b70dbc9653aa",
    guid: "f33e68ca-a8f0-4b1c-bf55-813bd2e46330",
    picture: "https://picsum.photos/200/80/?image=42",
    name: {
      first: "Tracie",
      last: "Castillo"
    },
    about:
      "sint quis irure nisi labore pariatur voluptate veniam esse sunt qui irure aliqua id esse dolor eiusmod",
    email: "tracie.castillo@undefined.biz",
    skill: "other"
  },
  {
    _id: "5c4e7ea8ae0ff1d14246b510",
    guid: "51fc3cbb-6c19-4490-8116-974949ddd516",
    picture: "https://picsum.photos/200/80/?image=61",
    name: {
      first: "Margo",
      last: "Sawyer"
    },
    about:
      "minim minim elit Lorem adipisicing ea ipsum anim eu nulla Lorem sunt qui ea elit et exercitation",
    email: "margo.sawyer@undefined.tv",
    skill: "legal"
  },
  {
    _id: "5c4e7ea8a08c20a8abac8a3f",
    guid: "f11322fc-f538-4f99-b9f6-5e59d2eedd87",
    picture: "https://picsum.photos/200/80/?image=162",
    name: {
      first: "Dalton",
      last: "Yates"
    },
    about:
      "fugiat pariatur ullamco consequat exercitation duis enim veniam sit consectetur elit incididunt elit proident sunt veniam anim",
    email: "dalton.yates@undefined.biz",
    skill: "developer"
  },
  {
    _id: "5c4e7ea8e255d8703e7958c9",
    guid: "4e4063c2-bd7e-4d0d-89f5-e737ac5b4359",
    picture: "https://picsum.photos/200/80/?image=55",
    name: {
      first: "Hawkins",
      last: "Roth"
    },
    about:
      "proident tempor eu duis occaecat laboris voluptate nisi ullamco elit consequat magna reprehenderit culpa deserunt mollit non",
    email: "hawkins.roth@undefined.org",
    skill: "legal"
  },
  {
    _id: "5c4e7ea8dbfecab876f83d61",
    guid: "00263e26-4dfb-4cac-a42c-4edefd18caa1",
    picture: "https://picsum.photos/200/80/?image=43",
    name: {
      first: "Kristy",
      last: "Morgan"
    },
    about:
      "exercitation cupidatat nulla consequat nulla adipisicing laboris commodo ex culpa ipsum adipisicing fugiat enim tempor commodo dolore",
    email: "kristy.morgan@undefined.name",
    skill: "legal"
  },
  {
    _id: "5c4e7ea82a7d5579f38df46f",
    guid: "79849bf0-48a5-4e68-bfb4-bef499faf5f7",
    picture: "https://picsum.photos/200/80/?image=104",
    name: {
      first: "Lolita",
      last: "Shelton"
    },
    about:
      "id aliqua ut est magna eiusmod commodo deserunt officia in consectetur anim tempor id eu adipisicing ea",
    email: "lolita.shelton@undefined.me",
    skill: "blockchain"
  },
  {
    _id: "5c4e7ea8bba303e2001b9433",
    guid: "c1ef0771-609e-4a70-9007-ec08250651fd",
    picture: "https://picsum.photos/200/80/?image=110",
    name: {
      first: "Rowe",
      last: "Valenzuela"
    },
    about:
      "labore ea qui incididunt velit id dolor qui mollit voluptate ex occaecat eiusmod et laborum deserunt voluptate",
    email: "rowe.valenzuela@undefined.us",
    skill: "other"
  },
  {
    _id: "5c4e7ea84b7252a184e48840",
    guid: "785af6d0-c0f3-4bb3-8a0a-bdea29d2a6ad",
    picture: "https://picsum.photos/200/80/?image=185",
    name: {
      first: "Sargent",
      last: "Hooper"
    },
    about:
      "ipsum pariatur amet ut consectetur et proident aute ipsum irure excepteur ex officia cupidatat culpa sint occaecat",
    email: "sargent.hooper@undefined.info",
    skill: "developer"
  },
  {
    _id: "5c4e7ea842aa56f6000bc806",
    guid: "d741366d-1f06-4a7e-bcf7-13b72a2e2b42",
    picture: "https://picsum.photos/200/80/?image=160",
    name: {
      first: "Gray",
      last: "Macias"
    },
    about:
      "qui non consectetur irure aliquip ut pariatur consectetur ipsum reprehenderit occaecat cupidatat mollit exercitation laboris cupidatat laboris",
    email: "gray.macias@undefined.com",
    skill: "other"
  },
  {
    _id: "5c4e7ea8333e0ca30b8b630e",
    guid: "9d482e15-1c4c-43d6-ad38-2addba714f5b",
    picture: "https://picsum.photos/200/80/?image=104",
    name: {
      first: "Buchanan",
      last: "French"
    },
    about:
      "id enim culpa sint pariatur proident incididunt veniam voluptate ea laborum ullamco quis et magna ullamco id",
    email: "buchanan.french@undefined.net",
    skill: "finance"
  },
  {
    _id: "5c4e7ea800450a7b029ade55",
    guid: "3a1c19af-7014-438e-aba7-4670cea121e1",
    picture: "https://picsum.photos/200/80/?image=116",
    name: {
      first: "Barry",
      last: "William"
    },
    about:
      "do excepteur qui aliquip nulla sunt ipsum mollit cillum voluptate tempor velit qui non eiusmod duis fugiat",
    email: "barry.william@undefined.co.uk",
    skill: "legal"
  },
  {
    _id: "5c4e7ea8c0b2911035369ca3",
    guid: "352b3ffa-bbdf-4d97-b7f5-b4052bedb26f",
    picture: "https://picsum.photos/200/80/?image=17",
    name: {
      first: "Chelsea",
      last: "Leblanc"
    },
    about:
      "minim fugiat sint mollit quis laborum officia commodo est proident consequat excepteur sit mollit aliquip velit incididunt",
    email: "chelsea.leblanc@undefined.ca",
    skill: "developer"
  },
  {
    _id: "5c4e7ea89fe6e2c0b0e94e8a",
    guid: "7daf1027-9957-491d-be8d-5f71bc4a0442",
    picture: "https://picsum.photos/200/80/?image=156",
    name: {
      first: "Araceli",
      last: "Stokes"
    },
    about:
      "veniam non minim excepteur ipsum ad amet amet irure nostrud voluptate id fugiat reprehenderit sunt mollit tempor",
    email: "araceli.stokes@undefined.biz",
    skill: "developer"
  },
  {
    _id: "5c4e7ea81b199544ac4670f7",
    guid: "11a185db-d8e1-4903-bdbc-d483c3a046e8",
    picture: "https://picsum.photos/200/80/?image=170",
    name: {
      first: "Frazier",
      last: "Murphy"
    },
    about:
      "aliqua in commodo laborum non anim est irure sit enim aliqua excepteur aliquip non Lorem occaecat labore",
    email: "frazier.murphy@undefined.tv",
    skill: "legal"
  },
  {
    _id: "5c4e7ea85ad30e4e62ec29e7",
    guid: "2c59228b-5922-4512-88a8-90fa0dc8dc4c",
    picture: "https://picsum.photos/200/80/?image=36",
    name: {
      first: "Crosby",
      last: "Finley"
    },
    about:
      "minim veniam ut laboris eu officia nisi ad enim velit sint proident mollit laborum pariatur ad aliqua",
    email: "crosby.finley@undefined.biz",
    skill: "finance"
  },
  {
    _id: "5c4e7ea8a5f7f20aaf70fc64",
    guid: "3560b367-42fb-4d0b-92ab-bf7492de8223",
    picture: "https://picsum.photos/200/80/?image=96",
    name: {
      first: "Phillips",
      last: "Conner"
    },
    about:
      "fugiat culpa reprehenderit velit occaecat ipsum occaecat exercitation in esse ut excepteur in dolor tempor nulla proident",
    email: "phillips.conner@undefined.org",
    skill: "finance"
  },
  {
    _id: "5c4e7ea83629d1abe7dadb8e",
    guid: "9b944815-01fd-44af-b427-437ecd912727",
    picture: "https://picsum.photos/200/80/?image=141",
    name: {
      first: "Kathrine",
      last: "Richmond"
    },
    about:
      "fugiat et excepteur incididunt sit reprehenderit tempor duis do amet Lorem nisi qui culpa deserunt aliquip esse",
    email: "kathrine.richmond@undefined.name",
    skill: "finance"
  },
  {
    _id: "5c4e7ea8244f5578278b11b7",
    guid: "93060c4a-4d8e-4c9a-996f-f68872037614",
    picture: "https://picsum.photos/200/80/?image=166",
    name: {
      first: "Odonnell",
      last: "Pollard"
    },
    about:
      "velit irure quis duis eiusmod sunt dolore non culpa consectetur mollit cillum ipsum sunt quis duis ea",
    email: "odonnell.pollard@undefined.me",
    skill: "other"
  },
  {
    _id: "5c4e7ea80d0773bc06ab7d75",
    guid: "24ff5c46-0277-4fac-8a87-4d29b85ddfa8",
    picture: "https://picsum.photos/200/80/?image=88",
    name: {
      first: "Barr",
      last: "Burton"
    },
    about:
      "magna minim enim Lorem aliquip non occaecat commodo amet veniam id et sint in amet ipsum exercitation",
    email: "barr.burton@undefined.us",
    skill: "legal"
  },
  {
    _id: "5c4e7ea8d96af32f3595a4cf",
    guid: "f684e8a8-3066-4ece-8d8e-90241a5dca75",
    picture: "https://picsum.photos/200/80/?image=70",
    name: {
      first: "Bette",
      last: "Vega"
    },
    about:
      "velit labore aliquip duis duis enim cillum culpa esse consequat aute culpa ut nulla cupidatat cupidatat occaecat",
    email: "bette.vega@undefined.info",
    skill: "other"
  },
  {
    _id: "5c4e7ea8c1fe29297bbeaacb",
    guid: "62cc488a-07b0-4f0d-aee8-ef0c7793eecf",
    picture: "https://picsum.photos/200/80/?image=39",
    name: {
      first: "Tessa",
      last: "Mathis"
    },
    about:
      "ipsum non amet elit voluptate enim reprehenderit adipisicing nulla nulla sunt adipisicing minim excepteur minim mollit tempor",
    email: "tessa.mathis@undefined.com",
    skill: "finance"
  },
  {
    _id: "5c4e7ea8935dac37975832f7",
    guid: "1ef168eb-bf8c-4aef-8a4e-355c881f15cb",
    picture: "https://picsum.photos/200/80/?image=58",
    name: {
      first: "Erickson",
      last: "Thomas"
    },
    about:
      "consectetur in ex mollit cupidatat ea ut enim culpa incididunt veniam ad incididunt mollit mollit et nostrud",
    email: "erickson.thomas@undefined.net",
    skill: "other"
  },
  {
    _id: "5c4e7ea8428f33b0236eccee",
    guid: "f4da79e3-54e6-47cf-a160-ac65bfff39cb",
    picture: "https://picsum.photos/200/80/?image=117",
    name: {
      first: "Frost",
      last: "Mcmahon"
    },
    about:
      "id Lorem minim eu dolor consequat adipisicing eu laborum consectetur amet nulla officia qui laboris sit voluptate",
    email: "frost.mcmahon@undefined.co.uk",
    skill: "legal"
  },
  {
    _id: "5c4e7ea87ed0791c6f7591f2",
    guid: "013dea0f-4445-4aa4-b0f7-f9623564cea8",
    picture: "https://picsum.photos/200/80/?image=197",
    name: {
      first: "Mcbride",
      last: "Fuller"
    },
    about:
      "tempor esse do occaecat officia aliqua officia aute amet eu minim sit veniam consequat id ad sint",
    email: "mcbride.fuller@undefined.ca",
    skill: "developer"
  },
  {
    _id: "5c4e7ea8e65ca25cf14640f5",
    guid: "4a5eb1ed-4269-4bbd-87ed-798ae226a207",
    picture: "https://picsum.photos/200/80/?image=37",
    name: {
      first: "Joanna",
      last: "Martinez"
    },
    about:
      "laborum eiusmod aliquip reprehenderit ut occaecat in officia magna laboris nulla ipsum do ipsum ex est in",
    email: "joanna.martinez@undefined.biz",
    skill: "finance"
  },
  {
    _id: "5c4e7ea8c70fbfdbb29e15c9",
    guid: "318ba50c-1bae-42a6-a32d-c709c2bf75f9",
    picture: "https://picsum.photos/200/80/?image=32",
    name: {
      first: "Figueroa",
      last: "Douglas"
    },
    about:
      "veniam dolor nulla nostrud excepteur officia amet non cupidatat consectetur consequat velit sit ea elit aliqua reprehenderit",
    email: "figueroa.douglas@undefined.tv",
    skill: "finance"
  },
  {
    _id: "5c4e7ea8f55493018252e003",
    guid: "7bdae4c5-ce46-4990-bd19-bb8218ff8a71",
    picture: "https://picsum.photos/200/80/?image=58",
    name: {
      first: "Georgette",
      last: "Dillard"
    },
    about:
      "proident nulla laborum excepteur consectetur minim tempor consectetur est enim laboris elit consectetur est Lorem cupidatat sint",
    email: "georgette.dillard@undefined.biz",
    skill: "other"
  },
  {
    _id: "5c4e7ea864d63de4b41f0c2e",
    guid: "752ee31b-d9eb-4921-b8d4-d9ce7f5b54c7",
    picture: "https://picsum.photos/200/80/?image=181",
    name: {
      first: "Nancy",
      last: "Lawrence"
    },
    about:
      "occaecat officia enim mollit ex non magna quis consequat non dolor aliquip ut sunt esse proident sunt",
    email: "nancy.lawrence@undefined.org",
    skill: "developer"
  },
  {
    _id: "5c4e7ea80431dfaa036fec25",
    guid: "bc71fe4b-6b79-44b7-a0bb-fb09fa74fd42",
    picture: "https://picsum.photos/200/80/?image=109",
    name: {
      first: "Shannon",
      last: "Campbell"
    },
    about:
      "sit amet ad incididunt laboris qui adipisicing anim est amet magna proident reprehenderit est aute exercitation nisi",
    email: "shannon.campbell@undefined.name",
    skill: "legal"
  },
  {
    _id: "5c4e7ea8a6a18855e5af7564",
    guid: "1112dd58-33de-4c20-958a-526caff6d884",
    picture: "https://picsum.photos/200/80/?image=83",
    name: {
      first: "Hancock",
      last: "Cabrera"
    },
    about:
      "commodo amet deserunt sunt id eu tempor consectetur ex sint do est irure eu labore dolor aliqua",
    email: "hancock.cabrera@undefined.me",
    skill: "other"
  },
  {
    _id: "5c4e7ea8e8f0ef60c996d9ca",
    guid: "115079d2-f5ee-41dc-9bd6-b749aba6a3e0",
    picture: "https://picsum.photos/200/80/?image=19",
    name: {
      first: "Lidia",
      last: "Bishop"
    },
    about:
      "quis nulla est excepteur esse culpa adipisicing consequat mollit ut elit esse mollit occaecat enim minim velit",
    email: "lidia.bishop@undefined.us",
    skill: "finance"
  },
  {
    _id: "5c4e7ea8eb62fd180916363a",
    guid: "fd971184-577d-4d32-ba4e-a64f6c863a04",
    picture: "https://picsum.photos/200/80/?image=106",
    name: {
      first: "Owen",
      last: "Mcmillan"
    },
    about:
      "deserunt aliquip id do incididunt qui nostrud dolore tempor veniam voluptate ipsum pariatur reprehenderit tempor ex in",
    email: "owen.mcmillan@undefined.info",
    skill: "blockchain"
  },
  {
    _id: "5c4e7ea8295990d0f9228918",
    guid: "dfd14ebf-51ee-42b9-a762-616874d9798d",
    picture: "https://picsum.photos/200/80/?image=114",
    name: {
      first: "Foley",
      last: "Rogers"
    },
    about:
      "velit veniam aliquip veniam exercitation enim excepteur occaecat duis sunt dolore cupidatat sit sit officia sit aliquip",
    email: "foley.rogers@undefined.com",
    skill: "legal"
  },
  {
    _id: "5c4e7ea81ba0cfe3bfb84ad1",
    guid: "bb61a8c1-3ad1-49fb-bc46-0c5db6078498",
    picture: "https://picsum.photos/200/80/?image=133",
    name: {
      first: "Sheri",
      last: "Singleton"
    },
    about:
      "ad magna cillum officia exercitation irure anim anim excepteur incididunt anim est esse cupidatat culpa dolore ullamco",
    email: "sheri.singleton@undefined.net",
    skill: "developer"
  },
  {
    _id: "5c4e7ea81a6d6b2f30e353ff",
    guid: "ce0d63b6-fa2a-4bcc-a3b8-49c74415d1e5",
    picture: "https://picsum.photos/200/80/?image=73",
    name: {
      first: "Juanita",
      last: "Velasquez"
    },
    about:
      "elit sit non ea enim culpa laboris officia duis do in officia minim voluptate deserunt ut ullamco",
    email: "juanita.velasquez@undefined.co.uk",
    skill: "finance"
  },
  {
    _id: "5c4e7ea83d07a48b879ab65e",
    guid: "624c9ad9-7722-4b19-bd1f-b6f97bd55aac",
    picture: "https://picsum.photos/200/80/?image=22",
    name: {
      first: "Jill",
      last: "Lewis"
    },
    about:
      "incididunt minim ipsum consequat sit do est elit aute commodo et ea elit aute cillum ipsum anim",
    email: "jill.lewis@undefined.ca",
    skill: "developer"
  },
  {
    _id: "5c4e7ea8b9fe24dc230994a9",
    guid: "ff9f39e8-f6e1-4492-836d-1202ebf8edb7",
    picture: "https://picsum.photos/200/80/?image=154",
    name: {
      first: "Amalia",
      last: "Gibson"
    },
    about:
      "Lorem eu laboris culpa consequat anim culpa commodo quis reprehenderit qui laborum ex voluptate ad in amet",
    email: "amalia.gibson@undefined.biz",
    skill: "finance"
  },
  {
    _id: "5c4e7ea8f8dee597472c5014",
    guid: "f9f89e03-5f2e-481e-bc91-a85cccfe7914",
    picture: "https://picsum.photos/200/80/?image=145",
    name: {
      first: "Lilly",
      last: "Boyle"
    },
    about:
      "aute et in tempor proident minim esse sunt elit non excepteur dolore consectetur laborum reprehenderit ut officia",
    email: "lilly.boyle@undefined.tv",
    skill: "blockchain"
  },
  {
    _id: "5c4e7ea89842feb38de1bbd4",
    guid: "56d0a656-35af-4930-ab20-f96c40ce8356",
    picture: "https://picsum.photos/200/80/?image=181",
    name: {
      first: "Eve",
      last: "George"
    },
    about:
      "veniam culpa labore pariatur nisi sit pariatur ipsum non elit ex aliqua dolor sunt sit amet ad",
    email: "eve.george@undefined.biz",
    skill: "other"
  },
  {
    _id: "5c4e7ea8fbbd59c9e93d8e77",
    guid: "5b78228f-82b7-4efb-b4f8-368c4b3e61bd",
    picture: "https://picsum.photos/200/80/?image=199",
    name: {
      first: "Vera",
      last: "Frye"
    },
    about:
      "non esse occaecat voluptate esse excepteur aute quis anim excepteur officia nulla laborum laboris dolore magna officia",
    email: "vera.frye@undefined.org",
    skill: "finance"
  },
  {
    _id: "5c4e7ea8c66a0a35910bcce9",
    guid: "7c877798-2850-44c1-bf38-7cd137329584",
    picture: "https://picsum.photos/200/80/?image=197",
    name: {
      first: "Solis",
      last: "Bernard"
    },
    about:
      "deserunt adipisicing magna eiusmod deserunt sit adipisicing incididunt sunt elit excepteur tempor consectetur deserunt pariatur minim nostrud",
    email: "solis.bernard@undefined.name",
    skill: "legal"
  },
  {
    _id: "5c4e7ea8f241bb53ec3c8c85",
    guid: "3d590c85-5ec6-4a23-aa82-4318d97d827a",
    picture: "https://picsum.photos/200/80/?image=29",
    name: {
      first: "Lessie",
      last: "Barrett"
    },
    about:
      "laborum veniam et consectetur et duis est consequat cupidatat Lorem laboris nostrud ad laborum duis nulla reprehenderit",
    email: "lessie.barrett@undefined.me",
    skill: "other"
  },
  {
    _id: "5c4e7ea8fe8bb46c839215bd",
    guid: "e7284379-8cbf-4e4f-8720-93daa3f2f3a3",
    picture: "https://picsum.photos/200/80/?image=3",
    name: {
      first: "Gwendolyn",
      last: "West"
    },
    about:
      "voluptate ut consectetur duis incididunt ipsum proident minim Lorem commodo laboris laboris incididunt aliqua proident et excepteur",
    email: "gwendolyn.west@undefined.us",
    skill: "developer"
  },
  {
    _id: "5c4e7ea81937b8ec2907c4c5",
    guid: "f78df4f0-e139-4bb0-91fe-f29473881ff5",
    picture: "https://picsum.photos/200/80/?image=27",
    name: {
      first: "Pacheco",
      last: "Leach"
    },
    about:
      "dolor et reprehenderit quis et ad est ipsum ipsum consectetur anim mollit pariatur minim dolor ullamco duis",
    email: "pacheco.leach@undefined.info",
    skill: "other"
  },
  {
    _id: "5c4e7ea8b5046f4e8650e555",
    guid: "434baacb-3862-45b7-ad82-d760eef48bea",
    picture: "https://picsum.photos/200/80/?image=59",
    name: {
      first: "Campbell",
      last: "Cannon"
    },
    about:
      "pariatur mollit ea est et quis non quis velit deserunt cupidatat ex ipsum ex proident cupidatat consequat",
    email: "campbell.cannon@undefined.com",
    skill: "other"
  }
];

export { skillList, collaboratorList };
