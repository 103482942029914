import React, { Component } from "react";
import { connect } from "react-redux";
import "./MyCollaborations.css";
import InfoCircle from "../InfoCircle";
import firebase from "../../constants";
import {
  Typography
} from "@material-ui/core";
import Axios from "axios";
import { launchAlert, getInvitations, sendRWOI, showRTWOIProfile } from "../../store/actions/actions";
import { hostApi, woiRole } from "../../constants";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton";


class MyCollaborations extends Component {
  state = {
    currentSelection: -1,
    myCollaborations: [],
    numberOfCollaboratorsList: [],
    numberOfCollaborators: 0
  };

  handleClick = i => {
    const { showCollaboratorsByIdea } = this.props;
    this.setState({ currentSelection: i });
    if(i >= 0 ){
      showCollaboratorsByIdea(this.state.myCollaborations[i].ideaId);
    }
  };

  componentDidMount() {
    const { getInvitations, cookies, collaboratorId, jwtToken } = this.props;

    this.updateNumberOfCollaborators();

    //FIXME: CollaboratorId is null
    let collab_id = 0;

    if(collaboratorId === 0){
      collab_id= this.props.cookies.get("collab_id");
    } else {
      collab_id = collaboratorId;
    }
    getInvitations(jwtToken, collab_id, "ideator", data => {

    });
  }

  viewProfile = (id, reason, value) => {
    let data = [
      {
        collabId: id,
        collabReason: reason,
        collabValue: value
      }
    ]

    this.props.showRTWOIProfile(data);
  }

  updateNumberOfCollaborators = () => {
    let myCollaborations = [];
    myCollaborations = this.props.myCollaborations;

    this.setState(state => {
      state.numberOfCollaboratorsList = myCollaborations.map(myIdea =>
        parseInt(myIdea.numberOfCollaborators, 10)
      );
      state.myCollaborations = myCollaborations;
      state.numberOfCollaborators =
        (state.numberOfCollaboratorsList.length &&
          state.numberOfCollaboratorsList.reduce((a, c) => a + c)) ||
        0;
      return state;
    });
  };

  approveCollaborator = (profileId, firebaseId, ideaId, approval) => {
    const { launchAlert, forceUpdateHandler, jwtToken } = this.props;
    let myFirebaseId = firebase.auth().currentUser.uid;
    sendRWOI(myFirebaseId, firebaseId);
    Axios.post(`${hostApi}/approveCollaborator`, {
      profile_id: profileId,
      idea_id: ideaId,
      decision: approval
    },
    {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then(response => {
        launchAlert({
          intent: "success",
          title: `${approval}`,
          message: (
            <Typography variant="Subheading" color="inherit">
              You have approved/rejected the request from a SPRXS user to
              collaborate on your idea with you.
            </Typography>
          )
        });
        forceUpdateHandler(); // refresh the collaborator list
      })
      .catch(error => {
        launchAlert({
          intent: "error",
          title: "Approval/Reject Failed",
          message: (
            <Typography variant="Subheading" color="inherit">
              There was a problem with your request to approve/reject. Please
              try again.
            </Typography>
          )
        });
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const {
      currentSelection,
      numberOfCollaboratorsList,
      numberOfCollaborators,
      myCollaborations
    } = this.state;

    const {
      navigateToIdea,
      myCollaborationsCollaboratorsByIdeaId,
      collaboratorsByIdeaList,
      startChatWithPerson,
      invitationData,
      jwtToken
    } = this.props;

    let copyOfcollaboratorsByIdeaList = null;

    if (currentSelection >= 0) {
      copyOfcollaboratorsByIdeaList = collaboratorsByIdeaList.filter(
        item => item.ideaId === myCollaborations[currentSelection].ideaId
      );
    } else {
      copyOfcollaboratorsByIdeaList =  collaboratorsByIdeaList.filter(item => item.collabApproved === 2);
    }

    return numberOfCollaborators ? (
      <div
        className="my-collaborators__wrapper"
        data-collaborator-number={numberOfCollaborators}
      >
        <h3 className="my-collaborators__title">Collaborators by Idea</h3>
        <InfoCircle
          width="200"
          height="200"
          data={numberOfCollaboratorsList}
          handleClick={this.handleClick}
        />
        {
          <div className="my-collaborators__list">
          {currentSelection >= 0 && ( 
            <div>
              <h3 className="my-collaborators__title">{myCollaborations[currentSelection].ideaName}</h3>
              <IconButtonComponent 
                type={"close"}
                iconStyle={"iconDefault iconCodeBlack iconRight"}
                toolTip={true}
                title={"Close Idea"}
                link={() => this.handleClick(-1)}
              />
              <p className="my-collaborators__titleId">#{myCollaborations[currentSelection].ideaId}</p>
            </div>
          )}
          {currentSelection === -1 && ( 
            <h3 className="my-collaborators__title">Pending Requests</h3>
          )}
            <table className="my-collaborators-list">
              <thead>
                <tr className="my-collaborators__header">
                  <td align="left">Name</td>
                  {currentSelection >= 0 ? <td align="left">Approval</td> : <td align="left">Idea #</td>}
                  <td align="center">Actions</td>
                </tr>
              </thead>
              <tbody>
                {copyOfcollaboratorsByIdeaList.map((value, id) => (
                  <tr key={id}>
                    <td align={"left"} className="my-collaborators__name">
                      <div
                        key={value.id}
                        style={{ minWidth: "120px" }}
                      >
                        {`${value.firstname} ${value.lastname}`}
                      </div>
                      <div className="idea-list__id">{`${woiRole[value.lkpWoiRole].label}`}</div>
                    </td>
                    <td align="left" className="my-collaborators__ideaId" >
                      <div>{currentSelection >= 0 ? `${value.collabApproved === 1 ? "Approved" : "Pending"}` : `${value.ideaId}`}</div>
                    </td>
                    
                    <td align={"right"} className="my-collaborators__actions">
                    {(value.collabApproved === 2 ) && (
                    <React.Fragment>
                      <IconButtonComponent 
                        type={"approve"}
                        iconStyle={"iconDefault iconCodeGreen iconPadRight"}
                        toolTip={true}
                        title={`Approve`}
                        link={() => {
                          this.approveCollaborator(
                            value.profileId,
                            value.collaboratorFirebaseUid,
                            value.ideaId,
                            "Approve"
                          );
                        }}
                      />
                      <IconButtonComponent 
                        type={"reject"}
                        iconStyle={"iconDefault iconWarning iconPadRight"}
                        toolTip={true}
                        title={"Reject"}
                        link={() => {
                          this.approveCollaborator(
                            value.profileId,
                            value.collaboratorFirebaseUid,
                            value.ideaId,
                            "Reject"
                          );
                        }}
                      />
                      <IconButtonComponent 
                      type={"profile"}
                      toolTip={true}
                      title={`View ${value.firstname}'s request`}
                      iconStyle={"iconAction"}
                      link={() => this.viewProfile(value.profileId, value.firstname, value.lastname, value.collabReason, value.collabValueadd)}
                    />

                    </React.Fragment>
                    )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
      </div>
    ) : (
      <div>Loading collaborators ...</div>
    );
  }
}



const mapStateToProps = (state) => ({
  invitationData: state.invitationData,
  userType: state.userType,
  collaboratorId: state.collaboratorID,
  token: state.jwtToken
})

const mapDispatchToProps = dispatch => ({
  launchAlert: payload => dispatch(launchAlert(payload)),
  getInvitations: (jwtToken,collaboratorID, filter, callback) => {
    getInvitations(jwtToken, collaboratorID, filter, callback, dispatch);
  },
  showRTWOIProfile: (data) => {
    showRTWOIProfile(data, dispatch);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCollaborations);
