import styled from "styled-components";

export const SkillTitleStyled = styled.div`
  padding: 1rem;
  font-size: 4rem;
`;

export const SkillCardWrapperStyled = styled.div`
  border: 1px solid var(--gray);
  margin: 1rem;
  margin-top: 5rem;
  overflow: hidden;
  border-radius: 10px;
`;

export const SkillCardBodyStyled = styled.div`
  // justify-content: center;
  // display: flex;
`;

export const SkillCardTitleStyled = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: black;
  align-items: center;
  padding: 0.5rem 1rem;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #f1f1f1 50%,
    #e1e1e1 51%,
    #f6f6f6 100%
  );

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 1rem;
  }
`;
// grid-template-columns: repeat(5, 1fr);

export const SkillCardStyled = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    color: orange;
  }

  @media (min-width: 768px) {
    padding: 1rem;
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const CollaboratorAvatar = styled.div`
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const CollaboratorCard = styled.div`
  border: 1px solid gray;
  border-radius: 10px;
  text-align: center;
`;

export const CollaboratorDisplayName = styled.div`
  flex-grow: 1;
`;

export const CollaboratorDisplayNameWrapper = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`;

export const DashboardIdeatorStyled = styled.div`
  color: black;

  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(
    ${({ singleLine }) => (singleLine ? 1 : 2)},
    1fr
  );
  padding: 10px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
`;
