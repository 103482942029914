import React, { Component } from "react";
import "./InboxList.css";

class InboxList extends Component {
  render() {
    const { inbox } = this.props;
    // const inbox = [...notification, ...activity].sort((a, b) => b.id - a.id);
    //     createdProfileRecipient: "marian@codetap.io"
    // createdProfileSender: "testuser@test.com"
    // dateCreated: "11-Jan-2019 12:28:21"
    // description: "Your collaboration request has been approved by testuser@test.com for the idea Vegan Extra Life"
    // eventDescription: "Request to collaborate Response"
    // eventType: 0
    // id: 764
    // ideaId: "XJMQZQ"
    // title: "Your collaboration request has been approved"
    return (
      <div>
        {/* 2019.01.22. KN - Edit an field changes */}
        <div className="inbox-list">
          {inbox.map((inbox, id) => (
            <React.Fragment key={id}>
              <div className="sprxs-inbox-list-wrapper">
                <div className="sprxs-gender-neutral" />
              </div>

              <div>
                <div className="inbox-list__name">{`${inbox.firstname} ${
                  inbox.surname
                }`}</div>
                <div className="inbox-list__name">
                  {inbox.createdProfileSender}
                </div>
                <div className="inbox-list__id">{inbox.date_created}</div>
              </div>

              <div className="inbox-list__message">{inbox.description}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
}
export default InboxList;
