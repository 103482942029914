import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavBar from "../../components/NavBar/NavBar";
import "../HomePage/HomePage.css"
import NTFTest from "../../components/NTFTest/NTFTest";

export class NTFTestPage extends Component {
  render() {
    return (
      <div className="Homepage-style">
        <NavBar />

        <NTFTest/>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NTFTestPage);
