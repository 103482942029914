import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import s from "../../../static/greencompleted.svg";
import Button from '@material-ui/core/Button';
import Modal from "@material-ui/core/Modal";
import MilestoneAcknowledgeAlert from "../MilestoneAcknowledgeAlert/MilestoneAcknowledgeAlert";
import MilestoneAcknowledgeDelete from "../MilestoneAcknowledgeDelete/MilestoneAcknowledgeDelete";
import Typography from "@material-ui/core/Typography";

import axios from "axios";
import { hostApi } from "../../../constants";
import { NotificationManager } from "react-notifications";

const styles = theme => ({
    card: {
        backgroundColor: "transparent",
        boxShadow: "none",
        marginRight: theme.spacing.unit * 5,
    },
    header: {
        width: "100%",
    },

    media: {
        height: 0,
        paddingTop: "56.25%" // 16:9
    },
    actions: {
        display: "flex"
    },
    expand: {
        transform: "rotate(0deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        }),
        marginLeft: "auto",
        [theme.breakpoints.up("sm")]: {
            marginRight: -8
        }
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    formLogo: {
        margin: 15,
        width: 65,
        height: 80
    },
});


class TimeCardCollab extends React.Component {
    state = { expanded: false, checked: false, anchorEl: null, openAlert: false, openDeleteAlert: false, open: false };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleOpenAcknowledge = () => {
        this.setState({ openAlert: true });
    }

    handleCloseAcknowledge = () => {
        this.setState({ openAlert: false });
    }

    handleAcknowledge = () => {
        const { milestone, token } = this.props;

        console.log("milestone data before axios", milestone)
        axios
          .post(
            `${hostApi}/lodgeConsensus`,
            {
              milestone_id: milestone.id,
              approval: 0,
              approval: 1
            },
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          )
          .then(({ data }) => {
            console.log("milestone data after axios", data);
          });
        this.handleCloseAcknowledge();
    };

    handleCloseAcknowledgeDelete = () => {
        this.setState({ openDeleteAlert: false });
    }

    handleAcknowledgeDelete = () => {
        this.setState({ openDeleteAlert: true });
    }

    // agreedCompletionDate: "15-Jan-2019 09:00:00"
    // approval: 2
    // collabProfile: null
    // id: 239
    // ideaId: "JSFTTI"
    // milestoneDateCreated: "14-Jan-2019 13:59:29"
    // milestoneDescription: "dsadas"
    // milestoneName: "milestone NO colalb O hai"
    // offeredTokens: 0
    // ownerProfile: "viviangalinari@gmail.com"

    render() {
        const { classes, milestone, launchMilestoneForm, updateMilestoneData, handleAcknowledge } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        console.log("Approval status:", milestone.id, milestone.milestoneName, milestone.collabProfile, milestone.approval)
        return (
            <div>
                <Card className={classes.card}>
                    <CardHeader
                        title={(milestone && milestone.milestoneName) || ""}
                        subheader={(milestone && milestone.milestoneDateCreated) || ""}
                    />
                    <CardActions className={classes.actions} disableActionSpacing>
                        {(milestone.approval === 3) ? <Typography variant="subheading" color="primary" gutterBottom > Milestone Active </Typography > : ""}

                        {(milestone.approval === 2) ?
                            <Button variant="contained" color="secondary"
                                className={classes.button}
                                onClick={this.handleOpenAcknowledge}
                            >
                                Acknowledge Edit
                        </Button>
                            : ""
                        }

                        {(milestone.approval === 4) ?
                            <Button variant="contained" color="secondary"
                                className={classes.button}
                                onClick={this.handleAcknowledgeDelete}
                            >
                                Acknowledge Delete
                        </Button>
                            : ""
                        }

                        <IconButton
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: this.state.expanded
                            })}
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                            aria-label="Show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography>
                                {(milestone && milestone.milestoneDescription) || ""}
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>
                <Modal open={this.state.openAlert}>
                    <MilestoneAcknowledgeAlert
                        open={true}
                        milestone={milestone}
                        onCancelAcknowledge={this.handleCloseAcknowledge}
                        onConfirmAcknowledge={this.handleAcknowledge}
                        onRejectAcknowledge={this.handleRejectAcknowledge}
                    />
                </Modal>
                <Modal open={this.state.openDeleteAlert}>
                    <MilestoneAcknowledgeDelete
                        open={true}
                        milestone={milestone}
                        onCancelAcknowledgeDelete={this.handleCloseAcknowledgeDelete}
                        onConfirmAcknowledgeDelete={this.handleAcknowledgeDelete}
                    />
                </Modal>
            </div>
        );
    }
}

TimeCardCollab.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  token: state.jwtToken
})

const mapDispatchToProps = {
  
}


export default withStyles(styles)(connect(mapStateToProps)(TimeCardCollab));
