import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ChatIcon from "@material-ui/icons/ChatBubbleOutline";
import DashIcon from "@material-ui/icons/Dashboard";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {
  ShareIcon,
  Edit,
  Chat,
  Feedback,
  Lock,
  LockOpen,
  Public,
  SupervisedUserCircle
} from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SecureIcon from "@material-ui/icons/VpnLock";
import CardHeader from "@material-ui/core/CardHeader";
import { fade } from "@material-ui/core/styles/colorManipulator";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import classNames from "classnames";
import { Tooltip } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import classnames from "classnames";
import IdeaDetails from "../IdeaDetails/PublishIdea";
import RequestWOI from "../IdeaDetails/RequestWOI";
import EditIdeaDialog from "../EditIdeaModal/EditIdeaDialog";
import Chip from "@material-ui/core/Chip";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import AttachmentIcon from "@material-ui/icons/Attachment";
import StyleIcon from "@material-ui/icons/Style";
import EmailIcon from "@material-ui/icons/AlternateEmail";
import LaunchIcon from "@material-ui/icons/Launch";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { setIdeaToEdit, unsetIdeaToEdit } from "../../store/actions/actions";
import { connect } from "react-redux";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";

// Image Store // PEXEL / https://www.pexels.com/photo-license/
// -----------------------------------------------------------------------------------------------------

import techImage from "../../static/technology.jpeg"; // https://www.pexels.com/search/technology/
import busImage from "../../static/business.jpeg"; // https://www.pexels.com/search/business/
import fashionImage from "../../static/fashionart.jpeg"; // https://www.pexels.com/search/fashion/
import filmImage from "../../static/film.jpeg"; // https://www.pexels.com/search/film/
import foodImage from "../../static/fooddrink.jpeg"; // https://www.pexels.com/search/food/
import lifestyleImage from "../../static/lifestyle.jpeg"; // https://www.pexels.com/search/lifestyle/
import mediaImage from "../../static/media.jpeg"; // https://www.pexels.com/search/media/
import gamingImage from "../../static/gaming.jpeg"; // https://www.pexels.com/search/gaming/
import otherImage from "../../static/other.jpeg"; // https://www.pexels.com/search/ideas/
import { idea_drilldown_feature_flag } from "../../constants";

const styles = theme => ({
  card: {
    maxWidth: 345,
    minWidth: 345,
    color: "#383736",
    border: "0px solid #383736"
  },
  media: {
    objectFit: "cover",
    height: 150,
    paddingTop: "56.25%" // 16:9
  },
  avatarLock: {
    backgroundColor: green[500]
  },
  avatarUnLock: {
    backgroundColor: red[500]
  },
  actions: {
    display: "flex"
  },
  textField: {
    /* marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit */
  },
  dense: {
    marginTop: 16
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: "auto",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      marginRight: 2
    }
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  chip: {
    margin: theme.spacing.unit
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardTitle: {
    fontWeight: "bold"
  },
  RtWOI: {
    border: "0px solid blue",
    height: 30,
    width: 30,
    cursor: "pointer"
  },
  subTitle: {
    fontWeight: "bold"
  }
});

class CardView2 extends React.Component {
  state = {
    expanded: false,
    launch: false,
    showEdit: false,
    showRWOI: false,
    selectedData: []
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };
  handleCloseDetails = () => {
    this.setState({ launch: false });
  };
  launchIdea = () => {
    this.setState({ launch: true });
  };
  launchEdit = ({ ideaUniqueID }) => {
    this.setState({ showEdit: true });
  };
  handleCloseEdit = () => {
    this.setState({ showEdit: false });
  };
  launchRWOI = () => {
    this.setState({ showRWOI: true });
  };
  handleCloseRWOI = () => {
    this.setState({ showRWOI: false });
  };

  componentDidUpdate(prevProps, prevState) {
  }

  getImage = (coverPhotoUrl, category) => {
    if (coverPhotoUrl === "" || coverPhotoUrl === null || coverPhotoUrl === undefined) {
      switch (category) {
        case "Technology":
          console.log("WHAT AM I GETTING", category);
        return techImage;
        case "Lifestyle & Wellbeing":
          return lifestyleImage;
        case "Food & Drink":
          return foodImage;
        case "Gaming":
          return gamingImage;
        case "Business/Finance":
          return busImage;
        case "Fashion & Art":
          return fashionImage;
        case "Film, Theatre & Music":
          return filmImage;
        case "Media and Journalism":
          return mediaImage;
        default:
          return otherImage;
      }
    } else {
      return coverPhotoUrl;
    }
  };

  render() {
    const { classes, entity, pub, cookie_email, launchIdeaDash, jwtToken } = this.props;

    console.log("ENTITY", entity);

    return (
      <Card className={classes.card}>
        <CardHeader

          action={
            (!entity.pendingApproval ?
            <IconButtonComponent 
              type={"collaborate"}
              iconStyle={"iconAction iconTopRight"}
              toolTip={true}
              title={"Request to Collaborate"}
              link={this.launchRWOI}
            />
            :
              <IconButtonComponent 
                type={"collaborate"}
                iconStyle={" iconDisabled iconTopRight"}
                toolTip={true}
                title={"Already collaborating / request pending"}
              />
              )
          }
          title={
            <Typography color="default" className={classes.cardTitle}>
              {entity.ideaName}
            </Typography>
          }
          subheader={
            <Typography color="default">
              Created {entity.ideaDateCreated}
            </Typography>
          }
        />
        <CardMedia
        className={classes.media}
        image={this.getImage(entity.coverPhotoURL, entity.lkpIdeaCat1)} // Return Stock Image if no cover photo is added.
        title={entity.coverPhoto}
        />
        <CardActions className={classes.actions} disableActionSpacing>
          <Tooltip title="Show Idea Details">
            <ExpandMoreIcon color="primary" 
              className={classnames(classes.expand, {
                [classes.expandOpen]: this.state.expanded
              })}
              onClick={this.handleExpandClick}/>
          </Tooltip>
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="body1"><span className={classes.subTitle}>Idea ID: </span>#{entity.ideaUniqueID}</Typography>
            <Typography variant="body1"><span className={classes.subTitle}>Category: </span>{entity.lkpIdeaCat1}</Typography>
            <Typography variant="body2" paragraph={true}>
            <span className={classes.subTitle}>Description: </span> {entity.ideaDescription}
            </Typography>
          </CardContent>
        </Collapse>
        <IdeaDetails
          open={this.state.launch}
          onClose={this.handleCloseDetails}
          data={entity}
        />
        <RequestWOI
          jwtToken={this.props.jwtToken}
          open={this.state.showRWOI}
          onClose={this.handleCloseRWOI}
          data={entity}
          cookie_email={cookie_email}
          collaboratorID={this.props.collaboratorID}
        />
        {/* <EditIdeaDialog
          open={this.state.showEdit}
          onClose={this.handleCloseEdit}
          data={entity}
        /> */}
      </Card>
    );
  }
}

CardView2.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ideaToEdit: state.ideaToEdit,
  collaboratorID: state.collaboratorID
});

const mapDispatchToProps = dispatch => ({
  setIdeaToEdit: payload => dispatch(setIdeaToEdit(payload)),
  unsetIdeaToEdit: () => dispatch(unsetIdeaToEdit())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CardView2));
