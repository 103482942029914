import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import spinner from "../../static/spin1a.gif"
import spinner from "../../static/Cube-1s-100px.gif";
import "./Spinner.css";

export class Spinner extends Component {
  static propTypes = {
    prop: PropTypes
  };

  render() {
    return (
      <div className="spinner-center">
        {this.props.showSpinner && (
          <div>
            <img src={spinner} height="100" width="100" alt="spinner" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Spinner);
