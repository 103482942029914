import React from "react";
import SPRXSExplainerThumb from "../../static/VideoThumbs/video1.jpg";
import ImperialFreshersThumb from "../../static/VideoThumbs/video2.jpg";
import NottinghamRefresherThumb from "../../static/VideoThumbs/nottinghamrefreshfair2019.jpg";
import UCLFreshersThumb from "../../static/VideoThumbs/video3.jpg";
import RavensbourneThumb from "../../static/VideoThumbs/video4.jpg";
import SPRXSMusicThumb from "../../static/VideoThumbs/musicJourney.jpg";
import SPRXSUCLThumb from "../../static/VideoThumbs/uclEvent2019.jpg";



export const videos = [
  {
    key: "292816980",
    link: "https://player.vimeo.com/video/292816980",
    title: "SPRXS Explainer",
    thumb: SPRXSExplainerThumb
  },
  {
    key: "333698589",
    link: "https://player.vimeo.com/video/333698589",
    title: "SPRXS Music",
    thumb: SPRXSMusicThumb
  },
  {
    key: "333513403",
    link: "https://player.vimeo.com/video/333513403",
    title: "SPRXS UCL 2019",
    thumb: SPRXSUCLThumb
  },
  {
    key: "326543488",
    link: "https://player.vimeo.com/video/326543488",
    title: "Nottingham Refreshers’ Fair 2019",
    thumb: NottinghamRefresherThumb
  },
  {
    key: "299007147",
    link: "https://player.vimeo.com/video/299007147",
    title: "Imperial Freshers Fair",
    thumb: ImperialFreshersThumb
  },
  {
    key: "299006879",
    link: "https://player.vimeo.com/video/299006879",
    title: "UCL Freshers Fair",
    thumb: UCLFreshersThumb
  },
  {
    key: "307671978",
    link: "https://player.vimeo.com/video/307671978",
    title: "Ravensbourne University",
    thumb: RavensbourneThumb
  }
];

export default {
  videos
};
