import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import MilestoneCollabList from "../MilestoneCollabList/MilestoneCollabList";
import MilestoneForm from "../MilestoneForm/MilestoneForm";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import milestonelogo from "../../../static/milestonelogo.svg";
import CardHeader from "@material-ui/core/CardHeader";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  title: {
    textAlign: "center",
    marginTop: theme.spacing.unit * 4,
    placeItem: "center"
  },
  dialog: {
    overflowX: "hidden"
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  formLogo: {
    width: 56,
    marginRight: -3,
    marginTop: 15
  },
  helperText: {
    color: "#000",
    textAlign: "center",
    paddingLeft: theme.spacing.unit * 5
  }
});

class MilestoneCollabDashboard extends React.Component {
  state = { openMilestoneForm: false, milestoneData: [] };

  handleLaunchMSForm = () => {
    this.setState({ openMilestoneForm: true });
  };

  closeMSForm = () => {
    this.setState({ openMilestoneForm: false });
  };

  render() {
    const {
      classes,
      fullScreen,
      open = false,
      onClose,
      myCollaborators,
      data /*pass the card data here*/
    } = this.props;

    return (
      <div className={classes.dialog}>
        <Dialog
          style={{ marginTop: 0 }}
          fullWidth={true}
          fullScreen={false} // for mobile
          open={open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <CardHeader
              action={<img src={milestonelogo} className={classes.formLogo} />}
              title={
                <Typography variant="h5" color="primary" gutterBottom>
                  Milestone Collaborators Timeline
                </Typography>
              }
              subheader={`Milestones for Idea: ${data.ideaUniqueID} - ${
                data.ideaName
              }`}
            />
          </DialogTitle>
          <div className="milestone-logo-bg-2">
            <div className="milestone-logo-bg grid-milestone-list">
              <div className="milestone-button">
                {!this.state.milestoneData.length && (
                  <div className="milestone-helpertext">
                    Milestones are a way for you to track the progress of your
                    collaborations.
                  </div>
                )}
                <MilestoneCollabList
                  data={myCollaborators}
                  ideaName={this.state.milestoneData.ideaName}
                  // ideaName={data.ideaName}
                />
              </div>
            </div>
          </div>
          <MilestoneForm
            jwtToken={this.props.jwtToken}
            data={data}
            open={this.state.openMilestoneForm}
            updateMilestoneData={this.updateMilestoneData}
            closeMilestoneForm={this.closeMSForm}
          />
        </Dialog>
      </div>
    );
  }
}

MilestoneCollabDashboard.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(MilestoneCollabDashboard)
);
