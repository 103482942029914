import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import "../../App.css";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormHeader from "../../components/FormHeader/FormHeader";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Terms from "../../components/Terms/TermsConds";
import Privacy from "../../components/Terms/PrivPol";
import AcceptUse from "../../components/Terms/AcceptUsePol";
import Cookie from "../../components/Terms/CookiePolicy";
import Collab from "../../components/Terms/CollabTokensPolicy";
import Competiton from "../../components/Terms/CompPolicy";
import { CLOSE_MODAL, showLegalModal} from "../../store/actions/actions";

import "./Legal.css";

const styles = theme => ({
  root: {
    width: "100%"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  }
});

class LegalModal extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      initValue: "",
      value: "",
      open: props.open,
    };
  }  

  setInitValue = () => {
    this.setState({value: this.props.initValue});
  }

  handleChange = (event, value) => {
    this.setState({ value: value });
    document.body.scrollTop = document.getElementById("legalModalContent").scrollTop = 0;
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.closeModal();
  };

  render() {
    const { fullScreen } = this.props;
    return (

      <div>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          fullScreen={fullScreen}
          open={this.props.showLegalModal}
          onEnter={this.setInitValue}
          onClose={this.handleClose}
          disableBackdropClick={true}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent className="legalModal" id="legalModalContent">
            {this.state.value === "terms" && (
                <Terms handleChange={this.handleChange}/>
            )};
            {this.state.value === "privacy" && (
                <Privacy handleChange={this.handleChange}/>
            )};
            {this.state.value === "acceptUse" && (
                <AcceptUse handleChange={this.handleChange} />
            )};
            {this.state.value === "collab" && (
               <Collab handleChange={this.handleChange} />
            )};
            {this.state.value === "cookie" && (
                <Cookie handleChange={this.handleChange}/>
            )};
            {this.state.value === "comp" && (
                <Competiton handleChange={this.handleChange}/>
            )};
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

LegalModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    initValue: state.policyType,
    showLegalModal: state.showLegalModal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: CLOSE_MODAL }),
  };
};

export default withRouter(
  withCookies(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(withStyles(styles)(withMobileDialog()(LegalModal)))
  )
);
