import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Typography from "@material-ui/core/Typography";
import {
  closeCookiePolicy,
  showCookieToaster,
  showCookiePolicy
} from "../../store/actions/actions";

class CookiesNTF extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props); //testing only

    /* const { cookies } = props;
    cookies.set("cookiePolicyAccepted", "no", { path: "/" }); */
    this.state = {
      open: false,
      cookiePolicyAccepted: false
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAgree = () => {
    const { cookies } = this.props;

    cookies.set("cookiePolicyAccepted", "yes", { path: "/" });
    this.props.closeCookiePol();
    this.setState({ open: false });
  };

  handleDisagree = () => {
    //console.log("handle disagree");
    const { cookies } = this.props;

    cookies.set("cookiePolicyAccepted", "no", { path: "/" });

    this.props.closeCookiePol();
    this.props.showCookieToaster();
    this.setState({ open: false });
  };

  componentDidMount() {
    /* const { cookies } = this.props;
    const cookiePolicy = cookies.get("cookiePolicyAccepted");

    //console.log("COOKIE POLICY", cookiePolicy);

    switch (cookiePolicy) {
      case "yes":
        {
          //console.log("setting open to false");
          this.setState({ open: false });
        }
        break;
      default:
        {
          //console.log("setting open to true");
          this.setState({ open: true });
        }
        break;
    } */
  }

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={this.props.cookiePol}
          onClose={this.handleDisagree}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Information about our use of cookies"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="body1" gutterBottom>
                Our website uses cookies to distinguish you from other users of
                our website. This helps us to provide you with a good experience
                when you browse our website and also allows us to improve our
                site. [By continuing to browse the site, you are agreeing to our
                use of cookies.]
              </Typography>
              <Typography variant="body1" gutterBottom>
                A cookie is a small file of letters and numbers that we store on
                your browser or the hard drive of your computer if you agree.
                Cookies contain information that is transferred to your
                computer's hard drive.
              </Typography>
              <Typography variant="title" gutterBottom>
                We use the following cookies:
              </Typography>
              <Typography variant="subheading" gutterBottom>
                Strictly necessary cookies.
              </Typography>
              <Typography variant="body1" gutterBottom>
                These are cookies that are required for the operation of our
                website. They include, for example, cookies that enable you to
                log into secure areas of our website, use a shopping cart or
                make use of e-billing services.
              </Typography>
              <Typography variant="subheading" gutterBottom>
                Analytical/performance cookies.
              </Typography>
              <Typography variant="body1" gutterBottom>
                {" "}
                They allow us to recognise and count the number of visitors and
                to see how visitors move around our website when they are using
                it. This helps us to improve the way our website works, for
                example, by ensuring that users are finding what they are
                looking for easily.
              </Typography>
              <Typography variant="subheading" gutterBottom>
                Functionality cookies.
              </Typography>
              <Typography variant="body1" gutterBottom>
                These are used to recognise you when you return to our website.
                This enables us to personalise our content for you, greet you by
                name and remember your preferences (for example, your choice of
                language or region).
              </Typography>
              <Typography variant="subheading" gutterBottom>
                Targeting cookies.
              </Typography>
              <Typography variant="body1" gutterBottom>
                These cookies record your visit to our website, the pages you
                have visited and the links you have followed. We will use this
                information to make our website and the advertising displayed on
                it more relevant to your interests. We may also share this
                information with third parties for this purpose.
              </Typography>
              <Typography variant="body1" gutterBottom>
                You can find more information about the individual cookies we
                use and the purposes for which we use them below:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Cookie Name: isAuthenticated
              </Typography>
              <Typography variant="body2" gutterBottom>
                Purpose: Allow you to use our site in a way that makes your
                browsing experience more convenient, for example, by allowing
                you to store items in an electronic shopping basket between
                visits. If you register with us or complete our online forms, we
                will use cookies to remember your details during your current
                visit, and any future visits provided the cookie was not deleted
                in the interim.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Cookie Name: emailAddress
              </Typography>
              <Typography variant="body2" gutterBottom>
                Purpose: Recognise you when you return to our site.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Cookie Name: cookiePolicyAccepted
              </Typography>
              <Typography variant="body2" gutterBottom>
                Purpose: Store information about your preferences, and so allow
                us to customise our site and to provide you with offers that are
                targeted at your individual interests.
              </Typography>
              <Typography variant="body1" gutterBottom>
                [Please note that third parties (including, for example,
                advertising networks and providers of external services like web
                traffic analysis services) may also use cookies, over which we
                have no control. These cookies are likely to be
                analytical/performance cookies or targeting cookies] You block
                cookies by activating the setting on your browser that allows
                you to refuse the setting of all or some cookies. However, if
                you use your browser settings to block all cookies (including
                essential cookies) you may not be able to access all or parts of
                our site. Except for essential cookies, all cookies will expire
                after one hour.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDisagree} color="primary">
              Disagree
            </Button>
            <Button onClick={this.handleAgree} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cookiePol: state.cookiePol
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeCookiePol: () => dispatch(closeCookiePolicy()),
    showCookieToaster: () => dispatch(showCookieToaster())
  };
};

CookiesNTF.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withCookies(
  withMobileDialog()(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CookiesNTF)
  )
);
