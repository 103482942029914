import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { CLOSE_MODAL, COLLAB_REQUEST } from "../../store/actions/actions";
import "./CollabRequestModal.css";
import "../../App.css";
import { MdHelpOutline, MdCheck, MdClear } from "react-icons/md";
import { hostApi } from "../../constants";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import logo from "../../static/Sunrise_white.png";

class ReactModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,

      lkp_woi_capacity: 0,
      lkp_woi_role: 0,
      lkp_woi_remun: 0,

      collab_valueadd: "",
      collab_reason: "",
      collab_filepath: ""
    };

    this.toggle = this.toggle.bind(this);

    //console.log("this.props", this);
    //console.log("this.props.className", this.props.className);
  }

  // componentDidUpdate(){
  //   if( this.props.showSearchModal ) {
  //     this.props.stopBusy();
  //   }
  // }

  //Open and close modal

  toggle(e) {
    e.preventDefault();
    //console.log("toggle modal");
    this.setState({
      modal: false
    });

    this.props.closeModal();
  }

  //Text Input Handlers

  handleDesc = event => {
    //console.log("handleDesc", event.target.value);
    this.setState({ collab_reason: event.target.value });
  };

  handleValue = event => {
    //console.log("handleValue", event.target.value);
    this.setState({ collab_valueadd: event.target.value });
  };

  //Select menu handlers
  handleCapacity = event => {
    //get value from event
    //console.log("handleCapacity", event.target.value);

    //convert the string to the number
    let capacityOptions = 0;

    capacityOptions = parseInt(document.getElementById("Capacity").value);

    // setState with that number
    this.setState({ lkp_woi_capacity: capacityOptions });
  };

  handleRole = event => {
    //console.log("handleRole", event.target.value);
    //get value from event

    //convert the string to the number
    let roleOptions = 0;

    roleOptions = parseInt(document.getElementById("Role").value);

    // setState with that number
    this.setState({ lkp_woi_role: roleOptions });
  };

  handleRemuneration = event => {
    //console.log("handleRemuneration", event.target.value);
    //get value from event

    //convert the string to the number
    let remunOptions = 0;

    remunOptions = parseInt(document.getElementById("Remuneration").value);

    // setState with that number
    this.setState({ lkp_woi_remun: remunOptions });
  };

  //Post to REST API
  handleSubmit = event => {
    const { jwtToken } = this.props;
    console.log("handleSubmit", this.state);
    //console.log("Email", this.props.collabRequestEmail);
    //console.log("Idea ID", this.props.collabRequestIdeaID);
    /* {
      "profile_id"			:319,
      "idea_id"				: "GEFBWG",
      "lkp_woi_role"			: 1,
      "lkp_woi_capacity"		: 2,
      "lkp_woi_remun"			: 3,
      "collab_reason"			:"TEST Reason 0923",
      "collab_valueadd"		:"TEST Value 0923"
    } */
    axios
      .post(`${hostApi}/requestWorkOnIdea`,         
        {
        profile_id: this.props.collaboratorID,
        idea_id: this.props.collabRequestIdeaID,
        collab_reason: this.state.collab_reason,
        collab_valueadd: this.state.collab_valueadd,
        lkp_woi_role: this.state.lkp_woi_role,
        lkp_woi_capacity: this.state.lkp_woi_capacity,
        lkp_woi_remun: this.state.lkp_woi_remun
      },
    {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      },
    })
      .then(response => {
        //console.log("axios response", response);

        if (response.data.requestWorkonIdea_response === "FAIL") {
          NotificationManager.error(
            response.data.requestWorkonIdea_message,
            "Work on Idea Request Error",
            4000
          );
        } else {
          NotificationManager.success(
            "Success",
            "Work on Idea Request Sent",
            3000
          );
        }
      });

    this.toggle(event);
  };

  render() {
    // const roleTitles = [
    //   { label: 'Select your role', value: 0 },
    //   { label: 'Collaborator', value: 1 },
    //   { label: 'Implementer', value: 2 },
    //   { label: 'Funder', value: 3 }
    // ];

    // const capacityOptions = [
    //   { label: 'Select your capacity', value: 0 },
    //   { label: 'Full Time', value: 1 },
    //   { label: 'Part Time', value: 2 },
    //   { label: 'Voluntary', value: 3 }
    // ];

    // const remunerationOptions = [
    //   { label: 'Select your preferred remuneration', value: 0 },
    //   { label: 'Salary', value: 1 },
    //   { label: 'Fixed Fee', value: 2 },
    //   { label: 'Pro Bono', value: 3 }
    // ];

    return (
      <div>
        <Modal isOpen={this.props.showModal} toggle={this.toggle}>
          <ModalHeader
            color="secondary"
            toggle={this.toggle}
            style={{ color: "#000", textAlign: "center" }}
            className={this.props.className}
          >
          <span className="create-idea-modal-header">
            <img
              src={logo}
              width={150}
              style={{
                marginTop: 10
              }}
            />
          </span>
            {this.props.title}
          </ModalHeader>
          <ModalBody style={{ color: "#000" }}>
            <MdHelpOutline style={{ float: "right" }} onClick={this.toggle} />
            <Form>
              <FormGroup>
                <Label for="Role">Role</Label>
                <Input
                  type="select"
                  name="Role"
                  id="Role"
                  onChange={this.handleRole}
                  // option={roleTitles}
                >
                  <option value="0">Select your role</option>
                  <option value="1">Collaborator</option>
                  <option value="2">Implementer</option>
                  <option value="3">Funder</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="Capacity">Capacity</Label>
                <Input
                  type="select"
                  name="Capacity"
                  id="Capacity"
                  onChange={this.handleCapacity}
                  // option={capacityOptions}
                >
                  <option value="0">Select your capacity</option>
                  <option value="1">Full Time</option>
                  <option value="2">Part Time</option>
                  <option value="3">Voiluntary</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="Remuneration">Remuneration</Label>
                <Input
                  type="select"
                  name="Remuneration"
                  id="Remuneration"
                  onChange={this.handleRemuneration}
                  // option={remunerationOptions}
                >
                  <option value="0">Select your remuneration preference</option>
                  <option value="1">Salary</option>
                  <option value="2">Fixed Fee</option>
                  <option value="3">Pro Bono</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="Describe">
                  Describe why you want to collaborate
                </Label>
                <Input
                  type="input"
                  name="ID"
                  id="ID"
                  placeholder="e.g. I'm an engineer"
                  onChange={this.handleDesc}
                />
              </FormGroup>
              <FormGroup>
                <Label for="Value">
                  What value could you bring to this idea?
                </Label>
                <Input
                  type="input"
                  name="Value"
                  id="ID"
                  placeholder="e.g. I have experience in this field"
                  onChange={this.handleValue}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Link
              to="#"
              className="custom-btn custom-btn-gray"
              onClick={this.toggle}
            >
              <MdClear size={30} />
            </Link>
            <Link
              to="#"
              className="custom-btn custom-btn-green"
              onClick={this.handleSubmit}
            >
              <MdCheck size={30} />
            </Link>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showModal: state.showCollabRequestModal,
    collabRequestEmail: state.collabRequestEmail,
    collabRequestIdeaID: state.collabRequestIdeaID,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: CLOSE_MODAL }),
    dispatchCollabRequest: CollabRequest => {
      dispatch({
        type: COLLAB_REQUEST,
        payload: {
          CollabRequest: CollabRequest
        }
      });
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReactModal)
);
