import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import "../../App.css";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormHeader from "../../components/FormHeader/FormHeader";
import RegStepQuick from "./RegStepQuick";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { timeOut } from "../../constants";
import "firebase/auth";
import "firebase/database";
import "../Signup/Signup.css";

const styles = theme => ({
  root: {
    width: "100%"
  },
  container: {
    display: "flex",
    flexWrap: "nowrap"
  },
  overrides: {
    MuiAvatar: {
      // Name of the component ⚛️ / style sheet
      // Name of the rule
      backgroundColor: "white !important"
    },
    MuiAvatarColorDefault: {
      backgroundColor: "white"
    },
    "& .MuiAvatar-colorDefault-254": {
      backgroundColor: "white !important"
    }
  }
});

const createSteps = [0, 1, 2];

const joinSteps = [0, 1, 2];

class QuickSignup extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      userId: "",
      profileId: "",
      journey: cookies.get("joinType" || ""),
      showReg: true,
      step: 1,
      journeySteps: [],
      journeyStep: "",
      myArray: [],
      firstname: "",
      surname: "",
      lkp_email: "",
      profilePicLink: "",
      joinTypeValue: "individual",
      authenticated: true,
      authUser: true,
      pendingResponse: false,
      isSubmit: false,
      disablePrev: true
    };

    this.setTitle = this.setTitle.bind(this);
    this.setJourney = this.setJourney.bind(this);
    this.setJourneyStep = this.setJourneyStep.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value }, () => {
      this.setJourney("IDEATOR");
    });
  };

  componentWillMount() {
    this.setJourney(this.state.journey);
  }

  componentWillUnmount() {
    this.setState({
      authenticated: false,
      loading: false
    });
  }

  setTitle = value => {
    if (this.state.headerTitle === value) {
      return;
    } else {
      this.setState({ headerTitle: value });
    }
  };

  setJourney(type) {
    if (type === "CREATE" || "IDEATOR") {
      this.state.journeySteps = createSteps;
    } else {
      this.state.journeySteps = joinSteps;
    }
    this.setState({ journeyStep: this.state.journeySteps[this.state.step] });
  }

  setJourneyStep(i) {
    this.setState({ journeyStep: this.state.journeySteps[i] });
  }

  handleNext = e => {
    let i = (this.state.step = this.state.step + 1);
    this.setJourneyStep(i);
  };

  handlePrev = e => {
    let i = (this.state.step = this.state.step - 1);
    this.setJourneyStep(i);
  };

  handleCloseReg = () => {
    this.setState({ showReg: false });
    this.props.history.push(`/`);
  };

  handlePassToLogin = email => {
    this.props.cookies.set("emailAddress", email, {
      path: "/",
      maxAge: timeOut
    });
    this.props.history.push("/login");
  };

  navigateToMySPRXS = (
    userId,
    profileId,
    email,
    firstName,
    lastName,
    userType
  ) => {
    this.handleCloseReg();
    window.sessionStorage.setItem(
      "userInfo",
      JSON.stringify({
        email: email,
        firstName: firstName,
        lastName: lastName
      })
    );

    this.props.cookies.set("profile_id", profileId, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("fireId", userId, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("isAuthenticated", true, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("emailAddress", email, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("name", firstName, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("last_name", lastName, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("user_type", userType, {
      path: "/",
      maxAge: timeOut
    });

    this.props.cookies.remove("joinType");

    var defaultPage = `/dashboard`;
    this.props.history.push(defaultPage, {
      data: { firstname: firstName }
    });
  };

  render() {
    const { lkp_email, profilePicLink } = this.state;
    const { fullScreen } = this.props;

    return (
      <div className="Signup-style app-bg">
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          fullScreen={fullScreen}
          open={this.state.showReg}
          onClose={this.handleCloseReg}
          disableBackdropClick={true}
          aria-labelledby="responsive-dialog-title"
        >
          <FormHeader>
            Create a Covid-19 idea {this.state.headerTitle}{" "}
          </FormHeader>
          <DialogContent>
            <RegStepQuick
              className={"MuiAvatar-colorDefault-254"}
              style={{ backgroundColor: "#fafafa !important" }}
              // className={styles.MuiAvatarColorDefault}
              journey={this.state.journey}
              currentStep={this.state.journeyStep}
              setFormTitle={this.setTitle}
              setJourney={this.setJourney}
              handleNext={this.handleNext}
              navigateToMySPRXS={this.navigateToMySPRXS}
              handlePassToLogin={this.handlePassToLogin}
              email={lkp_email}
              // profilePicLink={profilePicLink || null}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseReg} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.name && <h1>Hello {this.state.name}!</h1>}
      </div>
    );
  }
}

QuickSignup.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
  onClick: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

export default withRouter(
  withCookies(connect()(withStyles(styles)(withMobileDialog()(QuickSignup))))
);
