import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CompTC from "../Terms/CompTC";
import {showCompModal} from "../../store/actions/actions";

class CompDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      checkedComp: false
    };
  }
  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
    this.props.handleClose();
  };

  handleCheckedComp = () => {
    this.setState({checkedComp: !this.state.checkedComp});
  };

  /*   componentDidUpdate() {
    const { open } = this.props;

    if (open === true) {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
  } */

  render() {
    const {fullScreen, open} = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Enter competition"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To enter our competition for a chance to win £9,250 click the
              button below.
            </DialogContentText>
            <FormControlLabel
              style={{marginLeft: "0%", marginTop: "13px"}}
              control={
                <Checkbox
                  checked={this.state.checkedComp}
                  error={this.state.checkedComp}
                  onChange={this.handleCheckedComp}
                  value="checkedComp"
                  color="primary"
                />
              }
              //label="I have read and accepted the Privacy Policy and Terms and Conditions"
              label={
                <div>
                  <span>by ticking this box you agree to the </span>
                  <Link to="#" onClick={this.props.showCompModal}>
                    competition rules
                  </Link>
                </div>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              No thanks
            </Button>
            <Button
              disabled={!this.state.checkedComp}
              onClick={this.handleClose}
              color="primary"
              autoFocus
            >
              Enter
            </Button>
          </DialogActions>
        </Dialog>
        <CompTC />
      </div>
    );
  }
}

CompDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    showCompModal: () => dispatch(showCompModal())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withMobileDialog()(CompDialog));
