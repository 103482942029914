import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import "../../App.css";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormHeader from "../../components/FormHeader/FormHeader";
import RegistrationStepper from "./RegStep";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import fire from "../../constants";
import { timeOut } from "../../constants";
import "./Signup.css";
import * as firebase from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import "react-firebaseui";
import "firebase/auth";
import "firebase/database";

const styles = theme => ({
  root: {
    width: "100%"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  }
});

const createSteps = [0, 1, 2, 4];
const joinSteps = [0, 1, 2, 4];

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to sign in is successful.
  signInSuccessUrl: "/socialsignup",
  signInOptions: [
    // {
    //   provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //   requireDisplayName: false,
    //   authMethod: "https://accounts.google.com",
    //   clientId:
    //     "533616585441-8h1q7pliohhkcn9v1pggchtqqbtbn94p.apps.googleusercontent.com"
    // },
    {
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      scopes: ["public_profile", "email"]
    }
    //,{ provider: firebase.auth.EmailAuthProvider.PROVIDER_ID }
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => true,
    signInFailure: function(error) {
      // For merge conflicts, the error.code will be anonymous-upgrade-merge-conflict
      if (error.code !== "firebaseui/anonymous-upgrade-merge-conflict") {
        return Promise.resolve();
      }
    }
  }
};

class Signup extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      journey: cookies.get("joinType" || ""),
      showReg: true,
      step: 0,
      authUser: false,
      isSignedIn: false,
      journeySteps: [],
      journeyStep: "",
      myArray: [],
      disablePrev: true
    };
    this.setTitle = this.setTitle.bind(this);
    this.setJourney = this.setJourney.bind(this);
    this.setJourneyStep = this.setJourneyStep.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
  }

  componentDidMount() {
    this.setJourney("CREATE");
    this.unregisterAuthObserver = fire.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        this.setState({ authUser: true, isSignedIn: !!user });
        // this.props.history.push("/login");
      }
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  setTitle = value => {
    if (this.state.headerTitle === value) {
      return;
    } else {
      this.setState({ headerTitle: value });
    }
  };

  setJourney(type) {
    if (type === "CREATE" || "IDEATOR") {
      this.state.journeySteps = createSteps;
    } else {
      this.state.journeySteps = joinSteps;
    }
    this.setState({ journeyStep: this.state.journeySteps[this.state.step] });
  }

  setJourneyStep(i) {
    this.setState({ journeyStep: this.state.journeySteps[i] });
  }

  handleNext = e => {
    let i = (this.state.step = this.state.step + 1);
    this.setJourneyStep(i);
  };

  handlePrev = e => {
    let i = (this.state.step = this.state.step - 1);
    this.setJourneyStep(i);
  };

  handleCloseReg = () => {
    this.setState({ showReg: false });
    this.props.history.push(`/`);
  };

  handlePassToLogin = email => {
    this.props.cookies.set("emailAddress", email, {
      path: "/",
      maxAge: timeOut
    });
    this.props.history.push("/login");
  };

  navigateToMySPRXS = (
    userId,
    profileId,
    email,
    firstName,
    lastName,
    userType
  ) => {
    this.handleCloseReg();
    window.sessionStorage.setItem(
      "userInfo",
      JSON.stringify({
        email: email,
        firstName: firstName,
        lastName: lastName
      })
    );

    this.props.cookies.set("profile_id", profileId, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("fireId", userId, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("isAuthenticated", true, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("emailAddress", email, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("name", firstName, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("last_name", lastName, {
      path: "/",
      maxAge: timeOut
    });
    this.props.cookies.set("user_type", userType, {
      path: "/",
      maxAge: timeOut
    });

    this.props.cookies.remove("joinType");

    var defaultPage = `/dashboard`;
    this.props.history.push(defaultPage, {
      data: { firstname: firstName }
    });
  };

  render() {
    const { fullScreen } = this.props;
    const { step } = this.state;
    return (
      <div className="Signup-style app-bg">
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          fullScreen={fullScreen}
          open={this.state.showReg}
          onClose={this.handleCloseReg}
          disableBackdropClick={true}
          aria-labelledby="responsive-dialog-title"
        >
          <FormHeader>{this.state.headerTitle} </FormHeader>
          <DialogContent>
            <RegistrationStepper
              journey={this.state.journey}
              currentStep={this.state.journeyStep}
              setFormTitle={this.setTitle}
              setJourney={this.setJourney}
              handleNext={this.handleNext}
              handlePrev={this.handlePrev}
              navigateToMySPRXS={this.navigateToMySPRXS}
              handlePassToLogin={this.handlePassToLogin}
            />
            {step === 0 && (
              <StyledFirebaseAuth
                className={styles.firebaseui}
                uiConfig={uiConfig}
                firebaseAuth={fire.auth()}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseReg} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Signup.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  showReg: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

export default withRouter(
  withCookies(connect()(withStyles(styles)(withMobileDialog()(Signup))))
);
