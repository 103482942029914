import React from 'react';
import { Progress } from 'reactstrap';

const ProgressBar = (props) => {
    if(!props.show) {
        return null;
    }
  return (
    <div className="sticky-top">
      <Progress animated color="info" value={100} />
    </div>
  );
};

export default ProgressBar;