import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import VideoNav from "./VideoNav";
import Cvideo from "./Covid.mp4";
import greenCovid from "./greenCovid.jpg";
import PlayIcon from "@material-ui/icons/PlayCircleOutline";
import ReactPlayer from "react-player";
import "./Video.css";
// https://player.vimeo.com/video/292816980
const styles = theme => ({
  root: {
    flexGrow: 1,
    scrollBehavior: "smooth"
  }
});

class VideoPlayer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      playerSource: Cvideo,
      showOverlay: true,
      height: props.height,
      width: props.width,
      playing: false,
      isPlaying: false,
      play: e => this.onPlay(e)
    };
    this.updateVideo = this.updateVideo.bind(this);
  }

  onPause = e => {
    this.setState({ playing: false, showOverlay: true });
  };
  onPlay = e => {
    this.setState({ playing: true, showOverlay: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.playerSource !== prevState.playerSource) {
    }
  }

  updateVideo = e => {
    this.setState({ playerSource: e });
  };

  render() {
    const { playerSource, showOverlay } = this.state;
    return (
      <div className="sectionContainer video">
        <div className="empty" />
        <div>
          <h4 className="sectionTitle">WHAT IS SPRXS?</h4>
        </div>
        <div className="gridSection2Row">
          <div className="videoWrapper">
            <iframe
              src={playerSource}
              // src={"https://vimeo.com/400901429"}
              title="video"
              frameBorder="0"
              onPlay={e => this.onPlay(e)}
              onload="this.style.opacity = 1"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              sandbox
            />
          </div>
          <VideoNav updateVideo={this.updateVideo} />
        </div>
      </div>
    );
  }
}

{
  /* <PlayCircleFilledIcon
fontSize="large"
className="playButton2"
/> */
}

VideoPlayer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(VideoPlayer));
