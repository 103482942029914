import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  getCollaborators,
  getEquityByIdea,
  getRemainingTokens,
  showChatModal,
  getEquityPerIdea,
  getPendingEquityForIdea
} from "../../store/actions/actions";
import { connect } from "react-redux";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Slide from "@material-ui/core/Slide";
import "../../components/MyWallet/MyWallet.css";
import "./CollaboratorsByIdea.css";
import { hostApi } from "../../constants";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  root: {
    textAlign: "center",
    overflowX: "hidden"
  }
});

const maxEquity = 100;

class CollaboratorsByIdea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      error: false,
      disableSubmit: true,
      value: 3,
      userArray: [],
      collaborators: [],
      values: {},
      remainingIdeaEquity: maxEquity - props.tokenBalance,
      sumOfEquity: props.tokenBalance,
      valueArray: [],
      showChat: false
    };

    this.timer = null;
  }

  componentWillMount() {
    const { ideaID, jwtToken } = this.props;
    if (ideaID !== undefined) {
      this.props.getCollaborators(jwtToken, ideaID); //FIXME: only call this if it hasn't already been called for this ideaID
      this.props.getEquityByIdea(jwtToken, ideaID);
      this.props.getPendingEquityForIdea(jwtToken, ideaID);
      this.props.getRemainingTokens(jwtToken,ideaID);
      this.setState({ userArray: { ...this.props.collaboratorsByIdea } });
    }
  }

  componentDidUpdate(prevProps) {
    /**
     * This update function is used to listen for the response data
     * triggered by the getEquityByIdea() axios post in componentWillMount()
     */

    if (prevProps.equityHolders !== this.props.equityHolders) {
      let copyOfCollaborators = this.props.equityHolders.map(a =>
        Object.assign({ ...a, startingBalance: a.tokensOwned })
      );

      let copyOfPendingEquityRecord = this.props.pendingEquity.map(a =>
        Object.assign({ ...a, pendingBalance: a.tokens })
      );

      //let copyOfCollaborators = [...this.props.equityHolders];
      let _valueArray = [];
      let _pendingEquityValueArray = [];

      copyOfPendingEquityRecord.map(item =>
        _pendingEquityValueArray.push(item.tokens)
      );

      /* Create a values array that is used internally to manage equity  */
      copyOfCollaborators.map((item, i) => {
        if (item.id == 0) {
          copyOfPendingEquityRecord.map((pendingItem, j) => {
            if (item.profileId === pendingItem.profileId) {
              if (item.tokensOwned === pendingItem.tokens) {
                copyOfCollaborators[0].tokensOwned += item.tokensOwned;
                item.tokensOwned = 0;
              }
            }
          });
        }
      });
      copyOfCollaborators.map(item => _valueArray.push(item.tokensOwned));
      this.setState({
        collaborators: [...copyOfCollaborators],
        valueArray: [..._valueArray],
        sumOfEquity: _valueArray.reduce((a, b) => a + b, 0)
      });
    }
  }

  launchChat = () => {
    this.setState({ showChat: true });
    this.props.showChat();
  };

  handleCloseChat = () => {
    this.setState({ showChat: false });
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  updateEquity = (i, value) => {
    let copyOfValueArray = [...this.state.valueArray];
    let copyOfCollaborators = [...this.state.collaborators];
    let _value = value;

    copyOfValueArray[i] = _value;

    let tmpSum = copyOfValueArray.reduce((a, b) => a + b, 0);

    if (tmpSum > maxEquity) {
      _value = value - (tmpSum - maxEquity);
    }

    copyOfCollaborators[i].tokensOwned = _value;
    copyOfValueArray[i] = _value;

    this.setState({
      collaborators: [...copyOfCollaborators],
      valueArray: [...copyOfValueArray],
      sumOfEquity: copyOfValueArray.reduce((a, b) => a + b, 0), //sum of array
      disableSubmit: copyOfValueArray.length > 1 ? false : true
    });
  };

  handleChange = (event, value, i) => {
    const { sumOfEquity, collaborators, remainingIdeaEquity } = this.state;

    /* Make a copy so we can modify the copy */
    let _collaborators = [...this.state.collaborators];

    /* Modify the copy */
    _collaborators[i].tokensOwned = value;

    let calculatedEquity = 0;

    _collaborators.map(
      item => (calculatedEquity = calculatedEquity + item.tokensOwned)
    );

    if (calculatedEquity > maxEquity) {
      _collaborators[i].tokensOwned = remainingIdeaEquity;
      calculatedEquity = maxEquity;
    }
    /* Update the state with the new values */
    this.setState(prevState => ({
      collaborators: [..._collaborators],
      sumOfEquity: calculatedEquity,
      remainingIdeaEquity: maxEquity - calculatedEquity,
      disableSubmit: false
    }));
  };

  handleSubmit = () => {
    const { collaborators } = this.state;
    const { onClose, getRemainingTokens, token } = this.props;
    const that = this;
    let { timer } = this;
    let ownerEmail = "";
    let ideaID = "";
    let axiosCallStack = [];

    collaborators.map((item, i) => {
      if (i === 0) {
        /* This is the first in the list and therfore the owner */
        ownerEmail = item.lkpEmail;
        ideaID = item.ideaUniqueId;
      } else {
        /* Now build the list of axios posts for each collaborator
         * if their token allocation has changed.
         */
        if (item.startingBalance !== item.tokensOwned) {
          axiosCallStack.push(
            axios.post(
              `${hostApi}/AddEquity`,
              {
                idea_uniqueid: item.ideaUniqueId,
                owner_profile: ownerEmail,
                collab_profile: item.lkpEmail,
                tokens_allocated: item.tokensOwned - item.startingBalance
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            )
          );
        }
      }
    });

    const promisesResolved = axiosCallStack.map(promise =>
      promise.catch(error => ({ error }))
    );

    function checkFailed(then) {
      return function(responses) {
        const someFailed = responses.some(response => response.error);

        if (someFailed) {
          throw responses;
        }

        return then(responses);
      };
    }

    if (axiosCallStack.length) {
      axios
        .all(promisesResolved)
        .then(
          checkFailed(promisesResolved => {
            that.setState({ alert: false });

            promisesResolved.map(item => {
              switch (item.status) {
                case 200:
                  NotificationManager.success(
                    "Success",
                    "Equity transferred",
                    3000
                  );

                  that.setState({ alert: false });

                  break;
                default:
                  NotificationManager.error(
                    "Error",
                    "Equity transfer error",
                    3000
                  );
                  that.setState({ alert: false });
                  break;
              }
            });
            getRemainingTokens(this.props.jwtToken, ideaID);
          })
        )
        .catch(err => {
          NotificationManager.error("Error", "Equity transfer error", 3000);
          that.setState({ alert: false });
        });
    } else {
      NotificationManager.warning(
        "You must allocate the surplus to a collaborator.",
        "Alert",
        3000
      );
      this.setState({ alert: false });
    }
  };

  render() {
    const {
      classes,
      collaboratorsByIdea,
      equity,
      openChat,
      handleOpenMilestoneForm,
      startChatWithPerson,
      jwtToken
    } = this.props;
    const { sumOfEquity, collaborators } = this.state;

    return (
      <div className="equity-list__wrapper">
        <h3 className="equity-list__title">Idea Equity Breakdown</h3>
        <table className="equity-list">
          <thead>
            <tr className="equity-list__header">
              {/* <td></td> */}
              <td>Name</td>
              <td>Equity</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {collaborators.map((item, i) => {
              return (
                <tr key={item.id}>
                  <td className="equity-list__nameField">
                    <div className="equity-list__name">{`${item.firstname} ${item.lastname}`}</div>
                    {/* <div className="equity-list__id">{`${woiRole[item.lkpWoiRole].label}`}</div> */}
                  </td>
                  <td>
                    <div className="equity-list__status">
                      {this.state.collaborators[i].tokensOwned}
                    </div>
                  </td>
                  <td className="equity-list__expand">
                    {/* {i === 0 && (
                 <IconButtonComponent 
                    type={"chat"}
                    toolTip={true}
                    title={"Chat"}
                    iconStyle={"iconAction"}
                    link={() => startChatWithPerson(0)}
                  />
                )} */}
                    {i > 0 && (
                      <>
                        {/* <IconButtonComponent 
                      type={"chat"}
                      toolTip={true}
                      title={"Chat"}
                      iconStyle={"iconAction iconPadRight"}
                      link={() => startChatWithPerson(item.firebaseUid)}
                    /> */}
                        <IconButtonComponent
                          type={"token"}
                          toolTip={true}
                          title={`Transfer Equity to ${item.firstname} ${item.lastname}`}
                          iconStyle={"iconAction"}
                          link={i =>
                            handleOpenMilestoneForm(
                              i,
                              true,
                              item.profileId,
                              item.firstname,
                              item.lastname
                            )
                          }
                        />
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

CollaboratorsByIdea.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    collaboratorsByIdea: state.collaboratorsByIdea,
    equityHolders: state.equityHolders,
    token: state.jwtToken,
    pendingEquity: state.pendingEquity,
    availableTokens: state.availableTokens
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCollaborators: (jwtToken, payload) => {
      getCollaborators(jwtToken, payload, dispatch);
    },
    getEquityByIdea: (jwtToken, ideaID) => {
      getEquityByIdea(jwtToken, ideaID, dispatch);
    },
    getEquityPerIdea: (jwtToken, ideaID) => {
      getEquityPerIdea(jwtToken, ideaID, dispatch);
    },
    getRemainingTokens: (jwtToken, ideaID) => {
      getRemainingTokens(jwtToken, ideaID, dispatch);
    },
    getPendingEquityForIdea: (jwtToken, ideaID) => {
      getPendingEquityForIdea(jwtToken, ideaID, dispatch);
    },
    showChat: () => dispatch(showChatModal())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CollaboratorsByIdea));
