import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import MilestoneList from "../MilestoneList/MilestoneList";
import MilestoneForm from "../MilestoneForm/MilestoneForm";
import { hostApi } from "../../../constants";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { NotificationManager } from "react-notifications";
import { Fab } from "@material-ui/core";
import milestonelogo from "../../../static/milestonelogo.svg";
import "./MilestoneDashboard.css";
import CardHeader from "@material-ui/core/CardHeader";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  title: {
    textAlign: "center",
    marginTop: theme.spacing.unit * 4,
    placeItem: "center"
  },
  dialog: {
    overflowX: "hidden",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  formLogo: {
    width: 56,
    marginRight: -3,
    marginTop: 15
  },
  helperText: {
    color: "#000",
    textAlign: "center",
    paddingLeft: theme.spacing.unit * 5
  }
});

class MilestoneDashboard extends React.Component {
  state = { openMilestoneForm: false, milestoneData: [] };

  handleLaunchMSForm = () => {
    this.setState({ openMilestoneForm: true });
  };

  closeMSForm = () => {
    this.setState({ openMilestoneForm: false });
  };

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    // check if there is data inside props before trying accesmielsing it
    if (data && prevProps.data !== data) {
      console.log("vivian2", data);
      axios
        .post(`${hostApi}/viewMilestone`, {
          id: 0,
          idea_id: data.ideaUniqueID,
          collab_profile: "",
          owner_profile: ""
        },
        {
          headers:{
            "Authorization": `Bearer ${this.props.jwtToken}`
          },
        })
        .then(response => {
          //console.log("vivian3", response);
          this.setState({ milestoneData: response.data });
        })
        .catch(error => {
          NotificationManager.error(error.message, error.code, 4000);
        });
    }
  }

  updateMilestoneData = (mileStone, actionType) => {
    if (actionType === "add") {
      this.setState(state => {
        state.milestoneData = [...state.milestoneData, mileStone];
        return state;
      });
    } else if (actionType === "update") {
      //console.log(mileStone);
      this.setState(state => {
        state.milestoneData = state.milestoneData.map(mileStoneParam => {
          return mileStoneParam.id === mileStone.id
            ? // return the new updated milestone, otherwise
            {
              ...mileStoneParam,
              milestoneName: mileStone.milestone_name,
              milestoneDescription: mileStone.milestone_description
            }
            : mileStoneParam;
        });
        return state;
      });
    }
  };

  handleCancelMilestone = id => {
    console.log('milestone id', id)
    const newMilestones = this.state.milestoneData.filter(m => m.id != id)
    this.setState({
      milestoneData: newMilestones
    })
  }

  render() {
    const {
      classes,
      fullScreen,
      open = false,
      onClose,
      data /*pass the card data here*/,
      jwtToken
    } = this.props;

    return (
      <div className={classes.dialog}>
        <Dialog
          style={{ marginTop: 0 }}
          fullWidth={true}
          fullScreen={false} // for mobile
          open={open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <CardHeader
              action={<img src={milestonelogo} className={classes.formLogo} />}
              title={
                <Typography variant="h5" color="primary" gutterBottom>
                  Milestone Timeline
                </Typography>
              }
              subheader={`Milestones for Idea: ${data.ideaUniqueID} - ${
                data.ideaName
                }`}
            />
          </DialogTitle>

          <Tooltip title="Add new milestone">
            <Fab
              onClick={this.handleLaunchMSForm}
              className="add-milestone-button"
              variant="fab"
              color="secondary"
              aria-label="Add"
              size="large"
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <div className="milestone-logo-bg-2">
            <div className="milestone-logo-bg grid-milestone-list">
              <div className="milestone-button">
                {!this.state.milestoneData.length && (
                  <div className="milestone-helpertext">
                    Milestones are a way for you to track the progress of your
                    ideas.
                    <br />
                    Click on the + button to add your first milestone.{" "}
                  </div>
                )}
              </div>
              {this.state.milestoneData && (
                <MilestoneList
                  data={this.state.milestoneData}
                  ideaName={this.props.data.ideaName}
                  updateMilestoneData={this.updateMilestoneData}
                  handleCancelMilestone={this.handleCancelMilestone}
                />
              )}
            </div>
          </div>
          <MilestoneForm
            jwtToken={this.props.jwtToken}
            data={data}
            open={this.state.openMilestoneForm}
            updateMilestoneData={this.updateMilestoneData}
            closeMilestoneForm={this.closeMSForm}
          />
          
        </Dialog>
      </div>
    );
  }
}

MilestoneDashboard.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(MilestoneDashboard)
);
