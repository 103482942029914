import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  CLOSE_MODAL,
  SEARCH_CRITERIA,
  showCompModal,
  showPrivModal,
  showTermsPolicy,
  showCookiePolicy,
  showCompEntered,
  showIdeaSubmittedNTF
} from "../../store/actions/actions";
import {
  hostApi,
  ideaClassification,
  genesisMaturity,
  purposeClassification
} from "../../constants";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { FormLabel } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Icon from "@material-ui/core/Icon";
import { AccessAlarm, ThreeDRotation, Send } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import classNames from "classnames";
import ProgressButton from "../ProgressButton/ProgressButton";
import CompDialog from "./CompDialog";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import Chip from "@material-ui/core/Chip";
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";
import firebase from "../../constants";

const styles = theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2
  },
  resetContainer: {
    padding: theme.spacing.unit * 3
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },

  menu: {
    width: 400
  },

  input: {
    display: "none"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  chip: {
    margin: theme.spacing.unit * 2
  }
});

function getSteps() {
  return [
    "You have a ...",
    "based on my ...",
    "That will impact ...",
    "Idea name and description",
    "Submit a file"
  ];
}

function validateStep5(context) {
  const {
    firstname,
    lastname,
    lkp_email,
    lkp_email2,
    emailValidation,
    emailValidation2,
    checkedA,
    checkedB
  } = context.state;

  if (
    firstname === "" ||
    lastname === "" ||
    lkp_email === "" ||
    lkp_email2 === "" ||
    emailValidation === true ||
    emailValidation2 === true ||
    checkedA === false ||
    checkedB === false ||
    lkp_email !== lkp_email2
  ) {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

function getStepContent(step, classes, context, handleCat1, handleCat2) {
  switch (step) {
    case 0:
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="select-category"
              select
              /* label="Select Idea" */
              fullWidth={true}
              className={classes.textField}
              value={context.state.lkp_idea_cat1}
              onChange={handleCat1}
              /* InputLabelProps={{ shrink: true }} */
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              variant="outlined"
              margin="normal"
            >
              {ideaClassification.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {`${option.label} idea`}
                </MenuItem>
              ))}
            </TextField>
          </form>
        </div>
      );
    case 1:
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="select-category"
              select
              /* label="Select Idea/Invention" */
              fullWidth={true}
              className={classes.textField}
              value={context.state.lkp_idea_cat2}
              onChange={handleCat2}
              InputLabelProps={{ shrink: true }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              variant="outlined"
              margin="normal"
            >
              {genesisMaturity.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {`${option.label}`}
                </MenuItem>
              ))}
            </TextField>
          </form>
        </div>
      );
    case 2:
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="select-category"
              select
              fullWidth={true}
              /* label="Purpose Classification" */
              className={classes.textField}
              value={context.state.lkp_idea_cat3}
              onChange={context.handleCat3}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              variant="outlined"
              margin="normal"
            >
              {purposeClassification.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
        </div>
      );
    case 3:
      return (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              required
              error={context.state.invalidIdeaName}
              id="required"
              label="Idea Name"
              placeholder="e.g. Flux Capacitor"
              helperText="Enter the name of your idea"
              className={classes.textField}
              value={context.state.idea_name}
              onChange={context.handleIdeaName}
              fullWidth={true}
              variant="outlined"
              margin="normal"
            />

            <TextField
              required
              error={context.state.invalidDesc}
              id="multiline-flexible"
              label="Description"
              multiline
              rows="6"
              value={context.state.idea_description}
              placeholder="e.g. time travel"
              helperText={
                context.state.invalidDesc
                  ? "Please enter 500 characters or less"
                  : "Provide a short description of your idea"
              }
              onChange={context.handleDesc}
              className={classes.textField}
              fullWidth={true}
              variant="outlined"
              margin="normal"
            />

            <input
              accept="xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.odt,.ods,.svg,.csv"
              className={classes.input}
              onChange={context.updateFilename}
              id="contained-button-file"
              multiple
              type="file"
            />
            <div style={{ display: "inline-block", marginLeft: "0%" }}>
              <label htmlFor="contained-button-file">
                <Button
                  color="secondary"
                  variant="contained"
                  component="span"
                  className={classes.button}
                >
                  Submit a file
                </Button>
              </label>
            </div>
            <TextField
              placeholder=""
              variant="outlined"
              fullWidth={true}
              className={classes.textField}
              /* style={{fontSize:"10pt"}} */
              label="Filename"
              value={context.state.filename}
              inputProps={{
                "aria-label": "Description"
              }}
            />
          </form>
        </div>
      );
    default:
      return "Unknown step";
  }
}

class EditIdea extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    activeStep: 0,
    disableNext: true,
    lkp_idea_cat1: "",
    lkp_idea_cat2: "",
    lkp_idea_cat3: "",
    steps: [
      "I have a ...",
      "based on my ...",
      "That will impact ...",
      "Idea details"
    ],
    lkp_email: "",
    idea_name: "",
    idea_description: "",
    filename: "",
    fileDetails: [],
    fistname: "",
    lastname: "",
    checkedA: false,
    checkedB: false,
    openComp: false,
    successNtf: false
  };

  componentDidMount() {
    const { token } = this.props;
    
    axios
      .post(`${hostApi}/searchIdea`, {
        profile_id: this.props.cookies.collab_id,
        idea_uniqueid: this.props.data.ideaUniqueID
      },
    {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => {
        ////console.log("axios data", response.data);
        this.setState({
          lkp_email: response.data[0].lkp_email,
          idea_id: response.data[0].ideaUniqueID,
          idea_name: response.data[0].ideaName,
          idea_description: response.data[0].ideaDescription,
          lkp_idea_cat1: response.data[0].lkpIdeaCat1,
          lkp_idea_cat2: response.data[0].lkpIdeaCat2,
          lkp_idea_cat3: response.data[0].lkpIdeaCat3,
          ideaFilepath: response.data[0].ideaFilepath
        });
      });
  }

  handleNext = e => {
    e.preventDefault();

    ////console.log("activeStep", this.state.activeStep);
    ////console.log("state", this.state);

    let _steps = [...this.state.steps];

    /* Disble the 'NEXT' button if no entry selected */
    if (this.state.activeStep + 1 === 0 && this.state.lkp_idea_cat1 === "") {
      this.setState({ disableNext: true });
    } else if (
      this.state.activeStep + 1 === 1 &&
      this.state.lkp_idea_cat2 === ""
    ) {
      ////console.log("disable next step 1", this.state.activeStep);
      this.setState({ disableNext: true });
    } else if (
      this.state.activeStep + 1 === 2 &&
      this.state.lkp_idea_cat3 === ""
    ) {
      ////console.log("disable next step 2", this.state.activeStep);
      this.setState({ disableNext: true });
    } else if (
      this.state.activeStep + 1 === 2 &&
      this.state.lkp_idea_cat3 === ""
    ) {
      ////console.log("disable next step 2", this.state.activeStep);
      this.setState({ disableNext: true });
    } else if (
      this.state.activeStep + 1 === 3 &&
      (this.state.idea_name === "" ||
        this.state.idea_description === "" ||
        this.state.invalidDesc === true)
    ) {
      ////console.log("disable next step 3", this.state.activeStep);
      this.setState({ disableNext: true });
    } else {
      ////console.log("enable next step", this.state.activeStep);
      this.setState({ disableNext: false });
      ////console.log("state", this.state);
    }

    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));

    /* Display the selected item in the step label */
    switch (this.state.activeStep) {
      case 0:
        _steps[this.state.activeStep] = `${_steps[this.state.activeStep]}
          ${ideaClassification[this.state.lkp_idea_cat1 - 1].label} idea`;
        this.setState({ steps: [..._steps] });
        return;
      case 1:
        _steps[this.state.activeStep] = `${_steps[this.state.activeStep]}
          ${genesisMaturity[this.state.lkp_idea_cat2 - 1].label}`;
        this.setState({ steps: [..._steps] });
        return;
      case 2:
        _steps[this.state.activeStep] = `${_steps[this.state.activeStep]}
          ${purposeClassification[this.state.lkp_idea_cat3 - 1].label}`;
        this.setState({ steps: [..._steps] });
        return;
      default:
        return;
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));

    this.setState({ disableNext: false });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      lkp_idea_cat1: "",
      lkp_idea_cat2: "",
      lkp_idea_cat3: "",
      lkp_email: "",
      idea_name: "",
      idea_description: "",
      filename: "",
      fileDetails: [],
      steps: [
        "You have a ...",
        "based on my ...",
        "That will impact ...",
        "Idea details",
        "Your details"
      ]
    });
  };

  handleCat1 = event => {
    ////console.log("handleCat1", event.target.value);
    this.setState({
      lkp_idea_cat1: event.target.value,
      disableNext: false
    });
  };

  handleCat2 = event => {
    ////console.log("handleCat2", event.target.value);
    this.setState({
      lkp_idea_cat2: event.target.value,
      disableNext: false
    });
  };

  handleCat3 = event => {
    ////console.log("handleCat3", event.target.value);
    this.setState({
      lkp_idea_cat3: event.target.value,
      disableNext: false
    });
  };

  handleIdeaName = event => {
    ////console.log("handleIdeaName", event.target.value);
    this.setState({ idea_name: event.target.value });

    if (event.target.value === "" || this.state.idea_description === "") {
      this.setState({ disableNext: true });
    } else {
      this.setState({ disableNext: false });
    }
  };

  handleDesc = event => {
    ////console.log("handleDesc", event.target.value);

    ////console.log("length", event.target.value.length);

    this.setState({ idea_description: event.target.value });

    if (event.target.value.length > 500) {
      this.setState({ invalidDesc: true });
    } else {
      this.setState({ invalidDesc: false });
    }

    if (
      this.state.invalidDesc === true ||
      event.target.value === "" ||
      this.state.idea_name === ""
    ) {
      this.setState({ disableNext: true });
    } else {
      this.setState({ disableNext: false });
    }
  };

  updateFilename = event => {
    console.clear();
    console.log("EDIT IDEA MODAL");
    if (event.target.files.length) {
      this.setState({
        filename: event.target.files[0].name,
        fileDetails: event.target.files[0]
      });
    } else {
      console.log("no file");
    }
  };

  handleFirstname = e => {
    ////console.log("handle firstname", e.target.value);
    this.setState(
      {
        firstname: e.target.value,
        firstNameValid: true,
        invalidFirstName: false
      },
      () => {
        this.setState({ disableNext: validateStep5(this) });
      }
    );
  };

  handleLastname = e => {
    ////console.log("handle lastname", e.target.value);

    this.setState(
      {
        lastname: e.target.value,
        lastNameValid: true,
        invalidLastName: false
      },
      () => {
        this.setState({ disableNext: validateStep5(this) });
      }
    );
  };

  handleEmail = e => {
    const emailValid = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );

    ////console.log("handleEmail", emailValid);

    this.setState({
      emailValidation: emailValid === null ? true : false
    });

    this.setState({ lkp_email: e.target.value }, () => {
      this.setState({ disableNext: validateStep5(this) });
    });
  };

  handleEmail2 = e => {
    const emailValid2 = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );

    ////console.log("handleEmail2", emailValid2);

    this.setState({
      emailValidation2: emailValid2 === null ? true : false
    });

    this.setState({ lkp_email2: e.target.value }, () => {
      this.setState({ disableNext: validateStep5(this) });
    });
  };

  handleCheckA = event => {
    ////console.log("handleCheckA", event.target.checked);
    this.setState({ checkedA: !this.state.checkedA }, () => {
      this.setState({ disableNext: validateStep5(this) });
    });
    ////console.log("STATE", this.state);
  };
  handleCheckB = event => {
    ////console.log("handleCheckB", event.target.checked);
    this.setState({ checkedB: !this.state.checkedB }, () => {
      this.setState({ disableNext: validateStep5(this) });
    });

    ////console.log("STATE", this.state, !this.state.checkedB);

    //this.setState({ disableNext: validateStep5(this) });
  };

  handleCloseComp = () => {
    this.setState({ openComp: false });
  };

  handleCloseAlert = () => {
    this.setState({ successNtf: false });
  };

  handleSubmit = event => {
    //console.log("EditIdeaModal: handleSubmit");
    event.preventDefault();
    const { cookies, token } = this.props;
    // var formData = new FormData();
    // ////console.log("EditIdeaModal.jsx - Line 608: ", cookies.get("emailAddress"));
    // this.setState({ isLoading: true });
    // formData.append("lkp_email", cookies.get("emailAddress"));
    // formData.append("idea_id", this.state.idea_id);
    // formData.append("idea_name", this.state.idea_name);
    // formData.append("lkp_idea_cat1", this.state.lkp_idea_cat1);
    // formData.append("lkp_idea_cat2", this.state.lkp_idea_cat2);
    // formData.append("lkp_idea_cat3", this.state.lkp_idea_cat3);
    // formData.append("idea_description", this.state.idea_description);
    // if (this.state.fileDetails.filename !== "") {
    //   formData.append("filename", this.state.filename);
    // } else {
    //   formData.append("filename", "undefined");
    // }

    axios
      .post(
        `${hostApi}/editIdea_v2`,
        {
          lkp_email: this.props.emailAddress || cookies.get("emailAddress"),
          idea_id: this.state.idea_id,
          idea_name: this.state.idea_name,
          lkp_idea_cat1: this.state.lkp_idea_cat1,
          lkp_idea_cat2: this.state.lkp_idea_cat2,
          lkp_idea_cat3: this.state.lkp_idea_cat3,
          idea_description: this.state.idea_description,
          filename: this.state.filename
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        }
      )
      .then(response => {
        //console.log("axios response", response);
        if (response.data.createIdea_response === "FAIL") {
          NotificationManager.error(
            response.data.login_message,
            "Edit Idea Error",
            4000
          );
        } else {
          if (this.state.filename != "") {
            console.log("EIM Fileshare START");
            let filename = this.state.filename;
            var user = firebase.auth().currentUser;
            // Points to the root reference
            var storageRef = firebase.storage().ref();

            // Points to 'All user files'

            var myIdeaFolder = storageRef
              .child("AllUsersFileData")
              .child(user.uid)
              .child("myIdeas")
              .child(filename);
            var file = this.state.fileDetails;
            myIdeaFolder
              .put(file)
              .then(function(snapshot) {
                console.log("Uploaded a file for the idea");
              })
              .catch(error => {
                console.log("ERROR UPLOADING FILE in CIEU:", error);
              });
          }
          NotificationManager.success(
            `Idea updated on blockchain with ID ${response.data.idea_ID}`,
            "Edit Idea",
            5000
          );
        }
        //this.toggle(event);
      })
      .catch(error => {
        //console.log("ERROR", error);
        NotificationManager.error("API Error", "Edit Idea Error", 4000);
      });

    this.setState({ successNtf: true });

    this.props.onClose();

    //this.props.closeModal();
    //this.props.showIdeaSubmittedNTF();
    /* this.setState({openComp: true}); */
    // this.props.handleSetCloseButton();
  };

  render() {
    const { classes, lkp_idea_cat1, lkp_idea_cat2, lkp_idea_cat3 } = this.props;
    /* const steps = getSteps(); */
    const { activeStep, disableNext, steps } = this.state;

    const returnChips = (lkp_idea_cat1, lkp_idea_cat2, lkp_idea_cat3) => {
      return (
        <div>
          {lkp_idea_cat1 && (
            <Typography>
              You have a
              <Chip
                color="primary"
                label={`${ideaClassification[lkp_idea_cat1 - 1].label}`}
                className={classes.chip}
              />{" "}
              idea, <br />
              {lkp_idea_cat2 && "based on my "}
              {lkp_idea_cat2 && (
                <Chip
                  color="primary"
                  label={`${
                    genesisMaturity[this.state.lkp_idea_cat2 - 1].label
                  }`}
                  className={classes.chip}
                />
              )}{" "}
              {lkp_idea_cat3 && "that will impact "}
              {lkp_idea_cat3 && (
                <Chip
                  color="primary"
                  label={`${
                    purposeClassification[this.state.lkp_idea_cat3 - 1].label
                  }`}
                  className={classes.chip}
                />
              )}
            </Typography>
          )}
        </div>
      );
    };

    return (
      <div className={classes.root}>
        {returnChips(lkp_idea_cat1, lkp_idea_cat2, lkp_idea_cat3)}
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {getStepContent(
                    index,
                    classes,
                    this,
                    this.handleCat1,
                    this.handleCat2
                  )}
                  {/* <Typography>{getStepContent(index)}</Typography> */}

                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        disabled={disableNext}
                        color="secondary"
                        onClick={this.handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>
              All steps completed - you&#39;re finished. Click on submit to
              submit your idea to the blockchain
            </Typography>
            <Button onClick={this.handleReset} className={classes.button}>
              Reset
            </Button>

            <ProgressButton
              submitHandler={this.handleSubmit}
              closeModal={this.props.closeModal}
              circle={true}
              fullwidth={false}
            />
          </Paper>
        )}
        <CompDialog
          open={this.state.openComp}
          handleClose={this.handleCloseComp}
        />
      </div>
    );
  }
}

EditIdea.propTypes = {
  classes: PropTypes.object,
  lkpIdeaCat1: PropTypes.number.isRequired,
  lkpIdeaCat2: PropTypes.number.isRequired,
  lkpIdeaCat3: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  return {
    emailAddress: state.emailAddress,
    isAuthenticated: state.isAuthenticated,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: CLOSE_MODAL }),
    showCompEnteredModal: () => dispatch(showCompEntered()),
    showPriv: () => dispatch(showPrivModal()),
    showTerms: () => dispatch(showTermsPolicy()),
    showCompModal: () => dispatch(showCompModal()),
    showIdeaSubmittedNTF: () => dispatch(showIdeaSubmittedNTF()),
    dispatchSearchCriteria: searchCriteria => {
      dispatch({
        type: SEARCH_CRITERIA,
        payload: {
          searchCriteria: searchCriteria
        }
      });
    }
  };
};

export default withCookies(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(EditIdea))
);
