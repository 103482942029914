import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import {
  hostApi,
  ideaStatus,
  ideaClassification,
  genesisMaturity
} from "../../constants";
import { withStyles } from "@material-ui/core/styles";
import { userIsAuthenticated } from "../../authentication/authentication";
import "../../App.css";
import {
  TERMS_ALERT,
  SHOW_CREATE_IDEA_MODAL,
  showChatModal
} from "../../store/actions/actions";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { Paper, Grid } from "@material-ui/core";
import SpeedDials from "../../components/SpeedDials/SpeedDials";
import FrontPageButton from "../../components/FrontPageButton/FrontPageButton";
import ActivityFeed from "../../components/ActivityFeed/ActivityFeed";
import ActivityFeedNews from "../../components/ActivityFeed/ActivityFeedNews";
import CreateIcon from "@material-ui/icons/Create";
import ChatDialog from "../ChatDialog/ChatDialog";
import bgimage from "../../static/bk3.jpg";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    backgroundColor: /* theme.palette.primary.main */ "slategrey",
    backgroundImage: `url(${bgimage})`, 
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "top",
    paddingTop: "80px",
    minHeight: "100vh",
    margin: "-8px"
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  grow: {
    flexGrow: 1
  },
  card: {
    maxWidth: 345
  },
  media: {
    height: 140
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  paper: {
    padding: theme.spacing.unit * 0,
    textAlign: "center",
    color:
      theme.palette.primary.contrastText /* theme.palette.text.secondary */,
    backgroundColor: /* theme.palette.primary.main */ "transparent",
    boxShadow: "none",
    margin: "-8px"
  },

  backPaper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.main,
     backgroundImage: `url(${bgimage})`, 
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "top"
  },
  banner: {
    display: "flex",
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    justifyContent: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: /* theme.palette.primary.main */ "transparent",
    boxShadow: "none"
  },
  buttons: {
    display: "flex",
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    justifyContent: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: /* theme.palette.primary.main */ "transparent",
    boxShadow: "none"
  },
  logo: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "50vh"
  },
  button: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
    boxShadow: "none",
    /* height: "30vh", */
    transform: "translateY(20%)"
  },
  section: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    /* backgroundColor: "transparent", */
    boxShadow: "none",
    height: "80vh",
    marginBottom: "10%"
  },
  video: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    /* backgroundColor: "transparent", */
    boxShadow: "none",
    height: "80vh"
    /* marginBottom: "10%" */
  },
  media: {
    height: 140
  },
  avatar: {
    margin: 10,
    backgroundColor: "white"
  },
  bigAvatar: {
    width: 180,
    height: 180,
    border: "10px",
    borderColor: "#fff"
  }
});

class MySPRXS extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    this.state = {
      name: cookies.get("name") || "",
      isAuthenticated: cookies.get("isAuthenticated") || false,
      height: props.height,
      width: props.width,
      isLoading: false,
      cardView: true,
      isFlipped: false,
      data_items: [],
      showChat: false
    };
  }

  componentDidMount() {
    //console.log("componentDidMount(------------->)", this.props);
    //console.log("WINDOW : ", window);
    //console.log("theme", this.props.theme);
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
    const { cookies } = this.props;

    const cookie_email = cookies.get("emailAddress");

    //console.log("cookie email", cookie_email);

    this.setState({ isLoading: true }); //Start the spinner

    if (this.props.data_items) {
      //console.log("UPDATE DATA");
      this.setState({ data_items: this.props.data_items });
    }
    this.setState({ isLoading: false });
    axios
      .post(`${hostApi}/myIdeas`, {
        lkp_email: cookie_email,
        idea_name: this.props.searchName || "",
        idea_description: this.props.searchDesc || ""
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`
        }
      })
      .then(response => {
        //console.log("axios response ajb", response);

        this.setState({ data_items: response.data, isLoading: false });
      });

    this.setState({ isAuthenticated: cookies.get("isAuthenticated") });
  }

  handleLike = e => {
    e.preventDefault();
  };

  handleChangeView = e => {
    this.setState({ cardView: !this.state.cardView });
  };

  handleFlip = e => {
    e.preventDefault();
    this.setState({ isFlipped: !this.state.isFlipped });
  };

  launchChat = () => {
    this.setState({ showChat: true });
  };
  handleCloseChat = () => {
    this.setState({ showChat: false });
  };

  render() {
    const { classes, cookies, firebaseUid } = this.props;
    const { showChat } = this.state;
    const resizeWindow = this.state.width < 500;
    const sName = this.props.searchName;

    const firstName = cookies.get("name");
    const lastName = cookies.get("last_name");

    return (
      
      <Paper style={{backgroundColor: "transparent"}}>
        <Grid className={classes.root} container spacing={8}>
          <Grid item xs={2}><Paper className={classes.paper}></Paper></Grid>
          <Grid item xs={8}>
            <Paper className={classes.paper}>
              <br /><Typography color="secondary" variant="display1"> {firstName} {lastName}</Typography>
              <Paper className={classes.buttons}>
                <FrontPageButton title={"Create Idea"} icon={<CreateIcon className={classes.extendedIcon} />} onClick={this.props.showCreateIdeaModal}/>
              </Paper>
            </Paper>
          </Grid>
          <Grid item xs={2}><Paper className={classes.paper} /></Grid>
          <Grid item xs={1}><Paper className={classes.paper} /></Grid>
          <Grid item xs={10}><Paper className={classes.paper}><ActivityFeed /></Paper></Grid>
          <Grid item xs={1}><Paper className={classes.paper} /></Grid>
        </Grid>
        <SpeedDials hidden={false} direction="up" fixed={true} openChat={this.props.showChat}/>
        <ChatDialog open={showChat} onClose={this.handleCloseChat} />
      </Paper>


    );
  }
}

const mapStateToProps = state => {
  return {
    searchName: state.searchName,
    searchDesc: state.searchDesc,
    searchID: state.searchID,
    isAuthenticated: state.isAuthenticated,
    animate: state.animation,
    data_items: state.searchResults,
    emailAddress: state.emailAddress,
    firebaseUid: state.firebaseUid,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showCreateIdeaModal: () => dispatch({ type: SHOW_CREATE_IDEA_MODAL }),
    dispatchToggleTermsAlert: () => dispatch({ type: TERMS_ALERT }),
    showChat: () => dispatch(showChatModal())
  };
};

export default withRouter(
  withCookies(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(styles, { withTheme: true })(userIsAuthenticated(MySPRXS)))
  )
);
