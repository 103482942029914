import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationIcon from '@material-ui/icons/Navigation';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

function FrontPageButton(props) {
  const { classes, title, icon } = props;

  return (
    <div>
      <Button onClick={props.onClick} color="primary"  variant="extendedFab" aria-label="Delete" className={classes.button}>
        {icon}
        {title}
      </Button>
    </div>
  );
}

FrontPageButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FrontPageButton);
