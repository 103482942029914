import React, { Component } from "react";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { closeCookieToaster, showCookiePolicy } from "../../store/actions/actions";
import { withCookies, Cookies } from "react-cookie";

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2
  },

  depth: {
    zIndex: "999999",
  },

});

class Toaster extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props);

    /* const { cookies } = props;
    cookies.set("cookiePolicyAccepted", "no"); */ /* TESTING */

    this.state = {
      open: false,
      cookiePolicyAccepted: false
    };
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    const { cookies } = this.props;

    //console.log("Cookie Reason", reason);

    if (reason === "clickaway") {
      cookies.set("cookiePolicyAccepted", "no", { path: "/" });
      this.props.closeCookieToaster();
      return;
    }

    cookies.set("cookiePolicyAccepted", "yes", { path: "/" });

    this.setState({ open: false });
    this.props.closeCookieToaster();

  };

  handleShowPolicy = () => {
    this.setState({open: false});
    this.props.showCookiePolicy();
    this.props.closeCookieToaster();

  }

  componentDidMount() {
    const { cookies } = this.props;
    const cookiePolicy = cookies.get("cookiePolicyAccepted");

    //console.log("COOKIE POLICY", cookiePolicy);

    switch (cookiePolicy) {
      case "yes":
        {
          //console.log("setting open to false");
          this.setState({ open: false });
          this.props.closeCookieToaster();
        }
        break;
      default:
        {
          //console.log("setting open to true");
          this.setState({ open: true });
        }
        break;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.depth}>
        <Snackbar
        color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.open || this.props.cookieToaster}
          /* autoHideDuration={6000} */
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={
            <span id="message-id">
              We use cookies for this service. By closing this you agree to the
              cookie policy
            </span>
          }
          action={[
            <Button
              key="undo"
              color="primary"
              size="small"
              onClick={this.handleClose}
            >
              Accept Policy
            </Button>,
            <Button
            key="undo"
            color="primary"
            size="small"
            onClick={this.handleShowPolicy}
          >
            View Policy
          </Button>,
          <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

Toaster.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    cookieToaster: state.cookieToaster
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeCookieToaster: () => dispatch(closeCookieToaster()),
    showCookiePolicy: () => dispatch(showCookiePolicy())
  };
};

export default 
  withCookies(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(styles)(Toaster))
  )
;
