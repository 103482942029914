import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardLink,
  CardTitle,
  CardSubtitle,
  Container,
  Badge,
  Collapse,
  Button
} from "reactstrap";

import {
  MdEmail,
  MdAssessment,
  MdAssignment,
  MdShare,
  MdToys,
  MdTrackChanges,
  MdLaunch,
  MdQuestionAnswer,
  MdThumbUp,
  MdThumbDown,
  MdMore,
  MdSupervisorAccount,
  MdWhatshot,
  MdGroup,
  MdStar
} from "react-icons/md";
import {
  SHOW_COLLAB_REQUEST_MODAL,
  SHOW_ENQUIRY_MODAL,
  SHOW_REQUEST_FOR_UNIQUE_MODAL
} from "../../store/actions/actions";
import "../../App.css";
import CardView2 from "../Card/CardView2";

const iconSize = 30;
const iconStyle = { float: "right", marginBottom: "10px" };

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing.unit * 2
  },
  demo: {}
});

class CardView extends Component {
  static propTypes = {
    prop: PropTypes
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  //Event handlers for opening modals and passing props to them
  handleCollab = (e, lkpEmail, ideaUniqueID) => {
    e.preventDefault();
    ////console.log("Email", lkpEmail);
    ////console.log("Idea ID", ideaUniqueID);
    this.props.dispatchCollabModal(lkpEmail, ideaUniqueID);
  };

  handleEnquiry = (e, lkpEmail, ideaUniqueID) => {
    e.preventDefault();
    ////console.log("Email", lkpEmail);
    ////console.log("Idea ID", ideaUniqueID);
    this.props.dispatchEnquiryModal(lkpEmail, ideaUniqueID);
  };

  handleUnique = (e, lkpEmail, ideaUniqueID) => {
    e.preventDefault();
    ////console.log("Email", lkpEmail);
    ////console.log("Idea ID", ideaUniqueID);
    this.props.dispatchRequestForUniqueModal(lkpEmail, ideaUniqueID);
  };

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const {
      data_items,
      resizeWindow,
      classes,
      pub,
      cookie_email,
      launchIdeaDash,
      jwtToken
    } = this.props;
    ////console.log("Cardview.js Line 103");
    ////console.log(this.props);

    if (data_items) {
      data_items.map(v => (v.isWorkflow = true));
      ////console.log("data_items", data_items);
    }

    return (
      <Grid container className={classes.root} spacing={16}>
        <Grid item xs={12}>
          <Grid
            container
            className={classes.demo}
            justify="center"
            spacing={16}
          >
            {data_items &&
              data_items.map((item, i) => {
                ////console.log("item", item);
                ////console.log("IdeaID", item.ideaUniqueID);
                ////console.log("Cardview Line 124 -");
                ////console.log(this.props);
                ////console.log("Cardview Line 126 - ");
                ////console.log(this.props.cookie_email); // TEST

                return (
                  <Grid key={i} item >
                    <CardView2
                      jwtToken={this.props.jwtToken}
                      entity={item}
                      pub={pub}
                      cookie_email={this.props.cookie_email}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    dispatchCollabModal: (lkpEmail, ideaUniqueID) =>
      dispatch({
        type: SHOW_COLLAB_REQUEST_MODAL,
        payload: {
          lkpEmail: lkpEmail,
          ideaUniqueID: ideaUniqueID
        }
      }),
    dispatchEnquiryModal: (lkpEmail, ideaUniqueID) =>
      dispatch({
        type: SHOW_ENQUIRY_MODAL,
        payload: {
          lkpEmail: lkpEmail,
          ideaUniqueID: ideaUniqueID
        }
      }),
    dispatchRequestForUniqueModal: (lkpEmail, ideaUniqueID) =>
      dispatch({
        type: SHOW_REQUEST_FOR_UNIQUE_MODAL,
        payload: {
          lkpEmail: lkpEmail,
          ideaUniqueID: ideaUniqueID
        }
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CardView));
