import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { CLOSE_MODAL } from "../../store/actions/actions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Typography, Tooltip, Divider } from "@material-ui/core";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import FormHeader from "../FormHeader/FormHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";


const styles = theme => ({
  root: {
    marginTop: 100,
    width: "100%",
    flexGrow: 1
  },
  skillName: {
    fontSize: "0.8rem",
    border: "0px solid red",
    width: "280px"
  },
  skillLevel: {
    border: "0px solid blue",
    float: "Right"
  }

});

function getRating(rating) {
  switch (rating) {
    case 2:
      return (
        <Tooltip title={"Intermediate"}>
          <span>
            <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
            <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
            <IconButtonComponent  type={"starBorder"} iconStyle={"iconDefault iconCodeBlue"} />
          </span>
        </Tooltip>
      );

    case 3:
      return (
        <Tooltip title={"Expert"}>
          <span>
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          </span>
        </Tooltip>
      );

    default:
      //includes rating of 1
      return (
        <Tooltip title={"Beginner"}>
          <span>
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"starBorder"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"starBorder"} iconStyle={"iconDefault iconCodeBlue"} />
          </span>
        </Tooltip>
      );
  }
}

class CollabProfile extends Component {
  state = {
    open: false,
    scroll: "paper",
    closeButton: false
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.closeModal();
  };

  handleSetCloseButton = () => {
    this.setState({ closeButton: true });
  };

  render() {
    const { classes, fullScreen, data, open } = this.props;
    return (
      <div>
        {data != 0 && (
         <Dialog
          maxWidth="sm"
          fullWidth={true}
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleCloseReg}
          disableBackdropClick={false}
          aria-labelledby="responsive-dialog-title"
        >
          <FormHeader>{`${data.profileName} ${data.profileSurname}`}</FormHeader>
          <DialogContent>
            <DialogContentText>
              <Typography
                style={{ paddingLeft: 10 }}
                variant="h5"
                color="inherit"
                gutterBottom
              >
                
              </Typography>
              <Typography variant="caption" color="inherit">
                Reason to join:
              </Typography>
              <Typography
                style={{ paddingLeft: 10 }}
                variant="subtitle1"
                color="inherit"
                gutterBottom
              >
                {`${data.profileReason}`}
              </Typography>
              <Typography variant="caption" color="inherit">
                Value I will bring to the project:
              </Typography>
              <Typography
                style={{ paddingLeft: 10 }}
                variant="subtitle1"
                color="inherit"
                gutterBottom
              >
                {`${data.profileValue}`}
              </Typography>
              {data.profileDesc && (
              <>
              <Typography variant="caption" color="inherit">
                About me:
              </Typography>
              <Typography
                style={{ paddingLeft: 10 }}
                variant="subtitle1"
                color="inherit"
                gutterBottom
              >
                {`${data.profileDesc}`}
              </Typography>
              <Typography variant="caption" color="inherit">
                Skills:
              </Typography>
                {data.profileSkils.map(skill => {
                  return <List dense={true}>
                    <ListItem>
                      <ListItemText
                        className={classes.skillName}
                        primary={
                          <Typography >{skill.skillName}</Typography>
                        }
                      />
                      <ListItemSecondaryAction className={classes.skillLevel}>
                        {getRating(skill.skillLevel)}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                })}
              </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: state.showRTWOIProfile,
    emailAddress: state.emailAddress,
    isAuthenticated: state.isAuthenticated,
    data: state.rtwoiProfile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: CLOSE_MODAL })
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withMobileDialog()(CollabProfile)))
);
