import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  closeAccountModal,
  editProfile,
  getMyProfile,
  launchAlert,
  logOut
} from "../../store/actions/actions";
import fire, { hostApi, firebasePassword } from "../../constants";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormHeader from "../FormHeader/FormHeader";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import "./AccountSettings.css";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import { reset } from "ansi-colors";
import Switch from "@material-ui/core/Switch";

const firebase = require("firebase");

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 3}px`,
    marginTop: theme.spacing.unit * 2
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginTop: 10
  },
  input: {
    display: "none"
  },
  btnContainer: {
    overflowY: "hidden"
  }
});

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 2999;
`;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function validatePasswords(context) {
  const { currentPassword, password, confirmPassword } = context.state;

  if (currentPassword === "" || password === "" || confirmPassword === "") {
    return false; /* disable the button */
  } else {
    return true; /* enable the button */
  }
}

export class AccountSettings extends Component {
  static propTypes = {
    prop: PropTypes
  };
  state = {
    showEmailFields: false,
    showPasswordFields: false,
    showNotifications: false,
    showDeleteAccount: false,
    showConfirmDeleteAccount: false,
    warning: false,
    disableButton: true,
    disableText: "textEnabled",
    currentPassword: "",
    password: "",
    confirmPassword: "",
    currentEmail: "",
    email: "",
    confirmEmail: "",
    emailValidation: false,
    opt_in_notif: false,
    opt_in_comp: false,
    isLoading: false,
    saveDisabled: true,
    myProfile: []
  };

  componentDidMount = () => {
    this.props.getMyProfile(
      this.props.cookies.get("bearerToken"),
      this.props.cookies.get("emailAddress")
    );
  };
  ////// OnEnterDialog
  handleEnter = () => {
    this.closeForms();
    this.resetForm();
    this.props.getMyProfile(
      this.props.cookies.get("bearerToken"),
      this.props.cookies.get("emailAddress")
    );
    this.setState({
      opt_in_comp: this.props.myProfile.opt_in_comp,
      opt_in_notif: this.props.myProfile.opt_in_notif
    });
  };

  ////// Close Forms
  closeForms = () => {
    this.setState({
      showEmailFields: false,
      showPasswordFields: false,
      showNotifications: false,
      showDeleteAccount: false,
      showConfirmDeleteAccount: false,
      warning: false
    });
  };
  ////// Reset Form States
  resetForm = () => {
    this.setState({
      currentPassword: "",
      password: "",
      confirmPassword: "",
      currentEmail: "",
      email: "",
      confirmEmail: "",
      isLoading: false,
      saveDisabled: true
    });
  };

  updateData = () => {
    // this.props.getMyProfile(this.props.cookies.get("bearerToken"), this.props.cookies.get("emailAddress"));
    this.setState({
      // opt_in_comp: this.props.myProfile.opt_in_comp,
      opt_in_notif: this.props.myProfile.opt_in_notif
    });
  };

  //////// Show Hide Different Forms
  showEmailForm = () => {
    this.setState({ showEmailFields: true });
    this.disableAllText();
  };

  closeEmailForm = () => {
    this.setState({ showEmailFields: false });
    this.enableAllText();
    this.resetForm();
  };

  showPasswordForm = () => {
    this.setState({ showPasswordFields: true });
    this.disableAllText();
  };

  closePasswordForm = () => {
    this.setState({ showPasswordFields: false });
    this.enableAllText();
    this.resetForm();
  };

  showNotificationsForm = () => {
    this.setState({ showNotifications: true });
    this.disableAllText();
  };

  closeNotificationsForm = () => {
    this.setState({ showNotifications: false });
    this.enableAllText();
    this.resetForm();
  };

  showDeleteAccountForm = () => {
    this.setState({
      showDeleteAccount: true,
      showConfirmDeleteAccount: false,
      warning: true,
      showEmailFields: false,
      showPasswordFields: false,
      showNotifications: false
    });
  };

  closeDeleteAccountForm = () => {
    this.setState({ showDeleteAccount: false, warning: false });
    this.resetForm();
  };

  disableAllText = () => {
    this.setState({ disableText: "textEnabled" });
  };

  enableAllText = () => {
    this.setState({ disableText: "textEnabled" });
  };

  /// Form Field setState Updates
  handleUpdateComp = e => {
    this.setState(prevState => ({
      opt_in_comp: !prevState.opt_in_comp
    }));
  };

  handleUpdateNotifications = e => {
    this.setState(prevState => ({
      opt_in_notif: !prevState.opt_in_notif
    }));
  };

  handleSaveNotifications = () => {
    this.handleEditProfile();
  };

  handleCurrentEmail = e => {
    const emailValid = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    if (e.target.value.length === 0) {
      this.setState({ isDisabled: true, currentEmail: undefined });
    } else {
      this.setState({
        emailValidation: emailValid === null ? true : false
      });
      this.setState({ currentEmail: e.target.value.trim() });
    }
  };

  handleEmail = e => {
    const emailValid = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    if (e.target.value.length === 0) {
      this.setState({ isDisabled: true, email: undefined });
    } else {
      this.setState({
        emailValidation: emailValid === null ? true : false
      });
      this.setState({ email: e.target.value.trim() });
    }
  };

  handleConfirmEmail = e => {
    const emailValid = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    if (e.target.value.length === 0) {
      this.setState({ isDisabled: true, confirmEmail: undefined });
    } else {
      this.setState({
        emailValidation: emailValid === null ? true : false
      });
      this.setState({ confirmEmail: e.target.value.trim() });
    }
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleClickShowPassword2 = () => {
    this.setState(state => ({ showPassword2: !state.showPassword2 }));
  };

  handlePrevPassword = e => {
    this.setState({ currentPassword: e.target.value });
  };

  handlePassword = e => {
    this.setState({ password: e.target.value });
    if (e.target.value.length > 0) {
      this.setState({ disableButton: false });
    }
  };

  handleConfirmPassword = e => {
    this.setState({ confirmPassword: e.target.value });
    if (this.state.password === e.target.value && e.target.value.length > 0) {
      this.setState({ disableButton: false, saveDisabled: false });
    }
  };

  handleSavePassword = () => {
    //passthrough to edit profile.
    if (validatePasswords(this)) {
      this.handleEditProfile();
    } else {
    }
  };

  handlePass = () => {
    this.props.launchAlert({
      intent: "success",
      title: "Profile Updated",
      message: "Your Profile has been Updated"
    });
    // this.props.getMyProfile(this.props.cookies.get("bearerToken"), this.props.cookies.get("emailAddress"));
  };

  handleFail = () => {
    this.props.launchAlert({
      intent: "alert",
      title: "Error",
      message: "Sorry an error has occured. Please try again."
    });
  };

  handleDelete = () => {
    this.props.home();
    this.props.logOut(this.props.cookies);
  };

  handleCloseAccount = () => {
    this.setState({ showDeleteAccount: false, showConfirmDeleteAccount: true });
  };

  handleConfirmCloseAccount = () => {
    const that = this;
    this.setState({ isLoading: true });
    axios
      .post(
        `${hostApi}/deleteProfile`,
        {
          passwrd: this.state.password
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.cookies.get("bearerToken")}`
          }
        }
      )
      .then(response => {
        // success
        var user = fire.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          firebasePassword
        );
        user
          .reauthenticateWithCredential(credential)
          .then(function() {
            user
              .delete()
              .then(function() {
                // User deleted.
                //console.log("FIREBASE USER REMOVED");
                that.handleDelete();
              })
              .catch(function(error) {
                //console.log("FIREBASE DELETE ERROR", error);
                // An error happened.
                that.handleFail();
              });
          })
          .catch(function(error) {
            //console.log("FIREBASE AUTH ERROR", error);
            that.handleFail();
          });
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log("============ERROR===================");
        console.log(error);
        console.log("====================================");
        console.log(error.config);
        this.handleFail();
      });
  };

  handleEditProfile = () => {
    let dataPackage = {
      email_add: "",
      firstname: "",
      surname: "",
      lkp_country: 0,
      mobile_cc: 0,
      profileType: this.props.myProfile.profile_type,
      mobile_no: "",
      desktop_notif: false,
      opt_in_comp: this.state.opt_in_comp,
      opt_in_notif: this.state.opt_in_notif,
      existingPasswrd: this.state.currentPassword,
      passwrd: this.state.password,
      passwrd2: this.state.confirmPassword
    };
    //axios Post
    axios
      .post(`${hostApi}/editProfile`, dataPackage, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.cookies.get("bearerToken")}`
        }
      })
      .then(response => {
        if (response.data.createIdea_response === "FAIL") {
        } else {
          this.handlePass();
          dataPackage = {
            email_add: "",
            firstname: "",
            surname: "",
            lkp_country: 0,
            mobile_cc: 0,
            profileType: 0,
            mobile_no: "",
            desktop_notif: false,
            opt_in_comp: false,
            opt_in_notif: false,
            existingPasswrd: " ",
            passwrd: " ",
            passwrd2: " "
          };
          this.setState({
            password: "",
            currentPassword: "",
            confirmPassword: ""
          });
          this.closeForms();
          this.props.getMyProfile(
            this.props.cookies.get("bearerToken"),
            this.props.cookies.get("emailAddress")
          );
        }
      })
      .catch(error => {
        console.log("AXIOS ERROR", error);
        this.handleFail();
      });
  };

  render() {
    const { classes, cookies, onClose } = this.props;
    const {
      showEmailFields,
      showPasswordFields,
      showNotifications,
      showDeleteAccount,
      showConfirmDeleteAccount,
      warning,
      disableButton,
      disableText,
      opt_in_comp,
      opt_in_notif,
      currentEmail,
      email,
      confirmEmail,
      currentPassword,
      password,
      confirmPassword,
      saveDisabled
    } = this.state;

    const { myProfile } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.showAccountModal}
          onEnter={() => this.handleEnter()}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
          onClose={onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <FormHeader>Account Settings</FormHeader>
          <DialogContent>
            {Boolean(!warning) && (
              <>
                <span
                  className={`revealLink ${disableText}`}
                  onClick={() => this.showNotificationsForm()}
                >
                  Change Notifications
                </span>
                <p className={`revealText ${disableText}`}>
                  Update your SPRXS Notifications Settings.
                </p>
              </>
            )}
            {Boolean(showNotifications) && (
              <DialogContentText id="alert-dialog-slide-description">
                <form
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <FormControlLabel
                    style={{ marginLeft: "3%", marginTop: "-16px" }}
                    control={
                      <Switch
                        checked={opt_in_comp}
                        onChange={this.handleUpdateComp}
                        value="CheckedA"
                        color="primary"
                      />
                    }
                    label={
                      <div>
                        {opt_in_comp ? (
                          <span> {`Entered the SPRXS Competition.`}</span>
                        ) : (
                          <span>{`Removed from the SPRXS Competition.`}</span>
                        )}
                      </div>
                    }
                  />
                  <FormControlLabel
                    style={{ marginLeft: "3%", marginTop: "-10px" }}
                    control={
                      <Switch
                        checked={opt_in_notif}
                        onChange={this.handleUpdateNotifications}
                        value="CheckedB"
                        color="primary"
                      />
                    }
                    label={
                      <div>
                        {opt_in_notif ? (
                          <span>
                            Added to the SPRXS mailing list, newsletters and
                            SPRXS updates.{" "}
                          </span>
                        ) : (
                          <span>
                            Removed from the SPRXS mailing list, newsletters and
                            SPRXS updates.{" "}
                          </span>
                        )}
                      </div>
                    }
                  />

                  <span className="formButtons buttonBorder">
                    <IconButtonComponent
                      type={"close"}
                      toolTip={true}
                      title={"Close"}
                      iconStyle={"iconAction iconPadRight"}
                      link={() => {
                        this.closeNotificationsForm();
                      }}
                    />
                    <IconButtonComponent
                      type={"save"}
                      toolTip={true}
                      title={"Save"}
                      iconStyle={"iconAction"}
                      link={this.handleSaveNotifications}
                    />
                  </span>
                </form>
              </DialogContentText>
            )}
            {/* {Boolean(!warning) && (
            <>
              <span className={`revealLink ${disableText}`} onClick={() => this.showEmailForm()}>Change Email</span>
              <p className={`revealText ${disableText}`}>Update the email registered to your SPRXS account.</p>
            </>
            )}
            {Boolean(showEmail) && (
            <DialogContentText id="alert-dialog-slide-description">
            <form className={classes.container} noValidate autoComplete="off">
                <TextField
                  required
                  error={""}
                  id="required"
                  label="Type Your Current Email Address"
                  className={classes.textField}
                  value={currentEmail}
                  onChange={this.handleCurrentEmail}
                  fullWidth={true}
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  required
                  error={""}
                  id="required"
                  label="Type Your New Email Address"
                  className={classes.textField}
                  value={email}
                  onChange={this.handleEmail}
                  fullWidth={true}
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  required
                  error={""}
                  id="required"
                  label="Confirm Your New Email Address"
                  className={classes.textField}
                  value={confirmEmail}
                  onChange={this.handleConfirmEmail}
                  fullWidth={true}
                  variant="outlined"
                  margin="normal"
                />
                <span className="formButtons buttonBorder">
                 <IconButtonComponent type={"close"} toolTip={true} title={"Close"} iconStyle={"iconAction iconPadRight"} link={() => {
                  this.closeEmailForm();
                  }} />
                 <IconButtonComponent type={"save"} toolTip={true} title={"Save"} iconStyle={"iconAction"}  link={this.saveWeblinks}/>
                 </span>
                </form>
            </DialogContentText>
            )} */}
            {Boolean(!warning) && (
              <>
                <span
                  className={`revealLink ${disableText}`}
                  onClick={() => this.showPasswordForm()}
                >
                  Change Password
                </span>
                <p className={`revealText ${disableText}`}>
                  Update your password registered to your SPRXS account.
                </p>
              </>
            )}
            {Boolean(showPasswordFields) && (
              <DialogContentText id="alert-dialog-slide-description">
                <form
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    error={""}
                    label="Type Your Current Password"
                    required
                    id="outlined-adornment-password"
                    margin="normal"
                    autoFocus={true}
                    fullWidth={true}
                    className={classes.textField}
                    variant="outlined"
                    type={this.state.showPassword ? "text" : "password"}
                    value={currentPassword}
                    onChange={this.handlePrevPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    error={""}
                    label="Type Your New Password"
                    required
                    id="outlined-adornment-password"
                    margin="normal"
                    autoFocus={false}
                    fullWidth={true}
                    className={classes.textField}
                    variant="outlined"
                    type={this.state.showPassword2 ? "text" : "password"}
                    value={password}
                    onChange={this.handlePassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword2}
                          >
                            {this.state.showPassword2 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    error={
                      this.state.passWordValidation ||
                      password !== confirmPassword
                    }
                    label="Confirm Your New Password"
                    required
                    id="outlined-adornment-password"
                    margin="normal"
                    autoFocus={false}
                    fullWidth={true}
                    className={classes.textField}
                    variant="outlined"
                    type={this.state.showPassword2 ? "text" : "password"}
                    value={confirmPassword}
                    onChange={this.handleConfirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword2}
                          >
                            {this.state.showPassword2 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    helperText={
                      this.state.passwordValidation
                        ? " "
                        : password !== confirmPassword
                        ? "Password does not match"
                        : " "
                    }
                  />
                  <span className="formButtons buttonBorder">
                    <IconButtonComponent
                      type={"close"}
                      toolTip={true}
                      title={"Close"}
                      iconStyle={"iconAction iconPadRight"}
                      link={() => {
                        this.closePasswordForm();
                      }}
                    />
                    {Boolean(!saveDisabled) && (
                      <IconButtonComponent
                        type={"save"}
                        toolTip={true}
                        title={"Save"}
                        iconStyle={"iconAction"}
                        link={this.handleSavePassword}
                      />
                    )}
                  </span>
                </form>
              </DialogContentText>
            )}
            <span
              className={`revealLink ${disableText}`}
              onClick={() => this.showDeleteAccountForm()}
            >
              Close My SPRXS Account
            </span>
            <p className={`revealText ${disableText}`}></p>
            {Boolean(showDeleteAccount) && (
              <DialogContentText id="alert-dialog-slide-description">
                <p className="accountWarning">
                  Closing your SPRXS Account will remove all of your details,
                  Ideas and Equity from SPRXS.
                  <br />
                  Your Ideas will no longer be protected by Blockchain and no
                  data will be retained on our system.
                </p>
                <form
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    required
                    error={this.state.emailValidation}
                    id="required"
                    autoFocus={true}
                    label="Confirm Email Address"
                    className={classes.textField}
                    value={email}
                    onChange={this.handleEmail}
                    fullWidth={true}
                    variant="outlined"
                    margin="normal"
                    helperText={
                      this.state.emailValidation
                        ? "*Please enter a valid email address"
                        : ""
                    }
                  />
                  <TextField
                    required
                    id="outlined-adornment-password"
                    margin="normal"
                    autoFocus={false}
                    fullWidth={true}
                    className={classes.textField}
                    variant="outlined"
                    type={this.state.showPassword ? "text" : "password"}
                    label="Confirm Password"
                    value={this.state.password}
                    onChange={this.handlePassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <span className="formButtons">
                    <IconButtonComponent
                      type={"close"}
                      toolTip={true}
                      title={"Close"}
                      iconStyle={"iconAction iconPadRight"}
                      link={() => {
                        this.closeDeleteAccountForm();
                      }}
                    />
                    <Button
                      variant="contained"
                      disabled={this.state.disableButton}
                      color="secondary"
                      onClick={() => this.handleCloseAccount(this)}
                      className={`${classes.button} ${disableButton}`}
                    >
                      Close My SPRXS Account
                    </Button>
                  </span>
                </form>
              </DialogContentText>
            )}
            {showConfirmDeleteAccount && (
              <>
                {this.state.isLoading ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={35}
                    color={"#123abc"}
                    loading={this.state.isLoading}
                  />
                ) : (
                  <Button
                    variant="contained"
                    disabled={this.state.disableButton}
                    color="secondary"
                    onClick={() => this.handleConfirmCloseAccount(this)}
                    className={`${classes.button} ${disableButton}`}
                  >
                    Confirm SPRXS Account Deletion
                  </Button>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showAccountModal: state.showAccountModal,
    myProfile: state.myProfile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMyProfile: (jwtToken, emailAddress) => {
      getMyProfile(jwtToken, emailAddress, dispatch);
    },
    onClose: () => dispatch(closeAccountModal()),
    editProfile: (jwtToken, payload, callback) =>
      dispatch(editProfile(jwtToken, payload, callback)),
    launchAlert: payload => dispatch(launchAlert(payload)),
    logOut: cookies => {
      logOut(cookies, dispatch);
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMobileDialog()(AccountSettings)));
