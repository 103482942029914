import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { hostApi } from "../../constants";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { CLOSE_MODAL, REQUEST_FOR_UNIQUE } from "../../store/actions/actions";
import "./RequestForUniqueModal.css";
import "../../App.css";
import { MdHelpOutline, MdCheck, MdClear } from "react-icons/md";

class ReactModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,

      newlkpIdeaStatus: 0,
      note_summary: "",
      note_details: "",
      note_attachments: "",
      reviewer_profile_id: ""
    };

    this.toggle = this.toggle.bind(this);

    //console.log("this.props", this);
    //console.log("this.props.className", this.props.className);
  }

  // componentDidUpdate(){
  //   if( this.props.showSearchModal ) {
  //     this.props.stopBusy();
  //   }
  // }

  toggle(e) {
    e.preventDefault();
    //console.log("toggle modal");
    this.setState({
      modal: false
    });

    this.props.closeModal();
  }

  //Event handlers for text fields
  handleRequestSummary = event => {
    //console.log("handleRequestSummary", event.target.value);
    this.setState({ note_summary: event.target.value });
  };

  handleRequestDesc = event => {
    //console.log("handleRequestDesc", event.target.value);
    this.setState({ note_details: event.target.value });
  };

  handleSubmit = event => {
    const { token } = this.props;
    //console.log("handleSubmit", this.state);
    //console.log("Idea ID", this.props.requestForUniqueIdeaID);
    
    //Request for Enquiry Post Route for REST API
    axios
        .post(`${hostApi}/createIdeaWorkflow`, {
          idea_ID: this.props.requestForUniqueIdeaID,
          newlkpIdeaStatus: 6,
          note_summary: this.state.note_summary,
          note_details: this.state.note_details,
          note_attachments: this.state.note_attachments,
          note_attachments: this.state.note_attachments,
          reviewer_profile_id: this.state.reviewer_profile_id
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          //console.log("axios response", response);

          if (response.data.createIdeaWorkflow_response === "FAIL") {
            NotificationManager.error(
              response.data.createIdeaWorkflow_message,
              "Request for Unique Error",
              4000
            );
          } else {
            NotificationManager.success("Success", "Request for Unique Sent", 3000);
            
          }
        });

    this.toggle(event);
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.showModal} toggle={this.toggle}>
          <ModalHeader
            color="secondary"
            toggle={this.toggle}
            style={{ color: "#000", textAlign: "center" }}
            className={this.props.className}
          >
            {this.props.title}
          </ModalHeader>
          <ModalBody style={{ color: "#000" }}>
            <MdHelpOutline style={{ float: "right"}} onClick={this.toggle} />
            <Form>
              <FormGroup>
                <Label for="Summary">Enquiry Summary</Label>
                <Input
                  type="input"
                  name="Name"
                  id="Name"
                  placeholder="Describe the basic summary of your request"
                  onChange={this.handleRequestSummary}
                />
              </FormGroup>
              <FormGroup>
                <Label for="Description">Enquiry Details</Label>
                <Input
                  type="textarea"
                  style={{ height: "150px" }}
                  name="Description"
                  id="Description"
                  placeholder="Describe any further details you wish to add to your request"
                  onChange={this.handleRequestDesc}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Link
              to="#"
              className="custom-btn custom-btn-gray"
              onClick={this.toggle}
            >
              <MdClear size={30}/>
            </Link>
            <Link
              to="#"
              className="custom-btn custom-btn-green"
              onClick={this.handleSubmit}
            >
              <MdCheck size={30}/>
            </Link>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showModal: state.showRequestForUniqueModal,
    enquiryRequestEmail: state.enquiryRequestEmail,
    requestForUniqueIdeaID: state.requestForUniqueIdeaID,
    token: state.jwtToken
  };
};

//
const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: CLOSE_MODAL }),
    dispatchRequestforUnique: RequestForUnique => {
      dispatch({
        type: REQUEST_FOR_UNIQUE,
        payload: {
          RequestForUnique: RequestForUnique
        }
      });
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReactModal)
);
