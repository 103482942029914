import React from 'react'
import { StyledSprxsButton } from './SprxsButton.style';

const Button = ({
  click,
  icon,
  color,
  bgColor,
  border,
  children,
}) => {
  return (
    <StyledSprxsButton icon={icon} color={color} bgColor={bgColor} border={border} onClick={click}>{children}</StyledSprxsButton>
  )
}

export default Button