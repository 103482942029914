import ReactPixel from "react-facebook-pixel";
import { fb_pixel_analytics_enabled } from "../constants";

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false // enable logs
};

const PIXEL_ID = "1222265617941734";

/**
 * Initialise Facebook Pixel
 */
export function fbPixelInit() {
  if(fb_pixel_analytics_enabled){
    ReactPixel.init(PIXEL_ID, advancedMatching, options);
  }
}

/**
 * Track page view
 */
export function fbPixelPageView() {
  if(fb_pixel_analytics_enabled){
    ReactPixel.pageView();
  }
}

/**
 * Track signup
 */
export function fbPixelTrackSignup() {
  if(fb_pixel_analytics_enabled){
    ReactPixel.track("CompleteRegistration", {});
  }
}
