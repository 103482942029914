import ReactGA from "react-ga";
import { google_analytics_test_mode, google_analytics_uat } from "../constants";

const prodID = "UA-134712385-1";
const uatID = "UA-134712385-2";

/** If UAT is true then use a different tracking code id */
let gaId = google_analytics_uat? uatID : prodID;

/**
 * Initialise Google Analytics
 */
export function gaInitialise() {
    console.log("gaInitialise()");
  ReactGA.initialize(gaId, {
    testMode: google_analytics_test_mode
  });
  ReactGA.set({ debug: google_analytics_uat? true : false });
}

/**
 * gaPageview()
 * @param {String} pathname - the page path e.g. "/home"
 */
export function gaPageview(pathname) {
  ReactGA.pageview(pathname);
  //console.log("GA: pathname", pathname);
}

/**
 * ideatorAccountCreated()
 * Logs the event of an account being created.
 */
export function ideatorAccountCreated() {
  ReactGA.event({
    category: "User",
    action: "Created an Ideator Account"
  });
}

/**
 * collaboratorAccountCreated()
 * Logs the event of an account being created.
 */
export function collaboratorAccountCreated() {
  ReactGA.event({
    category: "User",
    action: "Created a Collaborator Account"
  });
}

/**
 * This function takes a short string that describes the event and then sends that event to Google Analytics
 * @param {String} action - a description of the event
 */
export function captureEvent(action) {
  console.log(`captureEvent: ${action}`)
  ReactGA.event({
    category: "User",
    action: action
  });
}
