import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { FaGithub, FaFacebook, FaLinkedin } from "react-icons/fa";
import logo from "../../static/fav.png";
import mainlogo from "../../static/sprxsmainlogo.png";
import logoname from "../../static/sprxslogo.png";
import chain from "../../static/chain.png";
import currency from "../../static/currency.png";
import idea from "../../static/idea.png";
import brain from "../../static/brain.png";
import spark from "../../static/spark.png";
import sparksname from "../../static/sparksname.png";
import bgimage from "../../static/mainbodypic_max.jpg";
import howimage from "../../static/Collaborate.jpg";
import GridIdeas from "../../components/GridIdeas/GridIdeas";
import Hidden from "@material-ui/core/Hidden";
import LandingDrawer from "./LandingDrawer";
import VideoModal from "./VideoModal";
import ReactPlayer from "react-player";
import Scrollchor from "react-scrollchor";
import techImage from "../../static/TECHNICALTEST.jpg";
import sprxlogo from "../../static/sprxslogo.png";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { renderHeaderDesktop, renderHeaderMobile } from "./SharedComponents";

const bgColor = "white";
const bgImage = "linear-gradient(to bottom right, #fc734a 20%, yellow)";
/* const bgImage = "radial-gradient(white ,#ff513a,  #FF9D3A)"; */
const howbgColor = "white";

const contactHeight = "160px";
const footerHeight = "35px";

const styles = theme => ({
  root: {
    backgroundColor: bgColor,
    // backgroundImage: bgImage,
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  menuItems: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  logo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    textAlign: "center"
  },
  mainButton: {
    position: "relative"
    /* backgroundColor: "transparent" */
  },
  menu: {
    flexGrow: 1,
    textAlign: "right"
  },
  header: {
    backgroundColor: "#212526",
    backgroundImage: "#212526",
    filter: "alpha(opacity=30)"
    /* boxShadow: "none" */
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "white",
    boxShadow: "none"
  },
  subTitle: {
    color: "white",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      letterSpacing: "7px",
      lineHeight: "1.2"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px",
      letterSpacing: "7px"
    }
  },
  whatTitle: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: bgColor,
    boxShadow: "none"
  },
  what: {
    padding: theme.spacing.unit * 2,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: bgColor,
    boxShadow: "none",
    height: "auto"
  },
  whatText: {
    fontSize: "19px",
    fontWeight: "bold"
  },
  video: {
    padding: theme.spacing.unit * 2,
    alignItems: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: bgColor,
    boxShadow: "none",
    height: "auto",
    position: "relative"
    // paddingTop: "46%",
    // marginTop: "2rem",
    // marginLeft: "4rem",
    // marginRight: "4rem"
  },
  howTitle: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    // color: theme.palette.secondary.contrastText,
    // color: howbgColor,
    backgroundColor: "transparent",
    boxShadow: "none",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "4px"
  },
  howSection: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url(${howimage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "top",
      backgroundSize: "cover"
    }
  },
  how: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.secondary.contrastText,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "250px"
  },
  howIconText: {
    fontSize: "22px",
    fontWeight: "bold"
  },
  contact1: {
    paddingTop: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: contactHeight
  },
  contact: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: contactHeight
  },
  footer: {
    paddingTop: theme.spacing.unit * 0,
    paddingLeft: theme.spacing.unit * 1,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none"
    /*  height: "100px" */
  },
  copyright: {
    paddingTop: theme.spacing.unit * 0,
    paddingLeft: theme.spacing.unit * 1,
    textAlign: "right",
    verticalAlign: "text-bottom",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: footerHeight
  },
  version: {
    paddingTop: theme.spacing.unit * 0,
    paddingLeft: theme.spacing.unit * 1,
    textAlign: "left",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#1a1c1e",
    boxShadow: "none",
    height: footerHeight
  },
  contactText: {
    color: "grey",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  container: {
    paddingTop: theme.spacing.unit * 0
  },
  hero: {
    paddingTop: theme.spacing.unit * 0,
    boxShadow: "none",
    backgroundImage: `url(${bgimage})`,
    /* backgroundImage: "url(https://source.unsplash.com/1200x600?innovation)", */
    height: "80vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("xs")]: {
      height: "105vh"
    }
  },
  iconHover: {
    margin: theme.spacing.unit * 0,
    marginRight: "10px",
    color: "grey",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  headingBox: {
    marginLeft: "50px",
    [theme.breakpoints.up("xl")]: {
      marginLeft: "160px"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px"
    }
  },
  faqHeading: {
    fontWeight: "bold",
    textAlign: "left",
    color: "#36464c",
    fontFamily: "Helvetica Neue, sans-serif"
  },
  techHeading: {
    fontWeight: "bold",
    textAlign: "left",
    // paddingTop: "110px",
    color: "#36464c",
    fontFamily: "Helvetica Neue, sans-serif"
  },
  subHeading: {
    fontSize: "22px",
    textAlign: "center",
    lineHeight: "1.5",
    color: "#36464c",
    fontFamily: "Helvetica Neue, sans-serif"
  },

  logoImage: {
    width: "18%",
    height: "18%",
    marginLeft: "-190px",
    float: "left",
    [theme.breakpoints.down("md")]: {
      marginLeft: "-75px",
      // marginRight: "-50px",
      marginTop: "-60px"
    }
  },
  speechImage: {
    width: "40%",
    float: "right",
    marginRight: "-260px",
    // marginBottom: "10px",
    marginTop: "-20px",
    [theme.breakpoints.down("md")]: {
      marginRight: "-130px",
      marginTop: "-70px"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  techImage: {
    width: "20%",
    float: "right",
    marginLeft: "10px",
    marginRight: "-190px",
    marginTop: "-10px",
    // marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      marginRight: "-130px",
      marginTop: "-70px"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  questionList: {
    marginTop: "25px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "5px"
    }
  },
  questionImage: {
    color: "#ff7b2f",
    fontFamily: "Helvetica Neue, sans-serif",
    lineHeight: "1.6",
    fontWeight: "bold",
    fontSize: "20px !important",
    [theme.breakpoints.down("xl")]: {
      float: "left",
      marginRight: "15px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      marginLeft: "-7px",
      marginBottom: "-10px"
    }
  },

  question: {
    textAlign: "left",
    fontSize: "20px !important",
    marginBottom: "14px",
    fontFamily: "Helvetica Neue, sans-serif",
    lineHeight: "1.6",
    listStyle: "none",
    fontWeight: "bold",
    color: "#ff7b2f",
    marginLeft: "35px",
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      marginLeft: "13px",
      marginRight: "0px"
    }
  },
  answer: {
    color: "#36464c !important",
    fontFamily: "Helvetica Neue",
    textAlign: "left",
    fontSize: "14px",
    lineHeight: "2.1",
    marginLeft: "35px",
    marginRight: "10px",
    marginTop: "10px",
    listStyle: "none",
    textJustify: "inter-word",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-5px",
      marginRight: "0px",
      fontSize: "14px"
    }
  },
  answerItem: {
    marginLeft: "17px"
  },
  answerLink: {
    textDecoration: "none",
    color: "#ff7b2f",
    "&:hover": {
      textDecoration: "none",
      color: "#f09819"
    }
  },
  contentsList: {
    listStyleType: "none",
    listStylePosition: "inside",
    textAlign: "left !important",
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
    lineHeight: "2.4",
    marginBottom: "80px",
    color: "#ff7b2f",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px !important"
    }
  },
  contentsEntry: {
    color: "#ff7b2f",
    fontFamily: "Helvetica Neue, sans-serif",
    fontSize: "20px",
    fontWeight: "bold",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "#f09819"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  contentsNumber: {
    color: "#ff7b2f",
    fontFamily: "Helvetica Neue, sans-serif",
    fontWeight: "bold",
    fontSize: "20px !important",
    [theme.breakpoints.down("xl")]: {
      float: "left",
      marginRight: "15px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      marginLeft: "-30px"
    }
  },
  imageContainer: {
    marginTop: "140px",
    [theme.breakpoints.down("xl")]: {
      marginBottom: "170px"
    },
    [theme.breakpoints.down("lg")]: {
      marginBottom: "70px"
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px"
    }
  },
  faq: {
    minHeight: "100vh",
    color: "none",
    boxShadow: "none",
    backgroundColor: "#fff",
    // color: theme.palette.text.secondary,
    height: "100%",
    marginLeft: "80px",
    marginRight: "80px",
    marginTop: "-50px",
    marginBottom: "50px",
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
      marginLeft: "0px",
      marginTop: "0px"
    }
  },
  buttonScroll: {
    position: "sticky !important",
    color: "#fff",
    backgroundColor: "#ff7b2f",
    "&:hover": {
      backgroundColor: "#f09819",
      color: "#fff"
    },
    float: "right",
    marginRight: "-130px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "-40px"
    }
  },
  card: {
    maxWidth: 345
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: "cover"
  }
});



const renderMediaMobile = classes => {
  return (
    <React.Fragment>
      <Grid container spacing={6} justify="center" alignItems="center">
        <Grid item xs={9}>
          <Paper className={classes.faq}>
            <div className={classes.imageContainer}>
              <img className={classes.logoImage} src={sprxlogo} />
              <br />
            </div>
            <div>
              <div className={classes.headingBox}>
                <h1 className={classes.faqHeading}>Media</h1>
                <br />
              </div>
              <Grid
                container
                direction="row"
                justify="center"
                alignContent="center"
                spacing={24}
              >
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia>
                        <ReactPlayer
                          url="https://player.vimeo.com/video/299007147"
                          width="100%"
                          height="100%"
                          controls
                          className={classes.reactPlayer}
                        />
                      </CardMedia>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h7"
                          component="h4"
                          style={{ color: "#000" }}
                        >
                          SPRXS Interview
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="p"
                          style={{ color: "#000" }}
                        >
                          October 2018
                        </Typography>
                        <Typography component="p">
                          
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia>
                        <ReactPlayer
                          url="https://player.vimeo.com/video/299006879"
                          width="100%"
                          height="100%"
                          controls
                          className={classes.reactPlayer}
                        />
                      </CardMedia>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h7"
                          component="h4"
                          style={{ color: "#000" }}
                        >
                          SPRXS Interview
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="p"
                          style={{ color: "#000" }}
                        >
                          October 2018
                        </Typography>
                        <Typography component="p">
                          
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia>
                        <ReactPlayer
                          url="https://player.vimeo.com/video/292816980"
                          width="100%"
                          height="100%"
                          controls
                          className={classes.reactPlayer}
                        />
                      </CardMedia>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h7"
                          component="h4"
                          style={{ color: "#000" }}
                        >
                          SPRXS Trailer
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="p"
                          style={{ color: "#000" }}
                        >
                          October 2018
                        </Typography>
                        <Typography component="p">
                          
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const renderMediaDesktop = classes => {
  return (
    <React.Fragment>
      <Grid container spacing={6} justify="center" alignItems="center">
        <Grid item xs={9}>
          <Paper className={classes.faq}>
            <div className={classes.imageContainer}>
              <img className={classes.logoImage} src={sprxlogo} />
              <br />
            </div>
            <div>
              <div className={classes.headingBox}>
                <h1 className={classes.faqHeading}>Media</h1>
                <br />
              </div>
              <Grid
                container
                direction="row"
                // justify="center"
                // alignContent="center"
                spacing={24}
              >
                <Grid item xs={4}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia>
                        <ReactPlayer
                          url="https://player.vimeo.com/video/299007147"
                          width="100%"
                          height="100%"
                          controls
                          className={classes.reactPlayer}
                        />
                      </CardMedia>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h7"
                          component="h4"
                          style={{ color: "#000" }}
                        >
                          SPRXS Interview
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="p"
                          style={{ color: "#000" }}
                        >
                          October 2018
                        </Typography>
                        <Typography component="p">
                          
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia>
                        <ReactPlayer
                          url="https://player.vimeo.com/video/299006879"
                          width="100%"
                          height="100%"
                          controls
                          className={classes.reactPlayer}
                        />
                      </CardMedia>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h7"
                          component="h4"
                          style={{ color: "#000" }}
                        >
                          SPRXS Interview
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="p"
                          style={{ color: "#000" }}
                        >
                          October 2018
                        </Typography>
                        <Typography component="p">
                          
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia>
                        <ReactPlayer
                          url="https://player.vimeo.com/video/292816980"
                          width="100%"
                          height="100%"
                          controls
                          className={classes.reactPlayer}
                        />
                      </CardMedia>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h7"
                          component="h4"
                          style={{ color: "#000" }}
                        >
                          SPRXS Trailer
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="p"
                          style={{ color: "#000" }}
                        >
                          October 2018
                        </Typography>
                        <Typography component="p">
                          
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

class MediaPage extends React.Component {
  state = {
    openDrawer: false,
    openVideo: false
  };

  handleCloseDrawer = () => {
    this.setState({ openDrawer: false });
  };

  handleOpenDrawer = () => {
    this.setState({ openDrawer: true });
  };

  handleOpenVideo = () => {
    this.setState({ openVideo: true });
  };

  handleCloseVideo = () => {
    this.setState({ openVideo: false });
  };

  render() {
    const { classes } = this.props;
    const { openDrawer, openVideo } = this.state;

    return (
      <div className={classes.root}>
        <LandingDrawer open={openDrawer} onClose={this.handleCloseDrawer} />
        <Grid container spacing={0} className={classes.container}>
          <Hidden smUp>{renderMediaMobile(classes)}</Hidden>
          <Hidden xsDown>{renderMediaDesktop(classes)}</Hidden>

          <Grid item xs={4}>
            <Paper square className={classes.contact1}>
              <img
                src={logoname}
                width={120}
                style={{
                  marginBottom: 5
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper square className={classes.contact}>
              <Typography variant="body2" className={classes.contactText}>
              </Typography>
              <Typography variant="body2" className={classes.contactText}>
              </Typography>
              <Typography variant="body2" className={classes.contactText}>
              </Typography>
              <Typography variant="body2" className={classes.contactText}>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper square className={classes.contact}>
              <Typography variant="body2" className={classes.contactText}>
              </Typography>
              <Typography
                variant="body2"
                className={classes.contactText}
                onClick={this.props.showTermsPolicyModal}
              >
                Terms and Conditions
              </Typography>
              <Typography
                variant="body2"
                className={classes.contactText}
                onClick={this.props.showPrivModal}
              >
                Privacy Policy
              </Typography>
              <Typography variant="body2" className={classes.contactText}>
                <a
                  className={classes.contactText}
                  href="mailto:support@sprxs.io?"
                >
                  Contact Us
                </a>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper square className={classes.footer}>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper square className={classes.version}>
              <Typography
                variant="caption"
                style={{ color: "grey", marginRight: "5px" }}
              >
                v. 2.0.0
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper square className={classes.copyright}>
              <Typography
                variant="caption"
                style={{ color: "grey", marginRight: "5px" }}
              >
                Copyright &copy; 2018 Fifth-9 Ltd.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MediaPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(MediaPage));
