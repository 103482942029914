import firebase from "firebase";
import "firebase/auth";
import "firebase/database";

/**
|--------------------------------------------------
| FEATURE FLAGS
| true = feature enabled
| false = feature disabled
|
| Note: this is required so that we can release a 
| cut down version to production.
|--------------------------------------------------
*/
export const my_collaborators_feature_flag = true;
export const my_notifications_feature_flag = true;
export const idea_drilldown_feature_flag = true;

/** If test flag is set to true then the collaborator view is enabled by default. */
export const collaborator_dashboard_test_flag = false; //DEBUG: just for testing.

/** google_analytics_test_mode - if set to true then enabled */
export const google_analytics_test_mode = false;

/** google_analytics_uat */
export const google_analytics_uat = false; // PRODUCTION SETTING KN 07.07.2019 

/** fb_pixel_analytics_enabled */
export const fb_pixel_analytics_enabled = true; // PRODUCTION  SETTING KN 07.07.2019
/*-------------------------------------------------*/

/**
|--------------------------------------------------
| Type constants
|--------------------------------------------------
| User type constants are declared here
|
*/
export const IDEATOR = "IDEATOR";
export const COLLABORATOR = "COLLABORATOR";

/**
|--------------------------------------------------
| Type constants
|--------------------------------------------------
| Cookie Timeout constants are declared here - 1hour
|
*/
export const timeOut = 86400;

/**
|--------------------------------------------------
| Type constants
|--------------------------------------------------
| Blog link declared here
|
*/
export const blogLink = 'https://sprxs.wordpress.com';

/*------------------------------------
| ReCaptcha Site Key ( Generated by google for specific domains)
*/
export const recaptchaSiteKey = "6LfKHYwUAAAAAPGmOBPApGyMbBX9YIu4zHZFGm-V"; // When using on Prod, use this!

var config = {
  apiKey: "AIzaSyBeIeYa6XlI9IX6wL11ZucEPzAz1oxTRm4",
  authDomain: "sprxs-5d785.firebaseapp.com",
  databaseURL: "https://sprxs-5d785.firebaseio.com",
  projectId: "sprxs-5d785",
  storageBucket: "sprxs-5d785.appspot.com",
  messagingSenderId: "546665462806",
  appId: "1:546665462806:web:c172fbccfd7ef7131c4937"
};

const fire = firebase.initializeApp(config);
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export default fire;

export const firebasePassword = "ljsdlgkj&fefsd$%SDFsdf123£";

export const hostApi = "https://sprxs.app";
export const fbApi = "https://facebook/api";

export const ideaStatus = [
  { value: 0, label: "" },
  { value: 1, label: "Registered" },
  { value: 2, label: "In Review" },
  { value: 3, label: "Approved" },
  { value: 4, label: "Declined" },
  { value: 5, label: "On Hold" },
  { value: 6, label: "Request for Unique" }
];

export const ideaClassification = [
  { value: 1, label: "Technology" },
  { value: 2, label: "Lifestyle & Wellbeing" }, 
  { value: 3, label: "Food & Drink" },
  { value: 4, label: "Gaming" },
  { value: 5, label: "Business/Finance" },
  { value: 6, label: "Fashion & Art" },
  { value: 7, label: "Film" },
  { value: 8, label: "Media and Journalism" },
  { value: 9, label: "Theatre" }, 
  { value: 10, label: "Music" },
  { value: 11, label: "Other" }
];

export const covid19Classification = [
  {value: 1, label: "Medical Research"},
  {value: 2, label: "Practical Application"},
  {value: 3, label: "Awareness"}
];


export const industry = [
  { value: 1, label: "Graphic & Design" },
  { value: 2, label: "Music & Voice Over" },
  { value: 3, label: "Video & Animation" },
  { value: 4, label: "Digital Marketing" },
  { value: 5, label: "Writing & Translation" },
  { value: 6, label: "Programming & Tech" },
  { value: 7, label: "Other" }
];

export const genesisMaturity = [
  { value: 1, label: "Hunch" },
  { value: 2, label: "Theory" },
  { value: 3, label: "Experiment" },
  { value: 4, label: "Discovery" },
  { value: 5, label: "Method" },
  { value: 6, label: "Design" },
  { value: 7, label: "Invention" },
  { value: 8, label: "Dream" } /* TODO: move to top */,
  { value: 9, label: "Other" } /* TODO: remove other */
];

export const purposeClassification = [
  { value: 1, label: "People, culture & society" },
  { value: 2, label: "Transport" },
  { value: 3, label: "Education" },
  { value: 4, label: "Mining" },
  { value: 5, label: "Health" },
  { value: 6, label: "Law & Government" },
  { value: 7, label: "Energy/Utility/Agriculture" },
  { value: 8, label: "Information/Communication" },
  { value: 9, label: "Other" }
];

export const lkp_profileType = [
  { value: 1, label: "Ideator - I want to create and manage ideas" },
  { value: 2, label: "Collaborator - I want to work on projects" }
];

export const woiRole = [
  { value: 1, label: "Developer" },
  { value: 2, label: "Creative" },
  { value: 3, label: "Musical" },
  { value: 4, label: "Accounting" },
  { value: 5, label: "Editorial" },
  { value: 6, label: "Marketing" },
  { value: 7, label: "Sales" },
  { value: 8, label: "Technical" },
  { value: 9, label: "Other" }
];

export const woiCapacity = [
  { value: 1, label: "Part-time" },
  { value: 2, label: "Full-time" },
  { value: 3, label: "Ad-hoc" }
];

export const woiRemun = [
  { value: 1, label: "Volunteer" },
  { value: 2, label: "Hourly Rate" },
  { value: 3, label: "Fixed Weekly Rate" },
  { value: 4, label: "Fixed Monthly Rate" }
];

export const primaryExpertise = [
  { value: 1, label: "Graphic & Design" },
  { value: 2, label: "Music & Voice Over" },
  { value: 3, label: "Video & Animation" },
  { value: 4, label: "Digital Marketing" },
  { value: 5, label: "Writing & Translation" },
  { value: 6, label: "Programming & Tech" },
  { value: 7, label: "Other (specify)" }
];

export const ExpertiseMap = {
  1: "Graphic & Design",
  2: "Music & Voice Over",
  3: "Video & Animation",
  4: "Digital Marketing",
  5: "Writing & Translation",
  6: "Programming & Tech",
  7: "Other (specify)"
};
