import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import connectedAuthWrapper from "redux-auth-wrapper/connectedAuthWrapper";

export const gotoMySPRXS = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: "/dashboard",
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => state.isAuthenticated === false,
  // A nice display name for this check
  wrapperDisplayName: "gotoMySPRXS"
});

export const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: "/login",
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => state.isAuthenticated === true,
  // A nice display name for this check
  wrapperDisplayName: "UserIsAuthenticated"
});

export const userIsNotAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: "/",
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => state.isAuthenticated === false,
  // A nice display name for this check
  wrapperDisplayName: "userIsNotAuthenticated"
});

export const userIsRegistered = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: "/signup",
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => state.isRegistered === true && state.isAuthenticated === true,
  // A nice display name for this check
  wrapperDisplayName: "userIsRegistered"
});

export const notAuthenticated = (Component, FailureComponent) =>
  connectedAuthWrapper({
    authenticatedSelector: state => state.isAuthenticated === false,
    wrapperDisplayName: "Allowed",
    FailureComponent
  })(Component);
