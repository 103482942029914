import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import {
  hostApi,
  ideaStatus,
  ideaClassification,
  genesisMaturity,
  purposeClassification
} from "../../constants";
import { userIsAuthenticated } from "../../authentication/authentication";

import "./SearchResultsPage.css";

import "../../App.css";
import { TERMS_ALERT, unsetIdeaToEdit } from "../../store/actions/actions";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import CardView from "../../components/CardView/CardView";
import { Spinner } from "../../components/Spinner/Spinner";
import { Paper } from "@material-ui/core";
import SpeedDials from "../../components/SpeedDials/SpeedDials";
import MyIdeas from "../../components/TableView/MyIdeas";
import { withStyles } from "@material-ui/core/styles";
import ChatDialog from "../ChatDialog/ChatDialog";
import firebase from "../../constants";
import IdeaDash from "../../containers/IdeaDash/IdeaDash";
import Progress from "../../components/ProgressBar/Progress";


const styles = theme => ({
  root: {
    width: "100%"
  }
});

/* Create a customised style for this instance of MyIdeas because we
 * want a margin at the top for this view
 */
const MyIdeas2 = withStyles(styles, { withTheme: true })(MyIdeas);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

class SearchResultsPage extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    this.state = {
      name: cookies.get("name") || "",
      isAuthenticated: cookies.get("isAuthenticated") || false,
      height: props.height,
      width: props.width,
      isLoading: false,
      cardView: true, //Set card view by default
      isFlipped: false,
      data_items: [],
      openChat: false,
      openIdeaDash: false,
      ideaDashData: []
    };
  }

  componentDidMount() {
    const { cookies } = this.props;

    const cookie_email = cookies.get("emailAddress");

    //console.log("cookie email", cookie_email);

    this.setState({ isLoading: true }); //Start the spinner

    if (this.props.data_items) {
      //console.log("UPDATE DATA");
      this.setState({ data_items: this.props.data_items });
    }
    this.setState({ isLoading: false });
    axios
      .post(`${hostApi}/myIdeas`, {
        lkp_email: cookie_email,
        idea_name: this.props.searchName || "",
        idea_description: this.props.searchDesc || ""
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`
        }
      })
      .then(({ data }) => {
        //console.log("axios response ajb", response);

        this.setState(state => {
          state.data_items = data;
          state.isLoading = false;
          const { ideaToEdit } = this.props;
          if (ideaToEdit) {
            data = data
              .filter(d => ideaToEdit === d.ideaUniqueID)
              .reduce(a => a);
            state.ideaDashData = data;
            state.openIdeaDash = true;
          }
          return state;
        });
      });

    this.setState({ isAuthenticated: cookies.get("isAuthenticated") });
  }

  handleLike = e => {
    e.preventDefault();
  };

  handleChangeView = e => {
    this.setState({ cardView: !this.state.cardView });
  };

  handleFlip = e => {
    e.preventDefault();
    this.setState({ isFlipped: !this.state.isFlipped });
  };

  launchChat = () => {
    this.setState({ showChat: true });
  };
  handleCloseChat = () => {
    this.setState({ showChat: false });
  };

  launchIdeaDash = (event, data) => {
    console.log("launchIdeaDash->>>", data);
    this.setState({ ideaDashData: data, openIdeaDash: true });
  };

  handleCloseIdeaDash = () => {
    this.setState({ openIdeaDash: false });
    if (this.props.ideaToEdit) {
      this.props.unsetIdeaToEdit();
      this.props.history.push("/dashboard");
    }
  };

  render() {
    return (
      <>
        <Progress />
        {/* <IdeaDash
          // open={this.state.openIdeaDash}
          // handleClose={this.handleCloseIdeaDash}
          data={this.state.ideaDashData}
        /> */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchName: state.searchName,
    ideaToEdit: state.ideaToEdit,
    searchDesc: state.searchDesc,
    searchID: state.searchID,
    isAuthenticated: state.isAuthenticated,
    animate: state.animation,
    data_items: state.searchResults,
    emailAddress: state.emailAddress,
    showCardView: state.showCardView,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchToggleTermsAlert: () => dispatch({ type: TERMS_ALERT }),
    unsetIdeaToEdit: () => dispatch(unsetIdeaToEdit())
  };
};

export default withRouter(
  withCookies(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(userIsAuthenticated(SearchResultsPage))
  )
);
