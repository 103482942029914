import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%",
  },
});

function AcceptUsePol( {handleChange}) {

  return (
    <div className="legalWrapper">
      <h1 className="legalHeader">
        Acceptable&nbsp;Use&nbsp;Policy
      </h1>
      <div className="legalContent">
          <h3 className="legalSubHead">
            What's in these terms?
          </h3>
          <p className="legalCopy">
            This Acceptable&nbsp;Use&nbsp;Policy sets out the content standards that
              apply when you upload content to our site, make contact with
              other users on our site, link to our site, or interact with our
              site in any other way,<br />
              By using our site you accept these terms.<br />
              By using our site, you confirm that you accept the terms of this
              policy and that you agree to comply with them.<br />
              If you do not agree to these terms, you must not use our site.
              There are other terms that may apply to you. Our{" "}
             <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "terms");
              }}
            >
                Terms&nbsp;of&nbsp;website&nbsp;use{" "}
              </a>
              also apply to your use of our site.<br />
               We may make changes to the terms of this policy<br />
               We amend these terms from time to time. Every time you wish to
              use our site, please check these terms to ensure you understand
              the terms that apply at that time. [<i>These terms were created on
              28 September 2018</i> ].
              <span className="copySubHead">Prohibited uses</span>
              You may use our site only for lawful purposes. You may not use
              our site:
              <ul>
              <li>If you are under the age of 18.</li>
              <li>
                In any way that breaches any applicable local, national or
                international law or regulation.
              </li>
              <li>
                In any way that is unlawful or fraudulent, or has any unlawful
                or fraudulent purpose or effect.
              </li>
              <li>
                For the purpose of harming or attempting to harm minors in any
                way. To send, knowingly receive, upload, download, use or
                re-use any material which does not comply with our content
                standards
              </li>
              <li>
                To transmit, or procure the sending of, any unsolicited or
                unauthorised advertising or promotional material or any other
                form of similar solicitation (spam).
              </li>
              <li>
                To knowingly transmit any data, send or upload any material
                that contains viruses, Trojan horses, worms, time-bombs,
                keystroke loggers, spyware, adware or any other harmful
                programs or similar computer code designed to adversely affect
                the operation of any computer software or hardware.
              </li>
              </ul>
              You also agree:
              Not to reproduce, duplicate, copy or re-sell any part of our
              site in contravention of the provisions of our <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "terms");
              }}
            >Terms&nbsp;of&nbsp;website&nbsp;use</a>.
              <ul>
              <li>
                Not to access without authority, interfere with, damage or
                disrupt:
              </li>
              <li>any part of our site;</li>
              <li>any equipment or network on which our site is stored;</li>
              <li>any software used in the provision of our site</li>
              <li>
                or any equipment or network or software owned or used by any
                third party.
              </li>
              </ul>
              <span className="copySubHead">Interactive services</span>
              We may from time to time provide interactive services on our
              site, including, without limitation:
              <ul>
              <li>Chat rooms.</li>
              <li>Bulletin boards.</li>
              <li>Collaboration tools. (interactive services.)</li>
              </ul>
               Where we do provide any interactive service, we will provide
              clear information to you about the kind of service offered, if
              it is moderated and what form of moderation is used (including
              whether it is human or technical).<br />
               We will do our best to assess any possible risks for users from
              third parties when they use any interactive service provided on
              our site, and we will decide in each case whether it is
              appropriate to use moderation of the relevant service (including
              what kind of moderation to use) in the light of those risks.<br />
              However, we are under no obligation to oversee, monitor or
              moderate any interactive service we provide on our site, and we
              expressly exclude our liability for any loss or damage arising
              from the use of any interactive service by a user in
              contravention of our content standards, whether the service is
              moderated or not.<br />
               Where we do moderate an interactive service, we will normally
              provide you with a means of contacting the moderator, should a
              concern or difficulty arise.<br />
              Content standards These content standards apply to any and all
              material which you submit to our site (Submission), and to any
              interactive services associated with it.<br />
              The Content Standards must be complied with in spirit as well as
              to the letter. The standards apply to each part of any
              Submission as well as to its whole.<br />
              SPRXS will determine, in its discretion, whether a Submission
              breaches the Content Standards.
              <span className="copySubHead">A Submission must:</span>
              <ul>
              <li>Be accurate (where it states facts).</li>
              <li>Be genuinely held (where it states opinions).</li>
              <li>
                To the best of the user’s knowledge, be novel, useful and
                non-obvious;
              </li>
              <li>
                Comply with the law applicable in England and Wales and in any
                country from which it is posted.
              </li>
              </ul>
              <span className="copySubHead">A Submission must not:</span>
              <ul>
              <li>Be defamatory of any person.</li>
              <li>Be obscene, offensive, hateful or inflammatory.</li>
              <li>Promote sexually explicit material. Promote violence.</li>
              <li>
                Promote discrimination based on race, sex, religion,
                nationality, disability, sexual orientation or age.
              </li>
              <li>
                Infringe any copyright, database right or trade mark of any
                other person. Be likely to deceive any person.
              </li>
              <li>
                Breach any legal duty owed to a third party, such as a
                contractual duty or a duty of confidence. Promote any illegal
                activity.
              </li>
              <li>
                Be in contempt of court. Be threatening, abuse or invade
                another's privacy, or cause annoyance, inconvenience or
                needless anxiety.
              </li>
              <li>
                Be likely to harass, upset, embarrass, alarm or annoy any
                other person.
              </li>
              <li>
                Impersonate any person, or misrepresent your identity or
                affiliation with any person.
              </li>
              <li>
                Give the impression that the Contribution emanates from [NAME
                OF COMPANY], if this is not the case.
              </li>
              <li>
                Advocate, promote, incite any party to commit, or assist any
                unlawful or criminal act such as (by way of example only)
                copyright infringement or computer misuse.
              </li>
              <li>
                Contain a statement which you know or believe, or have
                reasonable grounds for believing, that members of the public
                to whom the statement is, or is to be, published are likely to
                understand as a direct or indirect encouragement or other
                inducement to the commission, preparation or instigation of
                acts of terrorism.
              </li>
              <li>
                Contain any advertising or promote any services or web links
                to other sites.
              </li>
              </ul>
              <span className="copySubHead">Breach of this policy</span>
              When we consider that a breach of this Acceptable&nbsp;Use&nbsp;Policy has
              occurred, we may take such action as we deem appropriate.<br />
              Failure to comply with this Acceptable&nbsp;Use&nbsp;Policy constitutes a
              material breach of our <a href="#" className={" "} onClick={(e) => {
               handleChange(e, "terms");
                }}
              >
               Terms&nbsp;of&nbsp;website&nbsp;use{" "}
              </a> upon which you are permitted to use our site, and may
              result in our taking all or any of the following actions:
              <ul>
              <li>
                Immediate, temporary or permanent withdrawal of your right to
                use our site.
              </li>
              <li>
                Immediate, temporary or permanent removal of any Contribution
                uploaded by you to our site. Issue of a warning to you.
              </li>
              <li>
                Legal proceedings against you for reimbursement of all costs
                on an indemnity basis (including, but not limited to,
                reasonable administrative and legal costs) resulting from the
                breach.
              </li>
              <li>
                Further legal action against you. Disclosure of such
                information to law enforcement authorities as we reasonably
                feel is necessary or as required by law.
              </li>
              </ul>
               We exclude our liability for all action we may take in response
              to breaches of this Acceptable&nbsp;Use&nbsp;Policy. The actions we may
              take are not limited to those described above, and we may take
              any other action we reasonably deem appropriate.
              <span className="copySubHead">Which country's laws apply to any disputes?</span>
              If you are a consumer, please note that the terms of this
              policy, its subject matter and its formation are governed by
              English law. You and we both agree that the courts of England
              and Wales will have exclusive jurisdiction except that if you
              are a resident of Northern Ireland you may also bring
              proceedings in Northern Ireland, and if you are resident of
              Scotland, you may also bring proceedings in Scotland.<br />
              If you are a business, the terms of this policy, its subject
              matter and its formation (and any non-contractual disputes or
              claims) are governed by English law. We both agree to the
              exclusive jurisdiction of the courts of England and Wales.
          </p>
        </div>
    </div>
  );
  }

  export default withStyles(styles)(AcceptUsePol);

