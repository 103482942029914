import React from "react";
import { connect } from "react-redux";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { Link, withRouter } from "react-router-dom";
import Hoc from "../../hoc/Hoc";
import { TERMS_ALERT } from "../../store/actions/actions";

class NTFTest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  createNotification = type => {
    return () => {
      switch (type) {
        case "info":
          NotificationManager.info("Info message");
          break;
        case "success":
          NotificationManager.success("Success message", "Title here");
          break;
        case "terms":
          NotificationManager.warning(
            "You must check the terms and conditions",
            "Close after 3000ms",
            3000
          );
          break;
        case "error":
          NotificationManager.error("Error message", "Click me!", 5000, () => {
            alert("callback");
          });
          break;
      }
    };
  };

  componentDidMount(){
    if (this.props.termsAlert ) {
      this.createNotification('terms');
      this.props.dispatchToggleTermsAlert();
    }
  }

  render() {
    return (
      <Hoc>
        <NotificationContainer />
      </Hoc>
    );
  }
}

const mapStateToProps = state => {
  return {
    termsAlert: state.termsAlert
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchToggleTermsAlert: () => dispatch({ type: TERMS_ALERT })
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NTFTest)
);
