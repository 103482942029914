import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Modal, ModalHeader } from "reactstrap";
import {
  CLOSE_MODAL,
  SEARCH_CRITERIA,
  showCompModal,
  showPrivModal,
  showCookiePolicy,
  closeCompEntered
} from "../../store/actions/actions";
import "../../App.css";
import axios from "axios";
import {
  hostApi,
  ideaClassification,
  genesisMaturity,
  purposeClassification
} from "../../constants";
import { NotificationManager } from "react-notifications";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

import logo from "../../static/Sunrise_white.png";
import "../CreateIdeaModal/CreateIdeaModal.css";
import { FormLabel } from "@material-ui/core";
import CompTC from "../Terms/CompTC";

const styles = theme => ({
  colorSwitchBase: {
    color: "#8A7B46",
    "&$colorChecked": {
      color: "#f09619",
      "& + $colorBar": {
        backgroundColor: "#f09619"
      }
    }
  },
  colorBar: {},
  colorChecked: {},
  overrides: {
    textField: {
      // Name of the component ⚛️ / style sheet
      // Name of the rule
      color: "white" // Some CSS
    }
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: "5%",
    marginRight: "5%",
    fontSize: "30pt",
    color: "#000",
    width: "100%"
  },

  textField1: {
    marginLeft: "5%",
    marginRight: "0%",
    marginTop: "20px",
    marginBottom: "0px",
    fontSize: "15pt",
    color: "#000"
  },

  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: "none"
  }
});

const steps = ["Shipping address", "Payment details"];

function getStepContent(step) {
  switch (step) {
    case 0:
      return;
    case 1:
      return;
    case 2:
      return;
    default:
      throw new Error("Unknown step");
  }
}

let catStyle = { float: "left", marginLeft: "60px" };
let subcatStyle = { float: "right", marginRight: "60px" };

class CompEnteredModal extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      submitted: false,
      checkedA: false,
      checkedB: false,
      modal: false,
      emailValidation: true,
      emailValidation2: true,
      emailMatchFail: false,
      isEmail: false,
      invalidIdeaName: false,
      invalidDesc: false,
      lkp_email: "",
      lkp_email2: "",
      idea_name: "",
      lkp_idea_cat1: 9,
      lkp_idea_cat2: 9,
      lkp_idea_cat3: 9,
      idea_description: "",
      collapse: false,
      compDiagOpen: false,
      name: "Cat in the Hat",
      age: "",
      multiline: "Controlled",
      currency: "EUR"
    };

    this.toggle = this.toggle.bind(this);

    //console.log("this.props", this);
    //console.log("this.props.className", this.props.className);
  }

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };
  handleCheckA = event => {
    this.setState({ checkedA: event.target.checked });
  };
  handleCheckB = event => {
    this.setState({ checkedB: event.target.checked });
  };

  componentWillMount() {
    const { cookies } = this.props;

    const cookie_email = cookies.get("emailAddress");

    //console.log("componentWillMount()", this.props);
    //console.log("WINDOW : ", window);
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
      lkp_email: cookie_email,
      lkp_email2: cookie_email,
      submitted: false
    });
  }

  toggle(e) {
    //e.preventDefault();
    //console.log("toggle modal");
    this.setState({
      modal: false,
      isLoading: false,
      submitted: false,
      checkedA: false,
      checkedB: false,
      checkedC: false,
      emailValidation: true,
      emailValidation2: true,
      emailMatchFail: false,
      isEmail: false,
      invalidIdeaName: false,
      invalidDesc: false,
      lkp_email: "",
      lkp_email2: "",
      idea_name: "",
      lkp_idea_cat1: 9,
      lkp_idea_cat2: 9,
      lkp_idea_cat3: 9,
      idea_description: ""
    });

    this.props.closeCompEnteredModal();
  }

  handleSignup = () => {
    this.props.history.push(`/signup`);
    this.props.closeCompEnteredModal();
  }

  render() {
    if (this.state.width < 500) {
      catStyle = {};
      subcatStyle = {};
    }

    return (
      <div
        
      >
        <Modal
          isOpen={this.props.showCompEntered}
          toggle={this.toggle}
          centered={true}
          backdrop="static"
          contentClassName="create-idea-modal"
        >
          <ModalHeader
            toggle={this.toggle}
            text-center
            style={{ color: "#2c3e50", border: "0", position: "relative" }}
          />
          <span className="create-idea-modal-header">
            <img
              src={logo}
              width={150}
              style={{
                marginBottom: 5
              }}
            />
          </span>
          <h6
            style={{
              textAlign: "left",
              color: "#2c3e50",
              font: "bold 15pt",
              marginLeft: "10%",
              marginRight: "10%"
            }}
          >
            congratulations your idea has been submitted to the SPRXS
            competition, we will email you shortly with details.
          </h6>
          <Link
            to="#"
            className="custom-btn custom-btn-blue"
            onClick={this.toggle}
            style={{
              textAlign: "center",
              marginLeft: "10%",
              marginRight: "10%",
              marginBottom:"10%",
              position:"relative",
              width: "80%"
            }}
          >
            close
          </Link>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showCreateIdeaModal: state.showCreateIdeaModal,
    emailAddress: state.emailAddress,
    isAuthenticated: state.isAuthenticated,
    showCompEntered: state.showCompEntered
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: CLOSE_MODAL }),
    closeCompEnteredModal: () => dispatch(closeCompEntered()),
    showPriv: () => dispatch(showPrivModal()),
    showTerms: () => dispatch(showCookiePolicy()),
    showCompModal: () => dispatch(showCompModal()),
    dispatchSearchCriteria: searchCriteria => {
      dispatch({
        type: SEARCH_CRITERIA,
        payload: {
          searchCriteria: searchCriteria
        }
      });
    }
  };
};

export default withRouter(
  withCookies(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(styles)(CompEnteredModal))
  )
);
