import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { CLOSE_MODAL, CLOSE_COMP_MODAL } from "../../store/actions/actions";
import { NotificationManager } from "react-notifications";
import Typography from "@material-ui/core/Typography";

class CompTC extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      scroll: "paper"
    };
  }
  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.closeModal();
  };

  handleEnterComp = () => {
    
    this.setState({ open: false });
    this.props.closeModal();
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.cookiePol}
          onClose={this.handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            SPRXS IDEATION COMPETITION 2018/19
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="title" gutterBottom>
                Competition Rules
              </Typography>
              <Typography variant="body2" gutterBottom >
              1.    The promoter is: The Fifth9 Ltd (06423825) whose registered
              office is at 14 Hackwood Lane, Cliddesden, Basingstoke, RG25 2NH
              </Typography>
              <Typography variant="body2" gutterBottom >
              2.    The competition is open to applicants aged 18 years or over
              except employees of The Fifth9 Ltd, close relatives of Fifth9
              employees, or anyone otherwise connected with the organisation or
              adjudication of the competition. 3.    
              </Typography>
              <Typography variant="body2" gutterBottom >
              There is no entry fee and
              no purchase necessary to enter this competition. However
              applicants must create the idea on the SPRXS website and accept
              the associated terms and conditions and give us permission to
              contact them about the competition. 
              </Typography>
              <Typography variant="body2" gutterBottom >
              4.    Route to entry for the
              competition and details of how to enter are via
              http://www.sprxs.io 
              </Typography>
              <Typography variant="body2" gutterBottom >
              5.    Up to 10 novel/new entries will be
              accepted per person, however these must be submitted by the same
              person via the SPRXS website. 
              </Typography>
              <Typography variant="body2" gutterBottom >
              6. Any entries by Web Robot (BOT)
              will be disqualified. 
              </Typography>
              <Typography variant="body2" gutterBottom >
              7.    Closing date for entry will be 30th
              April 2020 at 12:00 GMT. After this date, the no further entries
              to the competition will be permitted.  
              </Typography>
              <Typography variant="body2" gutterBottom >
              8.    No responsibility can
              be accepted for entries not received for whatever reason, before
              or after the closing date. 
              </Typography>
              <Typography variant="body2" gutterBottom >
              9.    The rules of the competition and
              how to enter are as follows: Log your first idea on the SPRXS
              website through www.sprxs.io Enter the competition via the pop-up
              prompt that follows you create your idea. 
              </Typography>
              <Typography variant="body2" gutterBottom >
              10. The promoter
              reserves the right to cancel or amend the competition and these
              terms and conditions without notice in the event of a catastrophe,
              war, civil or military disturbance, act of God or any actual or
              anticipated breach of any applicable law or regulation or any
              other event outside of the promoter’s control. 
              </Typography>
              <Typography variant="body2" gutterBottom >
              11. Any changes to
              the competition will be notified to entrants as soon as possible
              by the promoter. Such change shall be effective immediately upon
              posting to the webpage. 
              </Typography>
              <Typography variant="body2" gutterBottom >
              12. The promoter is not responsible for
              inaccurate prize details supplied to any entrant by any third
              party connected with this competition. 
              </Typography>
              <Typography variant="body2" gutterBottom >
              12.  The prize is GBP
              9,250.00. Where the prize is payable in another currency, the
              exchange of Natwest Bank PLC at the date of award will apply. 
              </Typography>
              <Typography variant="body2" gutterBottom >
              13.
              The prize is as stated and no other alternatives will be offered.
              The prize is not transferable.   
              </Typography>
              <Typography variant="body2" gutterBottom >
              14.The winner will be chosen by a
              panel of 3 people to be set up by The Fifth9 Limited.   
              </Typography>
              <Typography variant="body2" gutterBottom >
              15. The
              promoter will notify the winner using the contact details provided
              when registering on the SPRXS website. The promoter’s decision in
              respect of all matters to do with the competition will be final
              and no correspondence will be entered into.   
              </Typography>
              <Typography variant="body2" gutterBottom >
              16. The winner will
              be notified by email within 28 days of the closing date. If the
              winner cannot be contacted personally or do not claim the prize
              within 14 days of notification, we reserve the right to withdraw
              the prize from the winner and pick a replacement winner.   
              </Typography>
              <Typography variant="body2" gutterBottom >
              17. By
              entering this competition, an entrant is indicating his/her
              agreement to be bound by these terms and conditions. As such,
              entry into the competition will be deemed as acceptance of these
              terms and conditions. 
              </Typography>
              <Typography variant="body2" gutterBottom >
              18. The competition and these terms and
              conditions will be governed by English law and any disputes will
              be subject to the exclusive jurisdiction of the courts of England.
                </Typography>
              <Typography variant="body2" gutterBottom >
              19. The winner agrees to the use of his/her name and image in
              any publicity material, as well as their entry. Any personal data
              relating to the winner or any other entrants will be used solely
              in accordance with current UK data protection legislation and will
              not be disclosed to a third party without the entrant’s prior
              consent.   
              </Typography>
              <Typography variant="body2" gutterBottom >
              20. You are providing your information to The Fifth9
              Limited and not to any other party. Any personal data/information
              provided will be used in line with the Privacy Policy found at
              http://www.sprxs.io
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cookiePol: state.compPol
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: CLOSE_COMP_MODAL })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompTC);
