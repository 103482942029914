import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { hostApi } from "../../constants";
import TextField from "@material-ui/core/TextField";
import techImage from "../../static/technology.jpeg";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Paper from "@material-ui/core/Paper";
import { SupervisedUserCircle } from "@material-ui/icons";
import logo from "../../static/Sunrise_white.png";
import fire from "../../constants";
import axios from "axios";
import { CardHeader } from "@material-ui/core";
import { connect } from "react-redux";
import FormHeader from "../FormHeader/FormHeader";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  bgimage: {
    backgroundColor: fade(theme.palette.secondary.dark, 0.2),
    backgroundImage: `url(${techImage})`
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
    marginTop: theme.spacing.unit * 2
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

class approveCollab extends React.Component {
  state = {
    open: false,
    data: this.data
  };

  componentDidMount() {
    const { data } = this.props;
    //console.log("setState",data)
    this.setState({});
  }

  // Handlers
  // --------------------------------------------------------------------------------------------------------------------------
  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked }, this.handleRWOI());
  };
  handleChangeData = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleRWOI = () => {
    const { token } = this.props;
    //console.log("handleRWOI", this.props.data);
    axios
      .get(`${hostApi}/getUserUid`,         
        { emailAdd: this.props.data.profileId },{
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
      )
      .then(response => {
        let toId = response.data;
        console.log("axios response Get User Uid:", toId);
        var message =
          "Hi! I have accepted you request to collaborate. Lets make our idea into a reality.";
        var currentUser = this.props.firebaseUid;
        const dateTime = Date.now();
        const timestamp = Math.floor(dateTime / 1000);
        var values = {
          type: "text",
          content: message,
          fromID: currentUser /*currentUserID*/,
          toID: toId,
          timestamp: timestamp,
          isRead: false
        };
        fire
          .database()
          .ref()
          .child("users")
          .child(currentUser) //Current User(Sender)
          .child("conversations")
          .child(toId) //Receiver
          .once("value")
          .then(snapshot => {
            if (snapshot.exists()) {
              console.log("It exists");

              //console.log(values);
              const locRef = fire
                .database()
                .ref()
                .child("conversations")
                .child(snapshot.val()["location"])
                .push(values);
            } else {
              fire
                .database()
                .ref()
                .child("users")
                .child(currentUser) //Current User(Sender)
                .child("conversations")
                .child(toId) //Receiver
                .once("value")
                .then(snapshot => {
                  console.log("Doesn't exists");
                  const messLoc = fire
                    .database()
                    .ref()
                    .child("conversations")
                    .push()
                    .push(values);
                  const messLocKey = messLoc.parent.key;
                  let sampleDict = { location: messLocKey };
                  fire
                    .database()
                    .ref()
                    .child("users")
                    .child(currentUser) //Current user(Sender)
                    .child("conversations")
                    .child(toId) //Receiver
                    .update(sampleDict);

                  fire
                    .database()
                    .ref()
                    .child("users")
                    .child(toId) //Receiver
                    .child("conversations")
                    .child(currentUser) //Current User (Sender)
                    .update(sampleDict);
                });
            }
            console.log("axios response Get User Uid", response.data); //The axios.get() returns the firebase uid associated with the email address. This returned value should be set for the variable toId at line 102 and also should replace this string "kBkpHisje7gk0S0Ed8VOHMwKXx82" everywhere in this page.
          });
      });

    axios
      .post(`${hostApi}/approveCollaborator`, 
        
        {
        profile_id: this.props.data.profileId,
        idea_id: this.props.data.ideaId,
        decision: "Approve"
      },
    {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => {
        //console.log("axios response", response);
      });
    this.props.onClose();
    //console.log("RWOI Updated");
  };

  handleDecline() {
    const { token } = this.props;
    axios
      .post(`${hostApi}/approveCollaborator`,         
        {
        profile_id: this.props.data.profileId,
        idea_id: this.props.data.ideaId,
        decision: "Reject"
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }
    )
      .then(response => {
        //console.log("axios response", response);
      });
    this.props.onClose();
    //console.log("RWOI Updated");
  }

  // Render
  // --------------------------------------------------------------------------------------------------------------------------

  render() {
    const { fullScreen, open, onClose, data, classes } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Paper>
            <FormHeader>
              <CardHeader
                avatar={
                  <SupervisedUserCircle
                    style={{ fontSize: 50 }}
                    color="primary"
                  />
                }
                title={
                  <Typography color="default" variant="title">
                    Approve Collaborator Request
                  </Typography>
                }
                subheader={
                  <Typography color="default">
                    You have received a request from the following collaborator
                    to work on your project. Once you approve this request, the
                    collaborator will be granted access to view the project
                    details as well as your contact email address.
                  </Typography>
                }
              />
              <DialogTitle id="responsive-dialog-title">{`Idea #${
                data.ideaId
              }`}</DialogTitle>
            </FormHeader>
            <DialogContent>
              <DialogContentText>
                <form
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="bxnTxnId"
                    label="Blockhain Hash"
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    value={data.bxnTxnId}
                    defaultValue={data.bxnTxnId}
                    fullWidth
                  />
                  <TextField
                    id="profileId"
                    label="Collaboration Requestor"
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    value={data.profileId}
                    defaultValue={data.profileId}
                    fullWidth
                  />

                  <TextField
                    id="lkpWoiRole"
                    label="Role on Project"
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    value={data.lkpWoiRole}
                    defaultValue={data.lkpWoiRole}
                    fullWidth
                  />

                  <TextField
                    id="lkpWoiCapacity"
                    label="Capacity on Project"
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    value={data.lkpWoiCapacity}
                    defaultValue={data.lkpWoiCapacity}
                    fullWidth
                  />

                  <TextField
                    id="lkpWoiRemun"
                    label="Remuneration on Project"
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    value={data.lkpWoiRemun}
                    defaultValue={data.lkpWoiRemun}
                    fullWidth
                  />

                  <TextField
                    isRequired
                    id="collabReason"
                    label="Reason to join project"
                    margin="normal"
                    variant="outlined"
                    rowsMax="3"
                    rows="3"
                    multiline
                    className={classes.textField}
                    value={data.collab_reason}
                    defaultValue={data.collabReason}
                    fullWidth
                  />

                  <TextField
                    isRequired
                    id="collabValueAdd"
                    label="Value to be brought to the project"
                    margin="normal"
                    variant="outlined"
                    rowsMax="3"
                    rows="3"
                    multiline
                    value={data.collab_valueadd}
                    defaultValue={data.collabValueadd}
                    lassName={classes.textField}
                    fullWidth
                  />
                </form>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                {" "}
                Cancel{" "}
              </Button>
              <Button onClick={this.handleRWOI} color="primary" autoFocus>
                {" "}
                Approve Collaborator
              </Button>
              <Button onClick={this.handleDecline} color="primary" autoFocus>
                {" "}
                Decline Request
              </Button>
            </DialogActions>
          </Paper>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    firebaseUid: state.firebaseUid,
    token: state.jwtToken
  };
};

approveCollab.propTypes = { fullScreen: PropTypes.bool.isRequired };

export default connect(
  mapStateToProps,
  null
)(withStyles(styles, { withTheme: true })(withMobileDialog()(approveCollab)));
