import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import FaceIcon from "@material-ui/icons/Face";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ChatIcon from "@material-ui/icons/ChatBubbleOutline";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      minWidth: "500px"
    }
  },
  listItem: {
    cursor: "pointer"
  },
  listItemActive: {
    backgroundColor: "var(--orange)"
  }
});

function ChatUser({ classes, userList, launchChat }) {
  // const { classes, userList, launchChat } = props;
  return (
    <div className={classes.root}>
      <List>
        {userList.map((item, i) => {
          return (
            <ListItem
              key={i}
              onClick={event => {
                launchChat(item);
              }}
              className={
                item.active ? classes.listItemActive : classes.listItem
              }
            >
              <Avatar>
                <FaceIcon color="inherit" />
              </Avatar>
              <ListItemText
                primary={`${item.firstName} ${item.lastName}`}
                // secondary={item.email}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

ChatUser.propTypes = {
  classes: PropTypes.object.isRequired,
  launchChat: PropTypes.func.isRequired
};

export default withStyles(styles)(ChatUser);
