import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PublicIcon from "@material-ui/icons/Public";
import PeopleIcon from "@material-ui/icons/People";
import PersonPinIcon from "@material-ui/icons/PersonPin";

const styles = theme => ({
  root: {
    width: "90%"
  },
  backButton: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

function getSteps() {
  return ["Idea created", "Open Idea for collaboration", "Collaborate"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Idea created, take it to the next level...collaborate";
    case 1:
      return "Push a request out to collaborate";
    case 2:
      return "Bring your idea to life!";
    default:
      return "Uknown stepIndex";
  }
}

class HorizontalLabelPositionBelowStepper extends React.Component {
  state = {
    activeStep: 0
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    const stepIcon = (n, activeStep) => {
      console.log("active step", activeStep, n);
      switch (n) {
        case 0:
          if (n <= activeStep) {
            return <PersonPinIcon color="primary" />;
          } else {
            return <PersonPinIcon style={{ color: "grey" }} />;
          }
        case 1:
          if (n <= activeStep) {
            return <PublicIcon color="primary" />;
          } else {
            return <PublicIcon style={{ color: "grey" }} />;
          }
        case 2:
          if (n <= activeStep) {
            return <PeopleIcon color="primary" />;
          } else {
            return <PeopleIcon style={{ color: "grey" }} />;
          }
        default:
          return <PeopleIcon style={{ color: "grey" }} />;
      }
    };

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, n) => {
            return (
              <Step key={label}>
                <StepLabel icon={stepIcon(n, activeStep)}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {this.state.activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={this.handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleNext}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

HorizontalLabelPositionBelowStepper.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepper);
