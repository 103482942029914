import styled from 'styled-components'

export const StyledSprxsButton = styled.button`
  border-radius: 0.5rem;
  padding: 0.6rem 2.0rem;
  border: ${props => props.border || "0"};
  text-transform: capitalize;
  // background-image: linear-gradient(#58b133,#0ca84f);
  color: ${props => props.color || "#fff"}};
  font-size: 1rem;
  margin: 0.5rem 0;
  background: ${props => props.bgColor || "var(--orange)"} ;
  content: ;
  cursor: pointer;

  ::before {
    content: ${props => props.icon || ';)'};
  }
`;