import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import CSSTransition from 'react-addons-css-transition-group';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CollaborateIcon from "../../../static/collaborateIdeasIcon.png";
import MonotizeIcon from "../../../static/fundIdeasIcon.png";
import CreateIdeaIcon from "../../../static/uploadIdeasIcon.png";
import Expand from "@material-ui/icons/ExpandMore";
import "./NavItem.css";

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

class NavItem extends React.Component {
  render() {
    const { classes, link, title, image, text, animTimeOut, journey } = this.props;

    const getImage = category => {
      switch (category) {
        case "CollaborateIcon":
          return CollaborateIcon;
        case "MonotizeIcon":
          return MonotizeIcon;
        case "CreateIdeaIcon":
          return CreateIdeaIcon;
        default:
          return CreateIdeaIcon;
      }
    };

    return (
      <div className="headerNavContainer" key={title}>
        <div className="headerNavItem" >
          <h4 className="headerNavLink" >
            {title}
          </h4>
        </div>
        {/* <div className="headerNavItem">
          <img src={getImage(image)} />
        </div>
        <div className="headerNavItem">
          <p className="headerNavText">{text}</p>
        </div> */}
      </div>
    );
  }
}

NavItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(NavItem));
