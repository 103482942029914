import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LandingDrawer from "../LandingPage/LandingDrawer";
import sprxlogo from "../../static/sprxslogo.png";
import { HomeFooter } from "../../components/HomeFooter/HomeFooter";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import { connect } from "react-redux";
import { SHOW_TERMS_MODAL, SHOW_PRIV_MODAL } from "../../store/actions/actions";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Scrollchor from "react-scrollchor";

import "./FAQ.css";

const bgColor = "white";

const styles = theme => ({
  root: {
    backgroundColor: bgColor,
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  top: {
    marginTop: 80
  }
});

const offset = -74;
const duration = 400;

class FAQPage extends React.Component {
  state = {
    openDrawer: false
  };

  handleCloseDrawer = () => {
    this.setState({ openDrawer: false });
  };

  handleOpenDrawer = () => {
    this.setState({ openDrawer: true });
  };

  handleChange = (event, value) => {
    this.setState({ value: value });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  render() {
    const { classes, fullScreen } = this.props;

    return (
      <div className={classes.root}>
        <div className="faqWrapper">
          <h1 className="faqHeader">Frequently Asked Questions</h1>
          <div className="faqContent">
            <ol className="highLight buffer">
              <li>
                <Scrollchor
                  to="#question1"
                  animate={{ offset: offset, duration: duration }}
                  className="questionLink"
                >
                  Why should I log my ideas on SPRXS?
                </Scrollchor>
              </li>
              <li>
                <Scrollchor
                  to="#question2"
                  animate={{ offset: offset, duration: duration }}
                  className="questionLink"
                >
                  Does creating an idea on SPRXS confirm that I own it?
                </Scrollchor>
              </li>
              <li>
                <Scrollchor
                  to="#question3"
                  animate={{ offset: offset, duration: duration }}
                  className="questionLink"
                >
                  Does creating my idea on SPRXS confirm that it's unique?
                </Scrollchor>
              </li>
              <li>
                <Scrollchor
                  to="#question4"
                  animate={{ offset: offset, duration: duration }}
                  className="questionLink"
                >
                  What is the difference between SPRXS and a Patent?
                </Scrollchor>
              </li>
              <li>
                <Scrollchor
                  to="#question5"
                  animate={{ offset: offset, duration: duration }}
                  className="questionLink"
                >
                  Is SPRXS for people or businesses?
                </Scrollchor>
              </li>
              <li>
                <Scrollchor
                  to="#question6"
                  animate={{ offset: offset, duration: duration }}
                  className="questionLink"
                >
                  Does it cost to log ideas?
                </Scrollchor>
              </li>
              <li>
                <Scrollchor
                  to="#question7"
                  animate={{ offset: offset, duration: duration }}
                  className="questionLink"
                >
                  Does putting my idea on SPRXS mean that it is publicly
                  available?
                </Scrollchor>
              </li>
              <li>
                <Scrollchor
                  to="#question8"
                  animate={{ offset: offset, duration: duration }}
                  className="questionLink"
                >
                  What are milestones?
                </Scrollchor>
              </li>
              <li>
                <Scrollchor
                  to="#question9"
                  animate={{ offset: offset, duration: duration }}
                  className="questionLink"
                >
                  What are SPRXS equity tokens?
                </Scrollchor>
              </li>
              <li>
                <Scrollchor
                  to="#question10"
                  animate={{ offset: offset, duration: duration }}
                  className="questionLink"
                >
                  When will you be announcing the prize winner?
                </Scrollchor>
              </li>
            </ol>
            <h3 className="faqSubHead" id="question1">
              1. Why should I log my ideas on SPRXS?
            </h3>
            <p className="faqCopy">
              There are three key benefits to logging your ideas on the SPRXS
              network:
            </p>
            <ol className="listSmall">
              <li>
                Your idea will be uploaded to the Blockchain, providing an
                immutable record that you logged it.
              </li>
              <li>
                You will have the ability to hand over equity in your idea
                (percentage ownership) to others in exchange for resources –
                e.g. skills, capabilities, office space.
              </li>
              <li>
                Once your ideas reach a certain level of maturity investors
                on SPRXS can purchase equity from you, giving you access to
                funding.
              </li>
            </ol>
            <h3 className="faqSubHead" id="question2">
              2. Does creating an idea on SPRXS confirm that I own it?
            </h3>
            <p className="faqCopy">
              Legal ownership of an idea is realised in the filing of a <a href="https://www.gov.uk/search-for-patent" target="_blank">patent</a>.
              Anything that needs to be patented needs to have a method of
              invention or novelty. Ideas on SPRXS are pre-patent, meaning that
              you get to work in a safe environment where you can be as
              secretive or as open as you want. Once you are ready, you can
              choose to file your idea as a patent or develop it. SPRXS bridges
              the gap between simply having a great idea and filing a legal
              patent of ownership.
            </p>
            <h3 className="faqSubHead" id="question3">
              3. Does creating my idea on SPRXS confirm that it's unique?
            </h3>
            <p className="faqCopy">
              SPRXS is not a search engine designed to check whether an idea is
              unique or not. Whilst SPRXS does not confirm your idea is unique,
              it does mark the submission, thoughts, inventions and novelties as
              belonging to you on the SPRXS platform. Since collaboration is
              encouraged within the SPRXS playground, we implore you to seek out
              other bright sparks that have the same ideas with you. We have
              every faith that you will be great alone, but we know that you
              will be better, together.
            </p>
            <h3 className="faqSubHead" id="question4">
              4. What is the difference between SPRXS and a Patent?
            </h3>
            <p className="faqCopy">
              SPRXS is not a substitute for a patent. SPRXS allows you to work
              in a distributed co-creation environment which ensures your
              contribution and collaboration is logged safely and securely. The
              safety of this process allows you to lodge ownership at the next
              stage of patent. SPRXS is a 'pre-patent' stage, in future releases
              SPRXS will be offering patent pending services using the trust of
              third parties.
            </p>
            <h3 className="faqSubHead" id="question5">
              5. Is SPRXS for people or businesses?
            </h3>
            <p className="faqCopy">
              SPRXS is for everyone, from the elderly woman who innovates a new
              knitting pattern to the young school child who figures out a way
              to reduce queue times at lunch, and not least everyone in between.
              We encourage businesses of all sizes to join the platform, and
              more than that we encourage start ups to be born from within our
              co-creation playground.
            </p>
            <h3 className="faqSubHead" id="question6">
              6. Does it cost to log ideas?
            </h3>
            <p className="faqCopy">
              SPRXS is currently an entirely free platform, we encourage you to
              create and nurture as many ideas as you can.
            </p>
            <h3 className="faqSubHead" id="question7">
              7. Does putting my idea on SPRXS mean that it is publicly
              available?
            </h3>
            <p className="faqCopy">
              No, your idea can only be seen by you until you give others
              permission to see it. By putting your idea on the blockchain you
              are providing an immutable record that you logged it, to protect
              you when collaborating with others on SPRXS. Even when you make
              your idea searchable you are still not giving others access to
              your idea, you are simply advertising a short ‘teaser’ of your
              idea to entice potential collaborators to work on it with you.
            </p>
            <h3 className="faqSubHead" id="question8">
              8. What are milestones?
            </h3>
            <p className="faqCopy">
              Milestones are our way of giving users the ability to log certain
              events as milestones on to the blockchain. By default, all equity
              transfers that you make on your idea will be logged onto the
              blockchain and provide an immutable record of said transfer. You
              should use the milestone feature to track your progress on
              targets, mark significant dates in your idea’s development, and
              build a timeline of your ideas evolution that can be trusted.
            </p>
            <h3 className="faqSubHead" id="question9">
              9. What are SPRXS equity tokens?
            </h3>
            <p className="faqCopy">
              Equity tokens represent percentage ownership in your ideas.,
              meaning that within the SPRXS network you can freely reward
              collaborators for their contributions with shares in your idea.
              With SPRXS, you have the power to distribute equity however you
              require and any equity transfers that you make with be logged onto
              the blockchain in the form of a milestone. Be warned that
              transferring equity tokens is binding so do not give it away
              lightly.
            </p>
            <h3 className="faqSubHead" id="question10">
              10. When will you be announcing the prize winner?
            </h3>
            <p className="faqCopy buffer">
              Closing date for entry will be 30th April 2020 at 12:00 GMT. After this date, the no further entries to the competition will be permitted. The winner will be notified by email within 28 days of the closing date. If the winner cannot be contacted personally or do not claim the prize within 14 days of notification, we reserve the right to withdraw the prize from the winner and pick a replacement winner.
            </p>
          </div>
        </div>
        {HomeFooter(classes, this)}
      </div>
    );
  }
}

FAQPage.propTypes = {
  classes: PropTypes.object
};

export default withRouter(
  connect()(withStyles(styles)(withMobileDialog()(FAQPage)))
);
