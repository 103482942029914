import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CreateIdeaFirstTime from "./CreateIdeaFirstTime";
import { CLOSE_MODAL } from "../../store/actions/actions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import CreateIdeaExistingUser from "./CreateIdeaExistingUser";
import FormHeader from "../FormHeader/FormHeader";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  black: {
    color: "black"
  }
});

class CreateIdeaModal extends Component {
  state = {
    open: false,
    scroll: "paper",
    closeButton: false
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.closeModal();
  };

  handleSetCloseButton = () => {
    this.setState({ closeButton: true });
  };

  render() {
    const { fullScreen, isAuthenticated, history, classes } = this.props;

    return (
      <div>
         <Dialog
          maxWidth="sm"
          fullWidth={true}
          fullScreen={fullScreen}
          open={this.props.showCreateIdeaModal}
          onClose={this.handleCloseReg}
          disableBackdropClick={false}
          aria-labelledby="responsive-dialog-title"
        >
          <FormHeader>Create Idea</FormHeader>
          <DialogContent>
            <CreateIdeaExistingUser updateData={this.props.updateData}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showCreateIdeaModal: state.showCreateIdeaModal,
    emailAddress: state.emailAddress,
    isAuthenticated: state.isAuthenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: CLOSE_MODAL })
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withMobileDialog()(CreateIdeaModal)))
);
