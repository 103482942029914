import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormHeader from "../../components/FormHeader/FormHeader";

import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import {
  isAuthenticated,
  dispatchAuth,
  saveToken
} from "../../store/actions/actions";
import axios from "axios";
import { hostApi, timeOut } from "../../constants";
import queryString from "query-string";
import "./LoginPage.css";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";

const styles = theme => ({
  form: {
    width: "100%",
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 2999;
`;
const errorNoNetwork = "*Please try again Network error";
const errorNetwork = "Login Timeout. Please try again.";
const errorInvalid = "Invalid Email or Password";

class FbLogin extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      lkp_email: cookies.get("emailAddress" || ""),
      errorMessage: "",
      isLoading: true,
      success: false,
      loading: false,
      isLoading2: true,
      success2: false,
      loading2: false,
      isDisabled: true,
      token: "",
      name: ""
    };
  }

  componentWillMount() {
    const { cookies, saveToken, location } = this.props;
    const values = queryString.parse(location.search);
    console.log(values.code, "queryString  code");
    // Validation
    if (values.code === "") {
      this.setState({ errorMessage: errorNoNetwork });
    } else {
      console.log("EMAIL VALIDATION FINE MOVE TO AXIOS");
      this.setState({ isLoading: true });
      this.setState({ loading: true });

      this.timer = setTimeout(() => {
        this.setState({ errorMessage: errorNetwork, loading: false });
      }, 30000); // https://www.fifth9-uat.com/
      axios
        .get(`${hostApi}/loginProfile_FB?code=${values.code}`, {
          headers: {
            "Access-Control-Allow-Origin": `${hostApi}`,
            //"Access-Control-Allow-Origin": "http://127.0.0.1:3000",
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.token}`
          }
        })
        .then(response => {
          clearTimeout(this.timer);
          this.setState({ name: response.data.login_firstname });
          this.setState({ loading: false, success: true });
          cookies.set("isAuthenticated", true, {
            path: "/",
            maxAge: timeOut
          }); //will expire in 1hr
          cookies.set("emailAddress", response.data.login_email, {
            path: "/",
            maxAge: timeOut
          });
          this.props.dispatchisAuthenticated(response.data.login_email);

          cookies.set("name", response.data.login_firstname, {
            path: "/",
            maxAge: timeOut
          });
          cookies.set("last_name", response.data.login_surname, {
            path: "/",
            maxAge: timeOut
          });
          // login_firstname is Not login_name

          window.sessionStorage.setItem(
            "userInfo",
            JSON.stringify({
              profile_id: response.data.id,
              email: response.data.login_email,
              firstName: response.data.login_firstname,
              lastName: response.data.login_surname
            })
          );
          //dispatch the redux action to save Jason Web Token
          if (!cookies.get("bearerToken")) {
            saveToken(cookies, response.data.token);
            console.log(" I am the Token");
          }
          cookies.set("profile_id", response.data.id, {
            path: "/",
            maxAge: timeOut
          });

          this.props.dispatchAuth(response.data.profile_type, response.data.id);
          this.navigateToMySPRXS();

          this.setState({ isLoading: false });
        })
        .catch(error => {
          clearTimeout(this.timer);
          this.setState({ errorMessage: errorInvalid });
          this.props.history.push(`/login`);
        })
        .catch(error => {
          console.log("ERROR", error);
          clearTimeout(this.timer);
          this.setState({ errorMessage: errorInvalid, loading: false });
        });
    }
  }

  navigateToMySPRXS = (profileId, email, firstName, lastName) => {
    var defaultPage = `/dashboard`;
    this.props.history.push(defaultPage);
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleClose = e => {
    this.props.history.push(`/`);
  };

  render() {
    const { classes, fullScreen } = this.props;
    const { isLoading, errorMessage } = this.state;

    return (
      <div className="Signup-style app-bg">
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={true}
          fullScreen={fullScreen}
          aria-labelledby="responsive-dialog-title"
        >
          <FormHeader>Loading...</FormHeader>
          <DialogContent>
            {isLoading ? (
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={35}
                color={"#123abc"}
                loading={this.state.isLoading}
              />
            ) : (
              <Typography style={{ padding: 0 }}>{errorMessage}</Typography>
            )}
            <form className={classes.form}></form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

FbLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchAuth: (type, profileID) => {
      dispatchAuth(type, profileID, dispatch);
    },
    dispatchisAuthenticated: (emailAddress = null) =>
      dispatch(isAuthenticated(emailAddress)),

    saveToken: (cookies, token) => saveToken(cookies, token, dispatch)
  };
};

export default withRouter(
  withCookies(connect(null, mapDispatchToProps)(withStyles(styles)(FbLogin)))
);
