import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";

import { TwitterTimelineEmbed } from "react-twitter-embed";
import InstagramEmbed from "react-instagram-embed";

const styles = theme => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center"

  },
  card: {
    maxWidth: 400,
    padding: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 2,
    flexDirection: "row"
  }
});

class SocialMediaFeed extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Card className={classes.card}>
          <CardContent className={classes.item}>
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsprxs&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=107280336006598"
              width="500"
              height="600"
              styles="border:none;overflow:hidden"
              scrolling="no"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
              allowFullScreen={true}
            />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="sprxsapp"
              options={{ height: 600, width: 500 }}
            />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <InstagramEmbed
              url="https://www.instagram.com/p/BqIh647Hz9p/"
              maxWidth={500}
              hideCaption={false}
              containerTagName="div"
              protocol=""
              injectScript
              onLoading={() => {}}
              onSuccess={() => {}}
              onAfterRender={() => {}}
              onFailure={() => {}}
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

SocialMediaFeed.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SocialMediaFeed);
