import React, { Component } from "react";
import { connect } from "react-redux";
import "./InviteList.css";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Check, Close } from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import CloseCircleIcon from "@material-ui/icons/CancelOutlined";
import Axios from "axios";
import { hostApi, IDEATOR, woiRole } from "../../constants";
import { NotificationManager } from "react-notifications";
import {
  getInvitations,
  sendRWOI,
  showRTWOIProfile
} from "../../store/actions/actions";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import firebase from "../../constants";
import Typography from "@material-ui/core/Typography";

class InviteListRTWOI extends Component {
  // componentDidMount(){
  //   console.log("INVITELISTRTWOI::::::::", this.props);
  //   debugger;
  // }

  viewProfile = (id, firstname, surname, reason, value) => {
    const { invitationDataRTWOI, jwtToken } = this.props;

    let data = [
      {
        collabId: id,
        collabFirstName: firstname,
        collabLastName: surname,
        collabReason: reason,
        collabValue: value
      }
    ];
    this.props.showRTWOIProfile(jwtToken, data);
  };

  respondToInvite = (action, inviteID, firebaseId) => {
    const {
      getInvitations,
      profileID,
      updateData,
      cookies,
      jwtToken
    } = this.props;
    let _profileID = 0;

    if (profileID === null) {
      _profileID = cookies.get("profile_id");
    } else {
      _profileID = profileID;
    }

    if (action === "approve") {
      let myFirebaseId = firebase.auth().currentUser.uid;
      sendRWOI(myFirebaseId, firebaseId);
    }
    Axios.patch(
      `${hostApi}/collaborations/invitation/${action}/${inviteID}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )
      .then(
        // success
        ({ data, status }) => {
          if (status === 200) {
            NotificationManager.success("Success", `Invite (${action})`, 3000);
            getInvitations(jwtToken, _profileID, "ideator", status => {
              if (status !== 200) {
              }
            });
            updateData(_profileID);
          } else {
            NotificationManager.error(
              "Error",
              "Approve/Reject API Error",
              3000
            );
          }
        }
      )
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  };

  render() {
    const { invitationDataRTWOI, jwtToken } = this.props;
    const _invitationData = invitationDataRTWOI;
    // .filter(
    //   item => item.collabApproved === 2
    // );
    // debugger;
    return _invitationData.length === 0 ? (
      <div>
        <Typography variant="Subheading" color="inherit">
          Keep a look out. SPRXS users that request to collaborate on your ideas
          will be shown here. You will be able to view their profile&nbsp;(
          <IconButtonComponent type={"profile"} iconStyle={"iconDisabled"} />)
          and accept&nbsp;(
          <IconButtonComponent type={"approve"} iconStyle={"iconDisabled"} />)
          or reject&nbsp;(
          <IconButtonComponent type={"reject"} iconStyle={"iconDisabled"} />)
          their request.
        </Typography>
      </div>
    ) : (
      <div className="invite-list__wrapper">
        <table className="invite-list">
          <thead>
            <tr className="invite-list__header">
              <td>Name / Role</td>
              <td>Idea Id</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {_invitationData.map(invite => {
              return (
                <tr key={invite.id}>
                  <td className="invite-list__nameField">
                    <div className="invite-list__name">{`${invite.firstname} ${invite.surname}`}</div>
                    <div className="invite-list__id">{`${woiRole[invite.lkpWoiRole].label}`}</div>
                  </td>
                  <td>
                    <div className="invite-list__status">{invite.ideaId}</div>
                  </td>
                  <td className="invite-list__expand">
                    <IconButtonComponent
                      type={"approve"}
                      toolTip={true}
                      title={`Approve`}
                      iconStyle={"iconDefault iconCodeGreen iconPadRight"}
                      link={() =>
                        this.respondToInvite(
                          "approve",
                          invite.id,
                          invite.collaboratorFirebaseUid
                        )
                      }
                    />
                    <IconButtonComponent
                      type={"reject"}
                      toolTip={true}
                      title={"Reject"}
                      iconStyle={"iconDefault iconWarning iconPadRight"}
                      link={() =>
                        this.respondToInvite(
                          "reject",
                          invite.id,
                          invite.collaboratorFirebaseUid
                        )
                      }
                    />
                    <IconButtonComponent
                      type={"profile"}
                      toolTip={true}
                      title={`View ${invite.firstname}'s request`}
                      iconStyle={"iconAction"}
                      link={() =>
                        this.viewProfile(
                          invite.collabProfile,
                          invite.firstname,
                          invite.surname,
                          invite.reason,
                          invite.valueadd
                        )
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
    // debugger;
  }
}

const mapStateToProps = state => ({
  emailAddress: state.emailAddress,
  userType: state.userType,
  profileID: state.profileID,
  token: state.jwtToken
});

const mapDispatchToProps = dispatch => ({
  getInvitations: (jwtToken, profileID, filter, callback) => {
    getInvitations(jwtToken, profileID, filter, callback, dispatch);
  },
  showRTWOIProfile: (jwtToken, data) => {
    showRTWOIProfile(jwtToken, data, dispatch);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteListRTWOI);
