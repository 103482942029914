import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { SHOW_IDEA_DETAILS } from "../../store/actions/actions";
import EditCollabDialog from "../EditCollabModal/EditCollabDialog";

let counter = 0;

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "dateCreated",              numeric: false, disablePadding: false, label: "Date" },
  { id: "createdProfileSender",     numeric: false, disablePadding: false, label: "From" },
  { id: "ideaId",                   numeric: false, disablePadding: false, label: "Idea" },
  { id: "eventDescription",         numeric: false, disablePadding: false, label: "Event" },
  { id: "description",              numeric: false, disablePadding: false, label: "Description" }
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => { this.props.onRequestSort(event, property); };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(row => {
            return (
              <TableCell key={row.id} numeric={row.numeric} padding={row.disablePadding ? "none" : "default"} sortDirection={orderBy === row.id ? order : false}>
                <Tooltip title="Sort" placement={row.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                  <TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>{row.label}</TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};
const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    backgroundColor: "transparent"
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  spacer: {
    flex: "1 1 auto"
  },
  actions: {
    boxShadow: "none",
    backgroundColor: "transparent",
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto",
    backgroundColor: "transparent"
  },
  button: {
    margin: theme.spacing.unit
  }
});
let EnhancedTableToolbar = props => {
  const { numSelected, classes, tableTitle } = props;
  return (
    <Toolbar className={classNames(classes.root, { [classes.highlight]: numSelected > 0 })}>
      <div className={classes.title}><Typography color="secondary" variant="h6" id="tableTitle">{tableTitle}</Typography></div>
      <div className={classes.spacer} /></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 0
  },
  table: {
    minWidth: "100%",
    backgroundColor: "transparent"
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class MyIdeas extends React.Component {
  state = {
    order: "asc",
    orderBy: "calories",
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 5,
    showPublish: false,
    selectedData: [],
    showCollab: false
  };
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };
  handleSelectAllClick = event => {
    const { data } = this.props;
    if (event.target.checked) {
      this.setState(state => ({ selected: data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };
  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  lauchPublish = (e, data) => {
    //console.log("lauchPublish", this.state.selected, this.props.data);

    this.setState(
      {
        selectedData: { ...data }
      },
      this.setState({
        showPublish: true
      })
    );
  };
  launchEdit = (e, data) => {
    //console.log("EDIT EVENT", e, data);
    this.setState(
      { selectedData: { ...data } },
      this.setState({ showCollab: true })
    );
  };
  handleCloseDetails = () => {
    this.setState({ showPublish: false });
  };
  handleCloseCollab = () => {
    this.setState({ showCollab: false });
  };
  handleShowCollab = () => {
    this.setState({ showCollab: true });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, data } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper square className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} rowCount={data.length} />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  
                  return (
                    <TableRow hover onClick={event => this.handleClick(event, n.id)} role="checkbox" tabIndex={-1} key={n.id}>
                      <TableCell>{n.date_created}</TableCell>
                      <TableCell>{n.created_profile_sender}</TableCell>
                      <TableCell>{n.Idea_id}</TableCell>
                      <TableCell>{n.event_description}</TableCell>
                      <TableCell>{n.description}</TableCell>                      
                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}><TableCell colSpan={5} /></TableRow>
              )}
            </TableBody>
          </Table>
          <EditCollabDialog open={this.state.showCollab} onClose={this.handleCloseCollab} data={this.state.selectedData} />
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

MyIdeas.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    showIdeaDetails: state.showIdeaDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    showIdeaDetails: () => dispatch({ type: SHOW_IDEA_DETAILS })
    
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MyIdeas))
);
