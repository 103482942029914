import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import person from "../../static/collaborator.png";
import { withStyles } from "@material-ui/core/styles";
import "./MyProfileEdit.css";
import {
  Typography,
  Paper,
  CardHeader,
  IconButton,
  CardContent,
  TextField,
  Divider,
  Tooltip
} from "@material-ui/core";
import { Translate, Edit, Save, Cancel, Add } from "@material-ui/icons";
import { getCollaborator, putCollaborator } from "../../store/actions/actions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import StarIcon from "@material-ui/icons/Star";
import DeleteIcon from "@material-ui/icons/Delete";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import { deep_copy } from "../../utility/utility_funcs";
import country_codes from "./country_codes.json";
import { withCookies } from "react-cookie";
import { COLLABORATOR, ExpertiseMap, primaryExpertise, timeOut, hostApi } from "../../constants";
import { isNull } from "util";
import IconButtonComponent from "../../components/IconButtonComponent/IconButton.jsx";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    textAlign: "left",
    position: "relative"
  },
  menu: {
    width: 200
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  textFieldBio: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: "100%"
  },
  textFieldAdd: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit * 5
  },
  linearColorPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  linearBarColorPrimary: {
    backgroundColor: theme.palette.secondary.main
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  iconButton: {
    marginBottom: theme.spacing.unit * 2
  },
  rightAlign: {
    textAlign: "left",
    minWidth: "30%"
  }
});

const experience = [
  {
    value: 1,
    label: "Beginner"
  },
  {
    value: 2,
    label: "Intermediate"
  },
  {
    value: 3,
    label: "Expert"
  }
];

function getRating(rating) {
  switch (rating) {
    case 2:
      return (
        <Tooltip title={"Intermediate"}>
          <span>
            <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
            <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
            <IconButtonComponent  type={"starBorder"} iconStyle={"iconDefault iconCodeBlue"} />
          </span>
        </Tooltip>
      );

    case 3:
      return (
        <Tooltip title={"Expert"}>
          <span>
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          </span>
        </Tooltip>
      );

    default:
      //includes rating of 1
      return (
        <Tooltip title={"Beginner"}>
          <span>
          <IconButtonComponent  type={"star"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"starBorder"} iconStyle={"iconDefault iconCodeBlue"} />
          <IconButtonComponent  type={"starBorder"} iconStyle={"iconDefault iconCodeBlue"} />
          </span>
        </Tooltip>
      );
  }
}

class MyProfileEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      editSkills: false,
      editWeblinks: false,
      editSkillsAddError: false,
      editWeblinkAddError: false,
      editBio: false,
      editExpertise: false,
      editPublic: false,
      pendingSaveSkillsResponse: false,
      pendingSavePublicResponse: false,
      pendingSaveBioResponse: false,
      pendingSaveEduResponse: false,
      pendingSaveWeblinkResponse: false,
      pendingSaveExpertiseResponse: false,
      deltaSkills: {},
      newSkill: {
        id: null,
        skillName: "",
        skillLevel: 1
      },
      newEdu: {
        id: null,
        schoolName: "",
        country: 1,
        qualification: "",
        major: "",
        year: null
      },
      newWeblink: {
        id: null,
        type: "",
        url: ""
      }
    };
  }

  static propTypes = {
    prop: PropTypes
  };

  componentDidMount() {
    const { cookies, profileID } = this.props;
    const _collabID = profileID
      ? profileID
      : cookies.get("profile_id");
      if(_collabID !== undefined || _collabID !== isNull) {
        this.props.getCollaborator(cookies.get("bearerToken"), _collabID);
      }
  }

  handleAddSkill = () => {
    const { collaboratorEntity, newSkill } = this.state;
    const { collaboratorData } = this.props;

    /* Create a deep copy and modify it */
    let copyDataObj = deep_copy(collaboratorEntity);

    if (
      copyDataObj.skills.find(skill => {
        return skill.skillName === newSkill.skillName;
      })
    ) {
      this.setState({ editSkillsAddError: true });
    } else {
      copyDataObj.skills.push(newSkill);

      this.setState(prevState => ({
        editSkillsAddError: false,
        collaboratorEntity: copyDataObj,
        newSkill: { id: null, skillName: "", skillLevel: 1 }
      }));
    }
  };

  onRemoveSkill = id => {
    const { collaboratorEntity } = this.state;
      axios.post(`${hostApi}/delete/skill/${id}`, null,
      {
        headers:{
          'Authorization': `Bearer ${this.props.cookies.get("bearerToken")}`
        }
      })
      .then(
          // success
          ({ data, status }) => {
            if (status === 200) {
              console.log("Success");
              this.saveSkills();
            } else {
              console.log("ERROR", status);
            }
          }
        )
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
        });
  };

  onRemoveWebLink = id => {
    const { collaboratorEntity } = this.state;
      axios.post(`${hostApi}/delete/weblink/${id}`, null,
      {
        headers:{
          'Authorization': `Bearer ${this.props.cookies.get("bearerToken")}`
        }
      })
      .then(
          // success
          ({ data, status }) => {
            if (status === 200) {
              console.log("Success");
              this.saveWeblinks();
            } else {
              console.log("ERROR", status);
            }
          }
        )
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
        });
  };

  onRemoveEducation = id => {
    const { collaboratorEntity } = this.state;
      axios.post(`${hostApi}/delete/education/${id}`, null,
      {
        headers:{
          'Authorization': `Bearer ${this.props.cookies.get("bearerToken")}`
        }
      })
      .then(
          // success
          ({ data, status }) => {
            if (status === 200) {
              console.log("Success");
              this.saveEdu();
            } else {
              console.log("ERROR", status);
            }
          }
        )
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
        });
  };

  onRemoveEdu = name => {

    const { collaboratorEntity } = this.state;

    /* Create a deep copy and modify it */
    let copyDataObj = deep_copy(collaboratorEntity);

    copyDataObj.educations = this.state.collaboratorEntity.educations.filter(
      item => item.major !== name
    );

    this.setState(prevState => ({
      collaboratorEntity: copyDataObj
    }));
  };

  handleChangeBio = event => {
    const { collaboratorEntity } = this.state;
    let copyOfcollaboratorEntity = { ...collaboratorEntity };
    copyOfcollaboratorEntity.profileDesc = event.target.value;
    this.setState({ collaboratorEntity: copyOfcollaboratorEntity });
  };

  handleChangePublic = () => {
    const { collaboratorEntity } = this.state;
    let copyOfcollaboratorEntity = { ...collaboratorEntity };
    copyOfcollaboratorEntity.hidden = !copyOfcollaboratorEntity.hidden;
      this.setState(prevState => ({
        collaboratorEntity: {...copyOfcollaboratorEntity}
      }));
      //  console.log("OLD ", copyOfcollaboratorEntity);
      //  console.log("UPDATED ", this.state.collaboratorEntity);
  };

  handleEduChange = (name, type) => event => {
    const { collaboratorEntity } = this.state;
    let copyOfcollaboratorEntity = { ...collaboratorEntity };
    copyOfcollaboratorEntity.educations = [...collaboratorEntity.educations];

    copyOfcollaboratorEntity.educations.map((item, i) => {
      if (item.major === name) {

        this.setState({ editEduAddError: true });

        if (type === "major") {
          copyOfcollaboratorEntity.educations[i].major = event.target.value;
        }
        if (type === "schoolName") {
          copyOfcollaboratorEntity.educations[i].schoolName =
            event.target.value;
        }
        if (type === "country") {
          copyOfcollaboratorEntity.educations[i].country = event.target.value;
        }
        if (type === "qualification") {
          copyOfcollaboratorEntity.educations[i].qualification =
            event.target.value;
        }
        if (type === "year") {
          copyOfcollaboratorEntity.educations[i].year = event.target.value;
        }
      } else {
        this.setState({ editEduAddError: false });
      }
    });

    // update
    this.setState({ collaboratorEntity: copyOfcollaboratorEntity });
  };

  handleWeblinkChange = (name, type) => event => {
    const { collaboratorEntity } = this.state;
    let copyOfcollaboratorEntity = { ...collaboratorEntity };
    copyOfcollaboratorEntity.weblinks = [...collaboratorEntity.weblinks];

    copyOfcollaboratorEntity.weblinks.map((item, i) => {
      if (item.url === name) {

        if (type === "type") {
          copyOfcollaboratorEntity.weblinks[i].type = event.target.value;
        }
        if (type === "url") {
          copyOfcollaboratorEntity.weblinks[i].url = event.target.value;
        }
      }
    });

    // update
    this.setState({ collaboratorEntity: copyOfcollaboratorEntity });
  };

  handleChange = (name, type) => event => {
    const { collaboratorEntity } = this.state;
    let copyOfcollaboratorEntity = deep_copy(collaboratorEntity);

    copyOfcollaboratorEntity.skills.map((item, i) => {
      if (item.skillName === name) {

        if (type === "name") {
          copyOfcollaboratorEntity.skills[i].skillName = event.target.value;
        }
        if (type === "level") {
          copyOfcollaboratorEntity.skills[i].skillLevel = event.target.value;
        }
      }
    });

    // update
    this.setState({ collaboratorEntity: copyOfcollaboratorEntity });
  };

  updateAddSkill = type => event => {
    const { newSkill, collaboratorEntity } = this.state;
    let copyOfnewSkill = { ...newSkill };

    //add a new skill
    if (
      type === "name" &&
      collaboratorEntity.skills.find(skill => {
        return skill.skillName === event.target.value;
      })
    ) {
      this.setState({ editSkillsAddError: true });
    } else {
      this.setState({
        editSkillsAddError: false
      });
    }

    if (type === "name") {
      copyOfnewSkill.skillName = event.target.value;
    } else {
      copyOfnewSkill.skillLevel = event.target.value;
    }

    this.setState({
      newSkill: { ...copyOfnewSkill }
    });
  };

  updateAddEdu = type => event => {
    const { newEdu } = this.state;
    let copyOfnewEdu = { ...newEdu };

    //add a new skill
    if (type === "major") {
      copyOfnewEdu.major = event.target.value;
    } else if (type === "schoolName") {
      copyOfnewEdu.schoolName = event.target.value;
    } else if (type === "country") {
      copyOfnewEdu.country = event.target.value;
    } else if (type === "year") {
      copyOfnewEdu.year = event.target.value;
    } else if (type === "qualification") {
      copyOfnewEdu.qualification = event.target.value;
    }

    this.setState({ newEdu: { ...copyOfnewEdu } });
  };

  updateAddWeblink = type => event => {
    const { newWeblink } = this.state;
    let copyOfnewWeblink = { ...newWeblink };

    //add a new skill
    if (type === "type") {
      copyOfnewWeblink.type = event.target.value;
    } else if (type === "url") {
      copyOfnewWeblink.url = event.target.value;
    }

    this.setState({ newWeblink: { ...copyOfnewWeblink } });
  };

  handleAddEdu = () => {
    const { collaboratorEntity, newEdu } = this.state;

    /* Create a deep copy and modify it */
    let copyDataObj = deep_copy(collaboratorEntity);

    copyDataObj.educations.push(newEdu);

    this.setState(prevState => ({
      collaboratorEntity: copyDataObj,
      newEdu: {
        schoolName: "",
        country: 1,
        qualification: "",
        major: "",
        year: null
      }
    }));
  };

  handleAddWeblink = () => {
    const { collaboratorEntity, newWeblink } = this.state;

    /* Create a deep copy and modify it */
    let copyDataObj = deep_copy(collaboratorEntity);

    copyDataObj.weblinks.push(newWeblink);

    this.setState(prevState => ({
      collaboratorEntity: copyDataObj,
      newWeblink: {
        type: "",
        url: ""
      }
    }));
  };

  saveBio = () => {
    this.setState({ pendingSaveBioResponse: true });
    this.props.putCollaborator(
      this.props.jwtToken,
      this.state.collaboratorEntity,
      this.responseSaveBioCallback
    );
  };

  responseSaveBioCallback = data => {
    this.setState({
      pendingSaveBioResponse: false,
      editBio: false,
      collaboratorEntity: data
    });
  };

  savePublic = () => {
    this.setState({ pendingSavePublicResponse: true });
    // console.log("PUBLIC ", this.state.collaboratorEntity);
    this.props.putCollaborator(
      this.props.jwtToken,
      this.state.collaboratorEntity,
      this.responseSavePublicCallback
    );
  };

  responseSavePublicCallback = data => {
    this.setState({
      pendingSavePublicResponse: false,
      editPublic: false,
      collaboratorEntity: data
    });
  };
  
  saveExpertise = () => {
    this.setState({ pendingSaveExpertiseResponse: true });
    this.props.putCollaborator(
      this.props.jwtToken,
      this.state.collaboratorEntity,
      this.responseSaveExpertiseCallback
    );
  };

  responseSaveExpertiseCallback = data => {
    this.setState({
      pendingSaveExpertiseResponse: false,
      editExpertise: false,
      collaboratorEntity: data
    });
  };

  saveEdu = () => {
    this.setState({ pendingSaveEduResponse: true });
    this.props.putCollaborator(
      this.props.jwtToken,
      this.state.collaboratorEntity,
      this.responseSaveEduCallback
    );
  };

  responseSaveEduCallback = data => {
    this.setState({
      pendingSaveEduResponse: false,
      editEdu: false,
      collaboratorEntity: data
    });
  };

  saveWeblinks = () => {
    this.setState({ pendingSaveWeblinkResponse: true });
    this.props.putCollaborator(
      this.props.jwtToken,
      this.state.collaboratorEntity,
      this.responseSaveWeblinkCallback
    );
  };

  responseSaveWeblinkCallback = data => {
    this.setState({
      pendingSaveWeblinkResponse: false,
      editWeblinks: false,
      collaboratorEntity: data
    });
  };

  saveSkills = () => {
    this.setState({ pendingSaveSkillsResponse: true });
    this.props.putCollaborator(
      this.props.jwtToken,
      this.state.collaboratorEntity,
      this.responseSaveSkillsCallback
    );
  };

  responseSaveSkillsCallback = data => {
    this.setState({
      pendingSaveSkillsResponse: false,
      editSkills: false,
      collaboratorEntity: data
    });
  };

  displayPublic = () => {
    const { classes, collaboratorData } = this.props;
    return (
      <Paper square className={classes.container}>
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <IconButtonComponent 
              type={"edit"}
              iconStyle={"iconAction iconRightCorner"}
              toolTip={true}
              title={"Edit Public"}
              link={() => {
                var newObject = deep_copy(collaboratorData);
                this.setState({
                    editPublic: true,
                    collaboratorEntity: newObject
                })
              }}
            />
          }
          title={<Typography className="my-profileEdit__card-header">Profile Status</Typography>}
        />
        <CardContent>
          <Typography component="p">
            {collaboratorData.hidden ? 
             <>
             <IconButtonComponent toolTip={true} type={"locked"} iconStyle={"iconDisabled iconPadRight"} title={"Profile Private"} />
             Profile Private
             </>
              : 
              <>
              <IconButtonComponent toolTip={true} type={"public"} iconStyle={"iconDefault iconPadRight"} title={"Profile Public"} />
              Profile Public
              </>
            } 
          </Typography>
        </CardContent>
      </Paper>
    );
  };

  editPublic = () => {
    const { classes } = this.props;
    const { pendingSavePublicResponse, collaboratorEntity } = this.state;
    return (
      <Paper square className={classes.container}>
        {pendingSavePublicResponse && (
          <LinearProgress
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary
            }}
          />
        )}
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <>
              <IconButtonComponent type={"close"} toolTip={true} title={"Close"} iconStyle={pendingSavePublicResponse ?  "iconDisabled iconPadRight" : "iconAction iconPadRight"} link={() => {
                  this.setState({ editPublic: false });
              }} />
              <button className="save_button" onClick={this.savePublic}>
              <IconButtonComponent type={"save"} toolTip={true} title={"Save"} iconStyle={pendingSavePublicResponse ? "iconDisabled iconPadRight" : "iconDefault iconPadRight"}/>
                Save
              </button>
            </>
          }
          title={
            <Typography className="my-profileEdit__card-header">
              {pendingSavePublicResponse ? "Saving..." : "Profile Status"}
            </Typography>
          }
          subheader={
            "Making your profile public will allow other SPRXS users to see your profile on the MarketPlace and request collaboration."
         }
        />
        <CardContent>
        <FormControlLabel
              style={{ marginLeft: "3%", marginTop: "-10px" }}
              control={
                <Switch
                  checked={!collaboratorEntity.hidden}
                  onChange={this.handleChangePublic}
                  value="CheckedB"
                  color="primary"
                />
              }
              label={
                <div>
                  {collaboratorEntity.hidden ? 
                   <>
                   <IconButtonComponent toolTip={true} type={"locked"} iconStyle={"iconDisabled iconPadRight"} title={"Profile Private"} />
                   <span>Your Profile is Private </span>
                   </>
                    :
                    <>
                    <IconButtonComponent toolTip={true} type={"public"} iconStyle={"iconDefault iconPadRight"} title={"Profile Seachable"} />
                    <span>Your Profile is Public </span>
                    </>
                  }
                </div>
              }
            />
        </CardContent>
      </Paper>
    );
  };

  displaySkills = () => {
    const { classes, collaboratorData } = this.props;
    return (
      <Paper square className={classes.container}>
        <CardHeader
          className="my-profileEdit__card-heading"
          action={
            <IconButtonComponent 
              type={"edit"}
              iconStyle={"iconAction iconRightCorner"}
              toolTip={true}
              title={"Edit Skills"}
              link={() => {
                var newObject = deep_copy(collaboratorData);
                this.setState({
                    editSkills: true,
                    collaboratorEntity: newObject
                })
              }}
            />
          }
          title={<Typography className="my-profileEdit__card-header">Skills / Resources</Typography>}
        />
        <CardContent>
          <List dense={true}>
            {collaboratorData && // check that there is some data
            collaboratorData.skills && // and that it contains a skills section
              collaboratorData.skills.map((item, key) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography className="my-profileEdit__card-skill">{item.skillName}</Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      {getRating(item.skillLevel)}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        </CardContent>
      </Paper>
    );
  };

  editSkills = () => {
    const { classes } = this.props;
    const { pendingSaveSkillsResponse, collaboratorEntity } = this.state;

    return (
      <Paper square className={classes.container}>
        {pendingSaveSkillsResponse && (
          <LinearProgress
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary
            }}
          />
        )}
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <>
              <IconButtonComponent type={"close"} toolTip={true} title={"Close"} iconStyle={pendingSaveSkillsResponse ?  "iconDisabled iconPadRight" : "iconAction iconPadRight"} link={() => {
                  this.setState({ editSkills: false });
              }} />
              <button className="save_button" onClick={this.saveSkills}>
              <IconButtonComponent type={"save"} toolTip={true} title={"Save"} iconStyle={pendingSaveSkillsResponse ? "iconDisabled iconPadRight" : "iconDefault iconPadRight"}/>
              Save
              </button>
            </>
          }
          title={
            <Typography className="my-profileEdit__card-header">
              {pendingSaveSkillsResponse ? "Saving..." : "Edit Skills"}
            </Typography>
          }
          subheader={
            "Published content will be visible to SPRXS users."
          }
        />
        <CardContent>
          <List dense={true}>
            {collaboratorEntity && // check that there is some data
            collaboratorEntity.skills && // and that it contains a skills section
              collaboratorEntity.skills.map((item, key) => {
                return (
                  <ListItem>
                    <TextField
                      id="skill-name"
                      label="Skill"
                      value={item.skillName}
                      onChange={this.handleChange(`${item.skillName}`, "name")}
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      disabled={pendingSaveSkillsResponse}
                    />

                    <TextField
                      id="outlined-select-experience"
                      disabled={pendingSaveSkillsResponse}
                      fullWidth
                      select
                      label="Experience"
                      className={classes.textFieldAdd}
                      value={item.skillLevel}
                      onChange={this.handleChange(`${item.skillName}`, "level")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="dense"
                      variant="outlined"
                    >
                      {experience.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Delete Skill"
                          onClick={() => {
                            this.onRemoveSkill(item.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            <Typography className="my-profileEdit__card-subheader">{"Add Skill"}</Typography>
            <ListItem>
              <TextField
                id="skill-name"
                error={this.state.editSkillsAddError}
                helperText={
                  this.state.editSkillsAddError ? "Duplicate entry!" : " "
                }
                label="Skill"
                value={this.state.newSkill.skillName}
                onChange={this.updateAddSkill("name")}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                disabled={pendingSaveSkillsResponse}
              />

              <TextField
                id="outlined-select-experience"
                disabled={pendingSaveSkillsResponse}
                helperText={" "}
                fullWidth
                select
                label="Experience"
                className={classes.textFieldAdd}
                value={this.state.newSkill.skillLevel}
                onChange={this.updateAddSkill("level")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {experience.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <ListItemSecondaryAction>
                <IconButton
                  className={classes.iconButton}
                  color="secondary"
                  aria-label="Delete"
                  onClick={this.handleAddSkill}
                  disabled={this.state.editSkillsAddError}
                >
                  <Add />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </Paper>
    );
  };

  displayBio = () => {
    const { classes, collaboratorData } = this.props;

    return (
      <Paper square className={classes.container}>
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <IconButtonComponent 
              type={"edit"}
              iconStyle={"iconAction iconRightCorner"}
              toolTip={true}
              title={"Edit Bio"}
              link={() => {
                var newObject = deep_copy(collaboratorData);
                this.setState({
                    editBio: true,
                    collaboratorEntity: newObject
                })
              }}
            />
          }
          title={<Typography className="my-profileEdit__card-header">Professional Bio</Typography>}
        />
        <CardContent>
          <Typography component="p">{collaboratorData.profileDesc}</Typography>
        </CardContent>
      </Paper>
    );
  };

  editBio = () => {
    const { classes } = this.props;
    const { pendingSaveBioResponse, collaboratorEntity } = this.state;
    return (
      <Paper square className={classes.container}>
        {pendingSaveBioResponse && (
          <LinearProgress
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary
            }}
          />
        )}
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <>
              <IconButtonComponent type={"close"} toolTip={true} title={"Close"} iconStyle={pendingSaveBioResponse ?  "iconDisabled iconPadRight" : "iconAction iconPadRight"} link={() => {
                  this.setState({ editBio: false });
              }} />
              <button className="save_button" onClick={this.saveBio}>
              <IconButtonComponent type={"save"} toolTip={true} title={"Save"} iconStyle={pendingSaveBioResponse ? "iconDisabled iconPadRight" : "iconDefault iconPadRight"} />
              Save
              </button>
            </>
          }
          title={
            <Typography className="my-profileEdit__card-header">
              {pendingSaveBioResponse ? "Saving..." : "Edit Bio"}
            </Typography>
          }
          subheader={
            "Published content will be visible to SPRXS users."
         }
        />
        <CardContent>
          <TextField
            id="edit-bio"
            fullWidth
            label="Bio"
            multiline
            rowsMax="10"
            value={collaboratorEntity.profileDesc}
            onChange={this.handleChangeBio}
            className={classes.textFieldBio}
            margin="normal"
            variant="outlined"
          />
        </CardContent>
      </Paper>
    );
  };

  displayEdu = () => {
    const { classes, collaboratorData } = this.props;

    return (
      <Paper square className={classes.container}>
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <IconButtonComponent 
              type={"edit"}
              iconStyle={"iconAction iconRightCorner"}
              toolTip={true}
              title={"Edit Education"}
              link={() => {
                var newObject = deep_copy(collaboratorData);
                this.setState({
                    editEdu: true,
                    collaboratorEntity: newObject
                })
              }}
            />
          }
          title={<Typography className="my-profileEdit__card-header">Education</Typography>}
        />
        <CardContent>
          <List dense={true}>
            {collaboratorData && // check that there is some data
            collaboratorData.educations && // and that it contains an educations section
              collaboratorData.educations.map((item, key) => {
                return (
                  <>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography className="my-profileEdit__card-header">{item.major}</Typography>
                        }
                      />
                      <div className={classes.rightAlign}>
                        <Typography variant="caption">
                          {item.schoolName}
                        </Typography>
                        <Typography variant="caption">
                          {item.country}
                        </Typography>
                        <Typography variant="caption">
                          {item.qualification}
                        </Typography>
                        <Typography variant="caption">{`Graduated: ${
                          item.year
                        }`}</Typography>
                      </div>
                    </ListItem>
                  </>
                );
              })}
            <Divider />
          </List>
        </CardContent>
      </Paper>
    );
  };

  editEdu = () => {
    const { classes } = this.props;
    const { pendingSaveEduResponse, collaboratorEntity } = this.state;

    return (
      <Paper square className={classes.container}>
        {pendingSaveEduResponse && (
          <LinearProgress
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary
            }}
          />
        )}
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <>
              <IconButtonComponent type={"close"} toolTip={true} title={"Close"} iconStyle={pendingSaveEduResponse ?  "iconDisabled iconPadRight" : "iconAction iconPadRight"} link={() => {
                  this.setState({ editEdu: false });
              }} />
              <button className="save_button" onClick={this.saveEdu}>
              <IconButtonComponent type={"save"} toolTip={true} title={"Save"} iconStyle={pendingSaveEduResponse ? "iconDisabled iconPadRight" : "iconDefault iconPadRight"}/>
              Save
              </button>
            </>
          }
          title={
            <Typography className="my-profileEdit__card-header">
              {pendingSaveEduResponse ? "Saving..." : "Edit Education"}
            </Typography>
          }
          subheader={
            "Published content will be visible to SPRXS users."
         }
        />
        <CardContent>
          <List dense={true}>
            {collaboratorEntity && // check that there is some data
            collaboratorEntity.educations && // and that it contains a skills section
              collaboratorEntity.educations.map((item, key) => {
                return (
                  <>
                    <ListItem>
                      <TextField
                        id="major-name"
                        fullWidth
                        label="Major / Subject"
                        value={item.major}
                        onChange={this.handleEduChange(
                          `${item.major}`,
                          "major"
                        )}
                        className={classes.textFieldAdd}
                        margin="dense"
                        variant="outlined"
                        disabled={pendingSaveEduResponse}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Delete"
                          onClick={() => {
                            this.onRemoveEducation(item.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <TextField
                        id="qualification"
                        fullWidth
                        label="Qualification"
                        value={item.qualification}
                        onChange={this.handleEduChange(
                          `${item.major}`,
                          "qualification"
                        )}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        disabled={pendingSaveEduResponse}
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        id="year"
                        fullWidth
                        label="Year"
                        value={item.year}
                        onChange={this.handleEduChange(`${item.major}`, "year")}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        disabled={pendingSaveEduResponse}
                      />
                      <TextField
                        id="school-name"
                        fullWidth
                        label="School Name"
                        value={item.schoolName}
                        onChange={this.handleEduChange(
                          `${item.major}`,
                          "schoolName"
                        )}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        disabled={pendingSaveEduResponse}
                      />
                      <TextField
                        id="outlined-select-experience"
                        disabled={pendingSaveEduResponse}
                        fullWidth
                        select
                        label="Country"
                        className={classes.textField}
                        value={item.country}
                        onChange={this.handleEduChange(
                          `${item.major}`,
                          "country"
                        )}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                        margin="dense"
                        variant="outlined"
                      >
                        {Object.keys(country_codes).map((key, i) => (
                          <MenuItem key={i} value={key}>
                            {country_codes[key]}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ListItem>
                    <Divider />
                  </>
                );
              })}
            <Typography className="my-profileEdit__card-header">{"Add Education"}</Typography>
            <ListItem>
              <TextField
                fullWidth
                id="add-major"
                error={this.state.editEduAddError}
                helperText={
                  this.state.editEduAddError ? "Duplicate entry!" : " "
                }
                label="Major / Subject"
                value={this.state.newEdu.major}
                onChange={this.updateAddEdu("major")}
                className={classes.textFieldAdd}
                margin="dense"
                variant="outlined"
                disabled={pendingSaveEduResponse}
              />

              <ListItemSecondaryAction>
                <IconButton
                  className={classes.iconButton}
                  color="secondary"
                  aria-label="Delete"
                  onClick={this.handleAddEdu}
                  disabled={this.state.editEduAddError}
                >
                  <Add />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <TextField
                id="year"
                fullWidth
                label="Year"
                value={this.state.newEdu.year}
                onChange={this.updateAddEdu("year")}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                disabled={pendingSaveEduResponse}
              />
              <TextField
                id="school-name"
                fullWidth
                label="School Name"
                value={this.state.newEdu.schoolName}
                onChange={this.updateAddEdu("schoolName")}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                disabled={pendingSaveEduResponse}
              />
              <TextField
                id="outlined-select-experience"
                disabled={pendingSaveEduResponse}
                fullWidth
                select
                label="Country"
                className={classes.textField}
                value={this.state.newEdu.country}
                onChange={this.updateAddEdu("country")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {Object.keys(country_codes).map((key, i) => (
                  <MenuItem key={i} value={key}>
                    {country_codes[key]}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>
          </List>
        </CardContent>
      </Paper>
    );
  };

  displayWeblinks = () => {
    const { classes, collaboratorData } = this.props;

    return (
      <Paper square className={classes.container}>
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <IconButtonComponent 
              type={"edit"}
              iconStyle={"iconAction iconRightCorner"}
              toolTip={true}
              title={"Edit Web Links"}
              link={() => {
                var newObject = deep_copy(collaboratorData);
                this.setState({
                    editWeblinks: true,
                    collaboratorEntity: newObject
                })
              }}
            />
          }
          title={<Typography className="my-profileEdit__card-header">Web Links</Typography>}
        />
        <CardContent>
          <List dense={true}>
            {collaboratorData && // check that there is some data
            collaboratorData.weblinks && // and that it contains an educations section
              collaboratorData.weblinks.map((item, key) => {
                return (
                  <>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography className="my-profileEdit__card-header">{item.type}</Typography>
                        }
                      />
                      <div
                        id={"permalink_section"}
                        className={classes.rightAlign}
                      >
                        <a href={`http://${item.url}`} target="blank">{`http://${item.url}`}</a>
                      </div>
                    </ListItem>
                  </>
                );
              })}
            <Divider />
          </List>
        </CardContent>
      </Paper>
    );
  };

  editWeblinks = () => {
    const { classes } = this.props;
    const {
      pendingSaveWeblinkResponse,
      collaboratorEntity,
      newWeblink
    } = this.state;

    return (
      <Paper square className={classes.container}>
        {pendingSaveWeblinkResponse && (
          <LinearProgress
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary
            }}
          />
        )}
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <>
              <IconButtonComponent type={"close"} toolTip={true} title={"Close"} iconStyle={pendingSaveWeblinkResponse ?  "iconDisabled iconPadRight" : "iconAction iconPadRight"} link={() => {
                  this.setState({ editWeblinks: false });
              }} />
              <button className="save_button" onClick={this.saveWeblinks}>
              <IconButtonComponent type={"save"} toolTip={true} title={"Save"} iconStyle={pendingSaveWeblinkResponse ? "iconDisabled iconPadRight" : "iconDefault iconPadRight"}/>
              Save
              </button>
            </>
          }
          title={
            <Typography className="my-profileEdit__card-header">
              {pendingSaveWeblinkResponse ? "Saving..." : "Edit Weblinks"}
            </Typography>
          }
          subheader={
            "Published content will be visible to SPRXS users."
         }
        />
        <CardContent>
          <List dense={true}>
            {collaboratorEntity && // check that there is some data
            collaboratorEntity.weblinks && // and that it contains a skills section
              collaboratorEntity.weblinks.map((item, key) => {
                return (
                  <>
                    <ListItem>
                      <TextField
                        fullWidth
                        id="add-weblink-type"
                        helperText={" "}
                        label="Short Description"
                        value={item.type}
                        onChange={this.handleWeblinkChange(
                          `${item.url}`,
                          "type"
                        )}
                        className={classes.textFieldAdd}
                        margin="dense"
                        variant="outlined"
                        disabled={pendingSaveWeblinkResponse}
                      />

                      <TextField
                        fullWidth
                        helperText={" "}
                        id="add-weblink"
                        label="url"
                        value={item.url}
                        onChange={this.handleWeblinkChange(
                          `${item.url}`,
                          "url"
                        )}
                        className={classes.textFieldAdd}
                        margin="dense"
                        variant="outlined"
                        disabled={pendingSaveWeblinkResponse}
                      />
                       <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Delete"
                          onClick={() => {
                            this.onRemoveWebLink(item.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                );
              })}
            <Typography className="my-profileEdit__card-subheader">{"Add Weblink"}</Typography>
            <ListItem>
              <TextField
                fullWidth
                id="add-weblink-type"
                error={this.state.editWeblinkAddError}
                helperText={" "}
                label="Short Description"
                value={this.state.newWeblink.type}
                onChange={this.updateAddWeblink("type")}
                className={classes.textFieldAdd}
                margin="dense"
                variant="outlined"
                disabled={pendingSaveWeblinkResponse}
              />

              <TextField
                fullWidth
                id="add-weblink"
                error={this.state.editWeblinkAddError}
                helperText={
                  this.state.editWeblinkAddError ? "Duplicate entry!" : " "
                }
                label="url"
                value={this.state.newWeblink.url}
                onChange={this.updateAddWeblink("url")}
                className={classes.textFieldAdd}
                margin="dense"
                variant="outlined"
                disabled={pendingSaveWeblinkResponse}
              />

              <ListItemSecondaryAction>
                <IconButton
                  className={classes.iconButton}
                  color="secondary"
                  aria-label="Delete"
                  onClick={this.handleAddWeblink}
                  disabled={this.state.editWeblinkAddError}
                >
                  <Add />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </Paper>
    );
  };

  displayExpertise = () => {
    const { classes, collaboratorData } = this.props;

    return (
      <Paper square className={classes.container}>
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <IconButtonComponent 
            type={"edit"}
            iconStyle={"iconAction iconRightCorner"}
            toolTip={true}
            title={"Edit Expertise"}
            link={() => {
              var newObject = deep_copy(collaboratorData);
              this.setState({
                  editExpertise: true,
                  collaboratorEntity: newObject
              })
            }}
          />
          }
          title={<Typography className="my-profileEdit__card-header">Expertise</Typography>}
        />
        <CardContent>
          <List dense={true}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography className="my-profileEdit__card-subheader" color="secondary">
                    Primary
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Typography className="my-profileEdit__card-skill">
                  {ExpertiseMap[collaboratorData.primaryExpertise]}
                </Typography>
                {collaboratorData.primaryExpertise === 7 /* Other */ && (
                  <Typography variant="p" className="my-profileEdit__card-skill">>
                    {collaboratorData.primaryOther}
                  </Typography>
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography className="my-profileEdit__card-subheader" color="secondary">
                    Secondary
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Typography className="my-profileEdit__card-skill">
                  {ExpertiseMap[collaboratorData.secondaryExpertise]}
                </Typography>
                {collaboratorData.secondaryExpertise === 7 /* Other */ && (
                  <Typography variant="p" className="my-profileEdit__card-skill">
                    {collaboratorData.secondaryOther}
                  </Typography>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </Paper>
    );
  };

  editExpertise = () => {
    const { classes } = this.props;
    const { pendingSaveExpertiseResponse, collaboratorEntity } = this.state;

    return (
      <Paper square className={classes.container}>
        {pendingSaveExpertiseResponse && (
          <LinearProgress
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary
            }}
          />
        )}
        <CardHeader
        className="my-profileEdit__card-heading"
          action={
            <>
              <IconButtonComponent type={"close"} toolTip={true} title={"Close"} iconStyle={pendingSaveExpertiseResponse ? "iconDisabled iconPadRight" : "iconAction iconPadRight"} link={() => {
                  this.setState({ editExpertise: false });
              }} />
              <button className="save_button" onClick={this.saveExpertise}>
              <IconButtonComponent type={"save"} toolTip={true} title={"Save"} iconStyle={pendingSaveExpertiseResponse ? "iconDisabled iconPadRight" : "iconDefault iconPadRight"}/>
              Save
              </button>
            </>
          }
          title={
            <Typography className="my-profileEdit__card-header">
              {pendingSaveExpertiseResponse ? "Saving..." : "Edit Expertise"}
            </Typography>
          }
          subheader={
            "Published content will be visible to SPRXS users."
         }
        />
        <CardContent>
          <List dense={true}>
            <ListItem>
              <TextField
                id="outlined-select-experience"
                disabled={pendingSaveExpertiseResponse}
                fullWidth
                select
                label="Primary Expertise"
                className={classes.textField}
                value={collaboratorEntity.primaryExpertise}
                onChange={this.updateExpertise("primary")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {primaryExpertise.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              {collaboratorEntity.primaryExpertise === 7 /* Other */ && (
                <TextField
                  fullWidth
                  id="add-primaryOther"
                  label="Please specify"
                  value={collaboratorEntity.primaryOther}
                  onChange={this.updateExpertise("primaryOther")}
                  className={classes.textFieldAdd}
                  margin="dense"
                  variant="outlined"
                  disabled={pendingSaveExpertiseResponse}
                />
              )}
            </ListItem>
            <ListItem>
              <TextField
                id="outlined-select-secondary-experience"
                disabled={pendingSaveExpertiseResponse}
                fullWidth
                select
                label="Secondary Expertise"
                className={classes.textField}
                value={collaboratorEntity.secondaryExpertise}
                onChange={this.updateExpertise("secondary")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {primaryExpertise.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              {collaboratorEntity.secondaryExpertise === 7 /* Other */ && (
                <TextField
                  fullWidth
                  id="add-secondaryOther"
                  label="Please specify"
                  value={collaboratorEntity.secondaryOther}
                  onChange={this.updateExpertise("secondaryOther")}
                  className={classes.textFieldAdd}
                  margin="dense"
                  variant="outlined"
                  disabled={pendingSaveExpertiseResponse}
                />
              )}
            </ListItem>
          </List>
        </CardContent>
      </Paper>
    );
  };

  updateExpertise = type => event => {
    const { collaboratorEntity } = this.state;
    let copyOfcollaboratorEntity = { ...collaboratorEntity };

    switch (type) {
      case "primary":
        /* update the primary value */
        copyOfcollaboratorEntity.primaryExpertise = event.target.value;
        break;
      case "secondary":
        /* update the primary value */
        copyOfcollaboratorEntity.secondaryExpertise = event.target.value;
        break;
      case "primaryOther":
        copyOfcollaboratorEntity.primaryOther = event.target.value;
        break;
      case "secondaryOther":
        copyOfcollaboratorEntity.secondaryOther = event.target.value;
        break;
      default:
        break;
    }

    this.setState({ collaboratorEntity: copyOfcollaboratorEntity });
  };

  render() {
    const {
      isLoading,
      editSkills,
      editBio,
      editEdu,
      editWeblinks,
      editExpertise,
      editPublic
    } = this.state;
    const { classes, collaboratorData, cookies } = this.props;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const { firstname, surname } = collaboratorData;

    return !isLoading ? (
      <div className={"my-profileEdit"}>
        {editPublic === true
          ? this.editPublic()
          : this.displayPublic()}
        {editExpertise === true
          ? this.editExpertise()
          : this.displayExpertise()}
        {editSkills === true ? this.editSkills() : this.displaySkills()}
        {editWeblinks === true ? this.editWeblinks() : this.displayWeblinks()}
        {editBio === true ? this.editBio() : this.displayBio()}
        {editEdu === true ? this.editEdu() : this.displayEdu()}
      </div>
    ) : (
      <div>Loading your profile...</div>
    );
  }
}

const mapStateToProps = state => ({
  collaboratorData: state.collaboratorData,
  profileID: state.profileID
});

const mapDispatchToProps = dispatch => ({
  getCollaborator: (jwtToken, profileID) => {
    getCollaborator(jwtToken, profileID, dispatch);
  },
  putCollaborator: (jwtToken, payload, callback) => {
    putCollaborator(jwtToken, payload, callback, dispatch);
  }
});

export default withCookies(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MyProfileEdit))
);
