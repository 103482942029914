import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  MoreRounded,
  MenuRounded,
  NotificationImportantRounded,
  MailRounded,
  SearchRounded,
  AccountCircle,
  AddCircle,
  ViewAgenda,
  ViewList,
  Brightness5,
  Brightness6,
  SwapCalls
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
// import green from "@material-ui/core/colors/green";
import { connect } from "react-redux";
import logo from "../../sprxsIcon.svg";
import {
  SHOW_SEARCH_MODAL,
  logOut,
  SHOW_CREATE_IDEA_MODAL,
  storeSearchResults,
  toggleTheme,
  storeGlobalSearchResults,
  storeGlobalSearchCvResults,
  storeGlobalSkillResults,
  toggleCardView,
  searchCollaborators
} from "../../store/actions/actions";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import axios from "axios";
import { hostApi, IDEATOR } from "../../constants";
import SPRXSDrawer from "../Drawer/Drawer";

const styles = theme => ({
  root: {
    width: "100%",
    boxSizing: "border-box",
    overflow: "auto"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    overflow: "auto"
  },
  menu: "mui-fixed",
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  buttonProgress: {
    color: "#b23820",
    position: "absolute",
    top: "50%",
    left: "90%",
    marginTop: -12,
    marginLeft: -12
  }
});

// const cvHostApi =
//   "http://fifth9-prototype-server1604.westeurope.cloudapp.azure.com:39457/searchIdea_covid19";
class NavBar extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    searchString: "",
    skillString: "",
    showCardIcon: true,
    loading: false,
    openDrawer: false
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };
  handleLogout = () => {
    this.props.logOut(this.props.cookies);
    this.props.history.push("/");
  };

  // KN 2019.01.08
  handleHome = () => {
    //console.log("Navbar.js Line 166 - handleHome");
    this.props.history.push("/mySPRXS");
  };

  // KN 2019.01.27
  handleDashboard = () => {
    //console.log("Navbar.js Line 166 - handleHome");
    this.props.history.push("/dashboard");
  };

  // KN 2019.01.08
  handleSearch = () => {
    //console.log("Navbar.js Line 166 - handleSearch");
    this.props.history.push("/publicIdeas");
  };

  handleSearchCovid = () => {
    this.props.history.push("/searchIdea_covid19");
  };

  handleMarketPlace = () => {
    this.props.history.push("/marketPlace");
  };

  // KN 2019.01.08
  searchResults = () => {
    //console.log("Navbar.js Line 172 - searchResults");
    this.props.history.push("/searchResults");
  };

  handleCreateIdea = () => {
    //console.log("handleCreateIdea");
    this.props.showCreateIdeaModal();
  };

  handleSearchString = event => {
    console.log("handleSearchString", event.target.value);
    this.setState({ searchString: event.target.value });
  };

  handleSearchSkillsString = event => {
    //console.log("handleSearchSkillsString", event.target.value);
    this.setState({ skillString: event.target.value });
  };

  handleKeyPress = event => {
    const { cookies, title, location, token } = this.props;

    const profileId = cookies.get("collab_id");

    //console.log(this.props);

    if (event.key === "Enter") {
      //console.log("enter key pressed");
      this.setState({ loading: true });

      switch (location.pathname) {
        case "/publicIdeas":
          axios
            .post(
              `${hostApi}/searchIdea`,
              {
                profile_id: profileId,
                idea_uniqueid: "",
                idea_name: this.state.searchString || "",
                idea_description: ""
              },
              {
                headers: {
                  Authorization: `Bearer ${this.props.cookies.get(
                    "bearerToken"
                  )}`
                }
              }
            )
            .then(response => {
              //console.log("axios response1", response.data);
              this.props.storeGlobalSearchResults(response.data);
              /* this.props.history.push(`/searchresults`); */
              this.setState({ loading: false });
            });
          break;
        case "/searchIdea_covid19":
          axios
            .post(
              `${hostApi}/searchIdea_covid19`,
              {
                profile_id: profileId,
                idea_uniqueid: "",
                idea_name: this.state.searchString || "",
                idea_description: ""
              },
              {
                headers: {
                  Authorization: `Bearer ${this.props.cookies.get(
                    "bearerToken"
                  )}`
                }
              }
            )
            .then(response => {
              //console.log("axios response1", response.data);
              this.props.storeGlobalSearchCvResults(response.data);
              /* this.props.history.push(`/searchresults`); */
              this.setState({ loading: false });
            });
          break;
        default:
          axios
            .post(
              `${hostApi}/myIdeas`,
              {
                lkp_email:
                  this.props.emailAddress || cookies.get("emailAddress"),
                idea_name: this.state.searchString || "",
                idea_description: ""
              },
              {
                headers: {
                  Authorization: `Bearer ${this.props.cookies.get(
                    "bearerToken"
                  )}`
                }
              }
            )
            .then(response => {
              //console.log("axios response1", response.data);
              this.props.storeSearchResults(response.data);
              /* this.props.history.push(`/searchresults`); */
              this.setState({ loading: false });
            });
          break;
      }
    }
  };

  handleKeyPressSkill = event => {
    const { cookies, title, searchCollaborators } = this.props;

    //console.log(this.props);

    if (event.key === "Enter") {
      //console.log("enter key pressed");
      if (this.state.skillString === "") {
        window.location.reload(); //FIXME: this is a workaround because the search API cannot handle empty string.
      } else {
        this.setState({ loading: true });

        searchCollaborators(
          cookies.get("bearerToken"),
          this.state.skillString,
          response => {
            //console.log("search response", response);
            this.setState({ loading: false });
          }
        );
      }
    }
  };

  handleChangeView = () => {
    //console.log("handleChangeView");
    this.setState({ showCardIcon: !this.state.showCardIcon });
    this.props.changeView();
  };

  handleThemeToggle = () => {
    //console.log("Theme toggle");
    this.props.toggleTheme();
  };

  toggleDrawer = () => {
    this.setState({ openDrawer: !this.openDrawer });
  };

  closeDrawer = () => {
    this.setState({ openDrawer: false });
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl, loading, openDrawer } = this.state;
    const {
      classes,
      userType,
      isAuthenticated,
      search,
      ideaView,
      cookies,
      emailAddress,
      location: { pathname }
    } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const _userType = userType || cookies.get("user_type");

    const renderMenu = (
      <Menu
        className={classes.menu}
        style={{
          position: "fixed",
          right: "10px"
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleClose}>Profile</MenuItem>
        <MenuItem onClick={this.handleClose}>My account</MenuItem>
      </Menu>
    );

    /* Removed / Not working // KN 2019.01.08
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem><IconButton onClick={this.handleCreateIdea()} color="inherit">            <AddCircle /></IconButton><Typography variant="p">Create Idea</Typography></MenuItem>
        <MenuItem><IconButton onClick={this.handleSearch()} color="inherit">                <AddCircle /></IconButton><Typography variant="p">Search</Typography></MenuItem>
      </Menu>
    ); */

    const homeLinks = [
      "/",
      "/v2",
      "/media",
      "/faq",
      "/technical",
      "/login",
      "/signup"
    ];

    // console.log("====================================");
    // console.log("pathname", pathname);
    // console.log("====================================");

    return (
      <div className={classes.root}>
        <SPRXSDrawer
          open={openDrawer}
          onClose={this.closeDrawer}
          logout={this.handleLogout}
          home={() => {
            this.props.history.push("/");
          }}
          handleSearch={this.handleSearch}
          handleSearchCovid={this.handleSearchCovid}
          handleMarketPlace={this.handleMarketPlace}
          searchResults={this.searchResults}
          handleHome={this.handleHome}
          handleDashboard={this.handleDashboard}
        />
        <AppBar position="fixed" className="mui-fixed">
          <Toolbar>
            <img
              onClick={() => {
                this.props.history.push("/dashboard");
              }}
              src={logo}
              alt="logo"
              width={50}
              style={{ marginRight: "20px", cursor: "pointer" }}
            />

            <Typography
              onClick={() => {
                this.props.history.push("/dashboard");
              }}
              className={classes.title}
              variant="title"
              color="inherit"
              noWrap
              style={{ cursor: "pointer" }}
            >
              {this.props.title}
            </Typography>

            {!homeLinks.includes(pathname) &&
              (pathname === "/searchResults" ||
                pathname === "/publicIdeas" ||
                pathname === "searchIdea_covid19") && (
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchRounded />
                  </div>
                  <Input
                    placeholder="Search idea name…"
                    onChange={this.handleSearchString}
                    onKeyPress={this.handleKeyPress}
                    disableUnderline
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                  />
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              )}

            {!homeLinks.includes(pathname) && pathname === "/marketplace" && (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchRounded />
                </div>
                <Input
                  placeholder="Search skills…"
                  onChange={this.handleSearchSkillsString}
                  onKeyPress={this.handleKeyPressSkill}
                  disableUnderline
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                />
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            )}
            {!homeLinks.includes(pathname) &&
              pathname === "/searchIdea_covid19" && (
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchRounded />
                  </div>
                  <Input
                    placeholder="Search CV-19 name…"
                    onChange={this.handleSearchString}
                    onKeyPress={this.handleKeyPress}
                    disableUnderline
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                  />
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              )}

            <div className={classes.grow} />
            {homeLinks.includes(pathname) && (
              <div className={classes.sectionDesktop}>
                {/* <Button
                  onClick={() => { this.props.history.push("/media"); }}
                  color="inherit"
                >MEDIA
                </Button> */}
                <Button
                  onClick={() => {
                    this.props.history.push("/faq");
                  }}
                  color="inherit"
                >
                  FAQ
                </Button>
                <Button
                  onClick={() => {
                    this.props.history.push("/technical");
                  }}
                  color="inherit"
                >
                  TECHNICAL
                </Button>
                <Button
                  onClick={() => {
                    this.props.history.push("/login");
                  }}
                  color="inherit"
                >
                  SIGN IN
                </Button>
                <Button
                  onClick={() => {
                    this.props.history.push("/signup");
                  }}
                  color="inherit"
                >
                  REGISTER
                </Button>
              </div>
            )}

            {!homeLinks.includes(pathname) && (
              <div className={classes.sectionDesktop}>
                <Button
                  onClick={() => {
                    this.props.history.push("/dashboard");
                  }}
                  color="inherit"
                >
                  Dashboard
                </Button>
                <Button
                  onClick={() => {
                    this.handleSearchCovid();
                  }}
                  color="inherit"
                >
                  Search Covid-19 Ideas
                </Button>
                <Button
                  onClick={() => {
                    this.handleSearch();
                  }}
                  color="inherit"
                >
                  Search Ideas
                </Button>

                <Button
                  onClick={() => {
                    this.props.history.push("/marketplace");
                  }}
                  color="inherit"
                >
                  Marketplace
                </Button>

                {pathname === "/searchResults" && (
                  <Tooltip title={"Change View"}>
                    <IconButton onClick={this.handleChangeView} color="inherit">
                      <SwapCalls />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}

            {!homeLinks.includes(pathname) && (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.toggleDrawer}
              >
                <MenuRounded />
              </IconButton>
            )}

            {/* KN 2019.01.08. Removed / Not working
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <MoreRounded />
              </IconButton>
            </div>
            */}
          </Toolbar>
        </AppBar>
        {renderMenu}
        {/*renderMobileMenu*/}
      </div>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated,
    emailAddress: state.emailAddress,
    themeType: state.themeType,
    userType: state.userType,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCardView: () => dispatch(toggleCardView()),
    storeSearchResults: payload => dispatch(storeSearchResults(payload)),
    storeGlobalSearchResults: payload =>
      dispatch(storeGlobalSearchResults(payload)),
    storeGlobalSearchCvResults: payload =>
      dispatch(storeGlobalSearchCvResults(payload)),
    storeGlobalSkillResults: payload =>
      dispatch(storeGlobalSkillResults(payload)),
    showSearchModal: () => dispatch({ type: SHOW_SEARCH_MODAL }),
    logOut: cookies => {
      logOut(cookies, dispatch);
    },
    toggleTheme: () => dispatch(toggleTheme()),
    showCreateIdeaModal: () => dispatch({ type: SHOW_CREATE_IDEA_MODAL }),
    searchCollaborators: (jwtToken, searchString, callback) => {
      searchCollaborators(jwtToken, searchString, callback, dispatch);
    }
  };
};

export default withRouter(
  withCookies(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(styles, { withTheme: true })(NavBar))
  )
);
