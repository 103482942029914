import React, { Component } from "react";
import * as d3 from "d3";

class InfoCircle extends Component {
  state = {
    width: 100,
    height: 100,
    // radius: this.getRadius(100),
    arc: this.getArc(100),
    pie: this.getPie(),
    color: this.getColor(),
    doughnutWidth: 24
  };

  componentDidMount() {
    const { color, pie } = this.state;
    const width = this.props.width || this.state.width;
    const height = this.props.height || this.state.height;
    const doughnutWidth = this.props.doughnutWidth || this.state.doughnutWidth;
    const data = this.props.data || data;
    const arc = this.getArc(width, doughnutWidth);

    const svg = d3
      .select(this.refs["put-svg-here"])
      .append("svg")
      .attr("width", width)
      .attr("height", height)

      .attr("text-anchor", "middle")
      .style("max-width", "100%")
      .style("font", "12px sans-serif");

    const arcs = pie(data);

    const g = svg
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);
    g.selectAll("path")
      .data(arcs)
      // .data(pie(data.map(d => ({name: d, value: d}))))
      .enter()
      .append("path")
      .attr("fill", (d, i) => color(i))
      .attr("d", arc)

      // .append("title")
      // .text((d, i) => `${i}`)

      .on("click", (d, i) => this.handleClick(i));

    const text = g
      .selectAll("text")
      .data(arcs)
      .enter()
      .append("text")
      .attr("transform", d => `translate(${arc.centroid(d)})`)
      .attr("dy", "0.35em");

    text
      .append("tspan")
      .attr("x", 0)
      .attr("y", "0")
      .style("font-weight", "bold")
      .style("font-size", "1rem")
      .style("fill", "white")
      .style("pointer-events", "none")
      .text(({ value }) => value);
  }

  getColor() {
    return d3.scaleOrdinal(d3.schemeCategory10);
  }

  getPie() {
    return d3.pie().padAngle(0.02);
  }

  getArc(number, width = 20) {
    const r = this.getRadius(number);
    return d3
      .arc()
      .innerRadius(r - width)
      .outerRadius(r);
  }

  getRadius(number) {
    return number / 2 - 10;
  }

  handleClick = i => {
    if (this.props.handleClick) {
      this.props.handleClick(i);
    }
  };

  render() {
    return (
      <div>
        <div ref="put-svg-here" />
      </div>
    );
  }
}

export default InfoCircle;
